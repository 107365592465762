import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Divider, ListItemText, Menu, MenuItem, } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import TreeMenuSub from "./TreeMenuSub";

const TreeMenu = ({ options, anchorEl, openPop, setOpenPop, handleClick }) => {

    const [openCollapse, setOpenCollapse] = useState({});

    const [treeItem, setTreeItem] = useState([]);
    const [stAnchor, setSTAnchor] = useState(null);
    const [stOpen, setSTOpen] = useState(false);

    function handleItemClick(name,) {
        handleClick && handleClick(name)
    }

    function handleOpenSubMenu(e, item) {
        setSTAnchor(e.currentTarget)
        setTreeItem(item)
        setSTOpen(true)
    }
    useEffect(() => { return () => { setOpenCollapse({}) } }, [])

    return (
        <>
            <Menu anchorEl={anchorEl} open={openPop} onClose={() => setOpenPop(false)}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}>
                {options.map((item, index) => (
                    <div key={index}>
                        <MenuItem
                            sx={{ minWidth: 180, py: 0 }}
                            name={item.name}
                            onClick={(e) =>
                                item?.items
                                    ? handleOpenSubMenu(e, item)
                                    : handleItemClick(item.name, e)
                            }
                        >
                            <ListItemText>{item.label}</ListItemText>
                            {item.items &&
                                (openCollapse[index] ? <ExpandLess /> : <ExpandMore />)}
                        </MenuItem>

                        {(options?.length - 1 > index && item !== false) ? <Divider /> : <></>}
                    </div>
                ))}
            </Menu>
            {stOpen && <TreeMenuSub item={treeItem} anchorEl={stAnchor} openPop={stOpen} setOpenPop={() => setSTOpen(false)} handleClick={handleClick} />}
        </>
    );
}

export default TreeMenu;