import { Navigate, useRoutes } from "react-router-dom";
import ProvidedLimits from "./features/master-data/provided-limits/ProvidedLimits";
import TestSets from "./features/master-data/test-sets/TestSets";
import TestItems from "./features/master-data/test-items/TestItems";
import SearchRegistration from "./features/quick-details/registrations/SearchRegistration";
import AutoEvaluation from "./features/trays/04-auto-evaluation/AutoEvaluation";
import Evaluation from "./features/trays/05-evaluation/Evaluation";
import CreateTray from "./features/trays/create-tray/CreateTray";
import MissedSamples from "./features/reports/missed-samples/MissedSamples";
import Registration from "./features/trays/01-registration/Registration";
import QualityCheck from "./features/trays/quality-check/QualityCheck";
import SelectImage from "./components/SelectImage";
import Chart from "./features/charts/Chart";
import FluidWatch from "./features/master-data/fluids/FluidWatch";
import Review from "./features/trays/review/Review";
import EditContacts from "./forms/EditContacts";
import AddCriticalLogs from "./features/reports/critical-logs/add-critical-logs/AddCriticalLogs";
import SearchContact from "./features/quick-details/SearchContact";
import Fluids from "./features/master-data/fluids/Fluids";
import CustomerParents from "./features/customer/Parents";
import UnitModels from "./features/master-data/unit-models/UnitModels";
import ComponentModels from "./features/master-data/components-model/ComponentModels";
import SiteSets from "./features/master-data/sites-set/SiteSets";
import UsersOverview from "./features/management/UsersOverview";
import AuditLogs from "./features/reports/audit-logs/AuditLogs";
import Laboratories from "./features/management/Laboratories";
import Products from "./features/management/Products";
import AutoEvaluationComments from "./features/management/AutoEvaluationComments";
import UnitHourHistory from "./features/quick-details/unit-hour-history/UnitHourHistory";
import RecentUnitResults from "./features/quick-details/recent-unit-result/RecentUnitResults";
import PresetComments from "./features/management/preset-comments/PresetComments";
import Messages from "./features/management/Messages";
import CustomerSites from "./features/management/CustomerSites";

import QuickMergeFluid from "./features/master-data/quick-merge-fluid/QuickMergeFluid";
import QuickMergeModel from "./features/master-data/quick-merge-model/QuickMergeModel";
import QuickMoveSites from "./features/master-data/quick-move-sites/QuickMoveSites";
import QuickMergeSites from "./features/master-data/quick-merge-sites/QuickMergeSites";
import Cleanup from "./features/dashboards/cleanup/Cleanup";
import IkowaFeedback from "./features/dashboards/ikowa-feedback/IkowaFeedback";
import RerunSamples from "./features/reports/rerun-samples/RerunSamples";
import HoldManagement from "./features/management/HoldManagement";
import CriticalLogs from "./features/reports/critical-logs/CriticalLogs";
import AccessPermissionOverview from "./features/reports/critical-logs/access-permission-overview/AccessPermissionOverview";
import InsiteMachineSearch from "./features/master-data/unit-models/InsiteMachineSearch";
import InsiteModelSearch from "./features/master-data/unit-models/InsiteModelSearch";
import MachineTemplates from "./features/master-data/machine-templates/MachineTemplates";
import GroupEmailDomains from "./features/management/GroupEmailDomains";
import MachineNotifications from "./features/management/MachineNotifications";
import ApprovalToRelease from "./features/trays/approval-to-release/ApprovalToRelease";
import TrayDashboard from "./features/dashboards/tray/TrayDashboard";
import EmailsAndSamplesReports from "./features/quick-details/manage/email-sample-report/EmailsAndSamplesReports";
import QuickDetails from "./features/details-old/QuickDetails";
import EditTray from "./forms/EditTray";
import SpecialReport from "./forms/SpecialReport";
import SpecialReportTemplates from "./features/reports/special-reports/SpecialReportTemplates";
import SpecialReportTemplatesTypes from "./features/reports/special-reports/SpecialReportTemplatesTypes";
import ReportToMail from "./features/reports/report-to-mail/ReportToMail";
import FluidNotifications from "./features/management/Notifications/FluidNotifications";
import ProductNotifications from "./features/management/Notifications/ProductNotifications";
import Hold from "./features/trays/laboratory/Hold";
import ManagementReport from "./features/management/ManagementReport";
import EditBillingRates from "./features/management/EditBillingRates";
import GlobalSettings from "./features/management/GlobalSettings";
import WebsiteNews from "./features/management/WebsiteNews/WebsiteNews";
import PrimaryContactOverview from "./features/management/primary-contact-overview/PrimaryContactOverview";
import ContactExports from "./features/reports/critical-logs/contact-permission-overview/ContactExports";
import SampleReportsOverview from "./features/management/sample-report-overview/SampleReportsOverview";
import SubscriptionOverview from "./features/quick-details/subscription/SubscriptionOverview";
import EditSample from "./forms/EditSample";
import EditCustomer from "./forms/EditCustomer";
import EditSite from "./forms/EditSite";
import EditUnit from "./forms/EditUnit";
import EditComponent from "./forms/EditComponent";
import RecentComponentResults from "./features/quick-details/recent-component-result/RecentComponentResults";
import ManageSample from "./features/quick-details/manage/manage-sample/ManageSample";
import ManageComponent from "./features/quick-details/manage/manage-component/ManageComponent";
import ManageUnit from "./features/quick-details/manage/manage-unit/ManageUnit";
import ManageSite from "./features/quick-details/manage/manage-site/ManageSite";
import ManageCustomer from "./features/quick-details/manage/manage-customer/ManageCustomer";
import Laboratory2 from "./features/trays/laboratory/Laboratory2";
import RerunToLaboratory from "./features/trays/quality-check/RerunToLaboratory";
import SearchFluid from "./features/master-data/fluids/SearchFluid";
import NewContacts from "./forms/NewContacts";
import EditEvent from "./features/events/EditEvent";
import ViewEvents from "./features/events/ViewEvents";
import SubscriptionReports from "./components/SubscriptionReports";
import UnitsRecentSamples from "./features/quick-details/recent-samples/UnitsRecentSamples";
import ComponentsRecentSamples from "./features/quick-details/recent-samples/ComponentsRecentSamples";
import SearchGeneral from "./features/details-old/SearchGeneral";
import Paperless from "./features/trays/paperless/Paperless";
import Paperless2 from "./features/trays/paperless2/Paperless2";
import EditRegistration from "./features/quick-details/registrations/ER/EditRegistration";
import ContactAccessPermission from "./features/reports/critical-logs/contact-permission-overview/ContactAccessPermission";
import ContactPrimary from "./features/reports/critical-logs/contact-permission-overview/ContactPrimary";
import ApplyCMSRole from "./features/management/ApplyCMSRole";
import MatchMachinesInsite from "./features/management/MatchMachinesInsite";
import KomatsuContacts from "./features/management/KomatsuContacts";
import EditUnitModel from "./forms/EditUnitModel";
import AutoEvaluationDashboard from "./features/dashboards/autoevaluation-dash/AutoEvaluationDashboard";
import ImportHistory from "./features/master-data/import-history/ImportHistory";

export default function AppRouter({ user }) {

  const adminRoutes = [
    { path: "management/usersOverview", element: <UsersOverview /> },
    { path: "reports/audit-Log", element: <AuditLogs /> },
    { path: "management/group-email-domains", element: <GroupEmailDomains />, },
    { path: "management/auto-evaluation-comments", element: <AutoEvaluationComments />, },
    { path: "management/laboratories", element: <Laboratories /> },
    { path: "management/products", element: <Products /> },
    { path: "management/management-report", element: <ManagementReport /> },
    { path: "management/edit-billing-rates", element: <EditBillingRates /> },
    { path: "management/global-settings", element: <GlobalSettings /> },
    { path: "management/website-news", element: <WebsiteNews /> },

    { path: "admin/komatsu-contacts", element: <KomatsuContacts /> },
    { path: "admin/match-machines-insite", element: <MatchMachinesInsite /> },
    { path: "admin/apply-cms-role", element: <ApplyCMSRole /> },

  ];

  const nonAdminRoutes = [

    { exact: true, path: "/", element: <TrayDashboard /> },
    {
      path: "manage-sample",
      element: <ManageSample />,
    },
    {
      path: "manage-component",
      element: <ManageComponent />,
    },
    {
      path: "manage-unit",
      element: <ManageUnit />,
    },
    {
      path: "manage-site",
      element: <ManageSite />,
    },
    {
      path: "manage-customer",
      element: <ManageCustomer />,
    },
    {
      path: "recent-unit-results",
      element: <RecentUnitResults />,
    },
    {
      path: "recent-component-results",
      element: <RecentComponentResults />,
    },
    {
      path: "add-critical-logs",
      element: <AddCriticalLogs />,
    },
    {
      path: "unit-hour-history",
      element: <UnitHourHistory />,
    },
    {
      path: "contact-primary-mapping",
      element: <ContactPrimary />,
    },
    {
      path: "primary-contacts-overview",
      element: <PrimaryContactOverview />,
    },
    {
      path: "export-contacts-overview",
      element: <ContactExports />,
    },
    {
      path: "report-contacts",
      element: <SampleReportsOverview />,
    },
    {
      path: "subscription-overview",
      element: <SubscriptionOverview />,
    },
    {
      path: "management/preset-comments",
      element: <PresetComments />,
    },
    {
      path: "management/messages",
      element: <Messages />,
    },
    {
      path: "management/machine-notifications",
      element: <MachineNotifications />,
    },
    {
      path: "management/customerSites",
      element: <CustomerSites />,
    },
    {
      path: "settings/fluid-type-notifications",
      element: <FluidNotifications />,
    },
    {
      path: "settings/product-notifications",
      element: <ProductNotifications />,
    },
    {
      path: "management/email-and-sample-reports",
      element: <EmailsAndSamplesReports />,
    },
    { path: "management/add-contact", element: <NewContacts /> },
    { path: "management/edit-contact", element: <EditContacts /> },

    { path: "return-to-lab", element: <RerunToLaboratory /> },
    { path: "search-fluid", element: <SearchFluid /> },
    { path: "search-general", element: <SearchGeneral /> },

    { path: "reports-by-date", element: <SubscriptionReports /> },

    { path: "special-unit-report", element: <SpecialReport /> },
    { path: "special-component-report", element: <SpecialReport /> },
    { path: "view-event", element: <ViewEvents /> },
    { path: "edit-event", element: <EditEvent /> },
    { path: "add-event", element: <EditEvent /> },

    { path: "units-no-recent-samples", element: <UnitsRecentSamples /> },
    { path: "components-no-recent-samples", element: <ComponentsRecentSamples /> },

    { path: "master-data/provided-limits", element: <ProvidedLimits /> },
    { path: "master-data/test-set", element: <TestSets /> },
    { path: "master-data/test-items", element: <TestItems /> },
    { path: "registrations/search", element: <SearchRegistration />, },
    { path: "trays/create", element: <CreateTray /> },
    { path: "trays/registration", element: <Registration /> },
    { path: "trays/laboratory", element: <Laboratory2 /> },
    { path: "trays/quality-check", element: <QualityCheck /> },
    { path: "trays/auto-evaluation", element: <AutoEvaluation /> },
    { path: "trays/evaluation", element: <Evaluation /> },
    { path: "trays/approval", element: <ApprovalToRelease /> },
    { path: "trays/review", element: <Review /> },
    { path: "details/quick", element: <QuickDetails /> },
    { path: "dashboards/tray", element: <TrayDashboard /> },
    { path: "missed-samples", element: <MissedSamples /> },
    { path: "chart", element: <Chart /> },
    { path: "master-data/fluids", element: <Fluids /> },
    { path: "master-data/quick-merge-fluid", element: <QuickMergeFluid /> },
    { path: "master-data/quick-merge-model", element: <QuickMergeModel /> },
    { path: "master-data/quick-move-sites", element: <QuickMoveSites /> },
    { path: "master-data/quick-merge-sites", element: <QuickMergeSites /> },
    { path: "master-data/import-history", element: <ImportHistory /> },

    { path: "reports/critical-logs", element: <CriticalLogs /> },

    // { path: "reports/customer-feedback", element: <CustomerFeedback /> },
    { path: "scan-bottle", element: <Paperless /> },
    { path: "create-trays-qrcode", element: <Paperless2 /> },

    { path: "test/chart", element: <Chart /> },
    { path: "images", element: <SelectImage />, },
    {
      path: "trays/edit-registration",
      element: <EditRegistration />,
    },

    {
      path: "edit-customer",
      element: <EditCustomer />,
    },
    {
      path: "add-new-customer",
      element: <EditCustomer />,
    },
    {
      path: "edit-site",
      element: <EditSite />,
    },
    {
      path: "add-new-site",
      element: <EditSite />,
    },
    {
      path: "edit-unit",
      element: <EditUnit />,
    },
    {
      path: "add-new-unit",
      element: <EditUnit />,
    },
    {
      path: "edit-component",
      element: <EditComponent />,
    },
    {
      path: "add-new-component",
      element: <EditComponent />,
    },

    {
      path: "trays/edit-sample",
      element: <EditSample />,
    },
    {
      path: "trays/edit-tray",
      element: <EditTray />,
    },
    {
      path: "master-data/FluidWatch",
      element: <FluidWatch fluidId={null} isShowTitle={true} />,
    },
    {
      path: "customer/Parents",
      element: <CustomerParents />,
    },
    {
      path: "management/search-contact",
      element: <SearchContact />,
    },
    {
      path: "dashboards/auto-evaluation",
      element: <AutoEvaluationDashboard />,
    },
    {
      path: "dashboards/ikowa-feedback",
      element: <IkowaFeedback />,
    },
    {
      path: "dashboards/Cleanup",
      element: <Cleanup />,
    },
    {
      path: "access-permission-overview",
      element: <AccessPermissionOverview />,
    },
    {
      path: "contact-access-permission",
      element: <ContactAccessPermission />,
    },
    {
      path: "master-data/unit-models",
      element: <UnitModels />,
    },
    {
      path: "master-data/component-models",
      element: <ComponentModels />,
    },
    {
      path: "master-data/machine-templates",
      element: <MachineTemplates />,
    },
    {
      path: "master-data/view-komatsu-machines",
      element: <InsiteMachineSearch />,
    },
    {
      path: "master-data/view-komatsu-models",
      element: <InsiteModelSearch />,
    },
    {
      path: "master-data/SiteSets",
      element: <SiteSets />,
    },
    {
      path: "reports/rerun-samples",
      element: <RerunSamples />,
    },
    {
      path: "reports/missed-and-hold-samples",
      element: <MissedSamples />,
    },
    {
      path: "reports/special-report",
      element: <SpecialReportTemplates />,
    },
    {
      path: "reports/special-report-types",
      element: <SpecialReportTemplatesTypes />,
    },
    {
      path: "reports/reports-email",
      element: <ReportToMail />,
    },
    {
      path: "reports/email-selected-items",
      element: <EmailsAndSamplesReports />,
    },
    {
      path: "management/hold-management",
      element: <HoldManagement />,
    },
    {
      path: "hold",
      element: <Hold />
    },
    {
      path: "new-unit-model",
      element: <EditUnitModel />
    },
    {
      path: "*",
      element: <Navigate to="/" />
    },
  ]
  let routes = ((user?.RoleAdministration || user?.RoleDeveloper) === true) ? [...adminRoutes, ...nonAdminRoutes] : nonAdminRoutes
  let element = useRoutes(routes);

  return element;
}
