import {
  Button,
  InputLabel,
  Grid,
  Typography,
  FormControlLabel,
  Radio,
  Card,
} from "@mui/material";
import { useState } from "react";
import TextBox from "../../../components/TextBox";
import useFetch from "../../../hooks/useFetch";
import { MASTER_DATA_APIS, TRAY_APIS } from "../../../common/apis";
import moment from "moment";
import { useEffect } from "react";
import { useRoot, } from "../../../rootContext";

export default function RerunToLaboratory({
  reLoadViewHandler,
  handleClose,
}) {

  const { get, post } = useFetch();
  const { user } = useRoot();

  const [commentValue, setCommentValue] = useState("Returned to laboratory as ");
  const [selectedValue, setSelectedValue] = useState("1");
  const [localData, setLocalData] = useState({});
  const [laboratories, setLaboratories] = useState([]);

  useEffect(() => {
    (async function () {
      const response = await get(`${MASTER_DATA_APIS.GET_BY_TYPES}?types=Laboratories`);
      setLaboratories(response.Laboratories || []);
    })()

    let parseData = JSON.parse(localStorage.getItem("return-to-lab"));
    if (parseData !== null && parseData !== undefined && parseData?.SampleNumber > 0) {
      setLocalData(parseData);
      setSelectedValue(parseData?.Option || "1")
    }
  }, [])

  //hold sample click
  async function returnSampleClick() {
    if (!localData?.SampleNumber === 0) return;
    let emailLabs = [];

    const filteredLab = laboratories.filter(item => item.LaboratoryId === user?.DefaultLaboratoryId)[0];
    if (filteredLab.Email1) { emailLabs.push(filteredLab.Email1); }
    if (filteredLab.Email2) { emailLabs.push(filteredLab.Email2); }
    if (filteredLab.Email3) { emailLabs.push(filteredLab.Email3); }

    const payloadObj = { SampleNumber: Number(localData?.SampleNumber), Reason: commentValue, LabEmails: emailLabs };
    if (localData?.ScreenName === "Quality Check") {
      if (selectedValue === "1") {
        let resp = await post(`${TRAY_APIS.QUALITY_CHECK_RERUN_SAMPLE}`, payloadObj);
        if (resp > -1) {
          handleClose && handleClose(false);
        }
      }
      if (selectedValue === "2") {
        let resp = await post(`${TRAY_APIS.QUALITY_CHECK_RERUN_TRAY}`, payloadObj);
        if (resp > -1) {
          handleClose && handleClose(false);
        }
      }
    }
    if (localData?.ScreenName === "Evaluation") {
      if (selectedValue === "1") {
        let resp = await post(`${TRAY_APIS.EVALUATION_RERUN_SAMPLE}`, payloadObj);
        if (resp > -1) {
          handleClose && handleClose(false);
          reLoadViewHandler && reLoadViewHandler();
        }
      }
      if (selectedValue === "2") {
        let resp = await post(`${TRAY_APIS.EVALUATION_RERUN_TRAY}`, payloadObj);
        if (resp > -1) {
          handleClose && handleClose(false);
          reLoadViewHandler && reLoadViewHandler();
        }
      }
    }
    if (window.location.pathname === "/return-to-lab") {
      localStorage.setItem("return-to-lab-resp", JSON.stringify({ resp: true }));
      window.close();
    }
  }

  return (
    <Card sx={{ p: 1, }}>
      <Grid container spacing={2} sx={{ p: 1, }}>
        <Grid item xs={2}>
          <Typography variant="subtitle2" style={{ marginTop: "6px" }}>
            Return to Laboratory
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Grid item container>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Radio
                    checked={selectedValue === "1"}
                    onClick={() => setSelectedValue("1")}
                    name="Sample"
                    size="small"
                    value="1"
                  />
                }
                sx={{ mt: "2px" }}
                label={"Sample " + (localData?.SampleNumber || "")}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Radio
                    checked={selectedValue === "2"}
                    onClick={() => setSelectedValue("2")}
                    value="2"
                    name="additional"
                    size="small"
                  />
                }
                sx={{ mt: "2px" }}
                // label="No additional samples are available "
                label={`All available samples in tray ${localData?.Tray || ""} on ${moment().format("dddd DD MMM  YYYY")} for ${localData?.Laboratory || ""}`}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputLabel style={{ marginTop: "15px" }}>Reason</InputLabel>
          <TextBox
            multiline
            rows={7}
            value={commentValue}
            // disabled={selectedValue === "2"}
            onChange={(v) => setCommentValue(v.target.value)}
          />
        </Grid>
      </Grid>

      <Grid container direction="row-reverse" spacing={1}>
        <Grid item>
          <Button
            item
            variant="contained"
            sx={{ mt: 1 }}
            // disabled={selectedValue === "2"}
            onClick={returnSampleClick}
          >
            Return and Rerun
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
}
