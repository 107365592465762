import { useEffect, useState } from "react";
import renderCellExpand from "../../../components/DatatableCellExpand";
import NewDataTable from "../../../components/NewDataTable";
import { Checkbox } from "@mui/material";

export default function TestSetsTable({
  data,
  actions,
  rowSelected,
  showArchived,
}) {

  const [rowSelection, setRowSelection] = useState({});

  const options = {
    columns: GetColumns(actions),
    dataSource: data || [],
    getRowId: (originalRow) => originalRow.TestSetId,

    sx: { height: "86dvh", },
    enableColumnActions: false,
    enableSorting: false,
    onRowSelectionChange: setRowSelection,

    muiTableBodyProps: {
      sx: (theme) => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "#e2f2ff" },
        // backgroundColor: "inherit",//
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "#e2f2ff" },
      }),
    },

    muiTableBodyCellProps: ({ cell, column, row, table }) => {
      return {
        sx: cell.column.columnDef.accessorKey === "Product" ? {
          backgroundColor: (row?.original?.Colour + "!important") || "",
          color: "white",
          border: "0.5px solid #d0d0d0",
          py: 0,
        } : { height: "8px", borderLeft: "0.5px solid #d0d0d0", }
      }
    },

    muiTableBodyRowProps: ({ row }) => ({
      //implement row selection click events manually
      onClick: () => {
        rowSelected(row.original);
        setRowSelection((prev) => ({
          [row.id]: !prev[row.id],
        }))
      },
      selected: rowSelection[row.id],
      sx: {
        cursor: 'pointer',
        [`.MuiTableRow-root.Mui-selected td`]: {
          backgroundColor: "#7030a0!important", color: "white",
        }


      },
    }),
    state: { rowSelection },
  };

  useEffect(() => {
    if (data?.length > 0) {
      setRowSelection({ [data[0]?.TestSetId]: true });
    }
  }, [showArchived]);

  return <NewDataTable {...options} />;
}

function GetColumns() {
  return [
    {
      header: "Test Set",
      accessorKey: "TestSet",
      size: 200,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "Fluid",
      accessorKey: "FluidType",
      size: 70,
    },
    {
      header: "Specific",
      accessorKey: "IsCustomerSpecific",
      size: 50,
      Cell: ({ row }) => (
        <Checkbox checked={row?.original?.IsCustomerSpecific}
          readOnly
          sx={{ [`.MuiSvgIcon-root`]: { fontSize: "18px" } }} />
      ),
    },
    {
      header: "Product",
      accessorKey: "Product",
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
      size: 100,
    },
  ];
}
