import { useState } from "react";
import GetStepNames from "../../../common/GetStepNames";
import DataTable, { DataGridAction } from "../../../components/DataTable";
import { toDateTimeFormat } from "../../../utils";
import AutoEvaluationBatchFile from "./AutoEvaluationBatchFile";
import AppDialog from "../../../components/AppDialog";
import { useEffect } from "react";
import renderCellExpand from "../../../components/DatatableCellExpand";
import useFetch from "../../../hooks/useFetch";
import { TRAY_APIS } from "../../../common/apis";

export default function AutoEvaluationTable({ data, filterState, canModify, handleRefresh }) {

  const { get } = useFetch();

  const [fileDialogueOpen, setFileDialogueOpen] = useState(false);
  const [batchNumber, setBatchNumber] = useState("");

  const [hasStepRank4, setHasStepRank4] = useState(true);
  const [hasNotStepRank4, setHasNotStepRank4] = useState(true);
  const [hasStepRank5, setHasStepRank5] = useState(true);
  const [hasLimitsUsed, setHasLimitsUsed] = useState(true);
  const [hasJpUsed, setHasJpUsed] = useState(true);
  const [hasAuUsed, setHasAuUsed] = useState(true);
  const [hasLocalUsed, setHasLocalUsed] = useState(true);
  const [hasAutoEvaluated, setHasAutoEvaluated] = useState(true);
  const [hasHold, setHasHold] = useState(true);

  // Check the conditions in the data array
  useEffect(() => {
    data?.forEach((item) => {
      if (item.StepRank === 4) {
        setHasStepRank4(false);
      }
      if (item.StepRank !== 4) {
        setHasNotStepRank4(false);
      }
      if (item.StepRank === 5) {
        setHasStepRank5(false);
      }
      if (item.LimitsUsed) {
        setHasLimitsUsed(false);
      }
      if (item.JpUsed) {
        setHasJpUsed(false);
      }
      if (item.AuUsed) {
        setHasAuUsed(false);
      }
      if (item.LocalUsed) {
        setHasLocalUsed(false);
      }
      if (item.setHasAutoEvaluated !== null) {
        setHasAutoEvaluated(false);
      }
      if (item.HoldTypeId !== null) {
        setHasHold(false);
      }
    });
  }, [data]);

  const actions = {
    handleBatchClick: async function (row) {
      if (row?.Batch != null) {
        const result = await get(`${TRAY_APIS.PUSH_BATCH}?batchId=${row?.Batch}`);
        handleRefresh()
      }
      else return;
    },
    handleSampleClick: async function (row) {
      if (row?.Batch != null) {
        const result = await get(`${TRAY_APIS.PUSH_BATCH_SAMPLE}?batchId=${row?.Batch}&PushSampleNumber=${row?.SampleNumber}`);
        handleRefresh()
      }
      else return;
    },
    handleReprocessClick: async function (row) {
      if (row?.Batch != null) {
        const result = await get(`${TRAY_APIS.RESEND_BATCH}?batchId=${row?.Batch}`);
        handleRefresh()
      }
      else return;
    },
    handleFileClick: async function (row) {
      if (row?.Batch != null) {
        setFileDialogueOpen(true);
        setBatchNumber(row?.Batch);
      }
      else return;
    },
  }

  const options = {
    columns: GetColumns(
      data,
      filterState,
      hasStepRank4,
      hasNotStepRank4,
      hasStepRank5,
      hasLimitsUsed,
      hasJpUsed,
      hasAuUsed,
      hasLocalUsed,
      hasAutoEvaluated,
      hasHold,
      canModify,
      actions,
    ),
    dataSource: (data || []).map((x) => ({
      ...x,
      keyProp: x.SampleNumber + "" + (x.UnitId || "") + "" + (x.Batch || ""),
    })),
    rowIdProperty: "keyProp",
    cellStylingHandler: "autoEvaluate",
    isDisableColumnFilter: true,
    sx: {
      height: 780,
    },
  };

  return (
    <>
      <DataTable {...options} />
      <AppDialog
        open={fileDialogueOpen}
        handleClose={() => setFileDialogueOpen(false)}
        title="Auto Evaluation Batch Files"
        maxWidth="xl"
      >
        <AutoEvaluationBatchFile batchNumber={batchNumber} />
      </AppDialog>
      <AppDialog />
    </>
  );
}

function GetColumns(
  data,
  filterState,
  hasStepRank4,
  hasNotStepRank4,
  hasStepRank5,
  hasLimitsUsed,
  hasJpUsed,
  hasAuUsed,
  hasLocalUsed,
  hasAutoEvaluated,
  hasHold,
  canModify,
  actions
) {
  return [
    {
      headerName: "Batch",
      field: "PushBatch",
      sortable: false,
      width: 80,
      hide: data?.every(i => i.StepRank !== 4),
      renderCell: function ({ row }) {
        return canModify &&
          row.StepRank === 4 ? (
          <>
            <DataGridAction row={row} label={row?.Batch !== null ? "Push Batch" : ""} onClick={() => actions.handleBatchClick(row)} />
          </>
        ) : null;
      },
    },
    {
      headerName: "Sample",
      field: "PushSample",
      sortable: false,
      width: 80,
      hide: data?.every(i => i.StepRank !== 4),
      renderCell: function ({ row }) {
        return canModify &&
          row.StepRank === 4 ? (
          <>
            <DataGridAction row={row} label={row?.Batch !== null ? "Push" : ""} onClick={() => actions.handleSampleClick(row)} />
          </>
        ) : null;
      },
    },
    {
      headerName: "Reprocess",
      field: "ResendBatch",
      sortable: false,
      width: 80,
      hide: data?.every(i => i.StepRank !== 5),
      renderCell: function ({ row }) {
        return canModify &&
          row.StepRank === 5 ? (
          <>
            <DataGridAction row={row} label={row?.Batch !== null ? "Reprocess" : ""} onClick={() => actions.handleReprocessClick(row)} />
          </>
        ) : null;
      },
    },

    {
      headerName: "Files",
      field: "ViewBatch",
      sortable: false,
      width: 80,
      hide: !(canModify &&
        (data?.some(item => item.JpUsed === true) || data?.some(item => item.AuUsed === true)) &&
        (data?.some(item => item.StepRank > 4))),
      renderCell: function ({ row }) {
        return canModify &&
          (data?.some(item => item.JpUsed === true) || data?.some(item => item.AuUsed === true)) &&
          row.StepRank > 4 &&
          row.Batch !== null ? (
          <>
            <DataGridAction row={row} label={row?.Batch !== null ? "Files" : ""} onClick={actions.handleFileClick} />
          </>
        ) : null;
      },
    },

    {
      headerName: "Sample Number",
      field: "SampleNumber",
      width: 120,
      minWidth: 120,
    },
    {
      headerName: "Batch",
      field: "BatchAdded",
      valueFormatter: ({ value }) => toDateTimeFormat(value),//toLocalDateTime removed for date conflict
      minWidth: 150,
    },

    {
      headerName: "Laboratory",
      field: "Laboratory",
      hide: filterState?.LabAndDateOption < 3,
      width: 120,
      minWidth: 120,
    },
    {
      headerName: "Step",
      field: "StepRank",
      hide: hasNotStepRank4,
      width: 90,
      minWidth: 90,

      valueFormatter: ({ value }) => GetStepNames(value).long,
      sortable: false,
    },
    {
      headerName: "Status",
      field: "Status",
      minWidth: 100,
    },

    {
      headerName: "Limits Start",
      field: "LimitsStart",
      minWidth: 150,
      hide: hasLimitsUsed,
      valueFormatter: ({ value }) => toDateTimeFormat(value),
    },
    {
      headerName: "Limits End",
      field: "LimitsEnd",
      hide: hasLimitsUsed,
      minWidth: 150,
      valueFormatter: ({ value }) => toDateTimeFormat(value),
    },

    {
      headerName: "Limits Run",
      field: "LimitsComplete",
      hide: hasLimitsUsed,
      valueFormatter: ({ value }) => (value ? "Yes" : ""),
      minWidth: 120,
    },
    {
      headerName: "Limits Alert",
      field: "LimitsAlertStatus",
      hide: hasLimitsUsed,
      minWidth: 100,
    },

    {
      headerName: "iKOWA Start",
      field: "JpStart",
      hide: hasJpUsed,
      minWidth: 140,
      valueFormatter: ({ value }) => toDateTimeFormat(value),
    },
    {
      headerName: "iKOWA End",
      field: "JpEnd",
      hide: hasJpUsed,
      minWidth: 140,
      valueFormatter: ({ value }) => toDateTimeFormat(value),
    },

    {
      headerName: "iKOWA Alert",
      field: "JpAlertStatus",
      hide: hasJpUsed,
      width: 85,
      minWidth: 85,
    },
    {
      headerName: "iKOWA Run",
      field: "JpComplete",
      hide: hasJpUsed,
      width: 85,
      minWidth: 85,
      valueFormatter: ({ value }) => (value ? "Yes" : ""),
    },

    {
      headerName: "AU Start",
      field: "AuStart",
      hide: hasAuUsed,
      minWidth: 150,
      valueFormatter: ({ value }) => toDateTimeFormat(value),
    },
    {
      headerName: "AU End",
      field: "AuEnd",
      hide: hasAuUsed,
      minWidth: 150,
      valueFormatter: ({ value }) => toDateTimeFormat(value),
    },

    {
      headerName: "AU Alert",
      field: "AuAlertStatus",
      hide: hasAuUsed,
      minWidth: 120,
    },
    {
      headerName: "AU Run",
      field: "AuComplete",
      hide: hasAuUsed,
      minWidth: 100,
      valueFormatter: ({ value }) => (value ? "Yes" : ""),
    },

    {
      headerName: "KLEAR Start",
      field: "LocalStart",
      hide: hasLocalUsed,
      minWidth: 150,
      valueFormatter: ({ value }) => toDateTimeFormat(value),
    },
    {
      headerName: "KLEAR End",
      field: "LocalEnd",
      hide: hasLocalUsed,
      minWidth: 150,
      valueFormatter: ({ value }) => toDateTimeFormat(value),
    },
    {
      headerName: "KLEAR Alert",
      field: "LocalAlertStatus",
      hide: hasLocalUsed,
      minWidth: 120,
    },
    {
      headerName: "KLEAR Run",
      field: "LocalComplete",
      hide: hasLocalUsed,
      width: 85,
      minWidth: 85,
      valueFormatter: ({ value }) => (value ? "Yes" : ""),
    },
    {
      headerName: "Response",
      field: "AutoEvaluationFeedback",
      renderCell: renderCellExpand,
      minWidth: 680,
      flex: 1,
      hide: !filterState?.ShowResponse,
    },
    {
      headerName: "AutoEvaluated",
      field: "AutoEvaluated",
      minWidth: 150,
      hide: hasAutoEvaluated,
      valueFormatter: ({ value }) => toDateTimeFormat(value),
    },

    {
      headerName: "Test Set",
      field: "TestSet",
      minWidth: 200,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Hold",
      field: "HoldType",
      hide: hasHold,
      minWidth: 120,
    },
    {
      headerName: "Component",
      field: "Component",
      minWidth: 140,
      hide: filterState?.SimplifyView,
    },
    {
      headerName: "Unit",
      field: "Unit",
      minWidth: 100,
      hide: filterState?.SimplifyView,
    },
    {
      headerName: "Site",
      field: "Site",
      minWidth: 100,
      hide: filterState?.SimplifyView,
    },
    {
      headerName: "Customer",
      field: "Customer",
      minWidth: 180,
      hide: filterState?.SimplifyView,
    },
    {
      headerName: "Product",
      field: "Product",
      minWidth: 180,
      hide: filterState?.SimplifyView,
      flex: 1,
    },
  ];
}
