import { Typography } from "@mui/material";
import { FLEET_APIS } from "../../../common/apis";
import useFetch from "../../../hooks/useFetch";
import renderCellExpand from "../../../components/DatatableCellExpand";
import { useState } from "react";
import NewDataTable from "../../../components/NewDataTable";

export default function SitesCustomersTable({
  data,
  selectedParent,
  refreshCustomers,
}) {
  const { get } = useFetch();

  const [rowSelection, setRowSelection] = useState({});

  const options1 = {
    columns: Columns1(deleteClick, selectedParent),
    dataSource: data || [],
    getRowId: (originalRow) => originalRow.FluidId,
    sx: { height: "83dvh", },
    onRowSelectionChange: setRowSelection,
    muiSelectCheckboxProps: {
      color: 'secondary',
      backgroundColor: "blue"
    },
    muiTableBodyProps: {
      sx: (theme) => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "#e2f2ff" },
        // backgroundColor: "inherit",//
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "#e2f2ff" },
      }),
    },
    muiTableBodyRowProps: ({ row }) => ({
      //implement row selection click events manually
      onClick: () => {
        setRowSelection((prev) => ({
          [row.id]: !prev[row.id],
        }))
        // rowSelected(row.original);
      },
      selected: rowSelection[row.id],
      sx: {
        cursor: 'pointer',
      },
    }),
    state: { rowSelection },
  };

  return (
    <>
      <Typography variant="subtitle2">
        Total Records: <b> {data && data.length}</b>
      </Typography>
      <NewDataTable {...options1} />
    </>
  );

  async function deleteClick(row) {
    await get(`${FLEET_APIS.DELETE_SITE_SET_SITE}?SiteSetId=${row.SiteSetId}&SiteId=${row.SiteId}`);
    refreshCustomers();
  }
}

function Columns1(deleteClick, selectedParent) {
  return [
    // {
    //   header: "Delete",
    //   accessorKey: "Delete",
    //   sortable: false,
    //   maxSize: 80,
    //   size: 80,
    //   // hide: selectedParent.AutoUpdated === "Yes" ? true : false,
    //   hide: true,
    //   enableColumnOrdering: true, //but you can turn back any of those features on if you want like this
    //   Cell: ({ row }) => (
    //     <DataGridAction row={row} onClick={deleteClick} label="Delete" />
    //   ),
    // },
    {
      header: "Product",
      accessorKey: "Product",
      maxSize: 100,
      size: 100,
    },
    {
      header: "Customer",
      accessorKey: "Customer",
      maxSize: 150,
      size: 150,
      renderCell: renderCellExpand,
    },
    {
      header: "Site",
      accessorKey: "Site",
      maxSize: 230,
      size: 230,
      renderCell: renderCellExpand,
    },
  ];
}

