import { useEffect, useMemo, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Card, FormGroup, InputLabel, Radio, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import useFetch from "../../../hooks/useFetch";
import RerunSampleTable from "./RerunSampleTable";
import { DETAILS_APIS, MASTER_DATA_APIS } from "../../../common/apis";
import TextBox from "../../../components/TextBox";
import { useUser } from "../../../rootContext";

const RerunSamples = () => {
  const user = useUser();
  const { get, post } = useFetch();

  const [selectedValue, setSelectedValue] = useState("1");
  const [dataSource, setDataSource] = useState([]);
  const [inputValue, setInputValue] = useState();
  const [laboratories, setLaboratories] = useState([]);
  const [lab, setlab] = useState({ Laboratory: "Brisbane", LaboratoryId: 1 });

  const handleChangeRadio = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleChangeDropdown = (event) => {
    setlab(laboratories.find((x) => event.target.value === x.LaboratoryId) || {});
  };

  const filteredData = useMemo(
    () =>
      inputValue > 0
        ? dataSource.filter((items) =>
          (items.SampleNumber + "").includes(inputValue)
        )
        : dataSource,
    [dataSource, inputValue]
  );

  useEffect(() => {
    (async () => {
      const response = await get(`${MASTER_DATA_APIS.GET_BY_TYPES}?types=Laboratories`);
      setLaboratories(response.Laboratories || []);
      setlab({
        Laboratory: user.DefaultLaboratory,
        LaboratoryId: user.DefaultLaboratoryId,
      });
    })();
  }, [user]);

  useEffect(() => {
    (async () => {

      let showToday = true;
      let showOutstandingOnly = false;
      let showCompleted = false;
      let showAll = false;

      if (selectedValue === "1") {
        showToday = true;
        showOutstandingOnly = false;
        showCompleted = false;
        showAll = false;
      } else if (selectedValue === "2") {
        showToday = false;
        showOutstandingOnly = true;
        showCompleted = false;
        showAll = false;
      } else if (selectedValue === "3") {
        showToday = false;
        showOutstandingOnly = false;
        showCompleted = true;
        showAll = false;
      } else if (selectedValue === "4") {
        showToday = false;
        showOutstandingOnly = false;
        showCompleted = false;
        showAll = true;
      }

      const data = await post(DETAILS_APIS.RERUN, {
        laboratoryId: lab.LaboratoryId === 0 ? null : lab.LaboratoryId,
        showToday: showToday,
        showOutstandingOnly: showOutstandingOnly,
        showCompleted: showCompleted,
        showAll: showAll,
      });
      if (data.length > 0) {
        setDataSource(data);
      } else {
        setDataSource();
      }
    })();

  }, [lab, selectedValue]);

  return (
    <>
      <Card sx={{ p: 1, minWidth: "70vw", mb: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={2} style={{ marginRight: "0px" }}>
            <InputLabel>Laboratory</InputLabel>
            <FormGroup row={true}>
              <Select
                value={lab?.LaboratoryId}
                onChange={handleChangeDropdown}
                displayEmpty
                inputProps={{ "aria-label": "" }}
                sx={{ width: 180 }}
              >
                {laboratories?.map((op) => (
                  <MenuItem value={op.LaboratoryId} key={op.LaboratoryId}>
                    {op.Laboratory}
                  </MenuItem>
                ))}
              </Select>
            </FormGroup>
          </Grid>
          <Grid item xs={6} style={{ marginTop: "15px" }}>
            <FormControlLabel
              control={
                <Radio
                  checked={selectedValue === "1"}
                  onChange={handleChangeRadio}
                  value="1"
                  name={"TodayRerun"}
                  size="small"
                />
              }
              sx={{ mt: "2px" }}
              label={"Today Rerun's"}
            />
            <FormControlLabel
              control={
                <Radio
                  checked={selectedValue === "2"}
                  onChange={handleChangeRadio}
                  value="2"
                  name={"OutstandingRerunsOnly"}
                  size="small"
                />
              }
              sx={{ mt: "2px" }}
              label={"Outstanding Reruns Only"}
            />
            <FormControlLabel
              control={
                <Radio
                  checked={selectedValue === "3"}
                  onChange={handleChangeRadio}
                  value="3"
                  name={"ProcessedRerunsOnly"}
                  size="small"
                />
              }
              sx={{ mt: "2px" }}
              label={"Processed Reruns Only"}
            />
            <FormControlLabel
              control={
                <Radio
                  checked={selectedValue === "4"}
                  onChange={handleChangeRadio}
                  value="4"
                  name={"AllReruns"}
                  size="small"
                />
              }
              sx={{ mt: "2px" }}
              label={"All Reruns"}
            />
          </Grid>
          <Grid item xs={2} style={{}}>
            <InputLabel>Filter by Sample Number</InputLabel>
            <TextBox
              id="outlined-number"
              value={inputValue}
              onChange={(v) => setInputValue(v.target.value)}
              type="text"
              inputProps={{ min: 0 }}
              sx={{ width: 200 }}
            />
          </Grid>
        </Grid>
      </Card>

      <Typography variant="subtitle2">
        Total Records: <b> {filteredData && filteredData.length}</b>
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RerunSampleTable data={filteredData} />
        </Grid>
      </Grid>
    </>
  );
};

export default RerunSamples;
