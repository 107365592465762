import { Button, Card, Typography } from "@mui/material";
import { Check, Search } from "@mui/icons-material";
import { useEffect, useReducer, useState } from "react";
import Form, { SectionTitle } from "../components/Form";
import FormFooter from "../components/FormFooter";
import useFetch from "../hooks/useFetch";
import TextBoxGroup from "./../components/TextBoxGroup";
import AppDialog from "./../components/AppDialog";
import useMasterData from "../hooks/useMasterData";
import { ValidateSite } from "../validation/schemas/SiteSchema";
import { DETAILS_APIS } from "../common/apis";
import { NewIcon } from "../icons/ButtonIcons";
import { useRoot, useUser } from "../rootContext";
import { getStartLetter } from "../utils";
import ManageSite from "../features/quick-details/manage/manage-site/ManageSite";
import { useWindowPopups } from "../components/useWindowPopups";
import { FormActions } from "../components/Form";

function EditSite({
  productId,
  customerId,
  customer,
  siteId,
  registrationDone,
  cancel,
  handleSearchTree,
  setExpandTreeData,
  startLetter
}) {

  const [data, dispatch] = useReducer(SiteReducer, { SiteExportOptionId: 1, });

  const [dropdownData, setDropdownData] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [editCheck, setEditCheck] = useState(false);
  const [openManagement, setOpenManagement] = useState(false);
  const [localData, setLocalData] = useState({});
  const [resp10, setResp10] = useState(false);
  const [pcIndex, setPCIndex] = useState(0);

  const user = useUser();
  const { CanDoDataMaintenance } = useRoot();
  const { get, post } = useFetch();
  const { getMasterData } = useMasterData();


  const handleUpdateSite = async () => {
    let result = await post(DETAILS_APIS.UPDATE_SITE, {
      Site: data.Site,
      CustomerId: customerId || localData?.CustomerId,
      UserId: user.UserId,
      SiteId: data.SiteId,
      KalBranchId: data?.KalBranchId,
      ShowSiteName: !!data.ShowSiteName,
      ApplyKalBranchToAll: !!data.ApplyKALBranch,
      ExportOptionId: data.SiteExportOptionId,
      IsPredelivery: !!data.IsPredelivery,
      IsArchived: !!data.SiteArchived,

      MakeAllParked: data?.makeAllUnits === "1" ? true : false,
      MakeAllScrapped: data?.makeAllUnits === "2" ? true : false,
      MakeAllArchived: data?.makeAllUnits === "3" ? true : false,

      IsShowLimitsOnReport: !!data.IsShowLimitsOnReport || false,
      IsAutoCreated: false,
      OwnerUserId: data?.SiteCmsOwnerId || 0,

      Notes: data?.SiteNotes,

      ExportBlock: data?.LocationTaken,

      PostalAddressId: data?.a,
      PostalStreet1: data?.PostalStreet1,
      PostalStreet2: data?.PostalStreet2,
      PostalCity: data?.PostalCity,
      PostalState: data?.PostalState,
      PostalPostCode: data?.PostalPostCode,
      PostalCountry: data?.PostalCountry,

      PhysicalAddressId: data?.a,
      PhysicalStreet1: data?.PhysicalStreet1,
      PhysicalStreet2: data?.PhysicalStreet2,
      PhysicalCity: data?.PhysicalCity,
      PhysicalState: data?.PhysicalState,
      PhysicalPostCode: data?.PhysicalPostCode,
      PhysicalCountry: data?.PhysicalCountry,

      PrimaryContactId1: data?.PrimaryContactId1,
      PrimaryContactId2: data?.PrimaryContactId2,
      PrimaryContactId3: data?.PrimaryContactId3,

      Latitude: data?.Latitude,
      Longitude: data?.Longitude,

      LocationTaken: data?.LocationTaken,

      Screen: "Edit Site",
    });
    registrationDone && registrationDone(data.SampleNumber);

    if (result === -10) {
      return;
    }
    if (result >= 0 || result?.length > 0) {

      const treeData = { ProductId: data?.ProductId, StartLetter: data?.StartLetter, CustomerId: data?.CustomerId, SiteId: data?.SiteId, refreshCheck: true }
      localStorage.setItem("tree-resp", JSON.stringify({ ...treeData }));
      window.dispatchEvent(new Event('storage'));

      setExpandTreeData && setExpandTreeData(treeData)
      handleSearchTree && handleSearchTree(treeData, null, true)
      cancel && cancel();
      if (window.location.pathname === '/add-new-site' || window.location.pathname === '/edit-site') {
        localStorage.removeItem("edit-site");
        window.close()
      }
      return;
    }
    if (result !== null && (result < 1 || result?.length < 1)) {
      return;
    }
    return;
  }

  const handleNewSite = async () => {
    let slIndex = 0;
    if (startLetter === undefined && localData?.StartLetter === undefined) {
      let name = (customer || localData?.Customer || "")?.trim();
      slIndex = Number(getStartLetter(name[0]));
    }
    else {
      slIndex = startLetter || localData?.StartLetter
    }

    let result = await post(DETAILS_APIS.ADD_SITE, {
      Site: data.Site,
      CustomerId: customerId || localData?.CustomerId,
      UserId: user.UserId,
      KalBranchId: data?.KalBranchId,
      ShowSiteName: !!data.ShowSiteName,
      ApplyKalBranchToAll: !!data.ApplyKALBranch,
      ExportOptionId: data.SiteExportOptionId,
      IsPredelivery: !!data.IsPredelivery,
      IsArchived: !!data.SiteArchived,

      MakeAllParked: data?.makeAllUnits === "1" ? true : false,
      MakeAllScrapped: data?.makeAllUnits === "2" ? true : false,
      MakeAllArchived: data?.makeAllUnits === "3" ? true : false,

      IsShowLimitsOnReport: !!data.IsShowLimitsOnReport || false,
      IsAutoCreated: false,
      OwnerUserId: data?.SiteCmsOwnerId || 0,

      Notes: data?.SiteNotes,

      ExportBlock: data?.LocationTaken,

      PostalAddressId: data?.a,
      PostalStreet1: data?.PostalStreet1,
      PostalStreet2: data?.PostalStreet2,
      PostalCity: data?.PostalCity,
      PostalState: data?.PostalState,
      PostalPostCode: data?.PostalPostCode,
      PostalCountry: data?.PostalCountry,

      PhysicalAddressId: data?.a,
      PhysicalStreet1: data?.PhysicalStreet1,
      PhysicalStreet2: data?.PhysicalStreet2,
      PhysicalCity: data?.PhysicalCity,
      PhysicalState: data?.PhysicalState,
      PhysicalPostCode: data?.PhysicalPostCode,
      PhysicalCountry: data?.PhysicalCountry,

      PrimaryContactId1: data?.PrimaryContactId1,
      PrimaryContactId2: data?.PrimaryContactId2,
      PrimaryContactId3: data?.PrimaryContactId3,

      Latitude: data?.Latitude,
      Longitude: data?.Longitude,

      LocationTaken: data?.LocationTaken,

      Screen: "New Site",
    });
    registrationDone && registrationDone(data.SampleNumber);

    if (result === -10) {
      return setResp10(true);
    }
    if (result >= 0 || result?.length > 0) {
      if (result > 0) {
        const treeData = { ProductId: productId || localData?.ProductId, StartLetter: slIndex, CustomerId: customerId || localData?.CustomerId, SiteId: result, refreshCheck: true }
        localStorage.setItem("tree-resp", JSON.stringify({ ...treeData }));
        window.dispatchEvent(new Event('storage'));

        setExpandTreeData && setExpandTreeData(treeData)
        handleSearchTree && handleSearchTree(treeData, null, true)
      }
      cancel && cancel();
      if (window.location.pathname === '/add-new-site' || window.location.pathname === '/edit-site') {
        localStorage.removeItem("edit-site");
        window.close()
      }
      return;
    }
    if (result !== null && (result < 1 || result?.length < 1)) {
      return;
    }
    return;
  }

  const actions = {
    save: async function () {
      if (siteId || data?.SiteId) {
        setFormErrors({});
        ValidateSite(data).then(async function ({ data: req, errors }) {
          if (req) {
            handleUpdateSite()
          }
          if (errors) {
            setFormErrors(errors);
          }
        });
      } else {
        setFormErrors({});
        ValidateSite(data).then(async function ({ data: req, errors }) {
          if (req) {
            handleNewSite()
          }
          if (errors) {
            setFormErrors(errors);
          }
        });
      }
    },
    manage: async function () {
      setOpenManagement(true);
    },
  };


  const HandleNewWindow = (actionName) => {
    useWindowPopups(actionName, { selectCheck: true, ...data });
  }

  const handleContactClick = (actionName, id) => {
    HandleNewWindow(actionName);
    setPCIndex(id);
  };

  useEffect(() => {
    (async () => {
      if (siteId > 0) {
        let data = await get(`/details/site?siteId=${siteId}`);
        data.Users?.unshift({ UserId: 0, Username: "Default User" });
        setDropdownData(data || {});
        dispatch({ type: SiteActions.LOAD_DATA, payload: data.Site });
        setEditCheck(true)
      }
      else {
        let lData = JSON.parse(localStorage.getItem("edit-site"));
        if (lData !== null) {
          setLocalData(lData)
          if (lData?.SiteId > 0) {
            let data = await get(`/details/site?siteId=${lData?.SiteId}`);
            data.Users?.unshift({ UserId: 0, FullName: "Default User" });
            setDropdownData(data || {});
            dispatch({ type: SiteActions.LOAD_DATA, payload: data.Site });
            setEditCheck(true)
          }
          else {
            let masterData = await getMasterData("KalBranches,ExportOptions,Users,Countries");
            masterData.Users?.unshift({ UserId: 0, Username: "Default User" });
            setDropdownData(masterData || {});
          }
        }
      }

    })();
  }, [siteId]);

  const handleCloseButton = () => {
    localStorage.removeItem("edit-site");
  }

  const eventCb = () => {

    const selectContact = JSON.parse(localStorage.getItem('search-contact-resp'));

    if (selectContact && pcIndex > 0) {
      const a = `PrimaryContactId${pcIndex}`;
      const b = `Username${pcIndex}`;

      dispatch({ type: SiteActions.LOAD_DATA, payload: { ...data, [a]: selectContact?.ContactId, [b]: selectContact?.Username, key: Date.now() } });
      setPCIndex(0);
      localStorage.removeItem('search-contact-resp')
    }
  }

  useEffect(function () {
    window.addEventListener("beforeunload", handleCloseButton);
    window.addEventListener("storage", eventCb);

    return function () {
      window.removeEventListener("beforeunload", handleCloseButton);
      window.removeEventListener("storage", eventCb);

    };
  });


  return (
    <Card sx={{ padding: 1 }}>
      <Form
        sections={FormSections(
          resp10,
          dropdownData,
          handleContactClick
        )}
        data={data}
        spacing={2}
        dispatch={dispatch}
        errors={formErrors}
        key={data.SiteId || data?.key}
      />

      <FormFooter
        footerText={`ProductId: ${productId || localData?.ProductId || ""}, CustomerId: ${customerId || localData?.CustomerId || ""
          }, SiteId: ${data?.SiteId || localData?.SiteId || ""} `}
        buttons={GetFormButtons(data, handleNewSite, actions, editCheck, CanDoDataMaintenance)} />

      <AppDialog
        open={openManagement}
        title={`Site Management  Site ${data.SiteId}`}
        maxWidth="100vw"
        handleClose={() => setOpenManagement(false)}
      >
        <ManageSite unit={data} />
      </AppDialog>
    </Card>
  );
}

export default EditSite;

//edit dialog ended
const GetFormButtons = (data, handleNewSite, { save, manage }, editCheck, CanDoDataMaintenance) => [
  !!editCheck && {
    variant: "outlined",
    label: "Manage",
    onClick: manage,
  },
  !!editCheck && {
    variant: "outlined",
    label: "Save New",
    onClick: handleNewSite,
    startIcon: <NewIcon />,
  },
  {
    hidden: !CanDoDataMaintenance,
    variant: "contained",
    label: "Save",
    onClick: save,
    startIcon: <Check />,
  },
];

const FormSections = (
  resp10,
  dropdownData,
  handleContactClick,
  editCheck
) => {
  return [
    {
      xsCol: 12,
      fields: [
        {
          xsCol: 12,
          component: function () {
            return <Typography sx={{ color: "red" }}> {resp10 === true ? "Please provide a UNIQUE name for Site" : ".  "}</Typography>
          },
        },
        { name: "Site", label: "Name", xsCol: 9 },
        {
          name: "SiteArchived",
          label: "Archived - no website",
          type: "switch",
          xsCol: 3,
        },
        {
          name: "CustomerCmsOwnerId",
          label: "CMS Owner",
          type: "dropdown",
          xsCol: 4,
          selectOptions: (dropdownData.Users || []).map((x) => ({
            value: x.UserId,
            text: x.FullName,
          })),
        },
        {
          name: "KalBranchId",
          label: "Branch",
          type: "dropdown",
          xsCol: 4,
          selectOptions: (dropdownData.KalBranches || []).map((x) => ({
            value: x.BranchId,
            text: x.FullBranch,
          })),
        },
        {
          name: "ApplyKALBranch",
          label: "Apply KAL Branch to ALL Units for this Site",
          type: "switch",
          xsCol: 4,
        },
        {
          xsCol: 4,
          name: "Username1",
          component: function ({ id, value }) {
            const options = {
              label: "Primary1",
              id,
              value,
              readOnly: true,
              buttons: [
                {
                  onClick: () => handleContactClick("search-contact", 1),
                  icon: <Search />,
                },
              ],
            };
            return <TextBoxGroup {...options} />;
          },
        },
        {
          xsCol: 4,
          name: "Username2",
          component: function ({ id, value }) {
            const options = {
              label: "Primary2",
              id,
              value,
              readOnly: true,
              buttons: [
                {
                  onClick: () => handleContactClick("search-contact", 2),
                  icon: <Search />,
                },
              ],
            };
            return <TextBoxGroup {...options} />;
          },
        },
        {
          xsCol: 4,
          name: "Username3",
          //  name: "PrimaryContactId3",
          component: function ({ id, value }) {
            const options = {
              label: "Primary3",
              id,
              value,
              readOnly: true,
              buttons: [
                {
                  onClick: () => handleContactClick("search-contact", 3),
                  icon: <Search />,
                },
              ],
            };
            return <TextBoxGroup {...options} />;
          },
        },
        {
          name: "LocationTaken",
          label: "Date Taken",
          xsCol: 4,
          type: "datetime",
        },
        { name: "Latitude", label: "Latitude (Negative Number)", xsCol: 4 },
        { name: "Longitude", label: "Longitude (Positive Number)", xsCol: 4 },
        !!editCheck && {
          component: function () {
            return (
              <>
                <Button sx={{ marginTop: "17px" }} variant="contained">
                  Get Address
                </Button>
              </>
            );
          },
        },
        !!editCheck && {
          component: function () {
            return (
              <>
                <Button sx={{ marginTop: "17px" }} variant="contained">
                  View
                </Button>
              </>
            );
          },
        },
        {
          name: "makeAllUnits",
          label: "Apply to All Units in this Site:",
          type: "dropdown",
          xsCol: 4,
          selectOptions: [
            { value: "1", text: "Make ALL Units Parked -on website" },
            { value: "2", text: "Make ALL Units Scrapped -not on website" },
            { value: "2", text: "Make ALL Units Archived -no website" },
          ],
        },
        {
          name: "IsShowLimitsOnReport",
          label: "Show Test Limits on Report",
          type: "switch",
          xsCol: 3,
        },
        {
          xsCol: 12,
          component: function () {
            return <SectionTitle title="Additional Information" />;
          },
        },
        { name: "SiteNotes", label: "Comment", xsCol: 12, type: "textarea" },
      ],
    },
    {
      xsCol: 6,
      fields: [
        { name: "PhysicalStreet1", label: "Physical Address 1", xsCol: 12 },
        { name: "PhysicalStreet2", label: "Address 2", xsCol: 12 },
        { name: "PhysicalCity", label: "Town", xsCol: 12 },
        { name: "PhysicalState", label: "State", xsCol: 6 },
        { name: "PhysicalPostCode", label: "Postcode", xsCol: 6 },
        {
          name: "PhysicalCountry",
          label: "Country",
          type: "dropdown",
          xsCol: 12,
          selectOptions: (dropdownData.Countries || []).map((x) => ({
            value: x.Country,
            text: x.Country,
          })),
        },
      ],
    },
    {
      xsCol: 6,
      fields: [
        { name: "PostalStreet1", label: "Postal Address 1", xsCol: 12 },
        { name: "PostalStreet2", label: "Address 2", xsCol: 12 },
        { name: "PostalCity", label: "Town", xsCol: 12 },
        { name: "PostalState", label: "State", xsCol: 6 },
        { name: "PostalPostCode", label: "Postcode", xsCol: 6 },
        {
          name: "PostalCountry",
          label: "Country",
          type: "dropdown",
          selectOptions: (dropdownData.Countries || []).map((x) => ({
            value: x.Country,
            text: x.Country,
          })),
          xsCol: 12,
        },
      ],
    },
    {
      xsCol: 12,
      fields: [
        {
          name: "SiteBlockEvaluationsFrom",
          label: "Limited From",
          xsCol: 3,
          type: "date",
        },
        {
          name: "SiteExportOptionId",
          label: "Exports",
          type: "dropdown",
          xsCol: 3,
          required: true,
          selectOptions: (dropdownData.ExportOptions || []).map((x) => ({
            value: x.ExportOptionId,
            text: x.ExportOption,
          })),
        },
      ],
    },
  ];
};


export const SiteActions = {
  LOAD_DATA: "load-data",
};

function SiteReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === SiteActions.LOAD_DATA) {
    return action.payload;
  }
  return state;
}
