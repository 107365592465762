import { Card, CardContent, Grid, Typography } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import { useUser } from '../../../rootContext';
import GetLaboratoriesNames from '../../../common/GetLaboratoriesNames';
import TraySummary from './TraySummary';
import Constants from '../../../common/Constants';
import GetStepNames from '../../../common/GetStepNames';

function TrayDashLab(props) {
    const { data, trayDate, labs } = props;
    const user = useUser();
    const [selectedCardLab, setSelectedCardLab] = useState({});

    let staticData = [
        "Laboratory",
        "Labels",
        "Samples",
        "Minimum Step",
        "Maximum Step",
        "Reruns",
        "Hold",
        "Missing Results",
        "Critical Logs",
        "Auto Evaluated",
    ];

    const handleCardClick = (items) => {
        setSelectedCardLab(items);
    };

    useEffect(() => {
        setSelectedCardLab({})
    }, [data])

    return (
        <Fragment >
            <Grid container width={"99vw"}>
                <Grid
                    item
                    xs={12}
                    sx={{
                        overflowX: "auto",
                        flexWrap: "nowrap",
                        ml: 2,
                        mt: 0.5,
                        borderRadius: 1,
                        display: "inline-flex",
                        "& > div:nth-of-type(2)": {
                            marginLeft: "120px",
                        },
                    }}
                >
                    <React.Fragment>
                        {data?.Trays?.length > 0 && (
                            <Grid item sx={{ position: "absolute" }}>
                                <Card
                                    sx={{
                                        borderRadius: 0,
                                        borderLeft: "1.1px solid gray",
                                    }}
                                >
                                    <CardContent
                                        style={{
                                            padding: "4px",
                                            backgroundColor:
                                                user?.DefaultLaboratoryId === data.LabId
                                                    ? "#253fc8"
                                                    : "",
                                            color:
                                                user?.DefaultLaboratoryId === data.LabId
                                                    ? "white"
                                                    : "",
                                            minWidth: "110px",
                                        }}
                                    >
                                        {staticData.map((item, index) => (
                                            <Typography
                                                sx={{
                                                    borderBottom: "1px solid #C0C0C0",
                                                    margin: "4px 0",
                                                    minWidth: "25px",
                                                    lineHeight: "1.33",
                                                    fontWeight: 650,
                                                }}
                                                key={index}
                                            >
                                                {index === 0
                                                    ? GetLaboratoriesNames(data.LabId).name
                                                    : item}
                                            </Typography>
                                        ))}
                                    </CardContent>
                                </Card>
                            </Grid>
                        )}
                        {data?.Trays?.length > 0 &&
                            data?.Trays?.map((items, j) => (
                                <React.Fragment key={j}>
                                    <Grid item textAlign="center">
                                        <Card
                                            sx={{
                                                borderRadius: 0,
                                                borderLeft: "1.1px solid gray",
                                            }}
                                        >
                                            <CardContent
                                                onClick={() => handleCardClick(items)}
                                                style={{
                                                    maxWidth: "100px",
                                                    padding: "4px",
                                                    backgroundColor: selectedCardLab === items ? "#87CEFA" : null,
                                                }}
                                            >
                                                <Typography
                                                    style={{
                                                        borderBottom: "1px solid #C0C0C0",
                                                        margin: "4px 0",
                                                        fontWeight: 650,
                                                    }}
                                                >
                                                    {"Tray " + items.Tray}
                                                </Typography>
                                                <Typography
                                                    style={{
                                                        lineHeight: "1.3",
                                                        margin: "4px 0",
                                                        width: "100px", // Set the desired width of the Typography component
                                                        whiteSpace: "nowrap", // Prevent text from wrapping to multiple lines
                                                        overflow: "hidden", // Hide any overflowing text
                                                        textOverflow: "ellipsis", // Show ellipsis (...) for truncated text
                                                        backgroundColor: "black",
                                                        color: "white",
                                                    }}
                                                    title={items.Label} // Show full text on hover using the title attribute
                                                >
                                                    {items.Label}
                                                </Typography>
                                                <Typography
                                                    style={{
                                                        lineHeight: "1.3",
                                                        borderBottom: "1px solid #C0C0C0",
                                                        margin: "4px 0",
                                                    }}
                                                >
                                                    {items.SampleCount}
                                                </Typography>
                                                <Typography
                                                    style={{
                                                        lineHeight: "1.3",
                                                        borderBottom: "1px solid #C0C0C0",
                                                        margin: "4px 0",
                                                        backgroundColor:
                                                            items.MinimumStep ===
                                                                Constants.completedStep
                                                                ? "green"
                                                                : items.MinimumStep ==
                                                                    Constants.approval
                                                                    ? "blue"
                                                                    : "",
                                                        color:
                                                            items.MinimumStep ===
                                                                Constants.completedStep ||
                                                                items.MinimumStep == Constants.approval
                                                                ? "white"
                                                                : "",
                                                    }}
                                                >
                                                    {GetStepNames(items.MinimumStep).mid}
                                                </Typography>
                                                <Typography
                                                    style={{
                                                        lineHeight: "1.3",
                                                        borderBottom: "1px solid #C0C0C0",
                                                        margin: "4px 0",
                                                        backgroundColor:
                                                            items.MaximumStep ===
                                                                Constants.completedStep
                                                                ? "green"
                                                                : items.MaximumStep ==
                                                                    Constants.approval
                                                                    ? "blue"
                                                                    : "",
                                                        color:
                                                            items.MaximumStep ===
                                                                Constants.completedStep ||
                                                                items.MaximumStep == Constants.approval
                                                                ? "white"
                                                                : "",
                                                    }}
                                                >
                                                    {GetStepNames(items.MaximumStep).mid}
                                                </Typography>
                                                <Typography
                                                    style={{
                                                        lineHeight: "1.3",
                                                        borderBottom: "1px solid #C0C0C0",
                                                        margin: "4px 0",
                                                    }}
                                                >
                                                    {items.Reruns}
                                                </Typography>
                                                <Typography
                                                    style={{
                                                        borderBottom: "1px solid #C0C0C0",
                                                        margin: "4px 0",
                                                    }}
                                                >
                                                    {items.HoldNotReruns}
                                                </Typography>
                                                <Typography
                                                    style={{
                                                        lineHeight: "1.3",
                                                        borderBottom: "1px solid #C0C0C0",
                                                        margin: "4px 0",
                                                        backgroundColor:
                                                            items.MaximumStep >= 3 &&
                                                                items.MissingResults > 0
                                                                ? "red"
                                                                : items.MaximumStep >= 3 &&
                                                                    items.MissingResults === 0
                                                                    ? "green"
                                                                    : "",
                                                        color:
                                                            (items.MaximumStep >= 3 &&
                                                                items.MissingResults > 0) ||
                                                                (items.MaximumStep >= 3 &&
                                                                    items.MissingResults === 0)
                                                                ? "white"
                                                                : "",
                                                    }}
                                                >
                                                    {items.MissingResults}
                                                </Typography>
                                                <Typography
                                                    style={{
                                                        lineHeight: "1.3",
                                                        borderBottom: "1px solid #C0C0C0",
                                                        margin: "4px 0",
                                                        backgroundColor:
                                                            items.MaximumStep > 6 &&
                                                                items.CriticalLogs > 0
                                                                ? "#ec4cad"
                                                                : "",
                                                        color:
                                                            items.MaximumStep > 6 &&
                                                                items.CriticalLogs > 0
                                                                ? "white"
                                                                : "",
                                                    }}
                                                >
                                                    {items.CriticalLogs}
                                                </Typography>
                                                <Typography
                                                    style={{
                                                        lineHeight: "1.3",
                                                        borderBottom: "1px solid #C0C0C0",
                                                        margin: "4px 0",
                                                    }}
                                                >
                                                    {items.AutoEval}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </React.Fragment>
                            ))}
                    </React.Fragment>
                </Grid>
            </Grid>

            <Grid container>
                {data && data?.Trays?.length > 0 && (
                    <Grid
                        item
                        sx={{
                            marginLeft: "16px",
                            marginRight: "16px",
                            mt: "5px",
                            width: "100%",
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "auto",
                        }}
                    >
                        <TraySummary
                            summary={selectedCardLab && selectedCardLab}
                            data={data}
                            trayDate={trayDate}
                            labs={labs}
                        />
                    </Grid>
                )}
            </Grid>
        </Fragment>
    )
}

export default TrayDashLab