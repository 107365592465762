import { Button, Card, Checkbox, FormControlLabel, Grid, Typography, } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import useFetch from "./../../../hooks/useFetch";
import { TRAY_APIS } from "../../../common/apis";
import PaperlessTable from "./PaperlessTable";
import TextBox from "../../../components/TextBox";
import { useUser } from "../../../rootContext";
import { useReactToPrint } from "react-to-print";
import AppDialog from "../../../components/AppDialog";
import ConfirmModal from "../../../components/ConfirmModal";
import { PrintSticker2 } from "./PrintSticker";
import ViewSticker from "./ViewSticker";

export default function Paperless() {

    const { get, post } = useFetch();
    const user = useUser();

    const regIdInput = useRef();
    // const componentRef = useRef();
    const componentRef2 = useRef();

    let audio = new Audio("/buzzer.mp3")

    const [tableData, setTableData] = useState([]);
    const [registrationVal, setRegistrationVal] = useState("");
    const [regData, setRegData] = useState({});
    const [errMessage, setErrMessage] = useState("")
    const [autoPrintCheck, setAutoPrintCheck] = useState(true);
    const [autoPrint, setAutoPrint] = useState(0)

    const [dialog, setDialog] = useState({
        title: "",
        message: "",
        actionName: "",
        open: false,
        width: "",
    });

    const sendToRegistration = async (a) => {
        setAutoPrint(0);
        // await post(TRAY_APIS.Paperless1, { RegId: a });
    }

    const handleDeleteEntry = (del) => {
        const arr = tableData.filter((item) => {
            return item.RegistrationId !== del
        })
        setTableData(arr);
        setRegData({});
        setRegistrationVal("");
        setDialog({ open: false, });
    }

    const actions = {
        handleDelete: function (row) {
            setDialog({
                title: "Delete",
                message: `Are you sure you want delete Bottle Number ${row?.BottleNumber} ?`,
                actionName: () => handleDeleteEntry(row?.RegistrationId),
                buttonText: "Delete",
                open: true,
                width: "md",
            })
        }
    };

    async function playSound() {
        setRegistrationVal("");
        regIdInput.current.focus();
        audio.play()
    }

    async function retrieveRegistration() {
        if (registrationVal) {
            // const temp = (await get(`${TRAY_APIS.REGISTRATIONS}?regId=` + registrationVal)) || {};
            const temp = (await get(`${TRAY_APIS.REGISTRATIONS_BOTTLENUMBER}?bottlenumber=` + registrationVal)) || {};
            const reg = temp?.Registration;
            const comp = temp?.Component;

            if (reg?.SIF > 0) {
                if (reg?.SampleNumber > 0) {
                    setRegData({});
                    setRegistrationVal("");
                    setErrMessage(`RegistrationId ${reg?.SIF} has already been registered for Sample Number ${reg?.SampleNumber}`);
                    return playSound();
                }
                if (reg?.BottleSampleNumber > 0) {
                    setRegData({});
                    setRegistrationVal("");
                    setErrMessage(`RegistrationId ${reg?.SIF} has already been entered against Sample Number ${reg?.BottleSampleNumber}`);
                    return playSound();
                }
                if (tableData.filter(i => i?.RegistrationId == reg?.SIF)?.length > 0) {
                    setRegData({});
                    setRegistrationVal("");
                    return setErrMessage(`RegistrationId ${reg?.SIF} has already been entered`);
                }
                const payload = {
                    BottleNumber: reg?.BottleNumber,
                    RegistrationId: reg?.SIF,
                    Customer: reg?.Customer,
                    Site: reg?.Site,
                    Model: reg?.UnitMakeNew + " " + reg?.UnitModelNew,//comp?.UnitModelFormatted,
                    Serial: reg?.SerialNew,//comp?.Serial,
                    Component: reg?.ComponentNameNew,//comp?.Component,
                    UnitMeter: reg?.UnitMeter,//comp?.LastMeterReading,
                    OilType: reg?.FluidTypeId,
                    Location: user?.DefaultLaboratory,
                    Tray: reg?.Tray,
                    UnitId: reg?.UnitId,
                    Unit: reg?.UnitNameNew,
                    ScannedDate: new Date().toLocaleString(),
                };
                setAutoPrint(reg?.SIF)
                setRegData(payload);
                setTableData([...tableData, payload]);
                setRegistrationVal("")
                setErrMessage("")

            }
            else {
                setRegData({});
                playSound();
                return setErrMessage(`Bottle Number ${registrationVal} does not exists!`);
            }
        }
        else return;
    }

    const handlePaste = event => {
        let val = (event?.clipboardData?.getData('text'));
        retrieveRegistration(val)
    };

    const handleEnterDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            retrieveRegistration(registrationVal)
        }
    }

    const handlePrintSticker = useReactToPrint({
        // content: () => componentRef.current,
        content: () => componentRef2.current,
        documentTitle: regData?.RegistrationId,
        // onBeforePrint: () => console.log("before printing..."),
        // onAfterPrint: () => console.log("after printing..."),
    });

    const handleSelectRow = (e) => {
        setErrMessage("");
        setRegData(e);
        setAutoPrint(0);
    }

    const handleKeyDown = useCallback(
        (event) => {
            if (event.key === "F4") {
                event.preventDefault();
                sendToRegistration(regData?.RegistrationId);
                handlePrintSticker();

            }
            if (event.key === "F12") {
                event.preventDefault();
                retrieveRegistration(registrationVal)
            }
        }, [registrationVal,]
    );

    useEffect(() => {
        if (regData?.RegistrationId > 0 && autoPrintCheck === true && autoPrint > 0) {
            sendToRegistration(regData?.RegistrationId);
            handlePrintSticker()
        }
    }, [regData])

    useEffect(() => {
        document.title = "Scan Bottles";

        const timeout = setTimeout(() => {
            regIdInput.current.focus();
        }, 100);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={2} >
                    <Card sx={{
                        p: 1,
                        minHeight: "89dvh",//"78vh"
                        backgroundColor: "#e2f2ff"
                    }}>
                        <Grid container spacing={1}>

                            <Grid item flex={1}>
                                <TextBox
                                    ref={regIdInput}
                                    id={"RegistrationId"}
                                    name="RegistrationId"
                                    value={registrationVal}
                                    onChange={(e) => {
                                        let a = e.target.value.replaceAll("*", "");
                                        setRegistrationVal(a);
                                    }}
                                    // placeHolder={"Registration Id"}
                                    placeHolder={"Bottle Number"}
                                    onPaste={(e) => handlePaste(e)}
                                    onKeyUp={(e) => handleEnterDown(e)}
                                    autoComplete="off"
                                    autoFocus
                                    sx={{ "& .MuiInputBase-input": { backgroundColor: "white" } }}
                                />
                            </Grid>
                            <Grid item >
                                <Button onClick={retrieveRegistration}>Scan Bottle (F12)</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={autoPrintCheck}
                                            onClick={() => setAutoPrintCheck(!autoPrintCheck)}
                                            sx={{
                                                [`.MuiSvgIcon-root`]: { //fill: "white",
                                                    fontSize: "18px"
                                                }
                                            }}
                                        />
                                    }
                                    label="Scan and Print"
                                    sx={{ margin: 0 }}
                                />
                            </Grid>
                            {/*  STICKER  AREA  */}
                            <Grid item xs={12}  >
                                {regData?.RegistrationId > 0 &&
                                    <Grid container justifyContent={"center"} sx={{ border: "1px solid black", p: 1, backgroundColor: "#E9F4FF", mt: 1, }}  >
                                        <Grid item xs={12}  >
                                            <Typography  >Bottle Sticker</Typography>
                                            <ViewSticker
                                                regData={regData}
                                            />

                                            <Grid hidden={true}>
                                                {/* <div ref={componentRef}  >
                                                    <PrintSticker
                                                        regData={regData}
                                                    />
                                                </div> */}
                                                <div ref={componentRef2}  >
                                                    <PrintSticker2
                                                        regData={regData}
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>

                                        {/* <Grid item  >
                                            <Button
                                                sx={{ ml: 2, backgroundColor: "white", mt: 2 }}
                                                variant="outlined"
                                                onClick={() => {
                                                    sendToRegistration(regData?.RegistrationId);
                                                    handlePrintSticker();
                                                }}
                                            >
                                                Print Sticker (F4)</Button>
                                        </Grid> */}

                                        <Grid item  >
                                            <Button
                                                sx={{ ml: 2, backgroundColor: "white", mt: 2 }}
                                                variant="outlined"
                                                onClick={() => {
                                                    sendToRegistration(regData?.RegistrationId);
                                                    handlePrintSticker();
                                                }}
                                            >
                                                Print Sticker  (F4)</Button>
                                        </Grid>
                                    </Grid>
                                }
                                {errMessage?.length > 0
                                    ? <Typography sx={{ color: "red", position: "relative", top: regData?.RegistrationId > 0 ? "10dvh" : "40dvh", border: "1px solid red", p: 1 }}>{errMessage}</Typography>
                                    : <></>
                                }
                            </Grid>

                        </Grid>
                    </Card>
                </Grid >

                <Grid item xs={10} >
                    <Typography  >Total Records: <b>{0 || tableData?.length}</b></Typography >

                    <PaperlessTable
                        data={tableData}
                        actions={actions}
                        rowsSelected={handleSelectRow}
                    />
                </Grid>
            </Grid >

            <AppDialog
                open={dialog.open}
                handleClose={() =>
                    setDialog((prevState) => ({
                        ...prevState,
                        open: false,
                    }))
                }
                title={dialog.title}
                maxWidth={dialog.width}
            >
                <ConfirmModal
                    handleClose={() => {
                        setDialog((prevState) => ({
                            ...prevState,
                            open: false,
                        }));
                    }}
                    alertAction={dialog?.actionName}
                    message={dialog.message}
                    buttonText={dialog?.buttonText || " "}
                    buttonText2={"Cancel"}
                />
            </AppDialog>
        </>
    );
}