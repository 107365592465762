import { Button, Card, Grid, Typography } from "@mui/material"
import { MANAGEMENT_APIS } from "../../../common/apis";
import useFetch from "../../../hooks/useFetch";
import { useCallback, useEffect, useMemo, useState } from "react";
import TextBox from "../../../components/TextBox";
import Form, { FormActions } from "../../../components/Form";
import WebsiteNewsTable from "./WebsiteNewsTable";
import { FormSections, WebsiteFormDefaults, WebsiteFormReducer } from "./WebsiteNewsForm";
import { useReducer } from "react";
import moment from "moment";
import { useUser } from "../../../rootContext";

const WebsiteNews = () => {

    const { get, post } = useFetch();
    const user = useUser();

    const [data, dispatch] = useReducer(WebsiteFormReducer, WebsiteFormDefaults);
    const [tableData, setTableData] = useState([]);
    const [filter, setFilter] = useState("");

    const getWebsiteNews = async () => {
        const resp = await get(MANAGEMENT_APIS.WEBSITE_NEWS)
        setTableData(resp)
    }

    const deleteWebsiteNews = async () => {
        if (data?.WebsiteNewsId > 0) {
            const resp = await get(`${MANAGEMENT_APIS.DELETE_WEBSITE_NEWS}?WebsiteNewsId=${data?.WebsiteNewsId}&Screen=Website News`)
            if (resp > 0) {
                getWebsiteNews();
                handleResetForm();
            }
        }
    }

    const editWebsiteNews = {
        ...data,
        Modified: moment(),
        ModifiedBy: user?.Username,
    }

    const handleSaveClick = async () => {
        if (data?.WebsiteNewsId > 0) {
            await post(MANAGEMENT_APIS.EDIT_WEBSITE_NEWS, editWebsiteNews)
        }
        else {
            await post(MANAGEMENT_APIS.EDIT_WEBSITE_NEWS, {
                ...data,
                DisplayFrom: data?.noStartDate === true ? null : data?.DisplayFrom,
                DisplayTo: data?.noEndDate === true ? null : data?.DisplayTo,
                Added: moment(),
                AddedBy: user?.Username
            })
        }
        getWebsiteNews();
        handleResetForm();
    };

    const handleRowSelect = (rows) => {
        if (rows?.length > 0) {
            dispatch({
                type: FormActions.LOAD_DATA,
                payload: rows[0],
            });
        }
    }

    const hanldeButtonClick = (name, val) => {
        dispatch({
            type: FormActions.LOAD_DATA,
            payload: { ...data, [name]: [val], key: Date.now() }
        })
    }

    const handleResetForm = () => {
        dispatch({ type: FormActions.RESET_FORM });
    }

    function handelFilterChange(e) {
        setFilter(e.target.value);
    }

    const filteredData = useMemo(
        function () {
            return tableData
                .filter(
                    (x) =>
                        (x.HeadingHtml.includes(filter))
                );
        },
        [tableData, filter]
    );

    const handleKeyDown = useCallback(
        (event) => {
            if (event.key === "F11") {
                event.preventDefault();
                deleteWebsiteNews();
            }
            if (event.key === "F12") {
                event.preventDefault();
                handleSaveClick();
            }
        }, [data]);


    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown]);

    useEffect(() => { getWebsiteNews() }, [])

    return (
        <Grid
            container
            spacing={2}
        >
            <Grid item xs={12} mt={1}>
                <Card sx={{ padding: 1 }}>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid item >
                            <TextBox
                                name="startsWith"
                                label="Starts with"
                                onChange={handelFilterChange}
                            //value={filter.startsWith}
                            />
                        </Grid>
                        <Grid item alignContent={"end"} >
                            <Button sx={{ mt: 1 }} onClick={handleResetForm}>New</Button>
                        </Grid>

                        <Grid item alignContent={"center"}>
                            <Typography  >Add the news item heading and body as HTML</Typography>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>

            <Grid item xs={12}>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid item xs={5} >
                        <WebsiteNewsTable
                            data={filteredData}
                            rowsSelected={handleRowSelect}
                        />
                    </Grid>

                    <Grid item xs={7} >
                        <Card sx={{ padding: 1, minHeight: "75vh" }}>
                            <Form
                                sections={FormSections(data, hanldeButtonClick, deleteWebsiteNews, handleSaveClick)}
                                dispatch={dispatch}
                                data={data}
                                key={data?.key}
                            />
                        </Card>
                    </Grid>

                </Grid>
            </Grid>
        </Grid>
    )
}

export default WebsiteNews;