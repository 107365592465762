import { Box, Button, Card, Grid, Typography } from "@mui/material";
import moment from "moment/moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { LabSteps, ResultColWidths } from "../../../common/Constants";
import DropDown from "../../../components/DropDown";
import TextBox from "../../../components/TextBox";
import useFetch from "../../../hooks/useFetch";
import { useRoot, useUser } from "../../../rootContext";
import { DateFormat, copyTextToClipboard, toLocalDate } from "../../../utils";
import HistoryTable from "./HistoryTable";
import ResultsTable2 from "./ResultsTable2";
import SearchFilter from "./SearchFilter";
import Hold from "./Hold";
import AppDialog from "./../../../components/AppDialog";
import ImagePick from "./../../../components/SelectImage";
import SwitchInput from "../../../components/SwitchInput";
import { TRAY_APIS } from "../../../common/apis";
import TickBlue from "../../../Image/TickBlue.svg";
import TickGreen from "../../../Image/TickGreen.svg";
import appResources from "../../../app-resources/en/Resources.json";
import ResizePanel from "react-resize-panel";
import { useScreenPopups } from "../../../components/useScreenPopups";
import GroupDialog from "../../../components/GroupDialog";
import { DataGridAction } from "../../../components/DataTable";
import DropdownMenu from "../../../components/DropdownMenu";
import { BadgeButton } from "../../../components/FormFooter";
import renderCellExpand from "../../../components/DatatableCellExpand";
import SamplesTable2 from "./SamplesTable2";
import { useWindowPopups } from "../../../components/useWindowPopups";

let columnsTestItem = "";
export default function Laboratory2({ laboratoryActions }) {
  const { post, get, getFile } = useFetch();
  const user = useUser();
  const { CanLaboratoryTrays } = useRoot();

  const input1 = useRef();

  const [samplesData, setSamplesData] = useState({});
  const [trayData, setTrayData] = useState({});
  const [results, setResults] = useState({});
  const [lockResults, setLockResults] = useState(true);
  const [isCompletedSample, setIsCompletedSample] = useState(false);
  const [isCompletedTray, setIsCompletedTray] = useState(false);
  const [infoMessage, setInfoMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [isInfoIcon, setIsInfoIcon] = useState(false);
  const [isSuccessIcon, setIsSuccessIcon] = useState(false);
  const [isWarningTrue, setIsWarningTrue] = useState(false);
  const [dialogType, setDialogType] = useState({ open: false });
  const [holdOpen, setHoldOpen] = useState(false);
  const [selectedSampleNo, setSelectedSampleNo] = useState(0);
  const [selectedSample, setSelectedSample] = useState(0);
  const [filterState, setFilterState] = useState();
  const [imageCount, setImageCount] = useState("");
  const [history, setHistory] = useState({});
  const [viewType, setViewType] = useState(0);
  const [selectedRowId, setSelectedRowId] = useState(0);
  const [originalText, setOriginalText] = useState("");

  const [dialog, setDialog] = useState({
    title: "",
    open: false,
  });

  function sampleInputOnChangeHandler(e) {
    if (selectedSample.Step > 1) {
      setLockResults(e.target.checked);
      setIsWarningTrue(false);
      // setIsSuccessIcon(false);
      setIsInfoIcon(false);
      setInfoMessage();
    } else {
      setIsWarningTrue(true);
      //  setIsSuccessIcon(false);
      setIsInfoIcon(true);
      setInfoMessage(
        `Unable to update cell value. The sample ${selectedSample.SampleNumber} is at registration step at the moment.`
      );
    }
  }

  //useEffect to handle click any where on the screen

  function handleImageClick() {
    setDialog({
      open: true,
      title: `Sample Images: Sample Number ${selectedSampleNo}`,
    });
  }
  // Imran Zulfiqar

  async function onViewHandler(filter, i) {
    if (filter.Date === "Invalid date") {
      setIsWarningTrue(true);
      setIsInfoIcon(true);
      return setInfoMessage(appResources.INVALID_DATE_FORMAT_MSG);
    }

    setIsWarningTrue(false);
    setIsInfoIcon(false);
    setInfoMessage();
    setFilterState(filter);
    const resp = await post(TRAY_APIS.GET_LABORATORY, {
      Date:
        filter.LabAndDateOption === 2 || filter.LabAndDateOption === 4
          ? null
          : moment(filter.Date).format(DateFormat),
      LabId:
        filter.LabAndDateOption === 3 || filter.LabAndDateOption === 4
          ? null
          : user.DefaultLaboratoryId,
      TrayNumber: filter.LabAndDateOption === 0 ? filter.Tray : null,
      MinimumStep: LabSteps.Registration,
      MaximumStep:
        filter.LabAndDateOption === 0
          ? LabSteps.Completed
          : LabSteps.Laboratory,
      Hold: true,
    });

    if (resp.Tray.length > 0) {

      setIsWarningTrue(false);
      setIsInfoIcon(false);
      setInfoMessage();
      setSamplesData(resp);
      const a = resp.Tray.map((x, i) => ({ ...x, keyProp: i, }));
      if (i > 0) {
        setSelectedRowId(i)
        setSelectedSampleNo(resp.Tray[i].SampleNumber);
        setSelectedSample(resp.Tray[i]);
        const key = a[i];
        if (key !== undefined) {
          sampleRowSelectedHandler(key)
        }
      }
      else {
        setSelectedRowId(0)
        sampleRowSelectedHandler(resp.Tray[0]);
        setSelectedSampleNo(resp.Tray[0].SampleNumber);
        setSelectedSample(resp.Tray[0]);
      }
      return setTrayData(a)
    }
    else {
      input1.current.value = "";
      setSelectedSampleNo(0);
      setOriginalText("");
      setSamplesData({});
      setTrayData([])
      setIsWarningTrue(true);
      setIsInfoIcon(true);
      return setInfoMessage(appResources.TRAY_DOES_NOT_EXIST);
    }
  }

  async function refreshGrid(i) {
    if (filterState) {
      onViewHandler(filterState, i);
    }
  }

  const handleRowEdit = async (e, v) => {
    if (v.row.original[v.column.columnDef.accessorKey] === e.target.value) {
      return;
    }
    else {
      const SampleNumber = v.row.original.SampleNumber;
      const TId = v.row.original[v.column.columnDef.accessorKey + "_id"];
      let TestItemId = TId > 0 ? TId : v?.column?.id;

      const formattedValue = e.target.value;
      const resp = await get(
        `${TRAY_APIS.EDIT_RESULT_TEXT}?SampleNumber=${SampleNumber}&TestItemId=${TestItemId}&formattedValue=${formattedValue === "" ? null : formattedValue}`
      );
      if (resp > 0) {

        const index = samplesData?.TrayResults?.findIndex((objs) => objs[0]?.SampleNumber === SampleNumber);
        const check = samplesData?.TrayResults[index]?.filter(i => i.TestItemId === TestItemId)?.length > 0;

        const newState = samplesData?.TrayResults[index].map(obj => {
          if (obj.TestItemId === TestItemId) {
            return { ...obj, FormattedText: formattedValue.toString() };
          }
          return obj;
        })

        const newData = [...newState, {
          SampleNumber: SampleNumber, TestItem: v.column.columnDef.accessorKey, TestItemId: TestItemId, TestItemCode: v.column.columnDef.accessorKey, FormattedText: formattedValue.toString()
        }]
        const replacedItem = samplesData.TrayResults

        replacedItem[index] = check === true ? newState : newData;

        setSamplesData({ ...samplesData, TrayResults: replacedItem })
      }
    }
  };

  const HandleNewWindow = (actionName, data) => {
    useWindowPopups(actionName, data)
  }

  function handleChartClick() {
    HandleNewWindow("chart", selectedSample)
  }

  function handelViewTypeChange(e) {
    setViewType(e.target.value);
  }

  async function getData(sample) {
    const CommentsData = await get(`${TRAY_APIS.SAMPLE_NOT_IMAGE_COUNT}?SampleNumber=${sample?.SampleNumber}`);
    if (CommentsData[0].SampleNotes) {
      if (sample?.Step > 1 && sample?.Released !== null) {
        setOriginalText(CommentsData[0].SampleNotes);
      }
      else {
        input1.current.value = CommentsData[0].SampleNotes;
      }
    } else {
      input1.current.value = "";
      setOriginalText("");
    }
    setImageCount(CommentsData[0].SampleImageCount);
  }

  async function saveNotes() {
    let labComments = "";
    if (selectedSample?.Step > 1 && selectedSample?.Released !== null) {
      labComments = originalText + "\n" + input1.current.value;
    }
    else {
      labComments = input1.current.value ? input1.current.value : null;
    }

    await post(TRAY_APIS.UPDATE_SAMPLE_NOTE, {
      SampleNumber: selectedSampleNo,
      Note: labComments,
      Screen: "Laboratory",
    });
  }

  async function ReleaseSampleClick() {
    let labComments = "";
    if (selectedSample?.Step > 1 && selectedSample?.Released !== null) {
      labComments = originalText + "\n" + input1.current.value;
    }
    else {
      labComments = input1.current.value ? input1.current.value : null;
    }

    const response = await post(TRAY_APIS.RELEASE_SAMPLE, {
      SampleNumber: selectedSample.SampleNumber,
      Note: labComments,
      Screen: "Laboratory",
    });
    if (response > 0) {
      refreshGrid(selectedRowId + 1);
      setIsWarningTrue(false);
      setIsInfoIcon(false);
      setIsSuccessIcon(true);
    }
  }

  async function ReleaseTrayClick() {
    let labComments = "";
    if (selectedSample?.Step > 1 && selectedSample?.Released !== null) {
      labComments = originalText + "\n" + input1.current.value;
    }
    else {
      labComments = input1.current.value ? input1.current.value : null;
    }
    const response = await post(TRAY_APIS.RELEASE_TRAY, {
      SampleNumber: selectedSample.SampleNumber,
      Note: labComments,
      Screen: "Laboratory",
    });
    if (response > 0) {
      refreshGrid(selectedRowId + 1);
      setIsWarningTrue(false);
      setIsInfoIcon(false);
    }
  }

  function handleSaveClick() {
    saveNotes();
  }

  function sampleRowSelectedHandler(sample) {
    if (sample?.keyProp !== undefined) {

      setSelectedRowId(sample?.keyProp || 0)
    }
    if (sample.SampleNumber === selectedSampleNo) {
      return;
    }
    input1.current.value = "";
    setIsWarningTrue(false);
    setIsInfoIcon(false);
    let totalValCount = 0;
    let hasValCount = 0;

    const d1 = results?.data?.filter(i => i?.SampleNumber === sample?.SampleNumber)[0];
    if (d1 && Object.entries(d1)?.length > 0) {

      for (const key in d1) {
        // for (const key in sample) {
        if (
          (key.endsWith("_hasVal") || key.endsWith("_valReq")) &&
          d1[key] === true
        ) {
          totalValCount++;
        }
      }
      for (const key in d1) {
        if (key.endsWith("_hasVal") && d1[key] === true) {
          hasValCount++;
        }
      }
      if (totalValCount === hasValCount && totalValCount > 0 && hasValCount > 0) {
        setIsCompletedSample(true);
      } else {
        setIsCompletedSample(false);
      }
    }
    else { setIsCompletedSample(false); }

    setSelectedSampleNo(sample.SampleNumber);
    setSelectedSample(sample);
    if (sample.Step < 2) {
      setLockResults(true);
    }
    localStorage.setItem("chartsData", JSON.stringify({ EqpMachineId: sample?.EqpMachineId || 0, sampleId: sample?.SampleNumber || 0, hideProp: 1 }));

    getData(sample);
  }

  useEffect(
    function () {
      (async function () {
        if (!selectedSampleNo) {
          setHistory({});
          return;
        }

        const sampleData = await get(`${TRAY_APIS.HISTORY}?sampleNo=${selectedSampleNo}&includeLaterSamples=true&resultView=0`, {});
        const { columns, data } =
          viewType === 1
            ? getDataAndColumnsPastSamplesHistory(
              sampleData,
              laboratoryActions,
              rowActionHandler
            )
            : viewType === 2
              ? getDataAndColumnsPastSamples(sampleData)
              : {};
        setHistory({ columns, data });
      })();
    },
    [selectedSampleNo, viewType]
  );

  function handleHoldClick() {
    setHoldOpen(true);
  }

  async function getSampleReport(row) {
    try {
      let fileName = `${row?.Customer} ${row?.Site} ${row?.Unit} ${row?.Component} ${row?.SampleNumber} `;
      await getFile(`${TRAY_APIS.REPORT_SAMPLE}/?sampleNo=${row?.SampleNumber}`, fileName);
    } catch (error) {
      console.error("Error fetching or opening PDF:", error);
    }
  }

  function getSampleGraph(row) {
    var windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    var windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    var url = `/Chart?EqpMachineId=${selectedSample?.EqpMachineId}&sampleId=${selectedSampleNo}&hideProp=2`;
    var windowFeatures = "width=" + windowWidth + ",height=" + windowHeight + ",top=0,left=0,resizable,scrollbars=yes";

    window.open(url, "_blank", windowFeatures);
  }

  function rowActionHandler(row, actionName) {
    if (
      [
        "add-sample-images",
        "edit-sample",
        "hold-management",
        "edit-sample",
      ].indexOf(actionName) > -1
    ) {
      openDialog(row, actionName);
      return;
    } else if (actionName === "view-graphs") {
      return getSampleGraph(row);
    } else if (actionName === "view-sample-report") {
      return getSampleReport(row);
    } else if (actionName === "copy-sample-number") {
      return copyTextToClipboard(row?.SampleNumber);
    }
  }

  const dialogOptions = useScreenPopups({
    data: dialogType.data || {},
    cancel: closeDialog,
    dialogType: dialogType.type,
  });

  function openDialog(row, actionName) {
    setDialogType({ type: actionName, open: true, data: row });
  }

  function closeDialog() {
    setDialogType({ ...dialogType, open: false });
  }

  const handleUpArrow = () => {
    if (selectedRowId > 0) {
      const key = trayData[selectedRowId - 1];
      if (key !== undefined) {
        sampleRowSelectedHandler(key)
      }
    }
  }

  const handleDownArrow = () => {
    if (selectedRowId < trayData?.length) {
      const key = trayData[selectedRowId + 1];
      if (key !== undefined) {
        sampleRowSelectedHandler(key)
      }
    }
  }

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "F4" && samplesData?.Tray?.length > 0) {
        event.preventDefault();
        handleChartClick();
      } else if (event.key === "F2") {
        event.preventDefault();
        if (CanLaboratoryTrays) {
          setHoldOpen(true);
        }
      } else if (event.key === "F3") {
        event.preventDefault();
        handleImageClick();
      } else if (event.key === "F12") {
        event.preventDefault();
        if (
          CanLaboratoryTrays &&
          selectedSample.Step > 1 &&
          selectedSample.HoldType === null
        ) {
          ReleaseSampleClick();
        }
      }
      else if (event.key === "ArrowDown" && !document.activeElement.className.includes("Mui")) {
        event.preventDefault();
        handleDownArrow()
      }
      else if (event.key === "ArrowUp" && !document.activeElement.className.includes("Mui")) {
        event.preventDefault();
        handleUpArrow()
      }
      else if (event.key === "F11") {
        event.preventDefault();
        if ((CanLaboratoryTrays
          && (samplesData?.Tray?.every((sample) => sample.HoldTypeId === null))
          && (trayCheck1 >= (samplesData?.Tray?.length - 4)))) {
          ReleaseTrayClick()
        }
      }
    },
    [samplesData, selectedSample, input1, originalText]
  );

  useEffect(() => {
    if (samplesData.TrayResults) {

      const { data, columns } = getDataAndColumns(
        samplesData,
        setIsCompletedTray,
        handleRowEdit,
        handleDownArrow,
        handleUpArrow,
      );
      setResults({ data, columns });
      return;
    }
    setResults({});
  }, [samplesData]);

  useEffect(() => {
    document.title = "Laboratory";

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  const trayCheck = (samplesData?.TrayResults?.map(item => item.length)?.filter(item => item < 50));
  const trayCheck1 = trayCheck?.length > 0 ? trayCheck?.reduce((a, b) => a + b) : 0;

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card sx={{ p: 0.5 }}>
            <Grid
              container
            >
              <Grid item xs={10}>
                <SearchFilter
                  onView={onViewHandler}
                  infoMessage={infoMessage}
                  successMessage={successMessage}
                  isInfoIcon={isInfoIcon}
                  isSuccessIcon={isSuccessIcon}
                  isWarningTrue={isWarningTrue}
                />
              </Grid>
              <Grid item justifyContent="flex-end" alignSelf="flex-end">
                <Typography>
                  Total Records: <b> {samplesData?.Tray?.length || 0}</b>
                </Typography>
              </Grid>
            </Grid>

          </Card>
        </Grid>

        <Grid item xs={12}>
          {/* <ResizePanel direction="s" height="10px" style={{ paddingBottom: "10px" }}> */}
          <div id={"s1"}>
            <SamplesTable2
              data={samplesData?.Tray || []}
              rowSelected={sampleRowSelectedHandler}
              selectedRow={selectedRowId}
              selectedSampleNo={selectedSampleNo}
              rowActionHandler={rowActionHandler}
            />
          </div>
          {/* </ResizePanel> */}
        </Grid>

        <Grid
          item
          xs={12}
          hidden={samplesData && samplesData?.Tray?.length > 0 ? false : true}
        >
          <Card sx={{ p: 1 }}>
            <Grid container justifyContent="space-between" spacing={1}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  {(selectedSample?.Step > 1 && selectedSample?.Released !== null) &&
                    <Grid item xs={3}>
                      <TextBox
                        label={"Original Notes"}
                        multiline
                        rows={4}
                        sx={{ "& .MuiInputBase-input": { backgroundColor: "#dda0dd" } }}
                        readOnly={true}
                        value={originalText}
                      />
                    </Grid>}
                  <Grid item xs={3}>
                    <TextBox
                      label={"Lab Notes (New)"}
                      multiline
                      rows={4}
                      readOnly={!CanLaboratoryTrays}
                      ref={input1}
                    />
                  </Grid>


                  <Grid item xs={6} justifyContent="flex-end" alignSelf="end" >
                    <Grid container spacing={1}>

                      <Grid item xs={4} alignSelf="end">
                        {CanLaboratoryTrays && (
                          <Grid container spacing={1}>

                            <Grid
                              item
                              xs={12}
                            >
                              <Button
                                sx={{ width: "100px", marginBottom: 2 }}
                                variant="contained"
                                onClick={handleSaveClick}
                                disabled={!samplesData?.Tray?.length}
                              >
                                Save Text
                              </Button>
                            </Grid>

                            <Grid item xs={12}
                            >
                              <SwitchInput
                                value={lockResults || false}
                                label="Lock Results"
                                className={samplesData?.Tray?.length ? "red" : ""}
                                disabled={!samplesData?.Tray?.length}
                                onChange={sampleInputOnChangeHandler}
                              />
                            </Grid>

                          </Grid>
                        )}
                      </Grid>

                      <Grid item xs={8}>
                        <Grid container spacing={1}
                          justifyContent="flex-end"   >
                          <Grid item xs={4}>
                            {isCompletedSample && (
                              <Box
                                component="img"
                                sx={{
                                  height: 41,
                                  marginTop: "20px",
                                }}
                                alt="TickBlue"
                                src={TickBlue}
                              />
                            )}{" "}
                            {isCompletedTray && (
                              <Box
                                component="img"
                                sx={{
                                  height: 41,
                                  marginTop: "20px",
                                }}
                                alt="TickGreen"
                                src={TickGreen}
                              />
                            )}
                          </Grid>

                          <Grid item xs={8}>
                            <Grid container spacing={1}
                            >
                              <Grid item xs={12}>
                                <Grid container spacing={1} justifyContent="flex-end">
                                  <Grid item>
                                    <DropDown
                                      sx={{ width: "120px" }}
                                      value={viewType}
                                      name="viewType"
                                      onChange={handelViewTypeChange}
                                      disabled={!samplesData?.Tray?.length}
                                      selectOptions={[
                                        { text: "Result", value: 0 },
                                        { text: "History", value: 1 },
                                        { text: "History Simple", value: 2 },
                                      ]}
                                    />
                                  </Grid>
                                  <Grid item>
                                    <Button
                                      sx={{ width: "100px" }}
                                      variant="outlined"
                                      onClick={handleChartClick}
                                      disabled={!samplesData?.Tray?.length}
                                    >
                                      Charts (F4)
                                    </Button>
                                  </Grid>

                                  {CanLaboratoryTrays &&
                                    selectedSample.Step > 1 &&
                                    selectedSample.HoldType === null && (
                                      <Grid item>
                                        <Button
                                          onClick={ReleaseSampleClick}
                                          sx={{ width: "145px" }}
                                        >
                                          Release Sample (F12)
                                        </Button>
                                      </Grid>
                                    )}

                                </Grid>

                              </Grid>

                              <Grid item xs={12}>
                                <Grid container spacing={1} justifyContent="flex-end">

                                  {CanLaboratoryTrays && (
                                    <Grid item>
                                      <Button
                                        sx={{ width: "120px" }}
                                        variant="outlined"
                                        onClick={handleHoldClick}
                                        disabled={!samplesData?.Tray?.length}
                                      >
                                        Hold (F2)
                                      </Button>
                                    </Grid>
                                  )}

                                  <Grid item>
                                    <BadgeButton
                                      sx={{ width: "100px" }}
                                      variant="outlined"
                                      badge={{ badgeContent: imageCount || 0, color: "error" }}
                                      label="Images (F3)"
                                      onClick={handleImageClick}
                                    />
                                  </Grid>

                                  <Grid item>
                                    <Button
                                      sx={{ width: "145px" }}
                                      onClick={ReleaseTrayClick}
                                      disabled={!(CanLaboratoryTrays
                                        && (samplesData?.Tray?.every((sample) => sample.HoldTypeId === null))
                                        && (trayCheck1 >= (samplesData?.Tray?.length - 4)))
                                      }
                                    >
                                      {viewType > 0
                                        ? "Release All (F11)"
                                        : "Release Tray (F11)"}
                                    </Button>
                                  </Grid>
                                  {/* )} */}
                                </Grid>
                              </Grid>

                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                    </Grid>
                  </Grid>

                </Grid>
              </Grid>

            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12}>
          {viewType === 1 || viewType === 2 ? (
            <ResizePanel direction="s" style={{ paddingBottom: "10px" }}>
              <HistoryTable {...history} key={viewType} />
            </ResizePanel>
          ) : (
            <div id={"s1"}>
              <ResultsTable2
                {...results}
                rowSelected={sampleRowSelectedHandler}
                selectedRow={selectedRowId}
                selectedSampleNo={selectedSampleNo}
                lockResults={lockResults}
              />
            </div>
          )}
        </Grid>
      </Grid>

      <AppDialog
        open={dialog.open}
        handleClose={() =>
          setDialog((prevState) => ({ ...prevState, open: false }))
        }
        title={`Sample Images: Sample Number ${selectedSampleNo}`}
        maxWidth="xl"
      >
        <ImagePick sampleId={selectedSampleNo} setDialog={setDialog} />
      </AppDialog>
      <AppDialog
        open={holdOpen}
        handleClose={() => setHoldOpen(false)}
        title="Put on Hold from Laboratory"
        maxWidth="xl"
      >
        <Hold
          sampleId={selectedSampleNo}
          refreshGrid={refreshGrid}
          setHoldOpen={setHoldOpen}
        />
      </AppDialog>

      <GroupDialog
        {...dialogOptions}
        open={dialogType.open || false}
        close={closeDialog}
      />
    </>
  );
}

function getDataAndColumns(
  { TrayResults: results, TestSetItems: testSetItems, Tray: sampleData },
  setIsCompletedTray, handleRowEdit, handleDownArrow, handleUpArrow,
) {
  const samplesMap = {};
  sampleData.forEach(function (s) {
    samplesMap[s.SampleNumber] = s;
  });
  const testItems = new Set();
  const testFilterItemsList = new Set();
  const dataObject = {};
  let testData = [];

  let allRegCheck =
    sampleData.filter((x) => x.Step < 2)?.length > 0 ? false : true;

  results.forEach(function (r) {
    if (r[0] != null) {
      if (!dataObject[r[0].SampleNumber]) {
        dataObject[r[0].SampleNumber] = { ...samplesMap[r[0].SampleNumber] };
      }
    }
    r.forEach(function (item) {
      testItems.add(item.TestItemCode + ": " + item.TestItem);
      testFilterItemsList.add(item.TestItemCode + ":" + item.TestItemId);
      var sample = dataObject[r[0].SampleNumber];
      sample[item.TestItemCode] = item.FormattedText;
      sample[item.TestItemCode + "_id"] = item.TestItemId;
    });
    testData = r.map((item) => {
      return {
        TestItemCode: item.TestItemCode,
        TestItem: item.TestItem,
        TestItemId: item.TestItemId,
      };
    });
  });
  const data = [];

  Object.keys(dataObject).forEach(function (key) {
    var sample = dataObject[key];
    (testSetItems[sample.TestSetId] || []).forEach(function (item) {
      if (!sample[item.TestItemCode]) {
        sample[item.TestItemCode + "_valReq"] = true;
        sample[item.TestItemCode + "_id"] = item.TestItemId;
        return;
      }
      sample[item.TestItemCode + "_hasVal"] = true;
    });
    data.push(sample);
  });

  let columns = [];

  if (allRegCheck === false) {
    columns = testData.map((x, i) => {

      return {
        id: x.TestItemId,
        header: x.TestItemCode,
        accessorKey: x.TestItemCode,
        size: ResultColWidths[x] || (x.TestItemCode.length < 3 ? 35 : x.TestItemCode.length < 5 ? 45 : 60),
        sortable: false,
        // enableEditing: !lockResults,
        muiEditTextFieldProps: ({ cell, column, row, table }) => ({
          placeholder: '',
          onKeyUp: (event) => {
            if (event.key === 'ArrowLeft') {
              let a = event.target.parentElement.parentElement.parentElement.previousElementSibling;
              const clickEvent = document.createEvent('MouseEvents');
              clickEvent.initEvent('dblclick', true, true);
              a.dispatchEvent(clickEvent);
              handleRowEdit(event, cell);
            }
            if (event.key === 'ArrowRight') {
              let a = event.target.parentElement.parentElement.parentElement.nextElementSibling;
              const clickEvent = document.createEvent('MouseEvents');
              clickEvent.initEvent('dblclick', true, true);
              a.dispatchEvent(clickEvent);
              handleRowEdit(event, cell);
            }
            if (event.key === 'ArrowUp') {
              let a = event.target.parentElement.parentElement.parentElement.parentElement.previousElementSibling;
              a = a.childNodes[i + 1];
              const clickEvent = document.createEvent('MouseEvents');
              clickEvent.initEvent('dblclick', true, true);
              a.dispatchEvent(clickEvent);
              handleRowEdit(event, cell);
            }
            if (event.key === 'ArrowDown') {
              let a = event.target.parentElement.parentElement.parentElement.parentElement.nextElementSibling;
              a = a.childNodes[i + 1];
              const clickEvent = document.createEvent('MouseEvents');
              clickEvent.initEvent('dblclick', true, true);
              a.dispatchEvent(clickEvent);
              handleRowEdit(event, cell);
            }
          },
          onBlur: (event) => {
            handleRowEdit(event, cell);
          },
        }),
      }
    });
  } else {
    columns = Object.values(testSetItems)[0].map((x, i) => ({
      id: x.TestItemId,
      header: x.TestItemCode,
      accessorKey: x.TestItemCode,
      size: ResultColWidths[x] || (x.TestItemCode.length < 3 ? 30 : x.TestItemCode.length < 5 ? 40 : 50),
      sortable: false,
      // enableEditing: !lockResults,
      placeHolder: "",
      muiEditTextFieldProps: ({ cell, column, row, table }) => ({
        placeholder: '',
        onKeyUp: (event) => {
          if (event.key === 'ArrowLeft') {
            let a = event.target.parentElement.parentElement.parentElement.previousElementSibling;
            const clickEvent = document.createEvent('MouseEvents');
            clickEvent.initEvent('dblclick', true, true);
            a.dispatchEvent(clickEvent);
            handleRowEdit(event, cell);
          }
          if (event.key === 'ArrowRight') {
            let a = event.target.parentElement.parentElement.parentElement.nextElementSibling;
            const clickEvent = document.createEvent('MouseEvents');
            clickEvent.initEvent('dblclick', true, true);
            a.dispatchEvent(clickEvent);
            handleRowEdit(event, cell);
          }
          if (event.key === 'ArrowUp') {
            let a = event.target.parentElement.parentElement.parentElement.parentElement.previousElementSibling;
            a = a.childNodes[i + 1];
            const clickEvent = document.createEvent('MouseEvents');
            clickEvent.initEvent('dblclick', true, true);
            a.dispatchEvent(clickEvent);
            handleRowEdit(event, cell);
          }
          if (event.key === 'ArrowDown') {
            let a = event.target.parentElement.parentElement.parentElement.parentElement.nextElementSibling;
            a = a.childNodes[i + 1];
            const clickEvent = document.createEvent('MouseEvents');
            clickEvent.initEvent('dblclick', true, true);
            a.dispatchEvent(clickEvent);
            handleRowEdit(event, cell);
          }
        },
        onBlur: (event) => {
          handleRowEdit(event, cell);
        },
      }),
    }));
  }
  columnsTestItem = [...columns].map((x) => ({
    TestItemCode: x.field,
    TestItemId: x.testId,
  }));
  columns.unshift({
    id: "Sample Number",
    header: "Sample Number",
    accessorKey: "SampleNumber",
    size: 100,
    sortable: false,
    enableEditing: false,
  }
  );

  //For Calculating Tray completion
  let totalValCounts = 0;
  let hasValCounts = 0;

  data.forEach((obj) => {
    for (const key in obj) {
      if (
        (key.endsWith("_hasVal") || key.endsWith("_valReq")) &&
        obj[key] === true
      ) {
        totalValCounts++;
      }
    }
  });

  data.forEach((obj) => {
    for (const key in obj) {
      if (key.endsWith("_hasVal") && obj[key] === true) {
        hasValCounts++;
      }
    }
  });

  if (
    totalValCounts === hasValCounts &&
    totalValCounts > 0 &&
    hasValCounts > 0
  ) {
    setIsCompletedTray(true);
  } else {
    setIsCompletedTray(false);
  }
  return { columns, data };
}
//Results for History Simple

function getDataAndColumnsPastSamples({
  PastSamplesElementData: data,
  PastSamples: sampleData,
}) {
  const dataObject = {};
  const colMap = {};
  const columns = [];

  const samplesMap = {};
  sampleData.forEach(function (s) {
    samplesMap[s.SampleNumber] = s;
  });

  data.forEach((x) => {
    if (!dataObject[x.SampleNumber]) {
      dataObject[x.SampleNumber] = { ...samplesMap[x.SampleNumber] };
    }
    dataObject[x.SampleNumber][x.TestItemCode] = x.FormattedText;
    if (colMap[x.TestItemCode]) {
      return;
    }
    colMap[x.TestItemCode] = x;
    columns.push(
      {
        id: x.TestItemCode,
        headerName: x.TestItemCode,
        field: x.TestItemCode,
        minWidth: ResultColWidths[x] || (x.TestItemCode.length < 3 ? 35 : x.TestItemCode.length < 5 ? 45 : 60),
        sortable: false,
        enableEditing: false,
      }
    );
  });

  columns.unshift({
    id: "Sample Number",
    headerName: "Sample Number",
    field: "SampleNumber",
    minWidth: 130,
    sortable: false,
    enableEditing: false,
  }
  );

  const dataSrc = [];
  for (var key in dataObject) {
    dataObject[key].SampleNumber = key;
    dataSrc.push(dataObject[key]);
  }

  return { columns, data: dataSrc };
}

//Results for History
function getDataAndColumnsPastSamplesHistory(
  { PastSamplesElementData: data, PastSamples: sampleData },
  laboratoryActions,
  rowActionHandler
) {
  const dataObject = {};
  const colMap = {};
  const columns = [];

  const samplesMap = {};
  sampleData.forEach(function (s) {
    samplesMap[s.SampleNumber] = s;
  });

  data.forEach((x) => {
    if (!dataObject[x.SampleNumber]) {
      dataObject[x.SampleNumber] = { ...samplesMap[x.SampleNumber] };
      // dataObject[x.SampleNumber].UnitHours =
      //   samplesMap[x.SampleNumber].UnitHours;
    }

    dataObject[x.SampleNumber][x.TestItemCode] = x.FormattedText;
    if (colMap[x.TestItemCode]) {
      return;
    }

    colMap[x.TestItemCode] = x;
    columns.push({
      header: x.TestItemCode,
      field: x.TestItemCode,
      minWidth: ResultColWidths[x] || (x.TestItemCode.length < 3 ? 35 : x.TestItemCode.length < 5 ? 45 : 60),
      flex: 1,
      sortable: false,
      description: `${x.TestItemCode}: ${x.TestItem}`,
      renderCell: renderCellExpand,
    });
  });

  const menuOptions = [
    { label: "View Sample Report", name: "view-sample-report" },
  ];

  const extraColumns = [
    // {
    //   headerName: "Sample Number",
    //   field: "SampleNumber",
    //   minWidth: ResultColWidths["SampleNumber"] || 80,
    //   flex: 1,
    // },
    {
      headerName: "Sample Number",
      field: "SampleNumber",
      minWidth: 128,
      maxWidth: 128,
      renderCell: function ({ row }) {
        return (
          <>
            {(laboratoryActions || []).map(function (ac, i) {
              return (
                <DataGridAction
                  key={i}
                  row={row}
                  onClick={ac.action}
                  label={ac.label}
                  startIcon={ac.startIcon}
                />
              );
            })}
            <DropdownMenu
              options={menuOptions}
              onItemSelect={(option) => rowActionHandler(row, option)}
            />
            <Typography paddingTop={0.25}>{row.SampleNumber}</Typography>
          </>
        );
      },
    },
    {
      headerName: "UH",
      field: "UnitHours",
      description: "Unit Hours",
      minWidth: 49,
      flex: 1,
    },
    {
      headerName: "CH",
      field: "ComponentHours",
      description: "Component Hours",
      minWidth: 49,
      flex: 1,
    },
    {
      headerName: "CR",
      field: "ComponentRepaired",
      description: "Component Repaired",
      minWidth: 45,
      flex: 1,
    },
    {
      headerName: "Fluid",
      field: "FluidFormatted",
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "FH",
      field: "FluidHours",
      description: "Fluid Hours",
      minWidth: 50,
      flex: 1,
    },
    {
      headerName: "FC",
      field: "FluidChanged",
      description: "Fluid Changed",
      minWidth: 45,
      flex: 1,
    },
    {
      headerName: "Topup",
      field: "Topup",
      minWidth: 54,
      flex: 1,
    },
    {
      headerName: "Filter",
      field: "FilterChanged",
      minWidth: 55,
      description: "Filter Changed",
      flex: 1,
    },
  ];

  const extraColumns2 = [
    {
      headerName: "Sampled",
      field: "Sampled",
      valueFormatter: ({ value }) => toLocalDate(value),
      minWidth: 85,
      maxWidth: 85,
    },
    {
      headerName: "SIF",
      field: "SIF",
      minWidth: 70,
      maxWidth: 70,
    },
    {
      headerName: "Technician",
      field: "Evaluator",
      minWidth: 80,
      maxWidth: 80,
      renderCell: renderCellExpand,
    },

    {
      headerName: "Status",
      field: "AlertStatus",
      description: "Alert Status",
      minWidth: 90,
      maxWidth: 90,
    },
  ];

  const dataSrc = [];
  for (var key in dataObject) {
    dataObject[key].SampleNumber = key;
    dataSrc.push(dataObject[key]);
  }
  return {
    columns: [...extraColumns, ...columns, ...extraColumns2],
    data: dataSrc,
  };
}
