import { Button, Typography } from "@mui/material";
import { useCallback, useEffect, useReducer, } from "react";
import Form from "../../../components/Form";
import { FormActions } from "../../../components/Form";
import { ExportIcon, SearchIcon } from "../../../icons/ButtonIcons";
import useFetch from "../../../hooks/useFetch";
import { CRITICAL_LOGS_APIS } from "../../../common/apis";
import { useUser } from "../../../rootContext";

export default function SearchFilter({
  onView,
  userTypes,
  infoMessage,
  responseData,
}) {
  const { getFile } = useFetch();
  const user = useUser();

  let dow = new Date().getDay();
  let dateFrom = new Date();
  let dateTo = new Date();

  const currentDate = new Date();
  const currentDate1 = new Date();

  if (dow == 1) {
    dateFrom = currentDate.setDate(currentDate.getDate() - 7);
    dateTo = currentDate1.setDate(currentDate1.getDate() - 1);
  }
  else {
    let dow2 = new Date().getDay() - 1;
    dateFrom = currentDate.setDate(currentDate.getDate() + (-1 * dow2));
    dateTo = new Date();
  }

  const SearchFilterDefaults = {
    DateFrom: dateFrom,// currentDate.toISOString(),
    DateTo: dateTo,// moment().toISOString(),
    radioOption: 0,
    UserId: user?.UserId,
  };

  const [data, dispatch] = useReducer(SearchFilterReducer, SearchFilterDefaults);
  const actions = {
    view: () => onView(data),
    exportClick: function () {
      data?.radioOption === 0
        ? getFile(
          `${CRITICAL_LOGS_APIS.EXPORT_CRITICAL_LOG_OUTSTANDING}?UserID=${data.UserId ? data.UserId : 0
          }&dateFrom=${new Date(data.DateFrom).toISOString()}&dateTo=${new Date(data.DateTo).toISOString()}`,
          "Outstanding Critical Samples"
        )
        : getFile(
          `${CRITICAL_LOGS_APIS.EXPORT_CRITICAL_LOGS}?UserID=${data.UserId ? data.UserId : 0
          }&dateFrom=${new Date(data.DateFrom).toISOString()}&dateTo=${new Date(data.DateTo).toISOString()}`,
          "Actioned Critical Samples"
        );
    },
  };
  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      actions.view();
    }
  }

  const handleKeyPress = useCallback(
    (event) => {
      event.stopPropagation();
      if (event.key === "F1") {
        event.preventDefault();
        actions?.view();
      }
    }, [data]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <>
      <Form
        sections={GetFormSections(
          actions,
          handleEnterPress,
          userTypes,
          infoMessage,
          responseData
        )}
        data={data}
        spacing={2}
        dispatch={dispatch}
      />
    </>
  );
}

function GetFormSections(
  { view, exportClick },
  handleEnterPress,
  userTypes,
  infoMessage,
  responseData
) {
  return [
    {
      fluid: true,
      fields: [
        {
          name: "DateFrom",
          label: "From",
          type: "date",
          alignSelf: "end",
          handleEnterPress: handleEnterPress,
        },
        {
          name: "DateTo",
          label: "To",
          type: "date",
          alignSelf: "end",
          handleEnterPress: handleEnterPress,
        },
        {
          xsCol: 2,
          name: "UserId",
          label: "Users",
          type: "dropdown",
          alignSelf: "end",
          selectOptions: (userTypes || []).map((x) => ({
            value: x.UserId,
            text: x.FullName,
          })),
        },
        {
          xsCol: 1,
          name: "radioOption",
          type: "dropdown",
          alignSelf: "end",
          required: true,
          selectOptions: getRadioOptions(),
        },
        {
          alignSelf: "end",
          component: function () {
            return (
              <Button
                variant="contained"
                onClick={view}
                startIcon={<SearchIcon />}
              >
                View (F1)
              </Button>
            );
          },
        },
        {
          flex: 2,
          alignSelf: "center",
          component: function () {
            return (
              <Typography sx={{ mt: 3, fontSize: 11, color: (infoMessage?.color || "") }}>
                {infoMessage?.message || ""}
              </Typography>
            );
          },
        },
        {
          component: function () {
            return (
              responseData?.length > 0 && (
                <Button
                  variant="outlined"
                  onClick={exportClick}
                  sx={{ mt: "23px" }}
                  startIcon={<ExportIcon />}
                >
                  Export
                </Button>
              )
            );
          },
        },
      ],
    },
  ];
}

function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  return state;
}

function getRadioOptions() {
  return [
    { value: 0, text: "Outstanding" },
    { value: 1, text: "Actioned" },
  ];
}
