import { useReducer, useState, } from "react";
import { Check, Settings } from "@mui/icons-material";
import { Button, Card, Grid, Typography } from "@mui/material";
import AppDialog from "../components/AppDialog";
import Form, { FormActions } from "../components/Form";
import useFetch from "../hooks/useFetch";
import { useRoot, useToast } from "../rootContext";
import { FLEET_APIS } from "../common/apis";
import { KomatsuCheckIcon } from "../icons/ButtonIcons";
import InsiteModelSearch from "../features/master-data/unit-models/InsiteModelSearch";
import appResources from "../app-resources/en/Resources.json";
import FormFooter from "../components/FormFooter";
import ManageUnitModels from "../features/quick-details/manage/ManageUnitModels";

export default function EditUnitModel({
  manageCheck,
  unitModel,
  cancel,
  fetchUnitModels,
}) {

  const { CanDoDataMaintenance } = useRoot();
  const { showToast, } = useToast();
  const { post } = useFetch();

  const [error, setError] = useState("");
  const [manageUnitOpen, setManageUnitOpen] = useState(false);
  const [komatsuCheckOpen, setKomatsuCheckOpen] = useState(false);

  const [data, dispatch] = useReducer(FluidReducer, {
    ...unitModel,
    Verified: unitModel?.Verified === "Yes",
  });

  const actions = {
    save: async function () {
      const response = await post(FLEET_APIS.SAVE_UNIT_MAKE, {
        UnitMakeId: data?.UnitMakeId,
        Make: data?.UnitMake,
        Model: data?.UnitModel,
        Screen: "Unit Models",
        UnitModelNotes: data?.UnitModelNotes || "",
        Verified: data.Verified ? "Yes" : "No",
      });
      if (response[0].failureReason !== null) {
        setError("The unit model was unable to be updated as: " +
          response[0].failureReason)
      } else {
        setError("")
        dispatch({ type: FormActions.RESET_FORM });

        if (window.location.pathname === "/new-unit-model") {
          localStorage.setItem("new-unit-model-resp", JSON.stringify({ success: true }))
          window.dispatchEvent(new Event('storage'))
          return window.close();
        }
        else {
          cancel && cancel();
          fetchUnitModels && fetchUnitModels();
          showToast(appResources.UNIT_MODEL_ADDED_SUCCESSFULLY_MSG);
        }
      }
    },

    manage: function () {
      setManageUnitOpen(true);
    },

    komatsuCheck: function () {
      setKomatsuCheckOpen(true);
    },

    overRide: function () {

    },

  };

  return (
    <Card sx={{ p: 1 }} >
      <Grid container >
        <Grid item xs={12}>
          {error?.length > 0
            ? <Typography sx={{ color: "red" }}>{error}</Typography>
            : <Typography>
              {unitModel?.UnitModelFormatted?.length > 0
                ? unitModel?.UnitModelFormatted
                : "Please provide the unit model details"}
            </Typography>}
          <Form
            sections={GetFormSections(actions?.komatsuCheck, unitModel)}
            data={data}
            dispatch={dispatch}
            key={data && data._key}
          />
        </Grid>

        <Grid item xs={12}>
          <FormFooter
            footerText={`UnitMakeId: ${data?.UnitMakeId || ""}`}
            buttons={GetFormButtons(actions, unitModel, data, CanDoDataMaintenance, manageCheck)}
            hideDivider
            gmt={0}
          />
        </Grid>
      </Grid>

      <AppDialog
        open={manageUnitOpen}
        handleClose={() => setManageUnitOpen(false)}
        title={`Unit Model Management ${unitModel && unitModel.UnitModelFormatted}`}
        maxWidth="100vw"
      >
        <ManageUnitModels
          SelectedUnit={unitModel}
          refreshGrid={fetchUnitModels}
          setManageUnitOpen={setManageUnitOpen}
        />
      </AppDialog>

      <AppDialog
        open={komatsuCheckOpen}
        handleClose={() => setKomatsuCheckOpen(false)}
        title="Insite Model Search"
        maxWidth="100vw"
      >
        <InsiteModelSearch />
      </AppDialog>
    </Card >
  );
}

const GetFormButtons = ({ overRide, manage, save }, unitModel, data, CanDoDataMaintenance, manageCheck) => [
  {
    variant: "outlined",
    label: "Override Limits",
    onClick: overRide,
    hidden: true,
    startIcon: <Settings />,
  },
  {
    variant: "outlined",
    label: "Manage",
    disabled: unitModel && !unitModel.UnitMakeId,
    onClick: manage,
    hidden: manageCheck === true ? false : true,// typeof unitModel === "undefined",
    startIcon: <Settings />,
  },
  {
    variant: "contained",
    label: "Save",
    type: "submit",
    onClick: save,
    startIcon: <Check />,
    hidden: !CanDoDataMaintenance,
    disabled: !data.UnitModel || !data.UnitMake,
  },
];

function GetFormSections(komatsuCheck, unitModel) {
  return [
    {
      xsCol: 12,
      fields: [
        {
          xsCol: 6,
          name: "UnitMake",
          label: "Make",
        },
        {
          xsCol: 6,
          name: "UnitModel",
          label: "Model",
        },
        {
          xsCol: 2,
          name: "Verified",
          label: "Verified",
          type: "switch",
        },
        {
          xsCol: 4,
          component: function () {
            return (
              <Button
                variant="outlined"
                onClick={komatsuCheck}
                startIcon={<KomatsuCheckIcon />}
              >
                Komatsu Check
              </Button>
            );
          },
        },
        {
          xsCol: 12,
          name: "UnitModelNotes",
          label: "Notes",
          type: "textarea",
        },
      ],
    },
  ];
}

function FluidReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === FormActions.RESET_FORM) {
    return { _key: Date.now() };
  }

  return state;
}
