import { Card, Grid } from "@mui/material";
import Form, { SectionTitle } from "../../../components/Form";
import FormFooter from "../../../components/FormFooter";
import { Edit, } from "@mui/icons-material";
import { useWindowPopups } from "../../../components/useWindowPopups";

export default function PrimaryContact({ data }) {

  const HandleNewWindow = (actionName) => {
    useWindowPopups(actionName, data)
  }

  return (
    <Card sx={{ p: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <SectionTitle title="Primary Contact" />
        </Grid>

        <Grid item xs={4}>
          <Form
            readOnly
            sections={FormSections}
            data={data?.Contact1}
            key={data?.Contact1?.ContactId}
          />
        </Grid>
        <Grid item xs={4}>
          <Form
            readOnly
            sections={FormSections}
            data={data?.Contact2}
            key={data?.Contact2?.ContactId}
          />
        </Grid>
        <Grid item xs={4}>
          <Form
            readOnly
            sections={FormSections}
            data={data?.Contact3}
            key={data?.Contact3?.ContactId}
          />
        </Grid>

        <FormFooter buttons={GetFormButtons(HandleNewWindow)} />
      </Grid>
    </Card>
  );
}

const GetFormButtons = (HandleNewWindow) => [
  {
    variant: "contained", label: "Edit", startIcon: <Edit />,
    onClick: () => HandleNewWindow("search-contact", {}),
  }
];

const FormSections = [
  {
    xsCol: 12,
    fields: [
      { name: "Forename", label: "Primary 1", xsCol: 6 },
      { name: "Surname", label: "", xsCol: 6, alignSelf: "flex-end" },
      { name: "Username", label: "Username", xsCol: 12 },
      { name: "Telephone", label: "Phone", xsCol: 12 },
      { name: "Mobile", label: "Mobile", xsCol: 12 },
      { name: "Street1", label: "Address 1", xsCol: 12 },
      { name: "Street2", label: "Address 2", xsCol: 12 },
      { name: "City", label: "Town", xsCol: 12 },
      { name: "PostCode", label: "Postcode", xsCol: 6 },
      { name: "State", label: "State", xsCol: 6 },
      { name: "Country", label: "Country", xsCol: 12 },
    ],
  },
];
