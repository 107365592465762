import { useCallback } from 'react';
import DropDownAdvance from '../../../components/DropDownAdvance';
import Form from '../../../components/Form'
import { Box, Typography } from '@mui/material';

function CommentsTop({ comments, dispatch, regBoxCheck, commentOptions, defaultValue1, handleChange1, defaultValue2, handleChange2, }) {
    return (
        < >
            <Form
                sections={GetFormSections(regBoxCheck, commentOptions, defaultValue1, handleChange1, defaultValue2, handleChange2,)}
                data={comments}
                spacing={2}
                dispatch={dispatch}
                key={comments?._ts_key}
            />
        </ >)
}

export default CommentsTop;

function GetFormSections(regBoxCheck, commentOptions, defaultValue1, handleChange1, defaultValue2, handleChange2,) {
    return [
        {
            fields: [
                {
                    sx: { "& .MuiInputBase-input": { backgroundColor: "#e0ffff" } },
                    name: "CustomerComments",
                    label: "Registration Comments: on report",
                    xsCol: 3,
                    alignSelf: "flex-end",
                    type: "textarea",
                    rows: 4,
                    hidden: !regBoxCheck,
                },
                {
                    name: "SampleNotes",
                    label: "Sample Comments: internal only",
                    xsCol: (regBoxCheck === true) ? 3 : 4,
                    type: "textarea",
                    rows: 4,
                    alignSelf: "flex-end",
                },
                {
                    xsCol: (regBoxCheck === true) ? 3 : 4,
                    group: [
                        {
                            xsCol: 12,
                            component: function () {
                                return <EvaluationDD
                                    id={"EvaluationId"}
                                    dataList={commentOptions?.evaluations || []}
                                    handleChange={handleChange1}
                                    defaultValue={defaultValue1}
                                />;
                            },
                        },
                        {
                            name: "Evaluation",
                            xsCol: 12,
                            type: "textarea",
                            rows: 4,
                            alignSelf: "flex-end",
                        },
                    ]
                },
                {
                    xsCol: (regBoxCheck === true) ? 3 : 4,
                    group: [
                        {
                            xsCol: 12,
                            component: function () {
                                return <RecommendationDD
                                    id={"RecommendationId"}
                                    dataList={commentOptions?.recommendations || []}
                                    handleChange={handleChange2}
                                    defaultValue={defaultValue2} />;
                            },
                        },
                        {
                            name: "Recommendation",
                            xsCol: 12,
                            type: "textarea",
                            rows: 4,
                            alignSelf: "flex-end",
                        },
                    ]
                },

            ],
        },
    ];
}


export const EvaluationDD = ({ id, dataList, handleChange, defaultValue }) => {

    const renderOptions = useCallback(function (props, option) {
        return (
            <Box component="li" {...props} key={option.CommentId}  >
                <Typography
                    variant="subtitle2"
                    sx={{ minWidth: "200px", maxWidth: "200px" }}
                >
                    {option.Code} :
                </Typography>
                <Typography
                    variant="subtitle2"
                    sx={{ minWidth: "400px", maxWidth: "400px" }}
                >
                    {option.Comment}
                </Typography>
            </Box>
        );
    }, []);

    const filterOptions = useCallback(function (options, value) {
        return !value.inputValue
            ? options
            : options.filter((x) =>
                x.Code.toLowerCase()?.startsWith(value.inputValue.toLowerCase())
                // .includes(value.inputValue.toLowerCase())
            );
    }, []);

    const getOptionLabel = (option) => {
        return defaultValue === null ? "" : option.Code || "";
    }

    return (
        <DropDownAdvance
            id={id}
            startCheck={true}
            renderOptions={renderOptions}
            filterOptions={filterOptions}
            getOptionLabel={getOptionLabel}
            onChange={handleChange("Evaluation")}
            options={dataList || []}
            defaultValue={dataList.find((x) => x.CommentId === defaultValue) || "Select"}
        />
    );
}

export const RecommendationDD = ({ id, dataList, handleChange, defaultValue }) => {

    const renderOptions = useCallback(function (props, option) {
        return (
            <Box component="li" {...props} key={option.CommentId}>
                <Typography
                    variant="subtitle2"
                    sx={{ minWidth: "200px", maxWidth: "200px" }}
                >
                    {option.Code}:{" "}
                </Typography>
                <Typography
                    variant="subtitle2"
                    sx={{ minWidth: "400px", maxWidth: "400px" }}
                >
                    {option.Comment}
                </Typography>
            </Box>
        );
    }, []);

    const filterOptions = useCallback(function (options, value) {
        return !value.inputValue
            ? options
            : options.filter((x) =>
                x.Code.toLowerCase()?.startsWith(value.inputValue.toLowerCase())
                // .includes(value.inputValue.toLowerCase())
            );
    }, []);

    const getOptionLabel = (option) => {
        return defaultValue === null ? "" : option.Code || "";
    }

    return (
        <DropDownAdvance
            id={id}
            renderOptions={renderOptions}
            filterOptions={filterOptions}
            getOptionLabel={getOptionLabel}
            onChange={handleChange("Recommendation")}
            options={dataList || []}
            defaultValue={dataList.find((x) => x.CommentId === defaultValue) || "Select"}
        />
    );
}