import { Box, Grid, Typography } from '@mui/material'
import QRCode from 'react-qr-code'
import "./sticker.css";

function PrintSticker({ regData }) {
    return (
        <Box
            sx={{
                border: "1px solid black",
                backgroundColor: "white",
                width: "40px!important",
                height: "30px!important",
                padding: "1px",
                marginLeft: "18px",
                marginTop: "22px"
            }}>
            <Grid container >
                <Grid item xs={5}>
                    {/* <QRCode
                        // value={regData?.RegistrationId}
                        value={regData?.BottleNumber}
                        size={15}
                        style={{ height: "auto", }}
                        viewBox={`0 0 256 256`}
                    /> */}
                </Grid>
                <Grid item xs={7}>
                    <Typography sx={{ fontSize: "3px!important", lineHeight: 1 }}>{regData?.BottleNumber || ""}</Typography>
                    {/* <Typography sx={{ fontSize: "3px!important", lineHeight: 1 }}>{regData?.RegistrationId || ""}</Typography> */}
                    <Typography sx={{ fontSize: "3px!important", lineHeight: 1 }} >{regData?.Component && regData?.Component?.slice(0, 13) + (regData?.Component?.length > 13 ? "..." : "")}</Typography>
                    <Typography sx={{ fontSize: "3px!important", lineHeight: 1 }} >{regData?.Serial && regData?.Serial?.slice(0, 12) + (regData?.Serial?.length > 12 ? "..." : "")}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography sx={{ fontSize: "3px!important", lineHeight: 1 }}>{regData?.Customer && regData?.Customer?.slice(0, 19) + (regData?.Customer?.length > 19 ? "..." : "")}</Typography>
                    <Typography sx={{ fontSize: "3px!important", lineHeight: 1 }} >{regData?.Model && regData?.Model?.slice(0, 19) + (regData?.Model?.length > 19 ? "..." : "")}</Typography>
                    <Typography sx={{ fontSize: "3px!important", lineHeight: 1 }}>{regData?.UnitId || ""}</Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

export const PrintSticker2 = ({ regData }) => {
    return (<>
        <body>
            <div class="sticker">
                <Grid container >
                    <Grid item xs={5}>
                        {(regData?.BottleNumber !== null && regData?.BottleNumber !== undefined)
                            ? <QRCode
                                // value={regData?.RegistrationId}
                                value={regData?.BottleNumber}
                                size={50}
                                style={{ height: "auto", }}
                                viewBox={`0 0 256 256`}
                            />
                            : <></>}
                    </Grid>
                    <Grid item xs={7}>
                        <Typography sx={{ fontSize: "10px!important", lineHeight: 1 }}>{regData?.BottleNumber || ""}</Typography>
                        {/* <Typography sx={{ fontSize: "10px!important", lineHeight: 1 }}>{regData?.RegistrationId || ""}</Typography> */}
                        <Typography sx={{ fontSize: "10px!important", lineHeight: 1 }} >{regData?.Component && regData?.Component?.slice(0, 23) + (regData?.Component?.length > 23 ? "..." : "")}</Typography>
                        <Typography sx={{ fontSize: "10px!important", lineHeight: 1 }} >{regData?.Serial && regData?.Serial?.slice(0, 12) + (regData?.Serial?.length > 12 ? "..." : "")}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: "10px!important", lineHeight: 1 }}>{regData?.Customer && regData?.Customer?.slice(0, 50) + (regData?.Customer?.length > 50 ? "..." : "")}</Typography>
                        <Typography sx={{ fontSize: "10px!important", lineHeight: 1 }} >{regData?.Model && regData?.Model?.slice(0, 19) + (regData?.Model?.length > 19 ? "..." : "")}</Typography>
                        <Typography sx={{ fontSize: "10px!important", lineHeight: 1 }}>{regData?.UnitId || ""}</Typography>
                    </Grid>
                </Grid>
            </div>
        </body>
    </>)
}

export default PrintSticker