import { Card } from "@mui/material";
import Form, { SectionTitle } from "../../../components/Form";
import { Edit } from "@mui/icons-material";
import FormFooter from "../../../components/FormFooter";
import { useRoot } from "../../../rootContext";
import { useWindowPopups } from "../../../components/useWindowPopups";

export default function SiteAddress({ data }) {
  const { CanDoDataMaintenance } = useRoot();

  const HandleNewWindow = (actionName) => {
    useWindowPopups(actionName, data)
  }

  return (
    <>
      <Card sx={{ p: 1 }}>
        <Form
          readOnly
          sections={FormSections}
          data={data}
          key={data?.SiteId}
        />
        {CanDoDataMaintenance && <FormFooter buttons={GetFormButtons(HandleNewWindow)} />}
      </Card>
    </>
  );
}

const GetFormButtons = (HandleNewWindow) => [
  {
    variant: "contained", label: "Edit", startIcon: <Edit />,
    onClick: () => HandleNewWindow("edit-site"),
  },
];

const FormSections = [
  {
    xsCol: 12,
    fields: [
      {
        xsCol: 12,
        component: function () {
          return <SectionTitle title="Site Address" />;
        },
      },
    ],
  },
  {
    xsCol: 6,
    fields: [
      { name: "PhysicalStreet1", label: "Physical Address 1", xsCol: 12 },
      { name: "PhysicalStreet2", label: "Address 2", xsCol: 12 },
      { name: "PhysicalCity", label: "Town", xsCol: 12 },
      { name: "PhysicalPostCode", label: "Postcode", xsCol: 6 },
      { name: "PhysicalState", label: "State", xsCol: 6 },
      { name: "PhysicalCountry", label: "Country", xsCol: 12 },
    ],
  },
  {
    xsCol: 6,
    fields: [
      { name: "PostalStreet1", label: "Physical Address 1", xsCol: 12 },
      { name: "PostalStreet2", label: "Address 2", xsCol: 12 },
      { name: "PostalCity", label: "Town", xsCol: 12 },
      { name: "PostalPostCode", label: "Postcode", xsCol: 6 },
      { name: "PostalState", label: "State", xsCol: 6 },
      { name: "PostalCountry", label: "Country", xsCol: 12 },
    ],
  },
];
