import { useState } from "react";
import NewDataTable from "../../../components/NewDataTable";

export default function UnitModelsTable({ data, rowSelected, selectedRow }) {

  const [rowSelection, setRowSelection] = useState(selectedRow);

  const options1 = {
    columns: Columns1,
    dataSource: data || [],
    getRowId: (originalRow) => originalRow.UnitMakeId + originalRow.TemplateId,
    enablePagination: false,
    sx: { height: 805 },
    enableColumnActions: false,
    onRowSelectionChange: setRowSelection,
    muiSelectCheckboxProps: {
      color: 'secondary',
      backgroundColor: "blue"
    },
    muiTableBodyProps: {
      sx: (theme) => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "#e2f2ff" },
        // backgroundColor: "inherit",//
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "#e2f2ff" },
      }),
    },
    muiTableBodyRowProps: ({ row }) => ({
      //implement row selection click events manually
      onClick: () => {
        rowSelected(row.original);
        setRowSelection((prev) => ({
          [row.id]: !prev[row.id],
        }))
      },
      selected: rowSelection[row.id],
      sx: {
        cursor: 'pointer',
      },
    }),
    muiTableBodyCellProps: ({ cell, column, row, table }) => {
      return {
        sx: cell.column.columnDef.accessorKey === "Verified" && row.original.Verified === "Yes" ? {
          backgroundColor: "green !important",
          color: "white",
          py: 0.25,
          border: "0.5px solid #d0d0d0"
        } : cell.column.columnDef.accessorKey === "UnitMake" && row.original.UnitMake?.toLowerCase() === "komatsu" ? {
          backgroundColor: "navy !important",
          color: "white",
          py: 0.25,
          border: "0.5px solid #d0d0d0"
        } : {
          py: 0.25,
          border: "0.5px solid #d0d0d0"
        }
      }
    },
    state: { rowSelection },
  };
  return <NewDataTable {...options1} />;

}

const Columns1 = [
  {
    header: "Make",
    accessorKey: "UnitMake",
    maxSize: 150,
    size: 150,
    sortable: false,
    enableEditing: false,
  },
  {
    header: "Model",
    accessorKey: "UnitModel",
    maxSize: 150,
    size: 150,
    sortable: false,
    enableEditing: false,
  },
  {
    header: "Verified",
    accessorKey: "Verified",
    maxSize: 300,
    size: 150,
    sortable: false,
    enableEditing: false,
  },
];