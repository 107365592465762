import { useEffect, useRef, useState } from "react";
import NewDataTable from "../../../components/NewDataTable";

export default function ResultsTable2({
  data = [],
  columns = [],
  rowSelected,
  selectedRow,
  selectedSampleNo,
  lockResults
}) {
  const [rowSelection, setRowSelection] = useState({});
  const rowVirtualizerInstanceRef = useRef(null);

  useEffect(() => {
    try {
      setRowSelection(
        { [selectedSampleNo]: true }
      )
      rowVirtualizerInstanceRef?.current?.scrollToIndex?.(selectedRow);

    } catch (error) {
      console.log("Message" + error);
    }
  }, [selectedRow, selectedSampleNo]);

  const options1 = {
    enableRowVirtualization: true,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enablePagination: false,
    enableGlobalFilter: false,
    enableEditing: !lockResults,
    editDisplayMode: 'cell',
    enableColumnFilterModes: false,
    enableColumnActions: false,
    enableSorting: false,
    columnVirtualizerOptions: { overscan: 1 },
    rowVirtualizerInstanceRef,
    rowVirtualizerOptions: { overscan: 5 },
    columns: columns,
    data: data || [],
    getRowId: (originalRow) => originalRow.SampleNumber,
    sx: {
      height: 405, minHeight: 405, maxHeight: 405
    },
    muiSelectCheckboxProps: {
      color: 'secondary',
      backgroundColor: "blue"
    },
    muiTableHeadCellProps: {
      align: 'left',
      sx: { border: "0.25px solid #E0E0E0", py: 0.25, backgroundColor: "silver" }
    },
    muiTableBodyProps: {
      sx: (theme) => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "#e2f2ff" },
        // backgroundColor: "inherit",//
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "#e2f2ff" },
      }),
    },
    muiTableBodyRowProps: ({ row }) => (
      {
        //implement row selection click events manually
        onClick: () => {
          rowSelected({ ...row?.original, keyProp: row.index })
          setRowSelection((prev) => ({
            [row.id]: !prev[row.id],
          }))
        },
        selected: rowSelection[row.id],
        sx: {
          cursor: 'pointer',
        },
      }),
    onRowSelectionChange: setRowSelection,
    state: { rowSelection },
    muiTablePaperProps: ({ cell, column, row, table }) => {
      return {
        sx: {
          border: "1px solid #253FC8",
          borderRadius: "5px",
          padding: "2px",
          [`.MuiTableRow-root:hover td`]: {},
          [`.MuiTableRow-root.Mui-selected td`]: {
            opacity: 1,
            // backgroundColor: "#inherit!important",
            filter: " brightness(90%)",
          }
        }
      }
    },
    muiTableBodyCellProps: ({ cell, column, row, table }) => {
      return {
        sx: cell.column.columnDef.accessorKey === "SampleNumber"
          ? {
            backgroundColor: "white !important",
            color: "black",
            py: 0.25,
            border: "0.25px solid #d0d0d0"
          }
          : cell.column.columnDef.accessorKey === "AutoEvaluated" && row.original.AutoEvaluated === "?"
            ? {
              backgroundColor: "#daa520 !important",
              color: "white",
              py: 0.25,
              border: "0.25px solid #d0d0d0"
            }
            : cell.row.original[cell.column.columnDef.header + "_hasVal"] === true
              ? {
                backgroundColor: "#99ddb8 !important",
                color: "black",
                py: 0.25,
                opacity: 0.85,
                border: "0.25px solid #d0d0d0",
                [`& .MuiInputBase-input`]: {
                  backgroundColor: "white"
                },
              }
              : cell.row.original[cell.column.columnDef.header + "_valReq"] === true
                ? {
                  backgroundColor: "red !important",
                  color: "white",
                  opacity: 0.5, py: 0.25,
                  border: "0.25px solid #d0d0d0",
                  [`& .MuiInputBase-input`]: {
                    backgroundColor: "white"
                  },
                }
                : {
                  py: 0.25,
                  border: "0.25px solid #d0d0d0",
                  [`& .MuiInputBase-input`]: {
                    backgroundColor: "white",
                    padding: 0
                  },
                }
      }
    },
  };

  return <>
    <NewDataTable {...options1} />
  </>;
}
