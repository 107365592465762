import { Box, ClickAwayListener, Typography } from "@mui/material";
import TreeItem from "../../../components/tree/TreeItem";
import getNodeId, { parseNodeId } from "./nodeId";
import TreeMenu from "../TreeMenu";
import { useState, useEffect } from "react";
import productColors from "../../../common/ProductColors";

export default function Component(props) {
  const { comp2Click } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [openPop, setOpenPop] = useState(false);

  const mouseDownHandler = (event) => {
    if (event.button === 2) {
      props.handleLabelClick && props.handleLabelClick(event, nodeId)
      setAnchorEl(event.currentTarget);
      setOpenPop(true);
    }
    else { return; }
  };

  const handleSelect = (name) => {
    props.handleRightSelect(name, props);
    setOpenPop(false)
    setAnchorEl(null)
  };

  const nodeId = getNodeId(props.pathItems);
  const expanded = props.expandedNode.includes(nodeId);

  const [productColor, setProductColor] = useState({})

  useEffect(() => {
    let color2 = productColors(props?.ProductId);
    setProductColor(color2);
  }, []);
  let node123 = parseNodeId(props?.selectedNode)

  return (
    <>
      <TreeItem
        selectedNode={Object.entries(node123)?.length <= 7 && node123.component == props.ComponentId ? props?.selectedNode : ""}
        nodeId={nodeId}
        label={
          <Box sx={Object.entries(node123)?.length <= 7 && node123.component == props.ComponentId
            ? { ...productColor } : props.IsArchived === false && props.IsNonFluid === false
              ? { color: "Navy" }
              : { color: "gray" } ||
                (props.IsArchived === false && props.IsNonFluid === true)
                ? { color: "Olive" }
                : { color: "gray" }}
            // onClick={(e) => props.handleLabelClick(e, nodeId)}
            onClick={comp2Click !== undefined ? (e) => { return null } : (e) => props.handleLabelClick(e, nodeId)}
            onDoubleClick={() => comp2Click && comp2Click(props.ComponentId)}
          >
            <Typography>
              {props.IsNonFluid == false
                ? props.Component + "  [" + props.SampleCount + "]" +
                (props.EventLogCount > 0 ? "  " + props.EventLogCount + " Event(s)" : "")
                : props.Component + "  [" + ([props]?.AdHocReportCount || "0") + "]" +
                (props.EventLogCount > 0 ? "  " + props.EventLogCount + " Event(s)" : "") +
                (props.IsArchived === true ? "  Archived " : "")
              }
            </Typography>
          </Box >
        }
        customProps={{ hasChildren: !!props.SampleCount, expanded }}
        onMouseDown={(e) => mouseDownHandler(e)}
      >
        {props.children}
      </TreeItem >
      {openPop && <ClickAwayListener onClickAway={() => setOpenPop(false)}>
        <TreeMenu options={Options} anchorEl={anchorEl} openPop={openPop} setOpenPop={setOpenPop} handleClick={handleSelect} />
      </ClickAwayListener>}
    </>
  );
}

const Options = [
  {
    label: "Copy or Add to Selected",
    name: "null",
    items: [
      { label: "Add Sample to Email Selected Items", name: "email-selected-items" },
      { label: "Add All Samples to Email Selected Items", name: "email-selected-items-all" },
      { label: "Copy Sample Number", name: "copy-sample-number" },
      { label: "Copy All Samples Number", name: "copy-sample-number-all" },
    ],
  },
  {
    label: "Tree",
    name: "null",
    items: [
      // { label: "Lift to Top", name: "lift-to-top" },
      // { label: "Go to Parent", name: "go-to-parent" },
      // { label: "Collapse Tree", name: "collapse-tree" },
      { label: "Refresh", name: "refresh-tree" },
    ],
  },
  { label: "Sample Report", name: "sample-report", },
  {
    label: "Additional Reports",
    name: "null",
    items: [
      { label: "Reports by Date", name: "reports-by-date" },
      { label: "Full History as XLSX", name: "history-as-xlsx" },
      { label: "Full History as XML", name: "history-as-xml" },
      { label: "Machine Report", name: "view-mhr-report" },
      { label: "Components No Recent Samples", name: "components-no-recent-samples" },
      { label: "Units No Recent Samples", name: "units-no-recent-samples" },
      { label: "Reference XSD", name: "reference-xsd" },
    ],
  },
  { label: "Unit Hour History", name: "unit-hour-history", },
  { label: "Recent Unit Results", name: "recent-unit-results", },
  { label: "Recent Component Results", name: "recent-component-results", },
  {
    label: "Events",
    name: "null",
    items: [
      { label: "View Events", name: "view-event" },
      { label: "New Events", name: "add-event" },
    ],
  },
  { label: "View in Quick Details", name: "view-in-quick-details" },
  {
    label: "Edit",
    name: "null",
    items: [
      { label: "Component", name: "edit-component" },
      { label: "Unit", name: "edit-unit" },
      { label: "Site", name: "edit-site" },
      { label: "Customer", name: "edit-customer" },
    ],
  },
  {
    label: "New",
    name: "null",
    items: [
      { label: "Special Component Report", name: "special-component-report" },
      { label: "Special Unit Report", name: "special-unit-report" },
      { label: "Component", name: "add-new-component" },
      { label: "Unit", name: "add-new-unit" },
      { label: "Site", name: "add-new-site" },
      { label: "Customer", name: "add-new-customer" },
    ],
  },
  {
    label: "Manage",
    name: "null",
    items: [
      { label: "Component", name: "manage-component" },
      { label: "Unit", name: "manage-unit" },
      { label: "Site", name: "manage-site" },
      { label: "Customer", name: "manage-customer" },
    ],
  },
  {
    label: "Contacts",
    name: "null",
    items: [
      { label: "Access Permission", name: "access-permission-overview" },
      { label: "Sample Reporting", name: "report-contacts" },
      { label: "Primary Contacts", name: "primary-contacts-overview" },
      { label: "Subscriptions", name: "subscription-overview" },
      { label: "Exports", name: "export-contacts-overview" },
    ],
  },
];
