import { Checkbox } from "@mui/material";
import renderCellExpand from "../../../components/DatatableCellExpand";
import NewDataTable from "../../../components/NewDataTable";
import { useState } from "react";

export default function TestItemsTable({ data, setTestSetItems }) {

  const [rowSelection, setRowSelection] = useState({});

  const handleChange = (i, val, name) => {
    const newState = data.map(obj => {
      if (obj.TestItemId === i?.TestItemId) {
        return { ...obj, [name]: val === true ? false : true };
      }
      return obj;
    });
    setTestSetItems(newState);
  };

  const options = {
    columns: GetColumns(handleChange),
    dataSource: (data || []).map((x) => ({
      ...x,
      keyProp: x.TestItemId + "" + (x.TestSetId || ""),
    })),
    getRowId: (originalRow) => originalRow.keyProp,

    sx: { height: "86dvh", },
    enableColumnActions: false,
    enableSorting: false,
    onRowSelectionChange: setRowSelection,

    muiTableBodyProps: {
      sx: (theme) => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "#e2f2ff" },
        // backgroundColor: "inherit",//
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "#e2f2ff" },
      }),
    },

    muiTableBodyRowProps: ({ row }) => ({
      //implement row selection click events manually
      onClick: () => {
        setRowSelection((prev) => ({
          [row.id]: !prev[row.id],
        }))
      },
      selected: rowSelection[row.id],
      sx: {
        cursor: 'pointer',
        p: 0,
        height: '24px'
      },
    }),
    state: { rowSelection },
  };

  return <NewDataTable {...options} />;
}

function GetColumns(handleChange) {
  return [
    {
      header: "Test Item",
      accessorKey: "TestItem",
      size: 180,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "AutoEval",
      accessorKey: "IsAutoEvaluation",
      size: 50,
      Cell: ({ row }) => (
        <Checkbox
          checked={row?.original?.IsAutoEvaluation}
          onChange={(e) => handleChange(row?.original, row?.original?.IsAutoEvaluation, "IsAutoEvaluation")}
          sx={{ [`.MuiSvgIcon-root`]: { fontSize: "18px" } }} />
      ),
    },
    {
      header: "Min",
      accessorKey: "AutoEvaluationLower",
      size: 50,
    },
    {
      header: "Max",
      accessorKey: "AutoEvaluationUpper",
      size: 50,
    },
    {
      header: "Selected",
      accessorKey: "Selected",
      size: 50,
      Cell: ({ row }) => (
        <Checkbox checked={row?.original?.Selected}
          onChange={(e) => handleChange(row?.original, row?.original?.Selected, "Selected")}
          sx={{ [`.MuiSvgIcon-root`]: { fontSize: "18px" } }} />
      ),
    },
  ];
} 