import { Button, Card, Grid, Typography } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useReducer, useState } from "react";
import moment from "moment";
import useFetch from "../../hooks/useFetch";
import Form, { FormActions } from "../../components/Form";
import DataTable, { DataGridAction } from "../../components/DataTable";
import { toIsoFormat, toLocalDateTime } from "../../utils";
import { MANAGEMENT_APIS, MASTER_DATA_APIS } from "../../common/apis";
import { NewIcon, SaveIcon } from "../../icons/ButtonIcons";
import AppDialog from "../../components/AppDialog";
import { useToast, useUser } from "../../rootContext";
import appResources from "../../app-resources/en/Resources.json";
import renderCellExpand from "../../components/DatatableCellExpand";
import ConfirmModal from "../../components/ConfirmModal";

export default function Messages() {
  const [data, dispatch] = useReducer(
    SearchFilterReducer,
    SearchFilterDefaults
  );
  const { get, post } = useFetch();
  const user = useUser();

  const [messagesData, setMessagesData] = useState([]);
  const [usersDropdownData, setUsersDropdownData] = useState([]);
  const [labDropdownData, setLabDropdownData] = useState([]);
  const [dialog, setDialog] = useState({ open: false, message: "" });
  const [rowData, setRowData] = useState();
  const { showToast } = useToast();

  //Call api for user dropdown data
  useEffect(() => {
    document.title = "Messages";
    loadData();
  }, []);

  async function loadData() {
    let resData = await get(`${MASTER_DATA_APIS.GET_BY_TYPES}?types=Laboratories`, {});
    if (resData) {
      resData.Laboratories.unshift({
        LaboratoryId: 4,
        Laboratory: "All Laboratories",
      });
      setLabDropdownData(resData.Laboratories);
    }
    refreshData();
  }

  async function refreshData() {
    let responseMessageData = await get(MANAGEMENT_APIS.MESSAGES_ALL);
    if (responseMessageData) {
      responseMessageData.Users.unshift({
        UserId: 0,
        FullName: "All Users",
      });
      setUsersDropdownData(responseMessageData.Users);
      if (responseMessageData.MessagesAll.length > 0) {
        setMessagesData(responseMessageData.MessagesAll);
      } else {
        setMessagesData([]);
      }
    }
  }

  async function saveClick() {
    await post(MANAGEMENT_APIS.EDIT_MESSAGE, {
      ...data,
      DisplayFrom: data.NoEndDate ? null : data.DisplayFrom,
      DisplayTo: data.NoEndDate ? null : data.DisplayTo,
      AddedBy: user.Username,
      LaboratoryId: data.LaboratoryId === 4 ? 0 : data.LaboratoryId,
      UserId: data.UserId ? data.UserId : 0,
    });
    handleClear();
    await refreshData();
  }

  const tblOptions = {
    columns: GetColumns(deleteClick),
    dataSource: messagesData || [],
    rowIdProperty: "MessageId",
    isDisableColumnFilter: true,
    sx: { height: 600 },
  };

  function deleteClick(row) {
    setDialog({
      open: true,
      message: appResources.DELETE_CONFIRMATION_MSG,
    });
    setRowData(row);
  }

  async function deleteClickAlert() {
    await get(`${MANAGEMENT_APIS.DELETE_MESSAGE}?MessageId=${rowData.MessageId}`);
    await refreshData();
    setDialog((prevState) => ({ ...prevState, open: false }));
    showToast(appResources.MESSAGE_DELETED_SUCCESSFULLY_MSG);
  }

  function handleClear() {
    dispatch({ type: FormActions.RESET_FORM });
  }

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "F11") {
        event.preventDefault();
        handleClear();
      }
      else if (event.key === "F12") {
        event.preventDefault();
        saveClick(data);
      }
    },
    [data]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ p: 1 }}>
            <Form
              sections={GetFormSections(
                data,
                usersDropdownData,
                labDropdownData,
                handleClear,
                saveClick,
              )}
              data={data}
              dispatch={dispatch}
              key={data._ts_key}
            />
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Total Records: <b> {messagesData?.length}</b>
          </Typography>
          <DataTable {...tblOptions} />
        </Grid>
      </Grid>

      <AppDialog
        open={dialog.open}
        handleClose={() =>
          setDialog((prevState) => ({ ...prevState, open: false }))
        }
        title="Delete Message"
        maxWidth="md"
      >
        <ConfirmModal
          handleClose={() => {
            setDialog((prevState) => ({
              ...prevState,
              open: false,
            }));
          }} alertAction={deleteClickAlert}
          message={dialog.message}
          buttonText={"Delete"}
          buttonText2={"Cancel"}
        />
      </AppDialog>
    </>
  );
}

function GetColumns(deleteClick) {
  return [
    {
      headerName: "Delete",
      field: "Delete",
      sortable: false,
      width: 80,
      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction row={row} onClick={deleteClick} label="Delete" />
          </>
        );
      },
    },
    {
      headerName: "From",
      field: "DisplayFrom",
      valueFormatter: ({ value }) => toLocalDateTime(value),
      width: 130,
    },
    {
      headerName: "To",
      field: "DisplayTo",
      valueFormatter: ({ value }) => toLocalDateTime(value),
      width: 130,
    },
    {
      headerName: "User",
      field: "FullName",
      valueFormatter: ({ value }) => (value === null ? "All" : value),
      width: 170,
    },
    {
      headerName: "Laboratory",
      field: "Laboratory",
      width: 140,
    },
    {
      headerName: "Created",
      field: "Created",
      valueFormatter: ({ value }) => toLocalDateTime(value),
      width: 150,
    },
    {
      headerName: "Created By",
      field: "CreatedBy",
      width: 160,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Message",
      field: "Message",
      minWidth: 300,
      flex: 1,
      renderCell: renderCellExpand,
    },
  ];
}

function GetFormSections(
  data,
  usersDropdownData,
  labDropdownData,
  handleClear,
  saveClick,
) {
  return [
    {
      fluid: true,
      fields: [
        {
          xsCol: 4,
          group: [
            {
              name: "DisplayFrom",
              label: "Display From",
              type: "date",
              xsCol: 4,
            },
            {
              name: "DisplayTo",
              label: "Until",
              type: "date",
              disablePast: true,
              xsCol: 4,
            },

            {
              name: "NoEndDate",
              label: "No End Date",
              xsCol: 4,
              type: "switch",
            },
            {
              name: "UserId",
              label: "Show To User",
              type: "dropdown",
              xsCol: 4,
              required: true,
              selectOptions: (usersDropdownData || []).map((x) => ({
                value: x.UserId,
                text: x.FullName,
              })),
            },
            {
              name: "LaboratoryId",
              label: "Laboratory",
              type: "dropdown",
              xsCol: 4,
              required: true,
              selectOptions: (labDropdownData || []).map((x) => ({
                value: x.LaboratoryId,
                text: x.Laboratory,
              })),
            },
          ]
        },
        {
          xsCol: 8,
          group: [
            {
              name: "Message",
              label: "Message",
              type: "textarea",
              rows: 4,
              xsCol: 12,
            },
            {
              xsCol: 10,
              component: function ({ data }) {
                return (
                  (data?.Message?.length > 1)
                    ? <Button
                      variant="contained"
                      onClick={saveClick}
                      startIcon={<SaveIcon />}
                    >
                      Save (F12)
                    </Button>
                    : <></>
                );
              },
            },
            {
              component: function () {
                return (
                  <Button
                    variant="outlined"
                    onClick={handleClear}
                    startIcon={<NewIcon />}
                  >
                    Clear (F11)
                  </Button>
                );
              },
            },
          ]
        },
      ],
    },
  ];
}

function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === FormActions.RESET_FORM) {
    return { ...SearchFilterDefaults, _ts_key: Date.now() };
  }
  return state;
}
const SearchFilterDefaults = {
  // DisplayFrom: moment().toISOString(),
  // DisplayTo: moment().toISOString(),
  DisplayFrom: toIsoFormat(new moment()),
  DisplayTo: toIsoFormat(new moment()),
  LaboratoryId: 4,
};
