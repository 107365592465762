import { Card, Grid, } from "@mui/material";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";

import useFetch from "../../../hooks/useFetch";
import SearchFilter from "./SearchFilter";
import OutstandingTable from "./OutstandingTable";
import { DateFormat } from "../../../utils";
import ActionedTable from "./ActionedTable";
import { CRITICAL_LOGS_APIS, MASTER_DATA_APIS } from "../../../common/apis";
import { useWindowPopups } from "../../../components/useWindowPopups";
import { useUser } from "../../../rootContext";
import AppDialog from "../../../components/AppDialog";
import LabDialog from "../../../components/LabDialog";

export default function CriticalLogs() {
  const { get, } = useFetch();
  const user = useUser();

  const [responseData, setResponseData] = useState([]);
  const [userTypes, setUserTypes] = useState([]);
  const [radioOptions, setRadioOption] = useState();
  const [infoMessage, setInfoMessage] = useState({ message: "", color: "black" });
  const [dialog, setDialog] = useState({
    open: false,
    fullWidth: null,
    width: "",
    title: "",
  });

  async function onViewHandler(filter) {
    //Get Grid data
    setRadioOption(filter.radioOption);
    setResponseData([]);
    if (
      filter?.DateFrom === "Invalid date" ||
      filter?.DateTo === "Invalid date"
    ) {
      return setInfoMessage({ message: "Invalid Date Format", color: "red" });
    }

    if (filter?.radioOption === 0) {
      const data = await get(`${CRITICAL_LOGS_APIS.CRITICAL_LOG_OUTSTANDING}?dateFrom=${moment(filter.DateFrom).format(DateFormat)}&dateTo=${moment(filter.DateTo).format(DateFormat)}&UserID=${filter.UserId}`);
      if (data?.length > 0) {
        setResponseData(data || []);
        setInfoMessage({ message: `There are ${data?.length} outstanding samples`, color: "green" });
      } else {
        setResponseData();
        setInfoMessage({ message: `There are no outstanding samples`, color: "black" });
      }

      return;
    }
    const data = await get(`${CRITICAL_LOGS_APIS.CRITICAL_LOGS}?dateFrom=${moment(filter.DateFrom).format(DateFormat)}&dateTo=${moment(filter.DateTo).format(DateFormat)}&UserID=${filter.UserId}`);
    if (data?.length > 0) {
      setResponseData(data || []);
      setInfoMessage({ message: `There are ${data?.length} critical log entries`, color: "green" });
    } else {
      setResponseData();
      setInfoMessage({ message: `There are no critical log entries`, color: "black" });
    }
  }

  //Get User types
  useEffect(function () {
    (async function () {
      const data = await get(`${MASTER_DATA_APIS.GET_BY_TYPES}?types=Users`, {});
      if (data.Users) {
        data.Users.unshift({ UserId: 0, FullName: "All Users" });
        setUserTypes(data.Users);
      }
    })();
  }, []);

  const handleEvalRoute = (row) => {
    localStorage.setItem("local05", JSON.stringify({ Date: row.TrayDate, Tray: row.Tray, LabAndDateOption: 0, sampleNo: row.SampleNumber, CreationDate: moment.utc().format("YYYYMMDD") }));
    window.open("/trays/evaluation", "_blank");
  }

  function handelEditClick(row) {
    HandleNewWindow("critical-log", row)
  }

  function handelViewClick(row) {
    if (row?.LaboratoryId === user?.DefaultLaboratoryId) {
      handleEvalRoute(row)
    }
    else {
      setDialog({
        open: true,
        title: "Change Laboratory",
        width: "md",
      });
    }
  }

  const HandleNewWindow = (actionName, data) => {
    useWindowPopups(actionName, data)
  }

  function handelAddClick(row) {
    HandleNewWindow("critical-log", row)
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ p: 1 }}>
            <SearchFilter
              onView={onViewHandler}
              userTypes={userTypes}
              infoMessage={infoMessage}
              responseData={responseData}
              key={userTypes.UserId}
            />
          </Card>
        </Grid>
        <Grid item xs={12}>
          {radioOptions === 0 ? (
            <OutstandingTable
              data={responseData}
              addClick={handelAddClick}
              viewClick={handelViewClick}
            />
          ) : (
            <ActionedTable
              data={responseData}
              editClick={handelEditClick}
              viewClick={handelViewClick}
            />
          )}
        </Grid>
      </Grid>

      <AppDialog
        open={dialog.open}
        fullWidth={dialog.fullWidth}
        title={dialog.title}
        maxWidth={dialog.width}
        handleClose={() =>
          setDialog({
            open: false,
          })
        }
      >
        {dialog.title === "Change Laboratory" && (
          <LabDialog
            setDialog={setDialog}
          />
        )}
      </AppDialog >
    </>
  );
}
