import DataTable from "../../../components/DataTable";
import GetStepNames from "../../../common/GetStepNames";
import { toDateTimeFormat } from "./../../../utils";
import renderCellExpand from "../../../components/DatatableCellExpand";

export default function CreateTrayTable(props) {
  const options = {
    ...props,
    columns: GetCreateTraysColumns(props),
    dataSource: props.data,
    rowIdProperty: "SampleNumber",
    isDisableColumnFilter: true,
    minHeight: 70,
    heightValue: 100,
  };
  return <DataTable {...options}></DataTable>;
}

const GetCreateTraysColumns = () => [
  {
    headerName: "Tray",
    field: "Tray",
    width: 70,
  },
  {
    headerName: "Sample",
    field: "Sample",
    width: 75,
  },
  {
    headerName: "Sample Number",
    field: "SampleNumber",
    width: 123,
  },
  {
    headerName: "Label",
    field: "Label",
    width: 200,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Training",
    field: "IsTrainingSample",
    valueFormatter: ({ value }) => (value ? "Yes" : "No"),
    width: 90,
  },
  {
    headerName: "Step",
    field: "Step",
    valueFormatter: ({ value }) => GetStepNames(value).long,
    width: 90,
  },
  {
    headerName: "Delivered",
    field: "Delivered",
    valueFormatter: ({ value }) => toDateTimeFormat(value),
    width: 150,
  },
  {
    headerName: "Tray Date",
    field: "Received",
    valueFormatter: ({ value }) => toDateTimeFormat(value),
    width: 160,
  },
  {
    headerName: "Test Set",
    field: "TestSet",
    width: 110,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Sample Notes",
    field: "CustomerComponentNoteCount",
    valueFormatter: ({ value }) => (value !== 0 ? value : ""),
    minWidth: 210,
    flex: 1,
    renderCell: renderCellExpand,
  },
];
