import { Box, Typography } from "@mui/material";
import React from "react";
import QRCode from "react-qr-code";
import styled from "styled-components";

const PageBreakWrapper = styled.div`
  && {
    page-break-after: always;
  }
`;

const PrintTemplate = ({ detail }) => {
    return (
        <div>
            <div>
                <Box sx={{
                    textAlign: "center",
                    border: "1px solid black",
                    borderRadius: 50,
                    backgroundColor: "white",
                    width: "115px!important",
                    height: "115px!important",
                    p: 1,
                    margin: "0 0 0 10px",
                }}>
                    {detail?.RegistrationId > 0
                        ? <QRCode
                            value={detail?.RegistrationId}
                            size={30}
                            style={{ height: "auto", marginTop: 2, }}
                            viewBox={`0 0 256 256`}
                        />
                        : <></>}
                    <Typography sx={{ fontSize: "8px!important", }}>{(detail?.BottleNumber || "") + " / " + detail?.RegistrationId}</Typography>
                    <Typography sx={{ fontSize: "8px!important", }}>{detail?.SampleNumber}</Typography>
                    <Typography sx={{ fontSize: "8px!important" }}>{detail?.Customer?.slice(0, 24) + (detail?.Customer?.length > 24 ? "..." : "")}</Typography>
                    <Typography sx={{ fontSize: "8px!important" }}>{detail?.Model}</Typography>
                    <Typography sx={{ fontSize: "8px!important" }}>{detail?.Component}</Typography>
                    <Typography sx={{ fontSize: "8px!important" }}>{detail?.Serial}</Typography>
                </Box>
            </div>
            <PageBreakWrapper>&nbsp;</PageBreakWrapper>
        </div>
    );
};

export default class ComponentToPrintSVG extends React.Component {
    render() {
        let printingPages = [];
        const { details, ref } = this.props;
        for (const detail of details) {
            const tempTemplate = <PrintTemplate detail={detail} />;
            printingPages.push(tempTemplate);
        }

        return (
            <div ref={ref}>
                {/* map and create single component for each page */}
                {printingPages}
            </div>
        );
    }
}
