import { Box, Grid, Typography } from '@mui/material'
import QRCode from 'react-qr-code'

function ViewSticker({ regData }) {
    return (
        <Box
            id="paperless1"
            sx={{
                border: "1px solid black",
                backgroundColor: "white",
                width: "240px!important",
                height: "180px!important",
                p: 1,
            }}>
            <Grid container >
                <Grid item xs={5}>
                    <QRCode
                        value={regData?.BottleNumber.toString()}
                        size={80}
                        style={{ height: "auto", marginTop: 4, }}
                        viewBox={`0 0 256 256`}
                    />
                </Grid>
                <Grid item xs={7}>
                    <Typography sx={{ fontSize: "16px!important", lineHeight: 1 }}>{regData?.BottleNumber || ""}</Typography>
                    {/* <Typography sx={{ fontSize: "16px!important", lineHeight: 1 }}>{regData?.RegistrationId || ""}</Typography> */}
                    <Typography sx={{ fontSize: "16px!important", lineHeight: 1 }} >{regData?.Component && regData?.Component?.slice(0, 26) + (regData?.Component?.length > 26 ? "..." : "")}</Typography>
                    <Typography sx={{ fontSize: "16px!important", lineHeight: 1 }} >{regData?.Serial && regData?.Serial?.slice(0, 13) + (regData?.Serial?.length > 13 ? "..." : "")}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography sx={{ fontSize: "16px!important", lineHeight: 1 }}>{regData?.Customer && regData?.Customer?.slice(0, 50) + (regData?.Customer?.length > 50 ? "..." : "")}</Typography>
                    <Typography sx={{ fontSize: "16px!important", lineHeight: 1 }} >{regData?.Model && regData?.Model?.slice(0, 21) + (regData?.Model?.length > 21 ? "..." : "")}</Typography>
                    <Typography sx={{ fontSize: "16px!important", lineHeight: 1 }}>{(regData?.UnitId) || ""}</Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

export default ViewSticker