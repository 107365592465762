import { Box, Button, Card, Typography } from "@mui/material";
import { Check, Person } from "@mui/icons-material";
import { useEffect, useReducer, useState, useMemo, useCallback } from "react";
import Form, { FormActions } from "../components/Form";
import FormFooter from "../components/FormFooter";
import UnitReducer, { UnitActions } from "./reducers/UnitReducer";
import useFetch from "../hooks/useFetch";
import useMasterData from "../hooks/useMasterData";
import { ValidateUnit } from "../validation/schemas/UnitSchema";
import { DETAILS_APIS, TRAY_APIS, FLEET_APIS } from "../common/apis";
import AppDialog from "../components/AppDialog";
import SameSerialUnit from "../features/master-data/unit-models/SameSerialUnit";
import InsiteMachineSearch from "../features/master-data/unit-models/InsiteMachineSearch";
import { NewIcon } from "../icons/ButtonIcons";
import { useRoot } from "../rootContext";

import DropDownAdvance from "../components/DropDownAdvance";
import ManageUnit from "../features/quick-details/manage/manage-unit/ManageUnit";
import ConfirmModal from "../components/ConfirmModal";
import { getStartLetter } from "../utils";
import EditUnitModel from "./EditUnitModel";
import ManageUnitModels from "../features/quick-details/manage/ManageUnitModels";
import { useWindowPopups } from "../components/useWindowPopups";

function EditUnit({ cancel, unitData, registrationDone, handleSearchTree, startLetter, setExpandTreeData }) {

  const [data, dispatch] = useReducer(UnitReducer, {
    Unit: "",
    UnitMake: "?",
    UnitTypeId: "1",
    MeasureId: "1",
    LocationTaken: new Date(),
  });

  const { CanDoDataMaintenance } = useRoot();
  const { getMasterData } = useMasterData();
  const { get, post, getFile } = useFetch();

  const [dropdownData, setDropdownData] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [openUnitManagement, setOpenUnitManagement] = useState(false);
  const [editCheck, setEditCheck] = useState(false);
  const [componentModelsList, setComponentModelsList] = useState([]);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [openMachineInsite, setOpenMachineInsite] = useState(false);
  const [localData, setLocalData] = useState({});
  const [resp10, setResp10] = useState(false)

  const handleUpdateUnit = async () => {
    let result = await post(DETAILS_APIS.UPDATE_UNIT, {
      ApplyKalBranchToAll: data.KalBranch1 ? data.KalBranch1 : false,
      IsArchived: !!data.UnitArchived,
      IsParked: !!data.IsParked,
      IsFixedPlant: !!data.IsFixedPlant,
      IsScrapped: !!data.IsScrapped,

      SiteId: data?.SiteId,
      UnitId: data?.UnitId,
      Unit: data?.Unit,
      Serial: data?.Serial,
      PIN: data?.PIN,
      UIN: data?.UIN,
      UnitMakeId: data?.UnitMakeId,
      UnitTypeId: data?.UnitTypeId,
      MeasureId: data?.MeasureId,

      DecimalLongitude: data?.LocationLongitude?.toString() || "",
      DecimalLatitude: data?.LocationLatitude?.toString() || "",
      LocationDate: data?.LocationTaken,
      LocationStreet1: data?.LocationStreet1,
      LocationStreet2: data?.LocationStreet2,
      LocationCity: data?.LocationCity,
      LocationState: data?.LocationState,
      LocationPostCode: data?.LocationPostCode,
      LocationCountry: data?.LocationCountry,

      KalBranchId: data?.KalBranchId,
      Notes: data?.UnitNotes === "" ? null : data?.UnitNotes,
      UserId: data?.UserId,
      Screen: "Edit Unit",
    });
    if (result === -10) {
      return;
    }
    if (result > 0 || result?.length > 0) {
      const treeData = { ProductId: data?.ProductId, StartLetter: data?.StartLetter, CustomerId: data?.CustomerId, SiteId: data?.SiteId, UnitId: data?.UnitId, refreshCheck: true }
      localStorage.setItem("tree-resp", JSON.stringify({ ...treeData }));
      window.dispatchEvent(new Event('storage'));

      setExpandTreeData && setExpandTreeData(treeData)
      handleSearchTree && handleSearchTree(treeData, null, true)
      cancel && cancel();
      if (window.location.pathname === '/add-new-unit' || window.location.pathname === '/edit-unit') {
        localStorage.removeItem("edit-unit");
        localStorage.setItem("edit-unit-resp", JSON.stringify({ ...treeData }));
        window.dispatchEvent(new Event('storage'));
        window.close()
      }
      return;
    }
    if (result !== null && (result < 1 || result?.length < 1)) {
      return;
    }

    registrationDone && registrationDone(data.SampleNumber);
    return;
  }
  const handleNewUnit = async () => {
    let result = await post(DETAILS_APIS.ADD_UNIT, {
      SiteId: unitData?.SiteId || localData?.SiteId,
      ApplyKalBranchToAll: data.KalBranch1 ? data.KalBranch1 : false,
      IsArchived: !!data.UnitArchived,
      IsParked: !!data.IsParked,
      IsFixedPlant: !!data.IsFixedPlant,
      IsScrapped: !!data.IsScrapped,

      UnitId: data?.UnitId,
      Unit: data?.Unit,
      Serial: data?.Serial,
      PIN: data?.PIN,
      UIN: data?.UIN,
      UnitMakeId: data?.UnitMakeId,
      UnitTypeId: data?.UnitTypeId,
      MeasureId: data?.MeasureId,

      DecimalLongitude: data?.LocationLongitude?.toString() || "",
      DecimalLatitude: data?.LocationLatitude?.toString() || "",
      LocationDate: data?.LocationTaken,
      LocationStreet1: data?.LocationStreet1,
      LocationStreet2: data?.LocationStreet2,
      LocationCity: data?.LocationCity,
      LocationState: data?.LocationState,
      LocationPostCode: data?.LocationPostCode,
      LocationCountry: data?.LocationCountry,

      KalBranchId: data?.KalBranchId,
      Notes: data?.UnitNotes === "" ? null : data?.UnitNotes,
      UserId: data?.UserId,
      Screen: "Edit Unit",
    });
    if (result === -10) {
      return setResp10(true);
    }
    if (result > 0 || result?.length > 0) {
      if (result > 0) {
        let slIndex = 0;
        if ((startLetter === undefined || startLetter === "") && localData?.StartLetter === undefined) {
          const cn = data?.Customer || localData?.Customer
          let name = cn?.trim();
          slIndex = Number(getStartLetter(name[0]));
        }
        else {
          slIndex = startLetter || localData?.StartLetter
        }
        const treeData = {
          ProductId: unitData?.ProductId || localData?.ProductId,
          StartLetter: slIndex,
          CustomerId: unitData?.CustomerId || localData?.CustomerId,
          SiteId: unitData?.SiteId || localData?.SiteId,
          UnitId: result,
          refreshCheck: true
        }
        setExpandTreeData && setExpandTreeData(treeData)
        handleSearchTree && handleSearchTree(treeData, null, true)

        localStorage.setItem("tree-resp", JSON.stringify({ ...treeData }));
        window.dispatchEvent(new Event('storage'));

      }
      cancel && cancel();
      if (window.location.pathname === '/add-new-unit' || window.location.pathname === '/edit-unit') {
        localStorage.removeItem("edit-unit");
        const treeData = {
          ProductId: unitData?.ProductId || localData?.ProductId,
          CustomerId: unitData?.CustomerId || localData?.CustomerId,
          SiteId: unitData?.SiteId || localData?.SiteId,
          UnitId: result,
        }
        localStorage.setItem("edit-unit-resp", JSON.stringify({ ...treeData }));
        window.dispatchEvent(new Event('storage')); window.close()
      }
      return;
    }
    if (result !== null && (result < 1 || result?.length < 1)) {
      return;
    }

    registrationDone && registrationDone(data.SampleNumber);
    return;
  }

  const actions = {
    //Save data function
    save: async function () {
      if (unitData?.UnitId || data?.UnitId) {
        setFormErrors({});
        ValidateUnit(data).then(async function ({ data: req, errors }) {
          if (req) {
            handleUpdateUnit()
          }
          if (errors) {
            setFormErrors(errors);
          }
        });
      } else {
        setFormErrors({});
        ValidateUnit(data).then(async function ({ data: req, errors }) {
          if (req) {
            handleNewUnit()
          }
          if (errors) {
            setFormErrors(errors);
          }
        });
      }
    },
    manage: async function () {
      setOpenUnitManagement(true);
    },
    applyTemplate: async function () {
      setOpenAlertDialog(true);
    },
    cancel: cancel,
  };

  const handleApplyTemplate = async () => {
    await get(`${DETAILS_APIS.APPLY_TEMPLATE_COMPONENTS_UNITS}?UnitId=${data?.UnitId || 0}&Screen=Edit Unit`);
    setOpenAlertDialog(false);
  };

  const viewMachineReport = async () => {
    let fileName = `${data?.Customer || ""} ${data?.Site || ""} ${data?.Unit || ""} `
    await getFile(`${TRAY_APIS.MACHINE_REPORT}/?Machine_UnitId=${data.UnitId}`, fileName);
  };

  const viewHealthReport = async () => {
    let fileName = `CMHR ${data?.UnitModelFormatted || ""} ${data?.Serial || ""} `
    await getFile(`${TRAY_APIS.MACHINE_HEALTH_REPORT}/?EqpMachineId=${data?.EqpMachineId}`, fileName);
  };

  const getModelsList = async (unit, check, allData, newCheck) => {
    const unitModelsList = await get(`${FLEET_APIS.UNIT_MODELS}?UnitMake=${unit || "?"}`);
    setComponentModelsList(unitModelsList);
    if (check === true) {
      let dataList = unitModelsList.filter(item => item.UnitModel === allData?.Model);
      if (dataList?.length > 0) {

        dispatch({
          type: UnitActions.LOAD_DATA,
          payload: {
            ...data,
            PIN: allData?.PIN,
            Serial: allData?.Serial,
            UIN: allData?.FleetNo,
            UnitMake: allData?.Make.toUpperCase(),
            UnitModel: allData?.Model,
            LocationTaken: new Date(),
            UnitMakeId: dataList[0]?.UnitMakeId
          }
        })
      }
    }
    if (newCheck === true) {
      dispatch({
        type: UnitActions.LOAD_DATA,
        payload: {
          ...data,
          UnitMake: unit || "?",
          UnitMakeId: unitModelsList[0]?.UnitMakeId
        }
      })
    }
  }

  const handleDDChange = (e, val, name) => {
    dispatch({
      type: FormActions.INPUT_CHANGED,
      payload: { name: name, value: val }
    });
    if (name === "UnitMake") {
      getModelsList(val, null, null, true)
    }
  }

  const handleSelectInsite = (row) => {
    setOpenMachineInsite(false);
    getModelsList(row?.Make, true, row)
  }

  const getMakesModels = async () => {
    const masterData = await getMasterData("KalBranches,Measures,UnitMakes,UnitTypes", ``);
    setDropdownData(masterData || {});
    getModelsList(null, null, null, true)
  };


  const HandleNewWindow = (actionName) => {
    useWindowPopups(actionName)
  }

  const FormSections = useFormSections({
    HandleNewWindow,
    getMakesModels,
    resp10,
    dropdownData,
    componentModelsList,
    editCheck,
    data,
    viewMachineReport,
    viewHealthReport,
    setOpenMachineInsite,
    openMachineInsite,
    handleDDChange,
    CanDoDataMaintenance,
    handleSelectInsite
  });

  const handleKeyDown = (event) => {
    if (event.key === "F9") {
      event.preventDefault();
      window.open(`https://insite.komatsu.com.au/list?make=${data?.UnitMake || 0}&model=${data?.UnitModelFormatted || ""}&serial=${data?.Serial || 0}`, "_blank")
    }
  }

  useEffect(() => {
    (async () => {
      if (!unitData?.UnitId) {
        let lData = localStorage.getItem("edit-unit");
        if (lData !== null) {
          lData = JSON.parse(lData);
          setLocalData(lData)

          if (lData?.UnitId > 0) {
            const data = await get(`/details/unit?unitId=${lData?.UnitId}`);
            setDropdownData(data || {});
            setComponentModelsList(data?.UnitModels);
            dispatch({ type: UnitActions.LOAD_DATA, payload: data.Unit });
            setEditCheck(true);
          }
          else {
            getMakesModels();
          }
        }
        else {
          const masterData = await getMasterData("KalBranches,Measures,UnitMakes,UnitTypes", ``);
          setDropdownData(masterData || {});
        }
      }
      if (
        unitData?.UnitId !== null &&
        unitData?.UnitId !== undefined &&
        unitData?.UnitId > 0
      ) {
        const data = await get(`/details/unit?unitId=${unitData?.UnitId}`);
        setDropdownData(data || {});
        setComponentModelsList(data?.UnitModels);
        dispatch({ type: UnitActions.LOAD_DATA, payload: data.Unit });
        setEditCheck(true);
      }
      // else getModelsList(null, null, null, true);

    })();
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleCloseButton = () => {
    localStorage.removeItem("edit-unit");
  }

  const handleStorage = async () => {
    let regData = JSON.parse(localStorage.getItem("new-unit-model-resp"))

    if (regData?.success === true) {
      getMakesModels();
      localStorage.removeItem("new-unit-model-resp");
    }
  }

  useEffect(function () {
    window.addEventListener("beforeunload", handleCloseButton);
    window.addEventListener('storage', handleStorage, false);
    return function () {
      window.removeEventListener("beforeunload", handleCloseButton);
      document.removeEventListener('storage', handleStorage);
    };
  });

  return (
    <Card sx={{ padding: 1 }}>
      <Typography sx={{ marginBottom: "10px" }}>
        {(unitData?.Product || "") +
          " " +
          (unitData?.Customer || "") +
          " " +
          (unitData?.Site || "")}
      </Typography>
      <Form
        sections={FormSections}
        data={data}
        spacing={2}
        dispatch={dispatch}
        errors={formErrors}
        key={data._key}
      />
      <FormFooter
        buttons={GetFormButtons(data, actions, editCheck, CanDoDataMaintenance, handleNewUnit)}
        footerText={`ProductId: ${unitData?.ProductId || localData?.ProductId || ""}, CustomerId: ${unitData?.CustomerId || localData?.CustomerId || ""
          }, SiteId: ${unitData?.SiteId || localData?.SiteId || ""}, UnitId: ${unitData?.UnitId || localData?.UnitId || ""
          } `}
      />

      <AppDialog
        open={openAlertDialog}
        title={`Apply Machine Template Components`}
        maxWidth="lg"
        handleClose={() => setOpenUnitManagement(false)}
      >
        <ConfirmModal
          handleClose={() => { setOpenAlertDialog(false); }}
          alertAction={handleApplyTemplate}
          message={"Do you wish to create components from the machine template, if they do not already exist?"}
        />
      </AppDialog>

      <AppDialog
        open={openUnitManagement}
        title={`Unit Management Unit ${data.UnitId}`}
        maxWidth="100vw"
        handleClose={() => setOpenUnitManagement(false)}
      >
        <ManageUnit unit={unitData} />
      </AppDialog>
    </Card>
  );
}
export default EditUnit;

const GetFormButtons = (
  data,
  { cancel, save, manage, applyTemplate },
  editCheck, CanDoDataMaintenance, handleNewUnit
) => [
    {
      hidden: !CanDoDataMaintenance,
      variant: "outlined",
      label: "Apply Template",
      onClick: () => applyTemplate(data),
      disabled:
        (data?.UnitMake === undefined && data?.UnitModel === undefined) ||
        data?.UnitMake === "?",
      startIcon: <Check />,
    },
    {
      hidden: !editCheck || !CanDoDataMaintenance,
      variant: "outlined",
      label: "Manage",
      onClick: manage,
      startIcon: <Check />,
    },
    {
      hidden: !editCheck || !CanDoDataMaintenance,
      variant: "outlined",
      label: "Save New",
      onClick: handleNewUnit,
      startIcon: <NewIcon />,
    },
    {
      hidden: !CanDoDataMaintenance,
      variant: "contained",
      label: "Save",
      onClick: save,
      startIcon: <Check />,
    },
  ];

function MakesDropDown({ id, dataList, handleChange, defaultValue, label }) {

  const renderOptions = useCallback(function (props, option) {
    return (
      <Box component="li" sx={{ minWidth: "350px", maxWidth: "350px" }} {...props} key={option.UnitMake}>
        {option.UnitMake || "Select"}
      </Box>
    );
  }, []);

  const filterOptions = useCallback(function (options, value) {
    return !value.inputValue
      ? options
      : options
        .filter((x) =>
          x.UnitMake.toLowerCase().includes(
            value.inputValue.toLowerCase()
          )
        )
  }, []);

  const getOptionLabel = useCallback(function (option) {
    return option.UnitMake || "Select";
  }, []);

  return (
    <DropDownAdvance
      id={id}
      label={label}
      renderOptions={renderOptions}
      filterOptions={filterOptions}
      getOptionLabel={getOptionLabel}
      onChange={(e, val) => handleChange(e, val?.UnitMake || null, "UnitMake")}
      options={dataList || []}
      defaultValue={dataList.find((x) => x.UnitMake === defaultValue) || "Select"}
    />
  );
}

function ModelsDropDown({ id, dataList, handleChange, defaultValue, label }) {

  const renderOptions = useCallback(function (props, option) {
    return (
      <Box component="li" sx={{ minWidth: "350px", maxWidth: "350px" }} {...props} key={option.UnitMakeId}>
        {option.UnitModel || "Select"}
      </Box>
    );
  }, []);

  const filterOptions = useCallback(function (options, value) {
    return !value.inputValue
      ? options
      : options
        .filter((x) =>
          x.UnitModel.toLowerCase().includes(
            value.inputValue.toLowerCase()
          )
        )
  }, []);

  const getOptionLabel = useCallback(function (option) {
    return option.UnitModel || "Select";
  }, []);

  return (
    <DropDownAdvance
      id={id}
      label={label}
      renderOptions={renderOptions}
      filterOptions={filterOptions}
      getOptionLabel={getOptionLabel}
      onChange={(e, val) => handleChange(e, val?.UnitMakeId || null, "UnitMakeId")}
      options={dataList || []}
      defaultValue={dataList.find((x) => x.UnitMakeId === defaultValue) || dataList[0] || "Select"}
    />
  );
}

const useFormSections = ({
  HandleNewWindow,
  getMakesModels,
  resp10,
  dropdownData,
  componentModelsList,
  editCheck,
  data,
  viewMachineReport,
  viewHealthReport,
  setOpenMachineInsite,
  openMachineInsite,
  handleDDChange, CanDoDataMaintenance, handleSelectInsite,
}) => {
  const [openNewModel, setOpenNewModel] = useState(false);
  const [openEditModel, setOpenEditModel] = useState(false);
  const [openManageModel, setOpenManageModel] = useState(false);
  const [openSameSerial, setOpenSameSerial] = useState(false);

  return useMemo(
    () => [
      {
        fields: [
          {
            xsCol: 12,
            component: function () {
              return <Typography sx={{ color: "red" }}> {resp10 === true ? "Please provide a UNIQUE name for Unit" : ".  "}</Typography>
            },
          },
          {
            xsCol: 12,
            group: [
              { name: "Unit", label: "Unit / Fleet No.", xsCol: 3 },
              { name: "UIN", label: "UIN Asset No.", xsCol: 3 },
              {
                xsCol: 6,
                group: [
                  (data?.Serial?.length > 0) && {
                    component: function () {
                      return (
                        <>
                          <Button
                            sx={{ marginTop: "14px" }}
                            variant="outlined"
                            onClick={() => setOpenSameSerial(true)}
                          // startIcon={<Person />}
                          >
                            Same Serial (0)
                          </Button>
                          <AppDialog
                            open={openSameSerial}
                            title="Same Serial"
                            maxWidth="100vw"
                            handleClose={() => setOpenSameSerial(false)}
                          >
                            <SameSerialUnit
                              userData={data}
                              makesList={dropdownData.UnitMakes}
                            />
                          </AppDialog>
                        </>
                      );
                    },
                  },
                  { xsCol: (data?.Serial?.length > 0) ? 4 : 6, name: "Serial", label: "Serial" },
                  (data?.Serial?.length > 0) && {
                    component: function () {
                      return (
                        <>
                          <Button
                            variant="outlined"
                            sx={{ width: "210px", mt: 1.5 }}
                            onClick={() => setOpenMachineInsite(true)}
                          >
                            Search Machines Insite
                          </Button>
                          <AppDialog
                            open={openMachineInsite}
                            title="Search Machines Insite"
                            maxWidth="100vw"
                            handleClose={() => setOpenMachineInsite(false)}
                          >
                            <InsiteMachineSearch
                              selectData={handleSelectInsite}
                              userData={data}
                            />
                          </AppDialog>
                        </>
                      );
                    },
                  },
                ],
              }
            ],
          },

          {
            name: "UnitTypeId",
            label: "Type",
            type: "dropdown",
            xsCol: 3,
            selectOptions: (dropdownData.UnitTypes || []).map((x) => ({
              value: x.UnitTypeId,
              text: x.UnitType,
            })),
          },
          {
            xsCol: 3,
            component: function () {
              return (
                <MakesDropDown
                  id={"UnitMake"}
                  label={"Make"}
                  dataList={dropdownData.UnitMakes || []}
                  handleChange={(e, val, text) => handleDDChange(e, val, text)}
                  defaultValue={data?.UnitMake}
                />
              );
            },
          },
          {
            xsCol: 3,
            component: function () {
              return (
                <ModelsDropDown
                  id={"UnitMakeId"}
                  label={"Model"}
                  dataList={componentModelsList || []}
                  handleChange={(e, val, text) => handleDDChange(e, val, text)}
                  defaultValue={data?.UnitMakeId}
                />
              );
            },
          },
          {
            xsCol: 12,
            group: [

              editCheck && {
                component: function () {
                  return (
                    <Button
                      variant="outlined"
                      sx={{ width: "210px" }}
                      onClick={() =>
                        window.open(
                          `https://insite.komatsu.com.au/list?make=${data?.UnitMake}&model=${data?.UnitModel}&serial=${data?.Serial}`,
                          "_blank"
                        )
                      }
                    >
                      View Machines Insite
                    </Button>
                  );
                },
              },
              editCheck && {
                component: function () {
                  return (
                    <Button
                      variant="outlined"
                      sx={{ width: "210px" }}
                      onClick={viewMachineReport}
                    >
                      View Machine Report
                    </Button>
                  );
                },
              },
              editCheck && {
                component: function () {
                  return (
                    <Button
                      variant="outlined"
                      sx={{ width: "210px" }}
                      onClick={viewHealthReport}
                    >
                      View Health Report
                    </Button>
                  );
                },
              },
            ],
          },
          {
            xsCol: 12,
            group: [
              {
                component: function () {
                  return (
                    <Button
                      variant="outlined"
                      sx={{ width: "210px" }}
                      onClick={() =>
                        window.open(
                          `https://insite.komatsu.com.au/list?make=${data?.UnitMake}&model=${data?.UnitModelFormatted}&serial=${data?.Serial}`,
                          "_blank"
                        )
                      }
                    >
                      Search Machines Websites (F9)
                    </Button>
                  );
                },
              },
              {
                component: function () {
                  return (
                    <>
                      <Button
                        variant="outlined"
                        sx={{ width: "210px" }}
                        // onClick={() => setOpenNewModel(true)}
                        onClick={() => HandleNewWindow("new-unit-model")}
                      >
                        New Model
                      </Button>
                      {/* <AppDialog
                        open={openNewModel}
                        title="New Unit Model"
                        maxWidth="md"
                        handleClose={() => setOpenNewModel(false)}
                      >
                        <EditUnitModel
                          cancel={() => setOpenNewModel(false)}
                          fetchUnitModels={getMakesModels}
                        />
                      </AppDialog> */}
                    </>
                  );
                },
              },
              {
                hidden: !editCheck,
                component: function () {
                  return (
                    <>
                      <Button
                        variant="outlined"
                        sx={{ width: "210px" }}
                        onClick={() => setOpenEditModel(true)}
                        disabled={!CanDoDataMaintenance}
                      >
                        Edit Model
                      </Button>
                      <AppDialog
                        open={openEditModel}
                        title="Edit Unit Model"
                        maxWidth="md"
                        handleClose={() => setOpenEditModel(false)}
                      >
                        <EditUnitModel
                          cancel={() => setOpenNewModel(false)}
                          fetchUnitModels={getMakesModels}
                          unitModel={{
                            HasIssues: data.HasIssues,
                            IsKomatsu: data.IsKomatsu,
                            IsKomatsuGroup: data.IsKomatsuGroup,
                            Komatsu: data.Komatsu,
                            KomatsuGroup: data.KomatsuGroup,
                            TemplateId: data.TemplateId,
                            UnitMake: data.UnitMake,
                            UnitMakeId: data.UnitMakeId,
                            UnitModel: data.UnitModel,
                            UnitModelFormatted: data.UnitModelFormatted,
                            UnitModelNotes: data.UnitModelNotes,
                            Verified: data.Verified,
                          }}
                        />
                      </AppDialog>
                    </>
                  );
                },
              },
              {
                component: function () {
                  return (
                    <>
                      <Button
                        variant="outlined"
                        sx={{ width: "210px" }}
                        onClick={() => setOpenManageModel(true)}
                        startIcon={<Person />}
                        disabled={!CanDoDataMaintenance}
                      >
                        Manage Model
                      </Button>
                      <AppDialog
                        open={openManageModel}
                        title="Unit Model Management"
                        maxWidth="100vw"
                        handleClose={() => setOpenManageModel(false)}
                      >
                        <ManageUnitModels
                          SelectedUnit={{
                            UnitMake: data.UnitMake,
                            UnitMakeId: data.UnitMakeId,
                            UnitModel: data.UnitModel,
                            UnitModelFormatted: data.UnitModelFormatted,
                            UnitModelNotes: data.UnitModelNotes,
                          }}
                        />
                      </AppDialog>
                    </>
                  );
                },
              },
            ],
          },
          {
            xsCol: 12,
            group: [
              {
                name: "UnitArchived",
                label: "Archived - no website",
                type: "switch",
              },
              {
                name: "IsScrapped",
                label: "Scrapped - no website",
                type: "switch",
              },
              {
                name: "IsFixedPlant",
                label: "Fixed Plant",
                type: "switch",
              },
              {
                name: "IsParked",
                label: "Parked - no website",
                type: "switch",
              },
            ],
          },
          { name: "PIN", label: "Komatsu PIN", xsCol: 3 },
          {
            name: "MeasureId",
            label: "Measure",
            type: "dropdown",
            xsCol: 3,
            selectOptions: (dropdownData.Measures || []).map((x) => ({
              value: x.MeasureId,
              text: x.MeasureLong,
            })),
          },
          {
            name: "KalBranchId",
            label: "KAL Branch",
            type: "dropdown",
            xsCol: 3,
            selectOptions: (dropdownData.KalBranches || []).map((x) => ({
              value: x.BranchId,
              text: x.FullBranch,
            })),
          },
          {
            name: "KalBranch1",
            label: "Apply KAL Branch to ALL Units for this Site",
            type: "switch",
            xsCol: 3,
          },
          // { name: "EqpMachineId", label: "MachineId", xsCol: 3 },
          { name: "LocationLatitude", label: "Latitude", xsCol: 3 },
          { name: "LocationLongitude", label: "Longitude", xsCol: 3 },
          {
            name: "LocationTaken",
            label: "Date Taken",
            type: "datetime",
            xsCol: 3,
          },
          { name: "UnitNotes", label: "Comment", type: "textarea", xsCol: 12 },
        ],
      },
    ],
    [
      dropdownData,
      componentModelsList,
      editCheck,
      openEditModel,
      openNewModel,
      openManageModel,
      openSameSerial,
      openMachineInsite,
      data,
      resp10,
    ]
  );
};
