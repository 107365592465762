import React, { useRef } from "react";
import { Chart } from "chart.js";
import Grid from "@mui/material/Grid";
import { Scatter } from "react-chartjs-2";
import { useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import SampleEventsTable from "./SampleEventsTable";
import AppDialog from "../../components/AppDialog";
import EditEvent from "../events/EditEvent";
import { Button, FormControlLabel, FormGroup, Stack, Switch, } from "@mui/material";
import ChartDataLabels from "chartjs-plugin-datalabels";
import ChartAnnotations from "chartjs-plugin-annotation";
import "chartjs-adapter-moment";
import { TRAY_APIS } from "../../common/apis";
import InsiteIframe from "./InsiteIframe";

//updating chart
Chart.register(ChartDataLabels);
Chart.register(ChartAnnotations);
let annotationDataArray = [];

const TabEventChart = ({ sampleId, eqpMachine }) => {
  const { get } = useFetch();

  const chartReference = useRef();

  const [chartDateData, setChartDateData] = useState(null)

  const [isShowFluidEvents, setIsShowFluidEvents] = useState(true);
  const [chartData, setChartData] = useState(null);
  const [eventsData, setEventsData] = useState(null);
  const [eqpMachineId, setEqpMachineId] = useState(null);

  const [productId, setProductId] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [siteId, setSiteId] = useState(null);
  const [unitId, setUnitId] = useState(null);

  const [openEditEvent, setOpenEditEvent] = useState(false);
  const [hideEventsTable, setHideEventsTable] = useState(false);
  const [eventFilter, setEventFilter] = useState({});
  const [dateCheck, setDateCheck] = useState(false)

  const [options, setOptions] = useState({
    responsive: true,
    elements: {
      pointStyle: "star",
    },

    plugins: {
      datalabels: {
        display: false,
        color: "black",
        align: "top",
        formatter: function (value, context) {
          return value.y;
        },
      },
      annotation: {
        annotations: [],
      },
      legend: {
        position: "right",
        align: "center",
        labels: {
          boxWidth: 10,
          pointStyle: "cross",
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return context.raw.elementHover;
          },
          title: function (context) {
            return "";
          },
        },
      },
    },
  });

  const sampleDataHours = (sampleData) => {
    let filterEventsData = [];

    const filteredArr = sampleData?.chartData?.datasets?.map(i => { return { ...i, "data": i?.data?.filter(function (a) { return a.x !== null }) } });
    const ncd = filteredArr?.map(i => { return { ...i, "data": i?.data?.sort(function (a, b) { return parseFloat(b.x) - parseFloat(a.x); }) } });
    setChartData({ labels: null, datasets: ncd || [] });
    // setChartData(sampleData && sampleData.chartData);

    filterEventsData = sampleData?.EventLogsBySampleNumber?.filter(
      (item) =>
        item.EventTypeId === 1 ||
        item.EventTypeId === 2 ||
        item.EventTypeId === 7
    );
    annotationDataArray = filterEventsData?.map((item) => ({
      type: "line",
      xMin: item?.UnitHours,
      xMax: item?.UnitHours,
      borderColor: "rgba(178, 178, 178, 0.8)",
      borderWidth: 1,
      margin: 5,
      label: {
        backgroundColor: "transparent",
        content: item?.EventType,

        display: true,
        position: "end",
        color: "rgba(178, 178, 178, 0.8)",
      },
    }));
    setOptions({
      responsive: true,
      elements: {
        pointStyle: "star",
      },

      // scales: {
      //   x: {
      //     type: 'time',
      //     time: {
      //       unit: 'quarter',
      //       parser: 'DD.MM.YYYY',
      //       displayFormats: {
      //         quarter: 'DD MM YYYY',
      //         // biannual: 'DD MM YYYY'
      //       }
      //     }
      //   },
      //   y: { beginAtZero: true, },
      // },

      plugins: {
        datalabels: { ...options.plugins.datalabels },
        // datalabels: {
        //   display: false,
        //   color: "black",
        //   align: "top",
        //   formatter: function (value, context) {
        //     return value.y;
        //   },
        // },
        annotation: {    //for unit hours
          annotations: annotationDataArray,
        },
        legend: {
          position: "right",
          align: "center",
          labels: {
            boxWidth: 10,
            pointStyle: "cross",
          },
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              return context.raw.elementHover;
            },
            title: function (context) {
              return "";
            },
          },
        },
      },
    });
  }

  async function fetchData(sampleNumbers) {
    if (sampleNumbers > 0 || sampleNumbers?.SampleNumber > 0) {

      const sampleData = await get(`${TRAY_APIS.SAMPLE_CHART}?SampleNumber=${sampleNumbers?.SampleNumber || sampleNumbers}&IncludeLaterSamples=true`);
      const a1 = sampleData;
      const ncd = a1?.chartData.datasets.map(i => { return { ...i, "data": i?.data?.map(j => { return { ...j, "x": (j?.x1) } }) } });
      setChartDateData({ labels: null, datasets: ncd || [] });

      sampleDataHours(sampleData);
      setEventsData(sampleData && sampleData.EventLogsBySampleNumber);

      setProductId(sampleData && sampleData.ProductId);
      setCustomerId(sampleData && sampleData.CustomerId);
      setSiteId(sampleData && sampleData.SiteId);
      setUnitId(sampleData && sampleData.UnitId);
      if (sampleData.EventLogsBySampleNumber?.length > 0) {
        setEventFilter({
          SiteId: sampleData?.SiteId || sampleData?.EventLogsBySampleNumber[0]?.SiteId,
          UnitId: sampleData?.UnitId || sampleData?.EventLogsBySampleNumber[0]?.UnitId,
          CustomerId: sampleData?.CustomerId || sampleData?.EventLogsBySampleNumber[0]?.CustomerId,
          ProductId: sampleData?.ProductId || sampleData?.EventLogsBySampleNumber[0]?.ProductId,
          SampleNumber: sampleData?.SampleNumber || sampleData?.EventLogsBySampleNumber[0]?.SampleNumber,
          ComponentId: sampleData?.ComponentId || sampleData?.EventLogsBySampleNumber[0]?.ComponentId,
        });
      }
      else {
        setEventFilter({
          SiteId: sampleData?.SiteId,
          UnitId: sampleData?.UnitId,
          CustomerId: sampleData?.CustomerId,
          ProductId: sampleData?.ProductId,
          ComponentId: sampleData?.ComponentId,
        });
      }
    }
  }

  function handleHideEventsClick() {
    if (hideEventsTable) {
      setHideEventsTable(false);
    } else {
      setHideEventsTable(true);
    }
  }

  function setChartOptions(isShow) {
    setOptions({
      ...options,
      plugins: {
        ...options.plugins,
        datalabels: {
          display: isShow,
          color: "black",
          align: "top",
          formatter: function (value, context) {
            return value.y;
          },
        },
      },
    });
  }

  const handleShowValue = (event, val) => {
    setChartOptions(val);
  };

  const handelFluidChangedCheck = (event, val) => {
    setIsShowFluidEvents(val);
  };

  const handleAddEventClick = () => {
    setOpenEditEvent(true);
  };

  const handleEditClick = (sample) => {
    setEventFilter({
      ...sample,
      SiteId: siteId,
      UnitId: unitId,
      CustomerId: customerId,
      ProductId: productId,
      Charts: true,
    });
    setOpenEditEvent(true);
  }

  useEffect(function () {
    fetchData(sampleId);
    setEqpMachineId(eqpMachine)
  }, [sampleId, eqpMachine]);

  return (
    <>
      <Grid container spacing={2}>
        {eqpMachineId > 0
          ? (
            <Grid xs={3}>
              <InsiteIframe eqpMachineId={eqpMachineId} />
              {/* <iframe
                id="insightsframe"
                title="insights frame"
                src={
                  "https://insiteuat.komatsu.com.au/profile/" + // For UAT
                  //"https://insite.komatsu.com.au/profile/" + // For PROD
                  eqpMachineId +
                  "/overview"
                }
                height={"100%"}
                width={"100%"}
              /> */}
            </Grid>
          )
          : (<></>)
        }

        <Grid xs={eqpMachineId > 0 ? 9 : 12}>
          <Stack direction="row" spacing={1}>
            <FormGroup>
              <FormControlLabel
                onChange={handleShowValue}
                control={<Switch />}
                label="Values"
                sx={{ mt: -0.5, ml: 6 }}
              />
            </FormGroup>

            <FormGroup>
              <Button onClick={handleHideEventsClick}>Show/ Hide Events</Button>
            </FormGroup>
            {!hideEventsTable && (
              <FormGroup>
                <FormControlLabel
                  onChange={handelFluidChangedCheck}
                  control={<Switch />}
                  checked={isShowFluidEvents}
                  label="Hide Fluid Changed Events"
                  sx={{ mt: -0.5, ml: 2 }}
                />
              </FormGroup>
            )}
            <FormGroup>
              <Button onClick={handleAddEventClick}>Add Event</Button>
            </FormGroup>
          </Stack>
          <Grid item xs={12} sx={{ pl: 2 }} hidden={hideEventsTable}>
            {chartData && (
              <SampleEventsTable
                data={isShowFluidEvents ? eventsData?.filter((item) => item?.EventTypeId !== 3) : eventsData}
                editClick={handleEditClick}
              />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              minHeight: hideEventsTable == true ? 800 : 580,
              maxHeight: hideEventsTable == true ? 1200 : 550,
              maxWidth: hideEventsTable == true ? "1400px !important" : "1200px!important",
              pl: 2,
            }}
          >
            {chartData && (<>
              {dateCheck === false
                ? <Scatter
                  ref={chartReference}
                  type="Scatter"
                  data={chartData}
                  options={options}
                />
                : chartDateData && <><Scatter options={options} data={chartDateData} ref={chartReference} /></>
              }
            </>
            )}
          </Grid>
        </Grid>
      </Grid >

      <AppDialog
        open={openEditEvent}
        title="Edit Event"
        maxWidth="xl"
        handleClose={() => setOpenEditEvent(false)}
      >
        <EditEvent
          cancel={() => setOpenEditEvent(false)}
          eventFilter={{
            ...eventFilter,
            ProductId: eventFilter?.ProductId,
            CustomerId: eventFilter?.CustomerId,
            SiteId: eventFilter?.SiteId,
            UnitId: eventFilter?.UnitId,
            ComponentId: eventFilter?.ComponentId,
            SampleNumber: eventFilter?.SampleNumber || sampleId,
            EventLogId: eventFilter?.EventLogId,
          }}
          setOpenEditEvent={setOpenEditEvent}
          fetchData={fetchData}
        />
      </AppDialog>
    </>
  );
};

export default TabEventChart;