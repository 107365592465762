import DataTable from "../../../../components/DataTable";
import renderCellExpand from "../../../../components/DatatableCellExpand";

export default function SampleTable({
  data,
  sampleReportDataCheckbox,
  rowsSelected,
}) {

  const sampleReportOptions = {
    columns: SampleTableColumns,
    dataSource: (data || []).map((x) => ({
      ...x,
      keyProp: x.ItemNumber,
    })),
    rowIdProperty: "keyProp",
    checkBoxSelect: true,
    selectedRowIds: sampleReportDataCheckbox,
    isDisableColumnFilter: false,
    rowsSelected: rowsSelected,
    sx: { height: 660 },
    checkboxColor: "#000000",
    selectedRowColor: "transparent",
    selectedTextColor: "#000000",
    cellStylingHandler: "emailSelectedSamples",
    clsCheck: true,
  };
  return <DataTable {...sampleReportOptions} />;
}

const SampleTableColumns = [
  {
    headerName: "Type",
    field: "ItemType",
    minWidth: 100,
  },
  {
    headerName: "Number",
    field: "ItemNumber",
    minWidth: 120,
  },
  {
    headerName: "Component",
    field: "Component",
    minWidth: 130,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Unit",
    field: "Unit",
    minWidth: 120,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Site",
    field: "Site",
    minWidth: 120,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Customer",
    field: "Customer",
    minWidth: 180,
    flex: 1,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Product",
    field: "Product",
    minWidth: 110,
  },
  {
    headerName: "Complete",
    field: "Complete",
    width: 65,
  },
  {
    headerName: "Archived",
    field: "Archived",
    width: 65,
  },
];
