import React, { useState, useEffect, useReducer, useMemo } from "react";
import { Grid, Box, Button } from "@mui/material";
import Form, { FormActions } from "../../components/Form";
import FormFooter from "../../components/FormFooter";
import useFetch from "../../hooks/useFetch";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Radio } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import { toLocalDateTime } from "../../utils";
import { SectionTitle } from "../../components/Form";
import { GRAPH_APIS, MANAGEMENT_APIS, MASTER_DATA_APIS } from "../../common/apis";
import { Search } from "@mui/icons-material";
import { Save } from "@mui/icons-material";
import AppDialog from "../../components/AppDialog";
import ConfirmModal from "../../components/ConfirmModal";
import { useUser } from "../../rootContext";
import appResources from "../../app-resources/en/Resources.json";

export default function NewUser({
  editData,
  usersData,
  refreshUsersData,
  setAddUserModal,
}) {
  const { get, post } = useFetch();
  const user = useUser();

  const [data, dispatch] = useReducer(NewUserReducer);
  const [dataToggle, dispatchToggle] = useReducer(NewToggleReducer);
  const [infoMessage, setInfoMessage] = useState({ color: "green", message: "" });
  const [userList, setUserList] = useState([]);
  const [editDataList, setEditDataList] = useState([]);
  const [newUserFlag, setNewUserFlag] = useState(false);
  const [newUserData, setNewUserData] = useState([]);
  const [isArchived, setIsArchived] = useState(false);
  const [roleValue, setRoleValue] = useState("RoleViewer");
  const [editFlag, setEditFlag] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openAddAlert, setOpenAddAlert] = useState(false);
  const [roleList, setRoleList] = useState({
    RoleLaboratory: false,
    RoleQualityCheck: false,
    RoleRegistration: false,
    RoleEvaluation: false,
    RoleSpecialReports: false,
    RoleMachineTemplates: false,
    RoleTestSets: false,
    RoleImportHistory: false,
  });
  const [checkedItems, setCheckedItems] = useState([]);
  const [fluidTypes, setFluidTypes] = useState([]);

  const handleChangeRadio = (event) => {
    setRoleValue(event.target.value);
  };

  const updateState = (fluidTypeId, isChecked) => {
    let check = checkedItems.findIndex((obj) => obj.FluidTypeId === fluidTypeId);
    if (check > -1) {
      const newState = checkedItems.map(obj => {
        if (obj.FluidTypeId === fluidTypeId) {
          return { ...obj, "IsDefault": isChecked, };
        }
        return obj;
      });
      setCheckedItems(newState);
    }
    if (check <= -1) {
      setCheckedItems((prevCheckedItems) => ([
        ...prevCheckedItems,
        { "FluidTypeId": fluidTypeId, "IsDefault": isChecked, "IsExisting": false }
      ]));
    }
  };

  const handleFluidChange = (fluidTypeId, isChecked) => {
    updateState(fluidTypeId, isChecked)
  };

  const handleRoleChange = (event) => {
    setRoleList({
      ...roleList,
      [event.target.name]:
        event.target.value == "true"
          ? false
          : event.target.value == "false"
            ? true
            : false,
    });
  };

  function GetFormSections() {
    return [
      {
        xsCol: 12,
        fields: [
          {
            xsCol: 4,
            name: "IsNotifyActionsCriticals",
            label: "Notify for Actions and Criticals",
            type: "switch",
          },
          {
            xsCol: 4,
            name: "IsBlockFeedback",
            label: "Exclude from ML Feedback",
            type: "switch",
          },
          {
            xsCol: 4,
            name: "IsDefaultEvaluator",
            label: "Default owner of Sites",
            type: "switch",
          },
        ],
      },
    ];
  }

  async function saveNewUser() {
    if (editFlag === true) {
      const response = await post(MANAGEMENT_APIS.UPDATE_USER, {
        UpdateUserId: editDataList.UserId || null,
        Fullname: editDataList.FullName || "",
        IsNotifyActionsCriticals: dataToggle?.IsNotifyActionsCriticals || false,
        BlockFeedback: dataToggle?.IsBlockFeedback || false,
        IsDefaultCustomerOwner: dataToggle?.IsDefaultEvaluator || false,
        RoleViewer: roleValue === "RoleViewer" ? true : false,
        RoleTrainee: roleValue === "RoleTrainee" ? true : false,
        RoleAdministration: roleValue === "RoleAdministration" ? true : false,
        RoleLaboratory: roleValue !== "combinationOf" ? false : roleList.RoleLaboratory || false,
        RoleQualityCheck: roleValue !== "combinationOf" ? false : roleList.RoleQualityCheck || false,
        RoleRegistration: roleValue !== "combinationOf" ? false : roleList.RoleRegistration || false,
        RoleEvaluation: roleValue !== "combinationOf" ? false : roleList.RoleEvaluation || false,
        RoleMachineTemplates: roleValue !== "combinationOf" ? false : roleList.RoleMachineTemplates || false,
        RoleTestSets: roleValue !== "combinationOf" ? false : roleList.RoleTestSets || false,
        RoleSpecialReports: roleValue !== "combinationOf" ? false : roleList.RoleSpecialReports || false,
        RoleImportHistory: roleValue !== "combinationOf" ? false : roleList.RoleImportHistory || false,
        IsArchived: isArchived,
        fluidTypeNotifications: checkedItems,
        Screen: "Users Overview",
      });
      refreshUsersData();
      setAddUserModal(false);
    } else {
      const payload = {
        Username: newUserData?.DisplayName || "",
        Forename: newUserData?.GivenName || "",
        Surname: newUserData?.Surname || "",
        Fullname: newUserData?.DisplayName || "",
        EmailAddress: newUserData?.Mail || "",
        Mobile: newUserData?.Mobile || "",
        Telephone: newUserData?.Telephone || newUserData?.Mobile || "",
        IsNotifyActionsCriticals: false,
        BlockFeedback: false,
        UserId: user?.UserId || 0,

        IsDefaultCustomerOwner: dataToggle?.IsDefaultEvaluator || false,
        RoleViewer: roleValue === "RoleViewer" ? true : false,
        RoleTrainee: roleValue === "RoleTrainee" ? true : false,
        RoleAdministration: roleValue === "RoleAdministration" ? true : false,
        RoleLaboratory: roleValue !== "combinationOf" ? false : roleList.RoleLaboratory || false,
        RoleQualityCheck: roleValue !== "combinationOf" ? false : roleList.RoleQualityCheck || false,
        RoleRegistration: roleValue !== "combinationOf" ? false : roleList.RoleRegistration || false,
        RoleEvaluation: roleValue !== "combinationOf" ? false : roleList.RoleEvaluation || false,
        RoleMachineTemplates: roleValue !== "combinationOf" ? false : roleList.RoleMachineTemplates || false,
        RoleTestSets: roleValue !== "combinationOf" ? false : roleList.RoleTestSets || false,
        RoleSpecialReports: roleValue !== "combinationOf" ? false : roleList.RoleSpecialReports || false,
        RoleImportHistory: roleValue !== "combinationOf" ? false : roleList.RoleImportHistory || false,
        IsArchived: isArchived,
        fluidTypeNotifications: checkedItems,
        Screen: "Users Overview",
      }
      const response = await post(MANAGEMENT_APIS.ADD_USER, payload);
      setOpenAddAlert(false);
      setInfoMessage({ color: "green", message: `The user ${newUserData?.DisplayName} has been added` })
      setTimeout(() => { setAddUserModal(false); refreshUsersData(); }, 2000)
    }
  }

  const handleDeleteUser = async () => {
    await get(`${MANAGEMENT_APIS.DELETE_USER_PROFILE}?DeleteUserId=${data.UserId}&Screen="Edit User`);
    refreshUsersData();
    setOpenAlert(false);
    setAddUserModal(false);
  }

  const actions = {
    deleteUser: async function () {
      setOpenAlert(true);
    },
    add: newUserFlag === true ? () => setOpenAddAlert(true) : saveNewUser,
  };

  const emailValidation = useMemo(
    function ValidateEmail() {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,9})+$/.test(data?.emailId)) {
        return (false)
      }
      return (true)
    }, [data?.emailId]
  );

  async function searchUserByKeyword() {
    const response = await post(GRAPH_APIS.USERS, { Mail: data?.emailId });
    if (response?.length > 0) { setNewUserFlag(true); setNewUserData(response[0]) }
    if (response?.length < 1) {
      setInfoMessage({ color: "red", message: appResources?.USER_DOESNOT_EXISTS });
    }
  }

  useEffect(() => {
    const filterUsers = usersData?.find((x) => x.UserId === data?.UserId);
    setRoleList({
      ...roleList,
      RoleLaboratory: filterUsers?.RoleLaboratory,
      RoleQualityCheck: filterUsers?.RoleQualityCheck,
      RoleRegistration: filterUsers?.RoleRegistration,
      RoleEvaluation: filterUsers?.RoleEvaluation,
      RoleSpecialReports: filterUsers?.RoleSpecialReports,
      RoleMachineTemplates: filterUsers?.RoleMachineTemplates,
      RoleTestSets: filterUsers?.RoleTestSets,
      RoleImportHistory: filterUsers?.RoleImportHistory,
    });
    dispatchToggle({
      payload: {
        ...filterUsers,
        BlockFeedback: filterUsers?.IsBlockFeedback,
      },
      type: FormActions.LOAD_DATA,
    });
    setIsArchived(filterUsers?.IsArchived);
    let fluidType = [];
    (async function () {
      let responseLabData = await get(`${MASTER_DATA_APIS.GET_BY_TYPES}?types=FluidTypes`);
      if (responseLabData.FluidTypes) {
        responseLabData.FluidTypes.unshift({
          FluidTypeId: 0,
          FluidType: "All Types",
        });
        fluidType = (responseLabData.FluidTypes);
        setFluidTypes(fluidType);
      }
    })()
    fluidType?.filter(item => item.FluidType === "Oil")
    let arr1 = fluidType;
    let arr2 = filterUsers?.Fluids?.split(", ") || [];
    let res1 = arr1?.filter((page1) => arr2?.find(page2 => page1?.FluidType === page2))

    const res2 = res1?.map(item => {
      return {
        IsExisting: true,
        IsDefault: true,
        FluidTypeId: item?.FluidTypeId,
        FluidType: item?.FluidType,
      }
    })

    setCheckedItems(res2);
  }, [data?.UserId]);

  useEffect(() => {
    setUserList(usersData);

    if (editData !== null && editData !== undefined) {
      setEditFlag(true);
      if (editData?.Fluids !== null && editData?.Fluids !== undefined) {
      }
      setEditDataList(editData);
      setIsArchived(editData?.IsArchived || false);

      setRoleList({
        ...roleList,
        RoleLaboratory: editData.RoleLaboratory,
        RoleQualityCheck: editData.RoleQualityCheck,
        RoleRegistration: editData.RoleRegistration,
        RoleEvaluation: editData.RoleEvaluation,
        RoleSpecialReports: editData.RoleSpecialReports,
        RoleMachineTemplates: editData.RoleMachineTemplates,
        RoleTestSets: editData.RoleTestSets,
        RoleImportHistory: editData.RoleImportHistory,
      });

      editData?.RoleAdministration === true
        ? setRoleValue("RoleAdministration")
        : editData?.RoleTrainee === true
          ? setRoleValue("RoleTrainee")
          : editData?.RoleViewer === true
            ? setRoleValue("RoleViewer")
            : setRoleValue("combinationOf");
    }

    dispatch({
      payload: {
        ...editData,
      },
      type: FormActions.LOAD_DATA,
    });
    dispatchToggle({
      payload: {
        ...editData,
        BlockFeedback: editData?.IsBlockFeedback,
      },
      type: FormActions.LOAD_DATA,
    });
    setIsArchived(editData?.IsArchived);

  }, [usersData, editData]);

  useEffect(() => {
    editDataList?.RoleAdministration === true
      ? setRoleValue("RoleAdministration")
      : editDataList?.RoleTrainee === true
        ? setRoleValue("RoleTrainee")
        : editDataList?.RoleViewer === true
          ? setRoleValue("RoleViewer")
          : setRoleValue("combinationOf");
  }, [data?.UserId, editDataList]);

  return (
    <>
      <Grid container>
        <Grid
          item
          md={12}
        >
          <Typography color={infoMessage?.color || ""} >{infoMessage?.message || ""}</Typography>
        </Grid>
        {editFlag === true ? (
          <Grid
            item
            md={6}
            style={{ marginBottom: "10px", paddingRight: "10px" }}
          >
            <Form
              sections={GetFormSectionsDD(userList)}
              data={data}
              dispatch={dispatch}
              key={data?.UserId}
            />
          </Grid>
        ) : (
          <Grid
            item
            md={6}
            style={{ marginBottom: "10px", paddingRight: "10px" }}
          >
            <Form
              sections={GetTextSection()}
              data={data}
              dispatch={dispatch}
              sx={{ mt: -0.2 }}
            />
          </Grid>
        )}
        <Grid item md={6} style={{ marginTop: "20px", paddingLeft: "10px" }}>
          {editFlag !== true && (
            <Button
              variant="contained"
              startIcon={<Search />}
              onClick={searchUserByKeyword}
              sx={{ margin: "0 10px 15px 0" }}
              // disabled={!data?.emailId?.trim()}
              disabled={emailValidation}
            >
              Search
            </Button>
          )}

          <FormControlLabel
            control={
              <Checkbox
                name="isArchived"
                value="remember"
                checked={isArchived}
                onChange={() => setIsArchived(!isArchived)}
              />
            }
            label="Archived - user is disabled in KLEAR"
            sx={{ margin: "0 10px 10px 0" }}
          />
        </Grid>
        <Grid item xs={12}>
          <Form
            sections={GetFormSections()}
            data={dataToggle}
            dispatch={dispatchToggle}
            key={data?.UserId}
          />
        </Grid>
        <Grid item xs={12} style={{ marginTop: "25px" }}>
          <Grid container>
            <Grid item md={4} className="label-height">
              <Typography variant="subtitle2" component="subtitle2">
                Role
              </Typography>

              <FormControlLabel
                control={
                  <Radio
                    checked={roleValue === "RoleAdministration"}
                    onChange={handleChangeRadio}
                    value="RoleAdministration"
                    name={"RoleAdministration"}
                    size="small"
                  />
                }
                label={"Administrator + all functions"}
                sx={{ width: "100%" }}
              />
              <FormControlLabel
                control={
                  <Radio
                    checked={roleValue === "RoleViewer"}
                    onChange={handleChangeRadio}
                    value="RoleViewer"
                    name={"RoleViewer"}
                    size="small"
                  />
                }
                label={"Viewing Only"}
                sx={{ width: "100%" }}
              />
              <FormControlLabel
                control={
                  <Radio
                    checked={roleValue === "RoleTrainee"}
                    onChange={handleChangeRadio}
                    value="RoleTrainee"
                    name={"RoleTrainee"}
                    size="small"
                  />
                }
                label={"Trainee Evaluator"}
                sx={{ width: "100%" }}
              />
              <FormControlLabel
                control={
                  <Radio
                    checked={roleValue === "combinationOf"}
                    onChange={handleChangeRadio}
                    value="combinationOf"
                    name={"combinationOf"}
                    size="small"
                  />
                }
                label={"A combination of"}
                sx={{ width: "100%" }}
              />
              <div className="ml-24">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="RoleLaboratory"
                      disabled={roleValue !== "combinationOf"}
                      value={roleList?.RoleLaboratory}
                      checked={roleList?.RoleLaboratory == true ? true : false}
                      onChange={(e) => handleRoleChange(e)}
                    />
                  }
                  label="Laboratory"
                  sx={{ width: "100%" }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="RoleQualityCheck"
                      disabled={roleValue !== "combinationOf"}
                      value={roleList?.RoleQualityCheck}
                      checked={
                        roleList?.RoleQualityCheck == true ? true : false
                      }
                      onChange={(e) => handleRoleChange(e)}
                    />
                  }
                  label="Quality Check"
                  sx={{ width: "100%" }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="RoleRegistration"
                      disabled={roleValue !== "combinationOf"}
                      value={roleList?.RoleRegistration}
                      checked={
                        roleList?.RoleRegistration == true ? true : false
                      }
                      onChange={(e) => handleRoleChange(e)}
                    />
                  }
                  label="Registration"
                  sx={{ width: "100%" }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="RoleEvaluation"
                      disabled={roleValue !== "combinationOf"}
                      value={roleList?.RoleEvaluation}
                      checked={roleList?.RoleEvaluation == true ? true : false}
                      onChange={(e) => handleRoleChange(e)}
                    />
                  }
                  label="Evaluation"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="RoleSpecialReports"
                      disabled={roleValue !== "combinationOf"}
                      value={roleList?.RoleSpecialReports}
                      checked={
                        roleList?.RoleSpecialReports == true ? true : false
                      }
                      onChange={(e) => handleRoleChange(e)}
                    />
                  }
                  label="Special Reports"
                  sx={{ width: "100%" }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="RoleMachineTemplates"
                      disabled={roleValue !== "combinationOf"}
                      value={roleList?.RoleMachineTemplates}
                      checked={
                        roleList?.RoleMachineTemplates == true ? true : false
                      }
                      onChange={(e) => handleRoleChange(e)}
                    />
                  }
                  label="Machine Templates"
                  sx={{ width: "100%" }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="RoleTestSets"
                      disabled={roleValue !== "combinationOf"}
                      value={roleList?.RoleTestSets}
                      checked={roleList?.RoleTestSets == true ? true : false}
                      onChange={(e) => handleRoleChange(e)}
                    />
                  }
                  label="Test Sets and Test Items"
                  sx={{ width: "100%" }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="RoleImportHistory"
                      disabled={roleValue !== "combinationOf"}
                      value={roleList?.RoleImportHistory}
                      checked={
                        roleList?.RoleImportHistory == true ? true : false
                      }
                      onChange={(e) => handleRoleChange(e)}
                    />
                  }
                  label="Import History and Fleet"
                  sx={{ width: "100%" }}
                />
              </div>
            </Grid>

            <Grid item md={4} className="label-height">
              <Typography variant="subtitle2" component="subtitle2">
                Evaluation Fluid Types
              </Typography>

              {fluidTypes?.map((ft) => {
                return (
                  <Grid item xs={12} key={ft.FluidTypeId} sx={{ mt: 1 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={ft.FluidType}
                          checked={checkedItems?.filter(item => item?.FluidTypeId === ft.FluidTypeId)[0]?.IsDefault === true ? true : false}
                          onChange={(e) => handleFluidChange(ft.FluidTypeId, e.target.checked)}
                        />
                      }
                      label={ft.FluidType + (ft.IsNonFluid ? " ( Non-Fluid )" : "")}
                      sx={{
                        width: "55%",
                        backgroundColor: "transparent",
                      }}
                    />
                  </Grid>)
              })}

            </Grid>

            {(editFlag || newUserFlag) && (
              <Grid item md={4} style={{ marginTop: "5px" }}>
                <SectionTitle title="User Details" />
                <Box
                  sx={{ p: 1, backgroundColor: "#bbdefb", paddingTop: "5px" }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{ marginLeft: "5px", marginTop: "5px" }}
                  >
                    Name: <b>{editDataList?.FullName || newUserData?.DisplayName}</b>
                  </Typography>
                  {editDataList?.Username && <Typography
                    variant="subtitle2"
                    sx={{ marginLeft: "5px", marginTop: "5px" }}
                  >
                    Username: <b>{editDataList?.Username}</b>
                  </Typography>}
                  {editDataList?.LastLogon && (
                    <Typography
                      variant="subtitle2"
                      sx={{ marginLeft: "5px", marginTop: "5px" }}
                    >
                      Last Logon:{" "}
                      <b>{toLocalDateTime(editDataList?.LastLogon)}</b>
                    </Typography>
                  )}
                  {newUserData?.Mail && (
                    <Typography
                      variant="subtitle2"
                      sx={{ marginLeft: "5px", marginTop: "5px" }}
                    >
                      Email:{" "}
                      <b>{newUserData?.Mail}</b>
                    </Typography>
                  )}
                  {newUserData?.JobTitle && (
                    <Typography
                      variant="subtitle2"
                      sx={{ marginLeft: "5px", marginTop: "5px" }}
                    >
                      Manager:{" "}
                      <b>{newUserData?.JobTitle}</b>
                    </Typography>
                  )}
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs={8}
          style={{
            float: "right",
            position: "absolute",
            bottom: "15px",
            right: "15px",
            height: "47px",
          }}
        >
          <FormFooter
            buttons={GetFormButtons(data, newUserData, editDataList, actions)}
            hideDivider
            gmt={0}
          />
        </Grid>
      </Grid>

      <AppDialog
        open={openAddAlert}
        title={"Add User"}
        maxWidth="md"
        handleClose={() => {
          setOpenAlert(false);
        }}
      >
        <ConfirmModal
          handleClose={() => {
            setOpenAlert(false);
          }}
          alertAction={saveNewUser}
          message={`Are you sure you wish to add  ${newUserData?.DisplayName || "user"} to KOWA?`}
          buttonText={"Add"}
          buttonText2={"Cancel"} />
      </AppDialog>

      <AppDialog
        open={openAlert}
        title={"Delete User"}
        maxWidth="md"
        handleClose={() => {
          setOpenAlert(false);
        }}
      >
        <ConfirmModal
          handleClose={() => {
            setOpenAlert(false);
          }}
          alertAction={handleDeleteUser}
          message={`Are you sure you wish to Delete ${editDataList?.FullName || "user"}`}
          buttonText={"Delete"}
          buttonText2={"Cancel"} />
      </AppDialog>
    </>
  );
}

const GetFormButtons = (data, newUserData, editDataList, { deleteUser, match, add }) => [
  {
    variant: "outlined",
    label: "Delete",
    type: "submit",
    onClick: deleteUser,
    disabled: newUserData?.length < 1 && editDataList?.length < 1,
    startIcon: <DeleteOutlineIcon />,
  },
  {
    variant: "contained",
    label: "Save",
    type: "submit",
    onClick: add,
    disabled: newUserData?.length < 1 && editDataList?.length < 1,
    startIcon: <Save />,
  },
];

function GetFormSectionsDD(data) {
  return [
    {
      xsCol: 12,
      fields: [
        {
          xsCol: 12,
          name: "UserId",
          label: "Users",
          type: "dropdown",
          selectOptions: (data || [])?.map((x) => ({
            value: x.UserId,
            text: x.Username,
          })),
        },
      ],
    },
  ];
}

function GetTextSection(data) {
  return [
    {
      xsCol: 12,
      fields: [
        {
          xsCol: 12,
          name: "emailId",
          label: "Search by email",
          type: "text",
        },
      ],
    },
  ];
}

function NewToggleReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === FormActions.LOAD_DATA) {
    return { ...action.payload };
  }
  return state;
}

function NewUserReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === FormActions.LOAD_DATA) {
    return { ...action.payload };
  }
  return state;
}
