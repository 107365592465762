import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabEventChart from "./TabEventChart";
import TabTrayChart from "./TabTrayChart";
import ImagePick from "../../components/SelectImage";
import { useState } from "react";
import { useEffect } from "react";

export default function Chart() {

  const [sampleId, setSampleId] = useState(0);
  const [eqpMachineId, setEqpMachineId] = useState(0);
  const [hideProp, setHideProp] = useState("");
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function eventCb(event) {
    if (event.storageArea != localStorage) return;
    const chartsData = JSON.parse(localStorage.getItem("chartsData"));
    document.title = `Charts and Event: ${chartsData?.sampleId || ""}`
    setHideProp(chartsData?.hideProp)
    setSampleId(chartsData?.sampleId)
    setEqpMachineId(chartsData?.EqpMachineId)

    document.title = `Charts and Event: ${chartsData?.sampleId || ""}`
    if (event.key === "isCloseWindow") {
      const checkWindow = JSON.parse(localStorage.getItem("isCloseWindow"));
      if (checkWindow == null || checkWindow == true) {
        window.close()
      }
    }
  }

  const handleCloseButton = () => {
    localStorage.setItem("isCloseWindow", true);
  }

  useEffect(function () {
    window.addEventListener("storage", eventCb);
    window.addEventListener("beforeunload", handleCloseButton);
    return function () {
      window.removeEventListener("storage", eventCb);
      window.removeEventListener("beforeunload", handleCloseButton);
    };
  });

  useEffect(() => {
    const chartsData = JSON.parse(localStorage.getItem("chartsData"));
    document.title = `Charts and Event: ${chartsData?.sampleId || ""}`
    setHideProp(chartsData?.hideProp)
    setSampleId(chartsData?.sampleId)
    setEqpMachineId(chartsData?.EqpMachineId)

    document.title = `Charts and Event: ${chartsData?.sampleId || ""}`
  }, [])

  return (
    <Box sx={{ width: "100%", typography: "body1", mt: -6 }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Events + Chart" value="1" />
            <Tab label="Sample Images" value="2" />
            {hideProp !== 1 && <Tab label="Tray Chart" value="3" />}
          </TabList>
        </Box>
        <TabPanel value="1">
          <TabEventChart sampleId={sampleId} eqpMachine={eqpMachineId} />
        </TabPanel>
        <TabPanel value="2">
          <ImagePick sampleId={sampleId} />
        </TabPanel>

        {hideProp !== 1 && (
          <TabPanel value="3">
            <TabTrayChart sampleId={sampleId} />
          </TabPanel>
        )}
      </TabContext>
    </Box>
  );
}
