import { Card } from '@mui/material';
import Form from '../../../components/Form'

function Comments({ comments, dispatch }) {
    return (
        <Card sx={{ p: 0.5 }}>
            <Form
                sections={GetFormSections()}
                data={comments}
                spacing={1}
                dispatch={dispatch}
                key={comments?._ts_key}
            />
        </Card>)
}

export default Comments;

function GetFormSections() {
    return [
        {
            fields: [
                {
                    name: "ComponentNotes",
                    label: "Component Comments: internal only",
                    xsCol: 3,
                    alignSelf: "flex-end",
                    type: "textarea",
                    rows: 3,
                },
                {
                    name: "UnitNotes",
                    label: "Unit Comments: internal only",
                    xsCol: 3,
                    type: "textarea",
                    rows: 3,
                },
                {
                    name: "SiteNotes",
                    label: "Site Comments: internal only",
                    xsCol: 3,
                    type: "textarea",
                    rows: 3,
                },
                {
                    name: "CustomerNotes",
                    label: "Customer Comments: internal only",
                    xsCol: 3,
                    type: "textarea",
                    rows: 3,
                },

            ],
        },
    ];
}
