import { Grid, Typography, } from "@mui/material";
import TextBox from "../../../components/TextBox";
import useFetch from "../../../hooks/useFetch";
import ComponentModelsTable from "../components-model/ComponentModelsTable";
import { FLEET_APIS } from "../../../common/apis";
import EditComponentModel from "../../../forms/EditComponentModel";
import { useEffect, useMemo, useState } from "react";

export default function ComponentModels() {
  const { get } = useFetch();

  const [unitsData, setUnitsData] = useState([]);
  const [selectedComponent, setSelectedComponent] = useState({});
  const [filter, setFilter] = useState({});

  async function fetchComponentModels() {
    const temp = await get(FLEET_APIS.COMPONENT_MODELS);
    setUnitsData(temp || []);
  }

  function handelFilterChange(e) {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  }

  function unitRowSelectedHandler(row) {
    setSelectedComponent(row);
  }

  const filteredData = useMemo(
    function () {
      return unitsData.filter(
        (x) =>
          !filter.startsWith ||
          x.ComponentMakeFormatted?.toUpperCase().startsWith(
            filter.startsWith.toUpperCase()
          )
      );
    },
    [unitsData, filter]
  );

  useEffect(function () {
    document.title = "Component Models";
    fetchComponentModels();
  }, []);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item container xs={12} spacing={1} alignItems="flex-end">
          <Grid item>
            <TextBox
              name="startsWith"
              label="Starts with"
              //value={filter.startsWith}
              onChange={handelFilterChange}
            />
          </Grid>

        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle2">
            Total Records: <b> {filteredData?.length}</b>
          </Typography>
          <ComponentModelsTable
            data={filteredData}
            rowSelected={unitRowSelectedHandler}
            selectedRow={
              selectedComponent?.ComponentMakeId +
              selectedComponent?.ComponentModel
            }
          />
        </Grid>
        <Grid item xs={6} mt={2}>
          <EditComponentModel
            componentModel={selectedComponent}
            key={selectedComponent?.ComponentMakeId}
            fetchComponentModels={fetchComponentModels}
          />
        </Grid>
      </Grid>
    </>
  );
}
