import { Button, Card, Grid, Typography } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useReducer, useState } from "react";
import DataTable, { DataGridAction } from "../../components/DataTable";
import Form, { FormActions } from "../../components/Form";
import useFetch from "../../hooks/useFetch";
import { MANAGEMENT_APIS } from "../../common/apis";
import { Delete } from "@mui/icons-material";
import AppDialog from "../../components/AppDialog";
import { useToast } from "../../rootContext";
import appResources from "../../app-resources/en/Resources.json";
import renderCellExpand from "./../../components/DatatableCellExpand";
import ConfirmModal from "../../components/ConfirmModal";

export default function MachineNotifications() {
  const [data, dispatch] = useReducer(
    SearchFilterReducer,
    SearchFilterDefaults
  );
  const { get } = useFetch();
  const [dialog, setDialog] = useState({
    open: false,
    message: "",
  });
  const [notificationsData, setNotificationsData] = useState([]);
  const { showToast } = useToast();

  useEffect(() => {
    document.title = "Machine Notifications";
    getNotificationsData();
  }, []);

  async function getNotificationsData() {
    let responseData = await get(MANAGEMENT_APIS.MACHINE_NOTIFICATIONS);
    if (responseData) {
      setNotificationsData(responseData);
    } else {
      setNotificationsData([]);
    }
  }
  //use memo
  const filteredData = useMemo(
    () =>
      data?.Category === 0
        ? notificationsData
        : (notificationsData || []).filter((x) => {
          return x.Flag === data?.Category;
        }),
    [data, notificationsData]
  );
  const tblOptions = {
    columns: GetColumns(deleteClick),
    dataSource: (filteredData || []).map((x) => ({
      ...x,
      keyProp: x.CustomerId + "" + (x.Flag || "") + "" + (x.Customer || ""),
    })),
    rowIdProperty: "keyProp",

    isDisableColumnFilter: true,
    cellStylingHandler: "laboratories",
    sx: { height: 680 },
  };
  async function deleteClick(row) {
    const response = await get(
      `${MANAGEMENT_APIS.DELETE_MACHINE_NOTIFICATIONS}?${row.CustomerId && row.Flag === 1 ? `CustomerId=${row.CustomerId}&` : ""
      }${row.SiteId && row.Flag === 2 ? `SiteId=${row.SiteId}&` : ""}${row.UnitId && row.Flag === 3 ? `UnitId=${row.UnitId}&` : ""
      }${row.ComponentId && row.Flag === 4
        ? `ComponentId=${row.ComponentId}&`
        : ""
      }Screen="Machine Notifications"`
    );
    if (response > 0) {
      showToast(appResources.MACHINE_NOTIFICATION_DELETED_SUCCESSFULLY_MSG);
      getNotificationsData();
    }
  }
  function deleteAllClick() {
    setDialog({
      open: true,
      message: "Do you wish to delete ALL of your machine notifications?",
    });
  }
  async function deleteAllClickAlert() {
    const response = await get(
      `${MANAGEMENT_APIS.DELETE_ALL_MACHINE_NOTIFICATIONS}?Screen="Machine Notifications"`
    );
    setDialog((prevState) => ({ ...prevState, open: false }));
    if (response > 0) {
      showToast(appResources.ALL_MACHINE_NOTIFICATION_DELETED_SUCCESSFULLY_MSG);
      getNotificationsData();
    }
  }

  return (
    <>
      <Card sx={{ p: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Form
              sections={GetFormSections()}
              data={data}
              dispatch={dispatch}
            />
          </Grid>
          <Grid item container xs={4} justifyContent="flex-end">
            <Button
              onClick={deleteAllClick}
              sx={{ marginTop: 3 }}
              startIcon={<Delete />}
              disabled={filteredData.length === 0}
            >
              Delete All
            </Button>
          </Grid>
        </Grid>
      </Card>

      <Grid item xs={12} sx={{ mt: 2 }}>
        <Typography
          sx={{
            marginLeft: "5px",
            color: filteredData?.length > 0 ? "" : "red",
          }}
        >
          {filteredData.length > 0 && (
            <b> Total Records: {filteredData?.length}</b>
          )}
          {"  "}
          {(data.Category === 0 || data.Category === 0) &&
            filteredData.length === 0 &&
            appResources.NO_MACHINE_NOTIFICATION}
          {data.Category === 1 &&
            filteredData.length === 0 &&
            appResources.NO_CUSTOMER_NOTIFICATION}
          {data.Category === 2 &&
            filteredData.length === 0 &&
            appResources.NO_SITES_NOTIFICATION}
          {data.Category === 3 &&
            filteredData.length === 0 &&
            appResources.NO_UNITS_NOTIFICATION}
          {data.Category === 4 &&
            filteredData.length === 0 &&
            appResources.NO_COMPONENTS_NOTIFICATION}
        </Typography>

        <DataTable {...tblOptions} />
      </Grid>

      <AppDialog
        open={dialog.open}
        handleClose={() =>
          setDialog((prevState) => ({ ...prevState, open: false }))
        }
        title="Delete All Machine Notifications"
        maxWidth="md"
      >
        <ConfirmModal
          handleClose={() => {
            setDialog((prevState) => ({
              ...prevState,
              open: false,
            }));
          }} alertAction={deleteAllClickAlert}
          message={dialog.message}
          buttonText={"Delete"}
          buttonText2={"Cancel"} />
      </AppDialog>
    </>
  );
}

function GetColumns(deleteClick) {
  return [
    {
      headerName: "Delete",
      field: "Delete",
      sortable: false,
      minWidth: 120,
      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction row={row} onClick={deleteClick} label="Delete" />
          </>
        );
      },
    },
    {
      headerName: "Product",
      field: "Product",
      minWidth: 170,
    },
    {
      headerName: "Customer",
      field: "Customer",
      minWidth: 170,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Site",
      field: "Site",
      minWidth: 170,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Unit",
      field: "Unit",
      minWidth: 170,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Serial",
      field: "Serial",
      minWidth: 170,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Component",
      field: "Component",
      minWidth: 170,
      flex: 1,
      renderCell: renderCellExpand,
    },
  ];
}

function GetFormSections() {
  return [
    {
      fields: [
        {
          xsCol: 10,
          group: [
            {
              name: "Category",
              label: "Category",
              type: "dropdown",
              xsCol: 4,
              required: true,
              selectOptions: getOptions(),
            },
          ],
        },
      ],
    },
  ];
}

function getOptions() {
  return [
    { Id: 0, value: 0, text: "All" },
    { Id: 1, value: 1, text: "Customer" },
    { Id: 2, value: 2, text: "Site" },
    { Id: 3, value: 3, text: "Unit" },
    { Id: 4, value: 4, text: "Component" },
  ];
}

function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  return state;
}

const SearchFilterDefaults = {
  Category: 0,
};
