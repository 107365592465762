import { Button, Card, Grid, Typography } from "@mui/material";
import { useReducer, useState } from "react";
import DataTable, { DataGridAction } from "../../../components/DataTable";
import Form, { FormActions } from "../../../components/Form";
import { useRoot } from "../../../rootContext";
import { useEffect } from "react";
import useFetch from "../../../hooks/useFetch";
import { REPORT_APIS } from "../../../common/apis";
import { toLocalDate, } from "../../../utils";
import ConfirmModal from "../../../components/ConfirmModal";
import AppDialog from "../../../components/AppDialog";

export default function ReportToMail() {

  const { get, getFile } = useFetch();
  const { CanDoDataMaintenance, CanEvaluate } = useRoot();
  const [data, dispatch] = useReducer(SearchFilterReducer, SearchFilterDefaults);

  const [reportToMailData, setReportToMailData] = useState([]);
  // const [infoMessage,] = useState("There is 1 report for 1 contact. first PRINT the Label and then PROCESS the report");
  const [infoMessage,] = useState("");
  const [dialog, setDialog] = useState({
    title: "",
    message: "",
    actionName: "",
    open: false,
    width: "",
  });

  const formActions = {
    printLabelClick: async function () {
      getFile(`${REPORT_APIS.PRINT_LABELS}?offset=${data?.LabelOffset}`, `Mail Address Labels `);
    },
    openAllClick: async function () {
      if (reportToMailData.length > 0) {
        reportToMailData.map(item => {
          getFile(`${REPORT_APIS.VIEW_REPORT_MAIL}?sampleNumber=${item?.SampleNumber}`, `${item?.Site} ${item?.Unit} ${item?.Component} ${item?.SampleNumber} `);
        })
      }
    },
    viewClick: async function (row) {
      getFile(`${REPORT_APIS.VIEW_REPORT_MAIL}?sampleNumber=${row?.SampleNumber}`, `${row?.Site} ${row?.Unit} ${row?.Component} ${row?.SampleNumber} `);
    },
    deleteClick: async function (row) {
      setDialog({
        title: "Delete Mail Record",
        message: "Are you you sure you wish to delete this record?",
        actionName: "deleteClickAlert",
        open: true,
        width: "md",
        func: function (row) {
          get(`${REPORT_APIS.DELETE_ALL_REPORTS}?ExportMailId=${[row.ExportMailId]}`);
        },
      });
    },
    deleteAllClick: async function () {
      setDialog({
        title: "Delete Mail Record",
        message: "Are you you sure you wish to delete all records?",
        actionName: "deleteClickAlert",
        open: true,
        width: "md",
        func: function (row) {
          let delList = [];
          if (reportToMailData.length > 0) {
            reportToMailData.map(item => { delList.push(item.ExportMailId) })
          }
          get(`${REPORT_APIS.DELETE_ALL_REPORTS}?ExportMailId=${delList}`);
        },
      });
    },

  };

  const tblOptions = {
    columns: GetColumns(formActions),
    dataSource: (reportToMailData || []).map((x, i) => ({
      ...x, keyProp: i
    })),
    cellStylingHandler: "productCommonCellColour",
    rowIdProperty: "keyProp",
    isDisableColumnFilter: true,
    sx: { height: 700 },
  };

  useEffect(() => {
    (async function () {
      const reportsResp = await get(`${REPORT_APIS.REPORT_MAIL}`)
      if (reportsResp?.length > 0) { setReportToMailData(reportsResp) }
    })()
  }, [])

  return (
    <>
      <Grid container>
        <Grid item xs={12} sx>
          <Card sx={{ p: 1 }}>
            <Form
              sections={GetFormSections(infoMessage, CanDoDataMaintenance, CanEvaluate, formActions)}
              data={data}
              dispatch={dispatch}
              key={data?.key}
            />
          </Card>
        </Grid>

        <Grid item xs={12} sx={{ mt: 2 }}>
          <Typography variant="subtitle2">
            Total Records: <b> {reportToMailData && reportToMailData.length}</b>
          </Typography>
          <DataTable {...tblOptions} />
        </Grid>
      </Grid>
      <AppDialog
        open={dialog.open}
        handleClose={() =>
          setDialog((prevState) => ({
            ...prevState,
            open: false,
          }))
        }
        title={dialog.title}
        maxWidth={dialog.width}
      >
        <ConfirmModal
          handleClose={() => {
            setDialog({ open: false, })
          }}
          alertAction={dialog.func}
          message={dialog.message}
          buttonText={"Delete"}
          buttonText2={"Cancel"}
        />
      </AppDialog>
    </>
  );
}

function GetColumns(formActions) {
  return [
    {
      headerName: "View",
      field: "View",
      sortable: false,
      width: 80,
      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction row={row} onClick={formActions?.viewClick} label="View" />
          </>
        );
      },
    },
    {
      headerName: "Username",
      field: "Username",
      width: 150,
    },
    {
      headerName: "Contact",
      field: "DisplayName",
      width: 150,
    },
    {
      headerName: "Sample",
      field: "SampleNumber",
      width: 110,
    },
    {
      headerName: "Added",
      field: "Added",
      width: 100,
      valueFormatter: ({ value }) => toLocalDate(value),
    },
    {
      headerName: "Product",
      field: "Product",
      width: 120,
    },
    {
      headerName: "Customer",
      field: "Customer",
      width: 150,
    },
    {
      headerName: "Site",
      field: "Site",
      width: 150,
    },
    {
      headerName: "Unit",
      field: "Unit",
      width: 150,
    },
    {
      headerName: "Component",
      field: "Component",
      width: 150,
    },
    {
      headerName: "Address",
      field: "AddressFormatted",
      flex: 1,
    },
    {
      headerName: "Delete",
      field: "del",
      sortable: false,
      width: 80,
      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction row={row} onClick={formActions?.deleteClick} label="Delete" />
          </>
        );
      },
    },
  ];
}

function GetFormSections(infoMessage, CanDoDataMaintenance, CanEvaluate, formActions) {
  return [
    {
      fields: [
        {
          component: function () {
            return (
              <Button
                variant="contained"
                onClick={formActions.printLabelClick}
                sx={{ mt: "12px" }}
              >
                Print Labels
              </Button>
            );
          },
        },
        {
          name: "LabelOffset",
          label: "Label Offset",
          type: "number",
          xsCol: 1,
          min: 0,
          max: 20,
        },
        {
          component: function () {
            return (
              <Button
                variant="outlined"
                onClick={formActions.openAllClick}
                sx={{ mt: "12px" }}
              >
                Open All Reports
              </Button>
            );
          },
        },
        {
          flex: 2,
          alignSelf: "center",
          component: function () {
            return <Typography sx={{ mt: 2 }}>{infoMessage}</Typography>;
          },
        },
        (CanDoDataMaintenance || CanEvaluate) && {
          alignSelf: "center",
          component: function () {
            return <Button
              variant="outlined"
              sx={{ mt: "12px" }}
              onClick={formActions.deleteAllClick}
            >
              Delete All Reports
            </Button>
          },
        },
      ],
    },
  ];
}

function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  return state;
}
const SearchFilterDefaults = {
  LabelOffset: 0
};
