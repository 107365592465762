import { Search } from "@mui/icons-material";
import { Card, Typography, Grid } from "@mui/material";
import { useMemo, useState, useReducer, useEffect, useCallback } from "react";
import AppDialog from "../../../components/AppDialog";
import { MASTER_DATA_APIS, TRAY_APIS } from "../../../common/apis";
import Form, { FormActions } from "../../../components/Form";
import FormFooter from "../../../components/FormFooter";
import useFetch from "../../../hooks/useFetch";
import RegistrationsTable from "./RegistrationsTable";
import EditRegistration from "./ER/EditRegistration";
import { ClearIcon } from "../../../icons/ButtonIcons";
import { useRoot } from "../../../rootContext";
import appResources from "../../../app-resources/en/Resources.json";
import { useWindowPopups } from "../../../components/useWindowPopups";

export default function SearchRegistration({
  actions,
  getRegistration,
  searchRegClickHandler,
  screen,
}) {
  const { get, post } = useFetch();
  const { CanRegister } = useRoot();

  const [data, setData] = useState([]);
  const [editRegId, setEditRegId] = useState(0);
  const [regData, setRegData] = useState({});
  const [filterData, dispatch] = useReducer(RegistrationFilterReducer, RegistrationFilterDefaults);
  const [allProducts, setAllProducts] = useState([]);

  const formActions = {
    search: async function () {
      const temp = await post(TRAY_APIS.FILTER_REGISTRATIONS, filterData);
      setData(temp);
    },
    clear: function (e) {
      dispatch({ type: FormActions.RESET_FORM });
    },
  };

  const getAllData = async (regId) => {
    const resp = await get(`/trays/registrations?regId=${regId}`, { skipBlocker: true, });
    return resp;
  }

  const tableActions = useMemo(
    () => [
      {
        action: async function (row) {
          const resp = await getAllData(row.SIF);
          if (Object.entries(resp)?.length > 0) {
            setRegData(resp)
            if (resp?.Registration?.RequiresAttention === false) {
              localStorage.setItem("edit-registration-resp", JSON.stringify({ regNo: row.SIF }));
              window.dispatchEvent(new Event('storage'))
              window.close();
            }
            else {
              HandleNewWindow("edit-registration", row.SIF)

            }
          }
          else {
            HandleNewWindow("edit-registration", row.SIF)
          }
        },
        label: "Select",
      },
      {
        action: async function (row) {
          HandleNewWindow("edit-registration", row.SIF)
        },
        label: "View/ Edit",
        sx: { marginLeft: "5px" }
      },
      ...(actions || []),
    ],
    []
  );

  const HandleNewWindow = (actionName, data) => {
    useWindowPopups(actionName, data)
  }

  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === "Enter" && CanRegister === true) {
        event.preventDefault();
        formActions.search();
      }
    },
    [filterData]
  );

  useEffect(() => {
    document.title = "Registration Search";

    document.addEventListener("keyup", handleKeyPress);
    return () => {
      document.removeEventListener("keyup", handleKeyPress);
    };
  }, [handleKeyPress]);

  useEffect(() => {
    (async function () {
      const response = await get(`${MASTER_DATA_APIS.GET_BY_TYPES}?types=Products`);
      //Products with AllProducts added at 0 index
      const allProd = JSON.parse(JSON.stringify(response.Products));
      if (allProd) {
        allProd.unshift({
          ProductId: 0,
          Product: "All Products",
          Colour: "",
        });
      }
      setAllProducts(allProd || {});
    })();
  }, [])

  return (
    <Grid container spacing={1}  >
      <Grid item>
        <Card sx={{ p: 1, width: "97vw" }}  >
          <Form
            sections={GetFormSections(allProducts, filterData)}
            data={filterData}
            spacing={2}
            dispatch={dispatch}
            key={filterData._ts_key || ""}
          />
          <FormFooter
            buttons={GetFormButtons(filterData, formActions, CanRegister)}
            gmt={2}
          />
          {data?.length < 1 && (
            <Typography sx={{ color: "red", mt: -2.5 }}>
              {appResources.NO_REGISTRATION_FOUND}
            </Typography>
          )}
        </Card>
      </Grid>
      <Grid item sx={{ p: 1, width: "99dvw" }} >
        <Typography  >
          Total Records: <b> {data?.length}</b>
        </Typography>
        <RegistrationsTable
          screen={screen}
          data={data}
          actions={tableActions}
        />
      </Grid>
      <AppDialog
        open={!!editRegId}
        handleClose={() => setEditRegId(0)}
        title="Edit Registration"
        maxWidth="100vw"
      >
        <EditRegistration
          resp={regData}
          regId={editRegId}
          setEditRegId={setEditRegId}
          getRegistration={getRegistration}
          searchRegClickHandler={searchRegClickHandler}
        />
      </AppDialog>
    </Grid>
  );
}

const GetFormButtons = (data, { search, clear }, CanRegister) => [
  {
    variant: "outlined",
    disabled:
      (data?.Customer === undefined || data?.Customer === "") &&
      (data?.Site === undefined || data?.Site === "") &&
      (data?.Unit === undefined || data?.Unit === "") &&
      (data?.Serial === undefined || data?.Serial === "") &&
      (data?.Component === undefined || data?.Component === "") &&
      (data?.SIF === undefined || data?.SIF === ""),
    label: "Clear",
    onClick: clear,
    startIcon: <ClearIcon />,
  },
  {
    hidden: !CanRegister,
    variant: "contained",
    label: "Search",
    onClick: search,
    startIcon: <Search />,
  },
];

function GetFormSections(allProducts, filterData) {
  return [
    {
      fields: [
        {
          name: "ProductId",
          label: "Product",
          xsCol: 2,
          type: "dropdown",
          selectOptions: (allProducts || []).map((x) => ({
            value: x.ProductId,
            text: x.Product,
          })),
          sx: {
            [`.MuiSelect-select`]: {
              backgroundColor:
                allProducts?.find((x) => x.ProductId === (filterData?.ProductId))?.Colour || "",
              color: filterData.ProductId === 0 ? "" : "white",
              [`.MuiSvgIcon-root-MuiSelect-icon`]: {
                color: filterData.ProductId === 0 ? "" : "white",
              },
            },
            [`.MuiSelect-icon`]: {
              color: filterData.ProductId === 0 ? "" : "white",
            },
          },
        },
        {
          name: "Customer",
          label: "Customer",
          xsCol: 2,
        },
        {
          name: "Site",
          label: "Site",
          xsCol: 2,
        },
        {
          name: "Unit",
          label: "Unit",
          xsCol: 2,
        },
        {
          name: "Serial",
          label: "Serial",
          xsCol: 2,
        },
        {
          name: "Component",
          label: "Component",
          xsCol: 2,
        },
        {
          name: "SIF",
          label: "Registration",
          xsCol: 2,
        },
        {
          name: "IncludeRegistered",
          label: "Include Registered or Deleted",
          type: "switch",
        },
        {
          name: "UseStartsWith",
          label: "Starts With Search",
          type: "switch",
        },
      ],
    },
  ];
}

function RegistrationFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === FormActions.RESET_FORM) {
    return { ...RegistrationFilterDefaults, _ts_key: Date.now() };
  }
  return state;
}

const RegistrationFilterDefaults = {
  IncludeRegistered: false,
  UseStartsWith: false,
  ProductId: 0,
};
