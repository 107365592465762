import { Box, ClickAwayListener, Typography } from "@mui/material";
import TreeItem from "../../../components/tree/TreeItem";
import getNodeId, { parseNodeId } from "./nodeId";
import TreeMenu from "../TreeMenu";
import { useState, useEffect } from "react";
import productColors from "../../../common/ProductColors";

export default function Customer(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPop, setOpenPop] = useState(false);

  const mouseDownHandler = (event) => {
    if (event.button === 2) {
      props.handleLabelClick && props.handleLabelClick(event, nodeId)
      setAnchorEl(event.currentTarget);
      setOpenPop(true);
    }
  };

  const handleSelect = (name) => {
    props.handleRightSelect(name, props);
    setOpenPop(false)
    setAnchorEl(null)
  };

  const nodeId = getNodeId(props.pathItems);
  const expanded = props.expandedNode.includes(nodeId);

  const [productColor, setProductColor] = useState({})

  useEffect(() => {
    let color2 = productColors(props?.ProductId);
    setProductColor(color2);
  }, []);
  let node123 = parseNodeId(props?.selectedNode)

  return (
    <>
      <TreeItem
        selectedNode={(Object.entries(node123)?.length <= 4 && node123.customer == props.CustomerId) ? props?.selectedNode : ""}
        nodeId={nodeId}
        label={
          <Box sx={(Object.entries(node123)?.length <= 4 && node123.customer == props.CustomerId) ? { ...productColor }
            : props.IsArchived === false ? { color: "#BF40BF" } : { color: "gray" }}
            onClick={(e) => props.handleLabelClick(e, nodeId)}
          >
            <Typography>
              {props.Customer + " [" + props.SiteCount + "]" + (props.IsArchived ? " Archived" : "")}
            </Typography>
          </Box>
        }
        onClick={() => { return; }}
        customProps={{ hasChildren: !!props.SiteCount, expanded }}
        onMouseDown={(e) => mouseDownHandler(e)}
      >
        {props.children}
      </TreeItem>

      {openPop && <ClickAwayListener onClickAway={() => setOpenPop(false)}>
        <TreeMenu options={Options} anchorEl={anchorEl} openPop={openPop} setOpenPop={setOpenPop} handleClick={handleSelect} />
      </ClickAwayListener>}
    </>
  );
}

const Options = [
  {
    label: "Copy or Add to Selected",
    name: "null",
    items: [
      { label: "Add Sample to Email Selected Items", name: "email-selected-items" },
      { label: "Copy Sample Number", name: "copy-sample-number" },
    ],
  },
  {
    label: "Tree",
    name: "null",
    items: [
      // { label: "Lift to Top", name: "lift-to-top" },
      // { label: "Go to Parent", name: "go-to-parent" },
      // { label: "Collapse Tree", name: "collapse-tree" },
      { label: "Refresh", name: "refresh-tree" },
    ],
  },
  {
    label: "Sample Report",
    name: "sample-report",
  },
  {
    label: "Additional Reports",
    name: "null",
    items: [
      { label: "Reports by Date", name: "reports-by-date" },
      { label: "Full History as XLSX", name: "history-as-xlsx" },
      { label: "Full History as XML", name: "history-as-xml" },
      { label: "Components No Recent Samples", name: "components-no-recent-samples" },
      { label: "Units No Recent Samples", name: "units-no-recent-samples" },
      { label: "Reference XSD", name: "reference-xsd" },
    ],
  },
  { label: "View in Quick Details", name: "view-in-quick-details" },
  {
    label: "Edit",
    name: "null",
    items: [
      { label: "Customer", name: "edit-customer" },
    ],
  },
  {
    label: "New",
    name: "null",
    items: [
      { label: "Site", name: "add-new-site" },
      { label: "Customer", name: "add-new-customer" },
    ],
  },
  {
    label: "Manage",
    name: "null",
    items: [
      { label: "Customer", name: "manage-customer" },
    ],
  },
  {
    label: "Contacts",
    name: "null",
    items: [
      { label: "Access Permission", name: "access-permission-overview" },
      { label: "Sample Reporting", name: "report-contacts" },
      { label: "Primary Contacts", name: "primary-contacts-overview" },
      { label: "Subscriptions", name: "subscription-overview" },
      { label: "Exports", name: "export-contacts-overview" },
    ],
  },
];
