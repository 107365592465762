import { Card, Grid, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { MASTER_DATA_APIS, TEST_SETS_APIS } from "../../../common/apis";
import SwitchInput from "../../../components/SwitchInput";
import useFetch from "../../../hooks/useFetch";
import { useToast } from "../../../rootContext";
import TestItemsTable from "./TestItemsTable";
import TestSetForm from "./TestSetForm";
import TestSetsTable from "./TestSetsTable";
import AppDialog from "../../../components/AppDialog";
import appResources from "../../../app-resources/en/Resources.json";
import ConfirmModal from "../../../components/ConfirmModal";

export default function TestSets() {
  const { showToast } = useToast();
  const { get, post } = useFetch();

  const [testSets, setTestSets] = useState([]);
  const [showArchived, setShowArchived] = useState(false);
  const [selectedTestSet, setSelectedTestSet] = useState([]);
  const [copied, setCopied] = useState();
  const [tempData, setTempData] = useState();
  const [dialog, setDialog] = useState({ open: false, message: "", });
  const [masterData, setMasterData] = useState({});
  const [selectedEmailUsers, setSelectedEmailUsers] = useState([]);
  const [testSetItems, setTestSetItems] = useState([]);

  async function refreshTestSets(i) {
    const testSetsResp = await get("/testSets");
    setTestSets(testSetsResp || []);
    if (i > 0) {
      const res = testSetsResp?.find(item => item.TestSetId === i);
      setSelectedTestSet(res);
      getTestSetsDetails(res);
    }
    else {
      getTestSetsDetails(testSetsResp[0]);
      setSelectedTestSet({});
    }
  }

  async function handelRowSelected(row) {
    getTestSetsDetails(row);
  }

  async function getTestSetsDetails(row) {
    if (row) {
      const resp = await get(`${TEST_SETS_APIS.DETAILS}?testSetId=${row.TestSetId}`);
      if (resp) {
        resp.testSet = row;
        setSelectedTestSet(resp || {});
        setTestSetItems(resp?.TestSetItems || []);

        if (resp?.Users?.length > 0) {
          setSelectedEmailUsers(resp?.Users)
        } else {
          setSelectedEmailUsers([]);
        }
        return;
      }
    }
    setSelectedTestSet({});
  }

  async function handelSaveTestSet(data, copy) {
    if (copy) {
      setDialog({
        open: true,
        message: "Are you sure you want to copy this test set?",
      });
      setCopied(copy);
      setTempData(data);
      return;
    }
    await saveTestSet(data);
    // showToast(appResources.TEST_SET_SAVED_SUCCESSFULLY_MSG);
  }

  async function handelSaveTestSetAlert() {
    await saveTestSet(
      {
        ...tempData,
        TestSetId: null,
        TestSet: tempData.TestSet + " copy",
      },
      copied
    );
    setDialog((prevState) => ({ ...prevState, open: false }));
    showToast(appResources.TEST_SET_COPY_SUCCESSFULLY_MSG);
  }

  async function saveTestSet(data, copy) {

    const { Customers, Users, ToTestSetId, UseAutoEvaluation, ...TestSet } = data;

    // const TestSetItems =
    //   Object.keys(dirtyTestItems).length || copy
    //     ? (selectedTestSet.TestSetItems || []).map(
    //       (x) => dirtyTestItems[x.TestItemId] || x
    //     )
    //     : null;

    const payload = {
      TestSet: TestSet.testSet,
      Users: copy ? null : selectedEmailUsers,
      Customers: Customers,
      TestSetItems: testSetItems || [],
      ToTestSetId,
      UseAutoEvaluation,
    };
    await post(TEST_SETS_APIS.SAVE, payload);
    await refreshTestSets(TestSet?.testSet?.TestSetId);
  }

  const testSetsFiltered = useMemo(
    () =>
      testSets.filter((x) => !x.IsArchived || x.IsArchived === showArchived),
    [showArchived, testSets]
  );

  useEffect(() => {
    if (testSetsFiltered.length > 0) {
      getTestSetsDetails(testSetsFiltered[0]);
    }
  }, [showArchived]);

  useEffect(function () {
    document.title = "Test Sets";
    (async function () {
      await refreshTestSets();
      const response = await get(`${MASTER_DATA_APIS.GET_BY_TYPES}?types=Products,FluidTypes`);
      setMasterData(response);
    })();
  }, []);

  return (
    <>
      <Grid container spacing={1} >
        <Grid item xs={12}>
          <SwitchInput
            label="Show Archived"
            onChange={(e) => setShowArchived(e.target.checked)}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Typography variant="subtitle2">
            Total Records: <b> {testSetsFiltered?.length}</b>
          </Typography>
          <TestSetsTable
            data={testSetsFiltered}
            rowSelected={handelRowSelected}
            showArchived={showArchived}
          />
        </Grid>
        <Grid item md={4} xs={12} mt={2}>
          <Card sx={{ p: 1, height: "85dvh", }}>
            <TestSetForm
              selectedEmailUsers={selectedEmailUsers}
              setSelectedEmailUsers={setSelectedEmailUsers}
              testSetData={selectedTestSet}
              key={selectedTestSet.testSet?.TestSetId}
              saveTestSet={handelSaveTestSet}
              testSets={testSets}
              refreshTestSets={refreshTestSets}
              masterData={masterData}
            />
          </Card>
        </Grid>
        <Grid item md={4} xs={12}>
          <Typography variant="subtitle2">
            Total Records: <b> {testSetItems?.length || ""}</b>
          </Typography>
          <TestItemsTable
            data={testSetItems || []}
            setTestSetItems={setTestSetItems}
            key={selectedTestSet.TestSetId}
          />
        </Grid>
      </Grid>

      <AppDialog
        open={dialog.open}
        handleClose={() => setDialog((prevState) => ({ ...prevState, open: false }))}
        title="Delete Note"
        maxWidth="md"
      >
        <ConfirmModal
          handleClose={() => {
            setDialog((prevState) => ({ ...prevState, open: false, }));
          }} alertAction={handelSaveTestSetAlert}
          message={dialog.message}
        />
      </AppDialog>
    </>
  );
}
