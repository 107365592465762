import { Close, Search } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { useEffect, useReducer, useState } from "react";
import Form, { FormActions } from "../../../components/Form";
import FormFooter from "../../../components/FormFooter";
import NewDataTable, { NewDataGridAction } from "../../../components/NewDataTable";
import { MASTER_DATA_APIS } from "../../../common/apis";
import useFetch from "../../../hooks/useFetch";

export default function SearchTestSet({
    masterData,
    testSets,
    actions,
    source,
}) {
    const { get } = useFetch();

    const [rowSelection, setRowSelection] = useState({});
    const [tableData, setTableData] = useState([]);
    const [testSetsList, setTestSetsList] = useState([]);
    const [allProducts, setAllProducts] = useState([]);

    const [data, dispatch] = useReducer(
        SearchFluidsFilterReducer,
        searchFilterDefaults
    );

    useEffect(() => {
        (async function () {
            const response = await get(`${MASTER_DATA_APIS.GET_BY_TYPES}?types=Products`);
            //Products with AllProducts added at 0 index
            const allProd = JSON.parse(JSON.stringify(response.Products));
            if (allProd) {
                allProd.unshift({
                    ProductId: 0,
                    Product: "All Products",
                    Colour: "",
                });
            }
            setAllProducts(allProd || {});
        })();

        if (source) {
            dispatch({
                payload: {
                    ProductId: source?.ProductId,
                    FluidTypeId: source?.FluidTypeId,
                },
                type: FormActions.LOAD_DATA,
            });
            if (testSets) {
                let filteredData = testSets.filter(item => item?.TestSetId !== source?.TestSetId);
                setTestSetsList(filteredData)
            }
        }
    }, [source, testSets]);

    const tblOptions = {
        columns: GetColumns(actions, setRowSelection),
        dataSource: tableData,
        getRowId: (originalRow) => originalRow.TestSetId,
        enableColumnActions: false,
        sx: { height: "50dvh", },

        muiTableBodyCellProps: ({ cell, column, row, table }) => {
            return {
                sx:
                    cell.column.columnDef.accessorKey === "Product" && row.original.Product ? {
                        backgroundColor: (row?.original?.Colour + "!important") || "",
                        color: "white",
                        py: 0.25,
                        border: "0.5px solid #d0d0d0",
                    } : cell.column.columnDef.accessorKey === "IsCustomerSpecific" ? {
                        backgroundColor: (row?.original?.IsCustomerSpecific === true ? "#800080!important" : "") || "",
                        color: "white",
                        py: 0.25,
                        border: "0.5px solid #d0d0d0",
                    } : cell.column.columnDef.accessorKey === "IsSystemTestSet" ? {
                        backgroundColor: (row?.original?.IsSystemTestSet === true ? "#800080!important" : "") || "",
                        color: "white",
                        py: 0.25,
                        border: "0.5px solid #d0d0d0",
                    } : cell.column.columnDef.accessorKey === "IsArchived" ? {
                        backgroundColor: (row?.original?.IsArchived === true ? "red !important" : "") || "",
                        color: "white",
                        py: 0.25,
                        border: "0.5px solid #d0d0d0",
                    } : {
                        py: 0.25,
                        border: "0.5px solid #d0d0d0",
                    }
            }
        },

        muiTableBodyProps: {
            sx: (theme) => ({
                '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
                    { backgroundColor: "inherit" },
                '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
                    { backgroundColor: "inherit" },
                '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
                    { backgroundColor: "#e2f2ff" },
                // backgroundColor: "inherit",//
                '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
                    { backgroundColor: "#e2f2ff" },
            }),
        },

        muiTableBodyRowProps: ({ row }) => ({
            selected: rowSelection[row.id],
            sx: {
                cursor: 'pointer',
                [`.MuiTableRow-root.Mui-selected td`]: {
                    backgroundColor: "#7030a0!important", color: "white",
                }
            },
        }),
    };

    const formActions = {
        clear: function () {
            dispatch({
                payload: {
                    ...searchFilterDefaults,
                    _key: Date.now(),
                },
                type: FormActions.RESET_FORM,
            });
        },
        search: async function () {
            let filteredArr = [];

            if (data?.ProductId > 0) {
                filteredArr = testSetsList?.filter(i => i.ProductId === data?.ProductId);
            }
            else { filteredArr = testSetsList; }

            filteredArr = filteredArr?.filter(i => i.FluidTypeId === data?.FluidTypeId);

            if (data?.TestSet?.trim()?.length > 0) {
                filteredArr = filteredArr.filter(i => i.TestSet?.toLowerCase()?.includes(data?.TestSet?.trim()?.toLowerCase()));
            }
            setTableData(filteredArr)
        },
    };

    return (
        <>
            <Grid container minWidth={700} spacing={1}>
                <Grid item xs={12}>
                    <Form
                        sections={GetFormSections(allProducts)}
                        data={data}
                        masterData={masterData}
                        dispatch={dispatch}
                        key={data && data._key}
                    />
                </Grid>
                <Grid item xs={12} sx={{ mt: -5 }}>
                    <FormFooter
                        buttons={GetFormButtons(data, formActions)}
                    />
                </Grid>
                <Grid item xs={12} sx={{ mt: 1 }}>
                    <Typography variant="subtitle2">
                        Total Records: <b> {tableData && tableData?.length}</b>
                    </Typography>
                    <NewDataTable {...tblOptions} />
                </Grid>
            </Grid>
        </>
    );
}

const GetFormButtons = (data, { clear, search }) => [
    {
        variant: "outlined",
        label: "Clear",
        onClick: clear,
        startIcon: <Close />,
    },
    {
        variant: "contained",
        label: "Search",
        onClick: search,
        startIcon: <Search />,
    },
];

function GetColumns(actions, setRowSelection) {
    return [
        {
            header: "Actions",
            size: 60,
            hide: !actions || !actions?.length,
            Cell: function ({ row }) {
                return (actions || []).map(function (ac, i) {
                    return (
                        <NewDataGridAction
                            row={row?.original}
                            onClick={() => {
                                ac?.action(row?.original);
                                setRowSelection((prev) => ({
                                    [row.id]: !prev[row.id],
                                }));
                            }}
                            label={ac.label}
                            startIcon={ac.startIcon}
                        />
                    );
                });
            },
        },
        {
            header: "Test Set",
            accessorKey: "TestSet",
            size: 500,
        },
        {
            header: "Type",
            accessorKey: "FluidType",
            size: 150,
        },
        {
            header: "Product",
            accessorKey: "Product",
            size: 100,
        },
        {
            header: "Specific",
            accessorKey: "IsCustomerSpecific",
            size: 100,
            Cell: ({ renderedCellValue }) => renderedCellValue === true ? "Yes" : ""
        },
        {
            header: "System",
            accessorKey: "IsSystemTestSet",
            size: 100,
            Cell: ({ renderedCellValue }) => renderedCellValue === true ? "Yes" : ""
        },
        {
            header: "Archived",
            accessorKey: "IsArchived",
            size: 100,
            Cell: ({ renderedCellValue }) => renderedCellValue === true ? "Yes" : ""
        },
    ];
}

function GetFormSections(allProducts) {
    return [
        {
            xsCol: 12,
            fields: [
                {
                    xsCol: 2,
                    name: "ProductId",
                    label: "Product",
                    type: "dropdown",
                    required: true,
                    selectOptions: (allProducts || []).map((x) => ({
                        value: x.ProductId,
                        text: x.Product,
                    })),
                },
                {
                    name: "FluidTypeId",
                    label: "Fluid Type",
                    xsCol: 2,
                    type: "dropdown",
                    required: true,
                    optionConfigs: {
                        name: "FluidTypes",
                        textProp: "FluidType",
                        valProp: "FluidTypeId",
                    },
                },
                {
                    name: "TestSet",
                    label: "Test Set",
                    xsCol: 2,
                },
                // {
                //     xsCol: 4,
                //     name: "UseStartsWith",
                //     label: "Starts With Search",
                //     type: "switch",
                //     alignSelf: "flex-end",
                // },
            ],
        },
    ];
}

const searchFilterDefaults = {
    ProductId: 0,
    FluidTypeId: 1,
    TestSet: "",
};

function SearchFluidsFilterReducer(state, action) {
    if (action.type === FormActions.INPUT_CHANGED) {
        return { ...state, [action.payload.name]: action.payload.value };
    }
    if (action.type === FormActions.LOAD_DATA) {
        return { ...action.payload, _key: Date.now() };
    }
    if (action.type === FormActions.RESET_FORM) {
        return { ...action.payload };
    }
    return state;
}
