import { Button, Card, Typography } from "@mui/material";
import Form, { SectionTitle } from "../../../components/Form";
import FormFooter from "../../../components/FormFooter";
import { Edit, EventNote, InsertDriveFile, } from "@mui/icons-material";
import { useState } from "react";
import AppDialog from "../../../components/AppDialog";
import EditSample from "../../../forms/EditSample";
import SelectImage from "../../../components/SelectImage";
import FluidWatch from "../../master-data/fluids/FluidWatch";
import { FluidWatchIcon, ImagesIcon } from "../../../icons/ButtonIcons";
import { TRAY_APIS } from "../../../common/apis";
import useFetch from "../../../hooks/useFetch";
import { toLocalDateTime } from "../../../utils";
import { useRoot } from "../../../rootContext";
import { useWindowPopups } from "../../../components/useWindowPopups";

export default function SampleOrReportDetail({
  data,
  productId,
  product,
  customer,
  site,
  unit,
  component,
  unitModel,
  customerId,
  componentId,
  StartLetter
}) {
  const { getFile } = useFetch();
  const { CanDoDataMaintenance } = useRoot();

  const [dialog, setDialog] = useState({
    open: false,
    width: "",
    title: "",
  });

  async function getSampleReport() {
    try {
      let fileName = `${customer} ${site} ${unit} ${component} ${data?.SampleNumber} `
      await getFile(`${TRAY_APIS.REPORT_SAMPLE}?sampleNo=${data?.SampleNumber}`, fileName);
    } catch (error) {
      console.error("Error fetching or opening PDF:", error);
    }
  }

  const HandleNewWindow = (actionName) => {
    useWindowPopups(actionName, { ...data, ProductId: productId, StartLetter: StartLetter, CustomerId: customerId, })
  }

  const actions = {
    edit: function (e) {
      HandleNewWindow("edit-sample")
    },
    images: function (e) {
      setDialog({
        open: true,
        title: "Add Image",
        width: "xl",
      });
    },
    events: function (e) {
      HandleNewWindow("edit-event")
    },
    sampleReport: function () {
      getSampleReport()
    }
  };

  function FluidWatchOpenClick() {
    setDialog({
      open: true,
      title: "Fluid Watch",
      width: "xl",
    });
  }

  return (
    <>
      <Card sx={{ p: 1 }}>
        <Form
          readOnly
          sections={FormSections(FluidWatchOpenClick, data)}
          data={data}
          key={data?.SampleNumber}
        />
        <FormFooter buttons={GetFormButtons(data, actions, CanDoDataMaintenance)} />
      </Card>
      <AppDialog
        open={dialog.open}
        title={dialog.title}
        maxWidth={dialog.width}
        handleClose={() =>
          setDialog((prevState) => ({
            ...prevState,
            open: false,
          }))
        }
      >
        {dialog.title === "Edit Sample" && (
          <EditSample
            cancel={() =>
              setDialog((prevState) => ({
                ...prevState,
                open: false,
              }))
            }
            sampleNo={data.SampleNumber}
          />
        )}

        {dialog.title === "Add Image" && (
          <SelectImage
            cancel={() =>
              setDialog((prevState) => ({
                ...prevState,
                open: false,
              }))
            }
            sampleId={data.SampleNumber}
          />
        )}

        {dialog.title === "Fluid Watch" && (
          <FluidWatch fluidId={data.FluidId} fluidTypeId={data.FluidTypeId} />
        )}
      </AppDialog>
    </>
  );
}

const GetFormButtons = (data, actions, CanDoDataMaintenance) => [
  {
    variant: data?.EventLogCount > 0 ? "contained" : "outlined",
    color: data?.EventLogCount > 0 ? "lightseagreen" : "primary",
    label: "Event Log",
    startIcon: <EventNote />,
    onClick: actions.events,
    badge: { badgeContent: data.EventLogCount, badgeColor: "lightseagreen", sx: { [`& .MuiBadge-badge`]: { border: "1px solid white" } } },
  },
  CanDoDataMaintenance && {
    variant: "outlined",
    label: "Images",
    badge: { badgeContent: data.SampleImageCount },
    onClick: actions.images,
    startIcon: <ImagesIcon />,
  },
  {
    variant: "outlined",
    label: "Sample Report",
    onClick: actions.sampleReport,
    startIcon: <InsertDriveFile />,
  },
  CanDoDataMaintenance && {
    variant: "contained",
    label: "Edit",
    onClick: actions.edit,
    startIcon: <Edit />,
  },
];

function FormSections(FluidWatchOpenClick, data) {
  return [
    {
      xsCol: 12,
      fields: [
        {
          xsCol: 12,
          component: function () {
            return <SectionTitle title="Sample Details" />;
          },
        },
      ],
    },
    {
      xsCol: 12,
      fields: [
        { name: "SampleNumber", label: "Sample Number", xsCol: 2 },
        { name: "UnitHours", label: "Unit HRs", xsCol: 2 },
        { name: "MeterReading", label: "Unit Meter HRs", xsCol: 2 },
        { name: "AlertStatus", label: "Alert Status", xsCol: 2, sx: { [`.MuiFilledInput-root`]: { backgroundColor: data?.AlertColour, color: data?.AlertColour ? "white" : "" } } },
        { name: "ComponentHours", label: "Comp Hrs", xsCol: 2 },
        { name: "ComponentReading", label: "Comp Meter HRs", xsCol: 2 },
        { name: "SIF", label: "Registration", xsCol: 2 },
        { name: "Step", label: "Step Completed", xsCol: 2 },
        { name: "FluidHours", label: "Fluid HRs", xsCol: 2 },
        { name: "SampleType", label: "Kit Type", xsCol: 2 },
        { name: "Topup", label: "Topup", xsCol: 2 },
        { name: "JobNumber", label: "Job Number", xsCol: 2 },
        {
          xsCol: 2,
          component: function () {
            return (
              <Button
                variant="contained"
                color={data?.HasFluidWatch === true ? "lightseagreen" : "primary"}
                onClick={FluidWatchOpenClick}
                sx={{ mt: "10px", }}
                startIcon={<FluidWatchIcon />}
              >
                Fluid Watch
              </Button>
            );
          },
        },
        { name: "FluidFormatted", label: "Oil", xsCol: 2 },
        { name: "TestSet", label: "Test Set", xsCol: 2, sx: { [`.MuiFilledInput-root`]: { color: data?.TestSet === "IMPORT" ? "Red" : "" } } },
        { name: "Evaluator", label: "Technician", xsCol: 2 },
        {
          name: "FluidChanged",
          label: "Fluid Changed on Sample",
          type: "switch",
          xsCol: 2,
        },
        {
          name: "FilterChanged",
          label: "Filter Changed",
          type: "switch",
          xsCol: 2,
        },
      ],
    },
    {
      xsCol: 12,
      fields: [
        { name: "SampleNotes", label: "Comments", type: "textarea", xsCol: 6 },
        { name: "CustomerComments", label: "Registration Comments", type: "textarea", xsCol: 6, },
        { name: "Evaluation", label: "Evaluation", type: "textarea", xsCol: 6, },
        { name: "Recommendation", label: "Recommendation", type: "textarea", xsCol: 6, },
        { name: "Search", label: "Website Search", type: "textarea", xsCol: 12, },
      ],
    },
    {
      xsCol: 12,
      fields: [
        {
          xsCol: 2,
          component: function () {
            return (<Typography><b>Sampled:</b> {toLocalDateTime(data?.Sampled)}</Typography>);
          },
        },
        {
          xsCol: 2,
          component: function () {
            return (<Typography><b>Card Created:</b> {toLocalDateTime(data?.RegistrationCreated)}</Typography>);
          },
        },
        {
          xsCol: 2,
          component: function () {
            return (<Typography> <b>Delivered:</b> {toLocalDateTime(data?.Delivered)}</Typography>);
          },
        },
        {
          xsCol: 2,
          component: function () {
            return (<Typography><b>Tray Date:</b>  {toLocalDateTime(data?.TrayDateDate)}</Typography>);
          },
        },
        {
          xsCol: 2,
          component: function () {
            return (<Typography> <b>Evaluated:</b> {toLocalDateTime(data?.Evaluated)}</Typography>);
          },
        },
        {
          xsCol: 2,
          component: function () {
            return (<Typography><b> Approved:</b> {toLocalDateTime(data?.Approved) || ""}</Typography>);
          },
        },
        {
          xsCol: 2,
          component: function () {
            return (<Typography><b> Sample Export:</b> {data?.ExportOption}</Typography>);
          },
        },
        {
          xsCol: 2,
          component: function () {
            return (<Typography> <b>Registered By:</b> {data?.RegisteredBy}</Typography>);
          },
        },
        {
          xsCol: 2,
          component: function () {
            return (<Typography><b> Approved By:</b> {data?.ApprovedBy || ""}</Typography>);
          },
        },
        {
          xsCol: 2,
          component: function () {
            return (<Typography> <b>Tray Owner:</b> {data?.OwnerLaboratory}</Typography>);
          },
        },
        {
          xsCol: 2,
          component: function () {
            return (<Typography><b> Display Order:</b> {data?.CompOrder}</Typography>);
          },
        },
        {
          xsCol: 2,
          component: function () {
            return (<Typography><b> Raw Order:</b> {data?.RawCompOrder}</Typography>);
          },
        },
      ]
    },
  ];
}
