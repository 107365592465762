import React, { Fragment, useEffect, useState, useMemo, useCallback } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import { Box, Card, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";

import MissedSampleTable from "./MissedSampleTable";
import MissedSampleDisplayTable from "./MissedSampleDisplayTable";
import DropDown from "../../../components/DropDown";
import useFetch from "../../../hooks/useFetch";
import { DETAILS_APIS } from "../../../common/apis";
import { Steps } from "./../../../common/Steps";
import Laboratory from "../../../common/Laboratory";
import { ExcelIcon } from "../../../icons/ButtonIcons";
import { useUser } from "../../../rootContext";

let missedTotal = 0;
let holdTotal = 0;
let sampleTotal = 0;

const MissedSamples = ({ screenName }) => {
  const user = useUser();
  const { post, postFile } = useFetch();

  const [state, setState] = useState({
    ExcludeToday: true,
    IncludeOnHold: true,
    ByTray: true,
    LaboratoryId: user.DefaultLaboratoryId,
  });

  const handleChangeSwitch = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const [dataSource, setDataSource] = useState([]);
  const [selectedStep, setSelectedStep] = useState(
    screenName === "Laboratory"
      ? 2
      : screenName === "QualityCheck"
        ? 3
        : screenName === "Registration"
          ? 1
          : screenName === "TrayDashboard"
            ? 0
            : screenName === "AutoEvaluationDashboard"
              ? 4
              : 0
  );
  //Steps Dropdown
  //const [steps] = useState(Steps);
  const handleChangeDropdown = (event) => {
    if (event.target.name === "Step") {
      setSelectedStep(event.target.value);
      return;
    }
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };
  const filteredData = useMemo(() => {
    let temp = dataSource;
    if (state) {
      temp = transformData(temp);

      missedTotal = temp.map((x) => x.missedTotal).reduce((a, b) => a + b, 0);
      holdTotal = temp.map((x) => x.holdTotal).reduce((a, b) => a + b, 0);
      sampleTotal = missedTotal + holdTotal;
    }
    if (selectedStep > 0) {
      let holdNumber = "hold" + selectedStep;
      let missedNumber = "missed" + selectedStep;
      temp = temp.filter(
        (item) => item[holdNumber] > 0 || item[missedNumber] > 0
      );
      missedTotal = temp.map((x) => x.missedTotal).reduce((a, b) => a + b, 0);
      holdTotal = temp.map((x) => x.holdTotal).reduce((a, b) => a + b, 0);
      sampleTotal = missedTotal + holdTotal;
    }
    return temp;
  }, [dataSource, selectedStep, state.ByTray]);

  //calling api
  useEffect(() => {
    (async () => {
      let data = await post(DETAILS_APIS.MISSED, state);
      setDataSource(data);
    })();
  }, [state]);

  //Export excel
  function exportToExcelClick() {
    postFile(DETAILS_APIS.EXPORT_MISSED, state, "Missing and Hold Samples");
  }

  const handleKeyPress = useCallback(
    (event) => {
      event.stopPropagation();
      if (event.key === "F12") {
        event.preventDefault();
        exportToExcelClick();
      }
    }, [state]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <>
      <Card sx={{ p: 1, minWidth: "70vw" }}>
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item xs={2}>
            <DropDown
              label="Laboratory"
              name="LaboratoryId"
              defaultValue={user.DefaultLaboratoryId || 1}
              onChange={handleChangeDropdown}
              selectOptions={Laboratory().map((x) => ({
                value: x.Id,
                text: x.Name,
              }))}
            />
          </Grid>
          <Grid item xs={2}>
            <DropDown
              label="Step"
              name="Step"
              defaultValue={selectedStep}
              onChange={handleChangeDropdown}
              selectOptions={Steps.map((x) => ({
                value: x.Id,
                text: x.Name,
              }))}
            />
          </Grid>

          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  checked={state.ExcludeToday}
                  onChange={handleChangeSwitch}
                  name="ExcludeToday"
                  size="small"
                />
              }
              label="Exclude Missed From Today"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={state.IncludeOnHold}
                  onChange={handleChangeSwitch}
                  name="IncludeOnHold"
                  size="small"
                />
              }
              sx={{ mt: "2px" }}
              label="Include Hold Samples"
            />

            <FormControlLabel
              control={
                <Switch
                  checked={state.ByTray}
                  onChange={handleChangeSwitch}
                  name={"ByTray"}
                  size="small"
                />
              }
              sx={{ mt: "2px" }}
              label="Display By Tray"
            />
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              onClick={exportToExcelClick}
              startIcon={<ExcelIcon />}
            >
              Excel (F12)
            </Button>
          </Grid>
        </Grid>
      </Card>

      <Box sx={{ mt: 1 }}>
        {state.IncludeOnHold && (
          <Typography variant="subtitle2" sx={{ color: "red" }}>
            {filteredData && filteredData.length ? (
              <>
                {sampleTotal} samples
                {sampleTotal.length !== 1 ? " were " : " was "}
                found for{" "}
                {Laboratory()
                  .filter((lab) => lab.Id === state.LaboratoryId)
                  .map((lab) => lab.Name)[0] || ""}{" "}
                having Missed = {missedTotal} and Hold = {holdTotal}{" "}
                {selectedStep !== 0 && "for "}
                {(selectedStep !== 0 &&
                  Steps.filter((step) => step.Id === selectedStep).map(
                    (step) => step.Name
                  )[0]) ||
                  ""}
              </>
            ) : (
              <>
                <span style={{ color: "green" }}>
                  {" "}
                  No samples were found for{" "}
                  {Laboratory()
                    .filter((lab) => lab.Id === state.LaboratoryId)
                    .map((lab) => lab.Name)[0] || " "}{" "}
                  for{" "}
                  {(selectedStep !== 0 &&
                    Steps.filter((step) => step.Id === selectedStep).map(
                      (step) => step.Name
                    )[0]) ||
                    ""}
                </span>
              </>
            )}
          </Typography>
        )}

        {!state.IncludeOnHold && (
          <Typography variant="subtitle2" sx={{ color: "red" }}>
            {filteredData && filteredData.length ? (
              <>
                {sampleTotal} samples
                {sampleTotal.length !== 1 ? "s were " : " was "}
                found for{" "}
                {Laboratory()
                  .filter((lab) => lab.Id === state.LaboratoryId)
                  .map((lab) => lab.Name)[0] || ""}{" "}
                {selectedStep !== 0 && "for "}
                {(selectedStep !== 0 &&
                  Steps.filter((step) => step.Id === selectedStep).map(
                    (step) => step.Name
                  )[0]) ||
                  ""}
              </>
            ) : (
              <>
                <span style={{ color: "green" }}>
                  {" "}
                  No samples were found for{" "}
                  {Laboratory()
                    .filter((lab) => lab.Id === state.LaboratoryId)
                    .map((lab) => lab.Name)[0] || " "}{" "}
                  for{" "}
                  {(selectedStep !== 0 &&
                    Steps.filter((step) => step.Id === selectedStep).map(
                      (step) => step.Name
                    )[0]) ||
                    ""}
                </span>
              </>
            )}
          </Typography>
        )}
        {state.ByTray ? (
          <MissedSampleDisplayTable data={filteredData} />
        ) : (
          <MissedSampleTable data={filteredData} />
        )}
      </Box>
    </>
  );
};

export default MissedSamples;
function transformData(data) {
  const map = {};
  if (data) {
    data.forEach((x) => {
      if (!map[x.TrayStem]) {
        map[x.TrayStem] = [];
      }
      map[x.TrayStem].push(x);
    });
    data = Object.keys(map).map((k) => {
      const arr = map[k];
      const tray = {
        ...arr[0],
        missed1: 0,
        hold1: 0,
        hold2: 0,
        hold3: 0,
        hold4: 0,
        hold5: 0,
        hold6: 0,
        holdTotal: 0,

        missed2: 0,
        missed3: 0,
        missed4: 0,
        missed5: 0,
        missed6: 0,
        missedTotal: 0,
      };
      arr.forEach((x) => {
        if (x.IsOnHold) {
          tray["hold" + x.Step] += 1;
          tray["holdTotal"] += 1;
          return;
        }
        tray["missed" + x.Step] += 1;
        tray["missedTotal"] += 1;
      });
      tray.total = arr.length;
      return tray;
    });
  }
  return data;
}
