import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { ClassNames } from "@emotion/react";
import {
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { DETAILS_APIS, FLEET_APIS } from "../../../../common/apis";
import useFetch from "../../../../hooks/useFetch";
import SearchSite from "../search/SearchSite";
import SearchCustomer from "../search/SearchCustomer";
import { useUser } from "../../../../rootContext";
import { useEffect } from "react";
import { useState } from "react";

const steps = ["Select Action", "Select Destination Site", "Action Summary"];

export default function ManageSite({ unit, close, handleSearchTree }) {
  const user = useUser();
  const { get, post } = useFetch();

  const [activeStep, setActiveStep] = useState(0);
  const [selectedUnit, setSelectedUnit] = useState({});
  const [skipped, setSkipped] = useState(new Set());
  const [processResponse, setProcessResponse] = useState("Process completed successfully.");
  const [selectedOption, setSelectedOption] = useState("deleteOriginal");
  const [siteDetails, setSiteDetails] = useState({});
  const [keepContacts, setKeepContacts] = useState(false);
  const [localData, setLocalData] = useState({});

  const getSiteDetails = async (siteId) => {
    const respData = await get(`${DETAILS_APIS.MANAGE_SITE_CHAIN}?siteId=${siteId}`);
    setSiteDetails(respData)
    document.title = (`Manage Site ${respData?.Site}`);
  }

  useEffect(() => {
    let parseData = JSON.parse(localStorage.getItem("manage-site"));
    if (parseData !== null && parseData !== undefined && parseData?.SiteId > 0) {
      setLocalData(parseData);
      getSiteDetails(parseData?.SiteId)
    }

    if (unit?.SiteId > 0) {
      getSiteDetails(unit?.SiteId)
    }
  }, [])

  async function PerformOperation() {
    if (selectedOption === "deleteOriginal") {
      const data = {
        FromSiteId: unit?.SiteId || localData?.SiteId,
        ToSiteId: selectedUnit.SiteId,
        ActionToTake: 2,
        Screen: "Site Management",
        CustomerId: selectedUnit.CustomerId,
        PreserveContacts: keepContacts,
      };
      const result = await post(FLEET_APIS.MANAGE_SITE, data);
      if (result.Success === true) {
        setProcessResponse(result);
        close && close();
        const treeData = { ProductId: selectedUnit?.ProductId, StartLetter: selectedUnit?.StartLetter, CustomerId: selectedUnit?.CustomerId, SiteId: selectedUnit.SiteId, refreshCheck: true }
        handleSearchTree && handleSearchTree(treeData, null, true)
        localStorage.setItem("tree-resp", JSON.stringify({ ...treeData }));
        window.dispatchEvent(new Event('storage'));

        if (window.location.pathname === '/manage-site') {
          localStorage.removeItem("manage-site");
          window.close();
        }
      }
    } else if (selectedOption === "keepOriginal") {
      const data = {
        FromSiteId: unit?.SiteId || localData?.SiteId,
        ToSiteId: selectedUnit.SiteId,
        ActionToTake: 1,
        Screen: "Site Management",
        CustomerId: selectedUnit.CustomerId,
        PreserveContacts: keepContacts,
      };
      const result = await post(FLEET_APIS.MANAGE_SITE, data);
      if (result.Success === true) {
        setProcessResponse(result);
        close && close();
        const treeData = { ProductId: selectedUnit?.ProductId, StartLetter: selectedUnit?.StartLetter, CustomerId: selectedUnit?.CustomerId, SiteId: selectedUnit.SiteId, refreshCheck: true }
        handleSearchTree && handleSearchTree(treeData, null, true)
        localStorage.setItem("tree-resp", JSON.stringify({ ...treeData }));
        window.dispatchEvent(new Event('storage'));

        if (window.location.pathname === '/manage-site') {
          localStorage.removeItem("manage-site");
          window.close();
        }
      }
    } else if (selectedOption === "moveSite") {
      const data = {
        FromSiteId: unit?.SiteId || localData?.SiteId,
        ToSiteId: selectedUnit.SiteId,
        ActionToTake: 3,
        Screen: "Site Management",
        CustomerId: selectedUnit.CustomerId,
        PreserveContacts: keepContacts,
      };
      const result = await post(FLEET_APIS.MANAGE_SITE, data);
      if (result.Success === true) {
        setProcessResponse(result);
        close && close();
        const treeData = { ProductId: selectedUnit?.ProductId, StartLetter: selectedUnit?.StartLetter, CustomerId: selectedUnit?.CustomerId, refreshCheck: true }
        handleSearchTree && handleSearchTree(treeData, null, true)
        localStorage.setItem("tree-resp", JSON.stringify({ ...treeData }));
        window.dispatchEvent(new Event('storage'));

        if (window.location.pathname === '/manage-site') {
          localStorage.removeItem("manage-site");
          window.close();
        }
      }
    } else if (selectedOption === "deleteSite") {
      const data = {
        FromSiteId: unit?.SiteId || localData?.SiteId,
        ToSiteId: selectedUnit.SiteId,
        ActionToTake: 4,
        Screen: "Site Management",
        CustomerId: selectedUnit.CustomerId,
        PreserveContacts: keepContacts,
      };
      const treeData = {
        ProductId: unit?.ProductId || localData?.ProductId,
        StartLetter: unit?.StartLetter || localData?.StartLetter,
        CustomerId: unit?.CustomerId || localData?.CustomerId,
      }
      const result = await post(FLEET_APIS.MANAGE_SITE, data);
      if (result.Success === true) {
        setProcessResponse(result);
        close && close();
        handleSearchTree && handleSearchTree(treeData, null, true)
        localStorage.setItem("tree-resp", JSON.stringify({ ...treeData }));
        window.dispatchEvent(new Event('storage'));

        if (window.location.pathname === '/manage-site') {
          localStorage.removeItem("manage-site");
          window.close();
        }
      }
    }
  }

  const handleNext = () => {
    let newSkipped = skipped;
    if (activeStep === 2) {
      PerformOperation();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const handleBack = () => {
    if (selectedOption === "deleteSite") {
      setActiveStep((prevActiveStep) => prevActiveStep - 2);
      setSelectedOption("deleteOriginal");
      setSelectedUnit({});
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setSelectedUnit({});
    }
  };

  const handleRadioGroupChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleReset = () => {
    setActiveStep(0);
    close();
    // refreshGrid();
  };

  // Table's Styling
  const tableCol2 = {
    background: "lightblue",
    color: "blue",
  };
  const tableCol3 = {
    background: "lightgreen",
    color: "green",
  };

  const handleSelectDestination = async (row) => {
    const respData = await get(`${DETAILS_APIS.MANAGE_SITE_CHAIN}?siteId=${row?.SiteId}`);
    setSelectedUnit(respData)
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  const handleSelectCustomerDestination = async (row) => {
    const respData = await get(`${DETAILS_APIS.MANAGE_CUSTOMER_CHAIN}?CustomerId=${row?.CustomerId}`);
    setSelectedUnit(respData)
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="deleteOriginal"
              name="manageAction"
              onChange={handleRadioGroupChange}
            >
              <FormControlLabel
                value="deleteOriginal"
                control={<Radio />}
                label="Merge the site into a different site, and DELETE the original site"
              />
              <FormControlLabel
                value="keepOriginal"
                control={<Radio />}
                label="Merge the site into a different site, and KEEP the original site"
              />
              <FormControlLabel
                value="moveSite"
                control={<Radio />}
                label="Move the site to a different customer"
              />
              {(user?.RoleAdministration === false && user?.RoleDeveloper === false)
                ? <>
                  <FormControlLabel
                    hidden={!(siteDetails?.SampleCount === 0 && siteDetails?.AdHocReportCount === 0)}
                    value="deleteSite"
                    control={<Radio />}
                    label="Delete the site "
                  />
                </>
                : (siteDetails?.SampleCount > 0 || siteDetails?.AdHocReportCount > 0)
                  ? <>
                    <FormControlLabel
                      hidden={!(siteDetails?.CanDeleteSamples || user?.RoleAdministration || user?.RoleDeveloper)}
                      value="deleteSite"
                      control={<Radio />}
                      label={"Delete the site and DELETE " + (siteDetails?.SampleCount > 0 ? siteDetails?.SampleCount + " sample" + (siteDetails?.SampleCount !== 1 ? "s" : "") : "") +
                        (siteDetails?.SampleCount > 0 && siteDetails?.AdHocReportCount > 0 ? " and " : "") +
                        (siteDetails?.AdHocReportCount > 0 ? siteDetails?.AdHocReportCount + " special report " + (siteDetails?.AdHocReportCount !== 1 ? "s" : "") : "")}
                    />
                  </>
                  : <>
                    <FormControlLabel
                      value="deleteSite"
                      control={<Radio />}
                      label="Delete the site "
                    />
                  </>
              }
            </RadioGroup>
            <Typography mt={1}>Options:</Typography>
            <FormControlLabel
              sx={{ "& .MuiFormControlLabel-label": { color: keepContacts === true ? "#228B22" : "#BF40BF" } }}
              checked={keepContacts}
              control={<Checkbox />}
              onChange={() => setKeepContacts(!keepContacts)}
              label="Keep all contacts that are on the Sites, Units or Components"
            />
          </FormControl>
        );
      case 1:
        return selectedOption === "deleteSite" ? (
          handleNext()
        ) : selectedOption === "moveSite"
          ? <SearchCustomer
            selectedUnit={unit}
            // rowSelected={(row) => setSelectedUnit(row)}
            actions={[
              {
                action: handleSelectCustomerDestination,
                label: "Select",
              },
            ]}
          /> : (
            <SearchSite
              selectedUnit={siteDetails}
              // rowSelected={(row) => handleSelectDestination(row)}
              actions={[
                {
                  action: handleSelectDestination,
                  label: "Select",
                },
              ]}
            />
          );
      case 2:
        return selectedOption === "deleteSite" ? (
          <>
            <Typography sx={{ mb: 1 }}>
              The Site {siteDetails?.Site} will be DELETED
            </Typography>
            <TableContainer sx={{ mb: 1 }} component={Paper}>
              <Table
                sx={{ textAlign: "left" }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    {/* Col 1 */}
                    <TableCell>Item</TableCell>
                    {/* Col 2 */}
                    <TableCell sx={tableCol2}>Source</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Product
                    </TableCell>
                    <TableCell sx={tableCol2}>{siteDetails?.Product || ""}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Customer
                    </TableCell>
                    <TableCell sx={tableCol2}>{siteDetails?.Customer || ""}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Site
                    </TableCell>
                    <TableCell sx={tableCol2}>{siteDetails?.Site || ""}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      SiteId
                    </TableCell>
                    <TableCell sx={tableCol2}>{siteDetails?.SiteId || ""}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Sample Count
                    </TableCell>
                    <TableCell sx={tableCol2}>  {siteDetails?.SampleCount || 0} </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Special Reports Count
                    </TableCell>
                    <TableCell sx={tableCol2}> {siteDetails?.SpecialReportsCount || 0}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>) : (
          <>
            <TableContainer component={Paper}>
              <Table
                sx={{ textAlign: "left" }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    {/* Col 1 */}
                    <TableCell>Item</TableCell>
                    {/* Col 2 */}
                    <TableCell sx={tableCol2}>Source</TableCell>
                    {/* Col 3 */}
                    <TableCell sx={tableCol3}>Destination</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Product
                    </TableCell>
                    <TableCell sx={tableCol2}>{siteDetails?.Product || ""}</TableCell>
                    <TableCell sx={tableCol3}> {selectedUnit?.Product}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Customer
                    </TableCell>
                    <TableCell sx={tableCol2}>{siteDetails?.Customer || ""}</TableCell>
                    <TableCell sx={tableCol3}>{selectedUnit?.Customer}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Site
                    </TableCell>
                    <TableCell sx={tableCol2}>{siteDetails?.Site || ""}</TableCell>
                    <TableCell sx={tableCol3}> {selectedUnit?.Site || ""} </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      SiteId
                    </TableCell>
                    <TableCell sx={tableCol2}>{siteDetails?.SiteId || ""}</TableCell>
                    <TableCell sx={tableCol3}>{selectedUnit?.SiteId || ""} </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Sample Count
                    </TableCell>
                    <TableCell sx={tableCol2}> {siteDetails?.SampleCount || 0} </TableCell>
                    <TableCell sx={tableCol3}> {selectedUnit?.SampleCount || 0}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Special Reports Count
                    </TableCell>
                    <TableCell sx={tableCol2}>  {siteDetails?.reportsCount || 0} </TableCell>
                    <TableCell sx={tableCol3}> {selectedUnit?.reportsCount || 0}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        );
      default:
        return "Unknown step";
    }
  }
  return (
    <Card
      sx={{
        padding: "10px",
        minWidth: "70vw",
        minHeight: "23vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Stepper activeStep={activeStep} sx={{ mr: 2 }}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <>
          <Typography sx={{ mt: "-20%" }}>{processResponse}</Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button onClick={handleReset} sx={{ ml: "auto", mr: 1 }}>
              Close
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Typography className={ClassNames.instructions} sx={{ mt: 4 }}>
            {getStepContent(activeStep)}
          </Typography>
          <Box
            sx={{
              marginTop: "auto",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              Back
            </Button>
            {activeStep !== 1
              ? <Button
                onClick={handleNext}
                disabled={activeStep === 1 && !selectedUnit?.Customer}
              >
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
              : <></>}
          </Box>
        </>
      )}
    </Card>
  );
}
