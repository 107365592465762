import { Button, Box, Typography } from "@mui/material";
import moment from "moment";
import { useCallback, useEffect, useReducer } from "react";
import Form, { FormActions } from "../../../components/Form";
import { SearchIcon, TickGreenIcon } from "../../../icons/ButtonIcons";
import { toIsoFormat } from "../../../utils";

export default function SampleFilter({
  cancel,
  onView,
  getTrayData,
  onUnregisteredOnlyChanged,
  infoMessage,
  successMessage,
  isInfoIcon,
  isSuccessIcon,
  isWarningTrue,
  setData,
}) {
  const [data, dispatch] = useReducer(SampleFilterReducer, SampleFilterDefaults);

  useEffect(
    function () {
      onUnregisteredOnlyChanged(data.UnregisteredOnly);
    },
    [data.UnregisteredOnly]
  );

  const actions = {
    view: () => {
      setData([])
      getTrayData(data); localStorage.setItem("local01", JSON.stringify({ ...data, CreationDate: moment.utc().format("YYYYMMDD") }))
    },
    cancel: cancel,
  };

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "F1") {
        event.preventDefault();
        onView(data);
      }
    },
    [data]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    let trayLocalData = localStorage.getItem("local01");
    if (trayLocalData !== null) {
      let checkDate = moment.utc().format("YYYYMMDD")
      trayLocalData = JSON.parse(trayLocalData);
      if (checkDate === trayLocalData?.CreationDate) {
        dispatch({
          payload: { ...trayLocalData, },
          type: EventActions.LOAD_DATA,
        });
        getTrayData(trayLocalData)
      }
      if (checkDate !== trayLocalData?.CreationDate) {
        getTrayData(data)
      }
    }
    if (trayLocalData === null) {
      getTrayData(data)
    }
  }, []);

  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      actions.view();
    }
  }

  return (
    <>
      <Form
        sections={GetFormSections(
          data,
          actions,
          handleEnterPress,
          infoMessage,
          successMessage,
          isInfoIcon,
          isSuccessIcon,
          isWarningTrue
        )}
        data={data}
        spacing={2}
        dispatch={dispatch}
        key={data?._ts_key}
      />
    </>
  );
}

function GetFormSections(
  data,
  { view }, handleEnterPress,
  infoMessage,
  successMessage,
  isInfoIcon,
  isSuccessIcon,
  isWarningTrue
) {
  return [
    {
      fields: [
        {
          name: "UnregisteredOnly",
          label: "Unregistered Only",
          type: "switch",
        },
        {
          name: "Date",
          label: "Date",
          type: "date",
          disabled: data.LabAndDateOption === 2 || data.LabAndDateOption === 4,
          handleEnterPress: handleEnterPress,
          arrowCheck: true,
        },
        {
          name: "Tray",
          label: "Tray",
          type: "number",
          disabled: data.LabAndDateOption !== 0,
          min: 1,
          max: 999,
          handleEnterPress: handleEnterPress,
        },

        {
          name: "LabAndDateOption",
          label: "Search by",
          type: "dropdown",
          alignSelf: "end",
          xsCol: 3,
          required: true,
          selectOptions: getDateOptions(),
        },
        {
          xsCol: 1,
          alignSelf: "flex-end",
          component: function () {
            return (
              <Button
                variant="contained"
                onClick={view}
                startIcon={<SearchIcon />}
              >
                View (F1)
              </Button>
            );
          },
        },
        {
          flex: 2,
          alignSelf: "center",
          component: function () {
            return (
              <Typography sx={{ fontSize: 11, mt: 1.2 }}>
                {isInfoIcon && (
                  <Box
                    component="div"
                    sx={{ color: isWarningTrue ? "red" : "" }}
                  >
                    {infoMessage}
                  </Box>
                )}
                {isSuccessIcon && (
                  <Box
                    component="div"
                    sx={{ display: "inline-flex", alignItems: "center" }}
                  >
                    {<TickGreenIcon />}
                    <Box component="div" sx={{ ml: 1 }}>
                      {successMessage}
                    </Box>
                  </Box>
                )}
              </Typography>
            );
          },
        },
      ],
    },
  ];
}
const EventActions = {
  LOAD_DATA: "load-data",
};

function SampleFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === EventActions.LOAD_DATA) {
    return { ...action.payload, _ts_key: new Date() }
  }
  return state;
}
function getDateOptions() {
  return [
    { value: 0, text: "By Date and Tray for Laboratory" },
    { value: 1, text: "Unregistered by Date for Laboratory" },
    { value: 2, text: "Unregistered for Laboratory" },
    { value: 3, text: "Unregistered by Date for ALL Laboratories" },
    { value: 4, text: "Unregistered for ALL Laboratories" },
  ];
}

const SampleFilterDefaults = {
  Date: toIsoFormat(new moment()),
  Tray: 1,
  LabAndDateOption: 0,
};
