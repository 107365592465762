import { memo } from "react";
import DataTable from "../../../components/DataTable";

export default memo(function ResultsTable({ data, columns, rowSelected, sx }) {
  const options = {
    columns: columns || [],
    dataSource: data || [],
    rowIdProperty: "keyProp",
    isDisableColumnFilter: true,
    rowStylingHandler: "resultSample",
    cellStylingHandler: "sampleResultEval",
    sxTable: {
      [`.MuiDataGrid-columnHeader`]: { backgroundColor: "#c0c0c0" },
    },
    rowsSelected: function (r) {
      r.length && rowSelected(r[0]);
    },
    minHeight: 39,
    heightValue: 100,
  };
  return <DataTable {...options} sx={sx} />;
});
