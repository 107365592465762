import { useState } from "react";
import NewDataTable from "../../../components/NewDataTable";

export default function QuickMergeSitesTable({ data, rowsSelected }) {
  const [rowSelection, setRowSelection] = useState({});

  const options1 = {
    columns: fromMergeColumns1,
    dataSource: data || [],
    getRowId: (originalRow) => originalRow.SiteId + originalRow.CustomerId,
    sx: { height: 850 },
    onRowSelectionChange: setRowSelection,
    muiSelectCheckboxProps: {
      color: 'secondary',
      backgroundColor: "blue"
    },
    muiTableBodyProps: {
      sx: (theme) => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "#e2f2ff" },
        // backgroundColor: "inherit",//
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "#e2f2ff" },
      }),
    },
    muiTableBodyRowProps: ({ row }) => ({
      //implement row selection click events manually
      onClick: () => {
        setRowSelection((prev) => ({
          ...prev,
          [row.id]: !prev[row.id],
        }))
        rowsSelected(row.original);
      },
      selected: rowSelection[row.id],
      sx: {
        cursor: 'pointer',
      },
    }),
    muiTableBodyCellProps: ({ cell, column, row, table }) => {
      return {
        sx: cell.column.columnDef.accessorKey === "Product" ? {
          backgroundColor: (row?.original?.ProductColour + "!important") || "",
          color: "white",
          border: "0.5px solid #d0d0d0",
          py: 0.1,
        } : { py: 0.1, borderLeft: "0.5px solid #d0d0d0", }
      }
    },
    state: { rowSelection },
  };
  return <NewDataTable {...options1} />;
  // return <DataTable {...fromMergeOptions} />;
}

const fromMergeColumns1 = [
  {
    header: "From Product",
    accessorKey: "Product",
    size: 120,
    enableEditing: false,
  },
  {
    header: "From Customer",
    accessorKey: "Customer",
    size: 200,
    sortable: false,
    enableEditing: false,
  },
  {
    header: "From Site",
    accessorKey: "Site",
    size: 200,
    sortable: false,
    enableEditing: false,
  },
];