import { Button, Grid, InputLabel } from "@mui/material";
import { useCallback } from "react";
import DataTable, { DataGridAction } from "../../../components/DataTable";
import { TestItemActions } from "./TestItemForm";
import { AddIcon } from "../../../icons/ButtonIcons";

export default function TextualRepresentation({ data, dispatch }) {
  function deleteClick(row) {
    dispatch({
      type: TestItemActions.REMOVE_TEXT_REP,
      payload: row.TestItemRepresentationId,
    });
  }
  const rowEditHandler = useCallback(function (newRow, oldRow) {
    return new Promise(async function (resolve, reject) {
      dispatch({ type: TestItemActions.DIRTY_TEXT_REP, payload: newRow });
      resolve({ ...newRow });
    });
  }, []);

  const options = {
    columns: GetColumns({ deleteClick }),
    dataSource: (data.Representations || []).filter((x) => !x.deleted),
    rowIdProperty: "TestItemRepresentationId",
    isDisableColumnFilter: true,
    sx: { height: "408px" },
    rowEditHandler: rowEditHandler,
    experimentalFeatures: { newEditingApi: true },
  };

  return (
    <Grid container spacing={1}>
      <Grid
        item
        container
        xs={12}
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <InputLabel>Textual Representations</InputLabel>
        <Button
          startIcon={<AddIcon />}
          variant="outlined"
          disabled={!data.TestItemId}
          onClick={() =>
            dispatch({
              type: TestItemActions.ADD_TEXT_REP,
            })
          }
        >
          Add Row
        </Button>
      </Grid>
      <Grid item xs={12}>
        <DataTable {...options} />
      </Grid>
    </Grid>
  );
}
const GetColumns = ({ deleteClick }) => [
  {
    headerName: "Delete",
    field: "TestItemRepresentationId",
    sortable: false,
    width: 80,
    renderCell: function ({ row }) {
      return <DataGridAction row={row} onClick={deleteClick} label="Delete" />;
    },
  },
  {
    headerName: "Display",
    field: "DisplayValue",
    sortable: false,
    editable: true,
    minWidth: 100,
    flex: 1,
  },
  {
    headerName: "Underlying",
    field: "UnderlyingValue",
    sortable: false,
    editable: true,
    type: "number",
    width: 80,
  },
  {
    headerName: "Graph",
    field: "GraphValue",
    sortable: false,
    editable: true,
    type: "number",
    width: 80,
  },
  {
    headerName: "Order",
    field: "DisplayRank",
    sortable: false,
    editable: true,
    type: "number",
    width: 60,
  },
];
