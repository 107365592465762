import { Box, Button, Card, Grid, Typography } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useReducer, useState } from "react";
import moment from "moment";
import Form, { FormActions } from "../../../components/Form";
import useFetch from "../../../hooks/useFetch";
import CreateTrayTable from "./CreateTrayTable";
import { useRoot, useUser } from "../../../rootContext";
import { toIsoFormat, toLocalDayDate, TrayDateFormat } from "../../../utils";
import useMasterData from "../../../hooks/useMasterData";
import { TRAY_APIS } from "../../../common/apis";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import {
  AddExtraSampleIcon,
  CreateNewTrayIcon,
  DeleteTrayIcon,
  InfoIcon,
  TickGreenIcon,
  TraySummaryIcon,
  UpdateIcon,
  ViewTrayIcon,
} from "../../../icons/ButtonIcons";
import AppDialog from "../../../components/AppDialog";
import appResources from "../../../app-resources/en/Resources.json";
import { ValidateCreateTray } from "../../../validation/schemas/CreateTraySchema";
import ResizePanel from "react-resize-panel";
import { toBasicLocalDate } from "../../../utils";
import ConfirmModal from "../../../components/ConfirmModal";

let sampleCount = 0;
const todayDate = new Date();

const maxDateAllowed = new Date(
  todayDate.getFullYear(),
  todayDate.getMonth(),
  todayDate.getDate() + 6
);

const minDateAllowed = new Date(
  todayDate.getFullYear(),
  todayDate.getMonth(),
  todayDate.getDate() - 6
);

export default function CreateTray() {

  const { get, post } = useFetch();
  const user = useUser();
  const { CanDoDataMaintenance, CanCreateTrays } = useRoot();
  const { getMasterData } = useMasterData();
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [createdTrayData, setCreatedTrayData] = useState([]);
  const [masterData, setMasterData] = useState({});
  const [infoMessage, setInfoMessage] = useState();
  const [isInfoIcon, setIsInfoIcon] = useState(false);
  const [isSuccessIcon, setIsSuccessIcon] = useState(false);
  const [isWarningTrue, setIsWarningTrue] = useState(false);
  const [buttonText, setButtonText] = useState("");
  const [selectedFunction, setSelectedFunction] = useState(null)

  const SearchFilterDefaults = {
    OwnerLaboratoryId: user.DefaultLaboratoryId,
    Tray: 1,
    NumberOfSamples: 50,
    Label: "",
    useDeliveredDate: toIsoFormat(new moment()), //moment.utc().format("YYYY-MM-DDTHH:mm:ss"),
    // TrayDate: moment.utc().format("YYYY-MM-DDTHH:mm:ss"),
    TrayDate: toIsoFormat(new moment()),
    _key: new Date(),
  };

  const EventActions = {
    LOAD_DATA: "load-data",
  };

  const [data, dispatch] = useReducer(
    SearchFilterReducer,
    SearchFilterDefaults
  );

  function SearchFilterReducer(state, action) {
    if (action.type === FormActions.INPUT_CHANGED) {
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    }
    if (action.type === FormActions.RESET_FORM) {
      return { ...SearchFilterDefaults, _key: new Date() };
    }
    if (action.type === EventActions.LOAD_DATA) {
      return { ...action.payload, _key: new Date() };
    }
    return state;
  }

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "F1") {
        event.preventDefault();
        if (
          CanCreateTrays &&
          data.Label?.trim() &&
          data.Tray &&
          data.NumberOfSamples
        ) {
          createTrayClick();
        }
      } else if (event.key === "F2") {
        event.preventDefault();
        traySummaryClick();
      } else if (event.key === "F3") {
        event.preventDefault();
        viewTrayClick();
      } else if (event.key === "F4") {
        event.preventDefault();
        if (CanCreateTrays && createdTrayData.length > 0) {
          updateLabelClick();
        }
      } else if (event.key === "F10") {
        event.preventDefault();
        if (CanCreateTrays && createdTrayData.length > 0) {
          addExtraSample();
        }
      } else if (event.key === "F11") {
        event.preventDefault();
        if (CanCreateTrays && createdTrayData.length) {
          deleteLastSampleClick();
        }
      } else if (event.key === "F12") {
        event.preventDefault();
        if (CanCreateTrays && createdTrayData.length) {
          deleteTrayClick();
        }
      }
    },
    [CanCreateTrays, createdTrayData, data]
  );

  useEffect(() => {
    document.title = "Create Trays";
    dispatch({ type: FormActions.INPUT_CHANGED, payload: { name: "OwnerLaboratoryId", value: user.DefaultLaboratoryId } })
  }, [user]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  const trayDate = new Date(data.TrayDate);
  const trayDateSorted = new Date(
    trayDate.getFullYear(),
    trayDate.getMonth(),
    trayDate.getDate()
  );
  const today = moment();
  const tomorrow = today.clone().add(1, "days");

  //Load laboratory data
  useEffect(function () {
    (async function () {
      const temp = await getMasterData("Laboratories");
      if (temp.Laboratories) {
        temp.Laboratories = temp.Laboratories.filter((x) => !x.IsForImporting);
      }
      setMasterData(temp);
    })();
  }, []);

  useEffect(() => {
    if (data.IsTrainingSample) {
      training();
    }
  }, [data.IsTrainingSample]);

  //Create the tray data
  async function createTrayClick() {
    if (data.TrayDate === "Invalid date") {
      setIsWarningTrue(true);
      setIsSuccessIcon(false);
      setIsInfoIcon(true);

      return setInfoMessage(appResources.INVALID_DATE_FORMAT_MSG);
    }
    setFormErrors({});
    ValidateCreateTray(data).then(async function ({ data: req, errors }) {
      if (req) {
        const resp = await post(TRAY_APIS.CREATE, {
          Tray: data.Tray > 999 ? 999 : data.Tray,
          NumberOfSamples:
            data.NumberOfSamples > 99 ? 99 : data.NumberOfSamples,
          Date: data.TrayDate,
          OwnerLaboratory: user.RealDefaultLaboratoryId,
          Laboratory: data?.OwnerLaboratoryId,
          Label: data.Label,
          IsTraining: data.IsTrainingSample ? data.IsTrainingSample : false,
          Screen: "Create Trays",
          Delivered: data.useDeliveredCheck ? data.useDeliveredDate : null,
        });
        if (resp.ExistingSamples !== 0) {
          setIsWarningTrue(true);
          setIsSuccessIcon(false);
          setIsInfoIcon(true);

          setInfoMessage(
            `Tray ${data.Tray} for ${masterData.Laboratories?.find(
              (x) => x.LaboratoryId === data?.OwnerLaboratoryId
            )?.Laboratory
            } already exists and has ${resp.ExistingSamples && resp.ExistingSamples
            } samples`
          );

          return;
        }
        if (resp.ExistingSamples === 0) {
          setIsSuccessIcon(true);
          setIsInfoIcon(false);
          setInfoMessage(
            `Samples 1 to ${data.NumberOfSamples} created in tray ${data.Tray
            } for ${masterData.Laboratories?.find(
              (x) => x.LaboratoryId === data?.OwnerLaboratoryId
            )?.Laboratory
            }`
          );
          onLoadGrid();
        }
      }
      if (errors) {
        setFormErrors(errors);
      }
    });
  }

  async function updateLabelClick() {
    if (data.Label) {
      if (data.TrayDate === "Invalid date") {
        setIsWarningTrue(true);
        setIsSuccessIcon(false);
        setIsInfoIcon(true);

        return setInfoMessage(appResources.INVALID_DATE_FORMAT_MSG);
      }
      const responseData = await get(
        `${TRAY_APIS.UPDATE_TRAY_LABEL}?Laboratory=${data.OwnerLaboratoryId
        }&Tray=${data.Tray}&TrayDate=${toBasicLocalDate(data.TrayDate)}&Label=${data.Label
        }`
      );
      if (responseData >= 1) {
        setIsSuccessIcon(true);
        setIsInfoIcon(false);
        setInfoMessage(`The details for tray ${data.Tray} have been updated`);
        onLoadGrid();
      }
    }
  }
  //onLoad
  async function onLoadGrid() {
    const responseData = await post(TRAY_APIS.GET, {
      Date: data.TrayDate,
      LabId: data?.OwnerLaboratoryId,
      TrayNumber: data.Tray,
      MinimumStep: 1,
      MaximumStep: data.NumberOfSamples ? data.NumberOfSamples : 1,
      Hold: true,
    });
    if (responseData.length === 0) {
      setIsWarningTrue(true);
      setIsSuccessIcon(false);
      setIsInfoIcon(true);
      setInfoMessage(
        `Tray ${data.Tray} for ${masterData.Laboratories?.find(
          (x) => x.LaboratoryId === data?.OwnerLaboratoryId
        )?.Laboratory
        } does NOT exist`
      );

      setCreatedTrayData([]);
    } else {
      setCreatedTrayData(responseData);
      dispatch({
        payload: {
          ...responseData[0],
          OwnerLaboratoryId: responseData[0].LaboratoryId,
          // TrayDate: responseData[0].Received,
          TrayDate: moment(responseData[0].TrayDate, TrayDateFormat)
            .local()
            .format("YYYY-MM-DDTHH:mm:ss"),
          useDeliveredCheck: responseData[0].Delivered !== null ? true : false,
          useDeliveredDate:
            responseData[0].Delivered !== null
              ? responseData[0].Delivered
              : moment.utc().local().format("YYYY-MM-DDTHH:mm:ss"),
          Label: responseData[0].Label,
          NumberOfSamples: 50,
        },
      });
      sampleCount = responseData.length;
    }
  }

  //
  async function viewTrayClick() {
    if (data.TrayDate === "Invalid date") {
      setIsWarningTrue(true);
      setIsSuccessIcon(false);
      setIsInfoIcon(true);
      return setInfoMessage(appResources.INVALID_DATE_FORMAT_MSG);
    }
    localStorage.setItem("local00", JSON.stringify({ ...data, CreationDate: moment.utc().format("YYYYMMDD") }))
    const responseData = await post(TRAY_APIS.GET, {
      //  Date: moment(data.TrayDate).format("MM/DD/YYYY h:mm:ss A"),
      Date: data.TrayDate,
      LabId: data?.OwnerLaboratoryId,
      Laboratory: data?.OwnerLaboratoryId,
      TrayNumber: data.Tray,
      MinimumStep: 1,
      MaximumStep: data.NumberOfSamples ? data.NumberOfSamples : 8,
      Hold: true,
    });
    if (responseData.length === 0) {
      setIsWarningTrue(true);
      setIsInfoIcon(true);
      setIsSuccessIcon(false);
      setInfoMessage(
        `Tray ${data.Tray} for ${masterData.Laboratories?.find(
          (x) => x.LaboratoryId === data?.OwnerLaboratoryId
        )?.Laboratory
        } does NOT exist`
      );
      setCreatedTrayData([]);
      sampleCount = responseData.length;
    } else {
      sampleCount = responseData.length;
      setCreatedTrayData(responseData);
      setIsWarningTrue(false);
      setIsInfoIcon(true);
      setIsSuccessIcon(false);
      setInfoMessage(
        `Tray  ${data.Tray} for ${masterData.Laboratories?.find(
          (x) => x.LaboratoryId === data?.OwnerLaboratoryId
        )?.Laboratory
        } has ${responseData.length} samples`
      );

      dispatch({
        payload: {
          ...responseData[0],
          OwnerLaboratoryId: responseData[0].LaboratoryId,
          // TrayDate: responseData[0].Received,
          TrayDate: moment(responseData[0].TrayDate, TrayDateFormat)
            .local()
            .format("YYYY-MM-DDTHH:mm:ss"),
          useDeliveredCheck: responseData[0].Delivered !== null ? true : false,
          useDeliveredDate:
            responseData[0].Delivered !== null
              ? responseData[0].Delivered
              : moment(responseData[0].TrayDate, TrayDateFormat)
                .local()
                .format("YYYY-MM-DDTHH:mm:ss"),
          Label: responseData[0].Label,
          NumberOfSamples: 50,
          _key: new Date(),
        },
        type: EventActions.LOAD_DATA,
      });
    }
  }

  async function traySummaryClick() {
    if (data.TrayDate === "Invalid date") {
      setIsWarningTrue(true);
      setIsSuccessIcon(false);
      setIsInfoIcon(true);
      return setInfoMessage(appResources.INVALID_DATE_FORMAT_MSG);
    }

    const summary = await post(TRAY_APIS.SUMMARY, {
      ...data,
      TrayDate: data.TrayDate,
      Laboratory: data?.OwnerLaboratoryId,
      Tray: data.Tray,
    });

    setIsInfoIcon(true);
    setIsSuccessIcon(false);
    if (summary.SampleCount > 0) {
      setIsWarningTrue(false);
      setInfoMessage(
        `Tray ${data.Tray} for ${summary.OwnerLaboratory} has ${summary.SampleCount
        } samples${summary.Delivered !== null ? ", delivered " + summary.Delivered : ""
        }`
      );
    } else {
      setIsWarningTrue(true);
      setInfoMessage(
        `Tray ${data.Tray} for ${masterData.Laboratories?.find(
          (x) => x.LaboratoryId === data?.OwnerLaboratoryId
        )?.Laboratory
        } does NOT exist`
      );
    }
  }

  function addExtraSample() {
    if (data.TrayDate === "Invalid date") {
      setIsWarningTrue(true);
      setIsSuccessIcon(false);
      setIsInfoIcon(true);
      return setInfoMessage(appResources.INVALID_DATE_FORMAT_MSG);
    }
    setSelectedFunction(() => alertAddExtraSample)
    setAlertTitle("Add Extra Sample");
    setOpenAlert(true);
    setButtonText("Add");

    setAlertMessage(
      `${appResources.CREATE_TRAY_ADD_EXTRA_SAMPLE_MSG
      } ${sampleCount} on ${toLocalDayDate(data.TrayDate)} for ${masterData.Laboratories?.find(
        (x) => x.LaboratoryId === data?.OwnerLaboratoryId
      )?.Laboratory
      }?`
    );
  }
  async function alertAddExtraSample() {
    const addedSample = await post(TRAY_APIS.ADD_EXTRA_SAMPLE, {
      TrayDate: data.TrayDate,
      Laboratory: data?.OwnerLaboratoryId,
      Tray: data.Tray,
      NoofSamples: 1,
      IsTraining: data.IsTrainingSample ? data.IsTrainingSample : false,
      Delivered: data.useDeliveredCheck ? data.useDeliveredDate : null,
      Screen: "Create Trays",
    });

    if (addedSample[0].Added) {
      setIsWarningTrue(false);
      setIsSuccessIcon(true);
      setIsInfoIcon(false);
      setInfoMessage(`Sample ${addedSample[0].NumberOfSamples
        } was added to tray ${data.Tray} for
       ${masterData.Laboratories?.find(
          (x) => x.LaboratoryId === data?.OwnerLaboratoryId
        )?.Laboratory
        }`);

      sampleCount = addedSample[0].NumberOfSamples;
      onLoadGrid();
      setOpenAlert(false);
    }
  }

  function deleteLastSampleClick() {
    if (data.TrayDate === "Invalid date") {
      setIsWarningTrue(true);
      setIsSuccessIcon(false);
      setIsInfoIcon(true);
      return setInfoMessage(appResources.INVALID_DATE_FORMAT_MSG);
    }
    setSelectedFunction(() => alertDeleteLastSample)
    setAlertTitle("Delete Last Sample");
    setOpenAlert(true);
    setButtonText("Delete");
    setAlertMessage(
      `${appResources.CREATE_TRAY_DELETE_LAST_SAMPLE_MSG
      } ${sampleCount} from tray ${data.Tray} on ${toLocalDayDate(
        data.TrayDate
      )} for ${masterData.Laboratories?.find(
        (x) => x.LaboratoryId === data?.OwnerLaboratoryId
      )?.Laboratory
      }?`
    );
  }

  async function alertDeleteLastSample() {
    const deleteLastSample = await post(TRAY_APIS.DELETE_END_SAMPLE, {
      TrayDate: data.TrayDate,
      Laboratory: data?.OwnerLaboratoryId,
      Tray: data.Tray,
      UserId: user.UserId,
      Screen: "Create Trays",
    });
    if (deleteLastSample.Deleted) {
      setIsWarningTrue(false);
      setIsInfoIcon(true);
      setIsSuccessIcon(false);
      setInfoMessage(
        `Tray ${data.Tray} for ${masterData.Laboratories?.find(
          (x) => x.LaboratoryId === data?.OwnerLaboratoryId
        )?.Laboratory
        } now has ${deleteLastSample.Count} samples`
      );
      sampleCount = deleteLastSample.Count;
      onLoadGrid();
    }
    setOpenAlert(false);
  }

  function deleteTrayClick() {
    if (data.TrayDate === "Invalid date") {
      setIsWarningTrue(true);
      setIsSuccessIcon(false);
      setIsInfoIcon(true);
      return setInfoMessage(appResources.INVALID_DATE_FORMAT_MSG);
    }
    setSelectedFunction(() => alertDeleteTray)
    setAlertTitle(`Delete Tray ${data.Tray}`);
    setOpenAlert(true);
    setButtonText("Delete");
    setAlertMessage(
      `${appResources.DELETE_TRAY_CONFIRMATION_MSG} ${data.Tray
      } on ${toLocalDayDate(data.TrayDate)} for ${masterData.Laboratories?.find(
        (x) => x.LaboratoryId === data?.OwnerLaboratoryId
      )?.Laboratory
      }?`
    );
  }
  async function alertDeleteTray() {
    const deleteData = await post(TRAY_APIS.DELETE_TRAY, {
      TrayDate: data.TrayDate,
      Laboratory: data?.OwnerLaboratoryId,
      Tray: data.Tray,
      UserId: user.UserId,
      Screen: "Create Trays",
    });

    if (deleteData.Deleted) {
      setIsWarningTrue(false);
      setIsSuccessIcon(true);
      setIsInfoIcon(false);
      setInfoMessage(
        `Tray ${data.Tray} for ${masterData.Laboratories?.find(
          (x) => x.LaboratoryId === data?.OwnerLaboratoryId
        )?.Laboratory
        } has been DELETED`
      );

      setCreatedTrayData([]);
      sampleCount = 0;
      dispatch({
        type: FormActions.RESET_FORM,
        payload: { _key: new Date() },
      });
    }
    setOpenAlert(false);
  }

  function training() {
    setSelectedFunction(() => alertTraining)
    setAlertTitle("Training Tray");
    setOpenAlert(true);
    setButtonText("Yes");
    setAlertMessage(appResources.CREATE_TRAY_TRAINING_MSG);
  }
  async function alertTraining() {
    setOpenAlert(false);
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ p: 1 }}>

            <Grid item container alignItems="flex-end">
              <Grid item xs={12} sx={{ mt: 1 }}>
                <Form
                  sections={FormSections(
                    data,
                    trayDateSorted,
                    infoMessage,
                    isInfoIcon,
                    isSuccessIcon,
                    isWarningTrue,
                    minDateAllowed,
                    CanDoDataMaintenance
                  )}
                  data={data}
                  masterData={masterData}
                  dispatch={dispatch}
                  errors={formErrors}
                  // key={data.sampleNumber}
                  key={data._key}
                />
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Grid container spacing={2} justifyContent="flex-end">
                  {trayDateSorted > tomorrow && (
                    <Grid item sx={{ display: "flex" }}>
                      <ReportGmailerrorredIcon
                        sx={{
                          fontSize: "24px",
                          color: "red",
                          mt: "1px",
                          mr: "2px",
                        }}
                      />
                      <Typography sx={{ color: "red", mt: "5px" }}>
                        Registration Date is in the FUTURE
                      </Typography>
                    </Grid>
                  )}
                  {CanCreateTrays &&
                    data.Label?.trim() &&
                    data.Tray &&
                    data.NumberOfSamples && (
                      <Grid item>
                        <Button
                          onClick={createTrayClick}
                          disabled={((CanDoDataMaintenance !== true && trayDateSorted < minDateAllowed) || (trayDateSorted > maxDateAllowed))}
                          startIcon={
                            <CreateNewTrayIcon sx={{ marginTop: "5px" }} />
                          }
                        >
                          Create Tray (F1)
                        </Button>
                      </Grid>
                    )}
                  <Grid item>
                    <Button
                      onClick={viewTrayClick}
                      startIcon={<ViewTrayIcon sx={{ marginTop: "5px" }} />}
                      disabled={!data.Tray}
                      variant={"outlined"}
                    >
                      View Tray (F3)
                    </Button>
                  </Grid>
                  {CanCreateTrays && createdTrayData.length > 0 && (
                    <Grid item>
                      <Button
                        onClick={updateLabelClick}
                        startIcon={<UpdateIcon />}
                        disabled={!data.Label?.trim()}
                        variant={"outlined"}
                      >
                        Update (F4)
                      </Button>
                    </Grid>
                  )}
                  <Grid item>
                    <Button
                      onClick={traySummaryClick}
                      startIcon={<TraySummaryIcon sx={{ marginTop: "5px" }} />}
                      disabled={!data.Tray}
                      variant={"outlined"}
                    >
                      Tray Summary (F2)
                    </Button>
                  </Grid>
                  {CanCreateTrays && createdTrayData.length > 0 && (
                    <>
                      <Grid item>
                        <Button
                          onClick={addExtraSample}
                          name="alertAddExtraSample"
                          startIcon={
                            <AddExtraSampleIcon sx={{ marginTop: "5px" }} />
                          }
                          disabled={sampleCount < 1}
                          variant={"outlined"}
                        >
                          Add Extra Sample (F10)
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={deleteLastSampleClick}
                          name="alertDeleteLastSampleClick"
                          startIcon={
                            <DeleteTrayIcon sx={{ marginTop: "5px" }} />
                          }
                          disabled={sampleCount < 1}
                          variant={"outlined"}
                        >
                          Delete Last Sample (F11)
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={deleteTrayClick}
                          name="alertDeleteTrayClick"
                          startIcon={
                            <DeleteTrayIcon sx={{ marginTop: "5px" }} />
                          }
                          disabled={sampleCount < 1}
                          variant={"outlined"}
                        >
                          Delete Tray (F12)
                        </Button>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Total Records: <b> {sampleCount}</b>
          </Typography>
          <ResizePanel direction="s" style={{ paddingBottom: "10px" }}>
            <CreateTrayTable data={createdTrayData} />
          </ResizePanel>
        </Grid>
      </Grid>

      <AppDialog
        open={openAlert}
        title={alertTitle}
        maxWidth="md"
        handleClose={() => {
          setOpenAlert(false);
        }}
      >
        <ConfirmModal
          handleClose={() => {
            setOpenAlert(false);
            dispatch({ type: FormActions.INPUT_CHANGED, payload: { name: "IsTrainingSample", value: false } })
          }}
          alertAction={selectedFunction}
          message={alertMessage}
          buttonText={buttonText}
          buttonText2={"Cancel"} />
      </AppDialog>
    </>
  );
}

const FormSections = (
  data,
  trayDateSorted,
  infoMessage,
  isInfoIcon,
  isSuccessIcon,
  isWarningTrue,
  minDateAllowed,
  CanDoDataMaintenance
) => {
  return [
    {
      fields: [
        {
          group: [
            {
              name: "useDeliveredCheck",
              label: "Delivered",
              type: "switch",
              xsCol: 1,
            },
            {
              name: "useDeliveredDate",
              label: "Use Delivered",
              type: "datetime",
              xsCol: 3,
              disabled: (data) => !data.useDeliveredCheck,
              disableFuture: true,
            },
            {
              name: "OwnerLaboratoryId",
              label: "Owner",
              type: "dropdown",
              xsCol: 2,
              required: true,
              optionConfigs: {
                name: "Laboratories",
                textProp: "Laboratory",
                valProp: "LaboratoryId",
              },
            },
            {
              xsCol: 6,
              alignSelf: "center",
              component: function () {
                return (
                  <Typography sx={{ mt: 1, fontSize: 11 }}>
                    {isInfoIcon && (
                      <Box
                        component="div"
                        sx={{ display: "inline-flex", alignItems: "center" }}
                      >
                        {<InfoIcon />}
                        <Box
                          component="div"
                          sx={{ ml: 1, color: isWarningTrue ? "red" : "" }}
                        >
                          {infoMessage}
                        </Box>
                      </Box>
                    )}
                    {isSuccessIcon && (
                      <Box
                        component="div"
                        sx={{ display: "inline-flex", alignItems: "center" }}
                      >
                        {<TickGreenIcon />}
                        <Box component="div" sx={{ ml: 1 }}>
                          {infoMessage}
                        </Box>
                      </Box>
                    )}
                  </Typography>
                );
              },
            },
          ],
        },
        {
          group: [
            {
              name: "IsTrainingSample",
              label: "Training",
              type: "switch",
              xsCol: 1,
            },
            {
              name: "TrayDate",
              label: "Create Tray For",
              type: "date",
              sx: {
                [`.MuiInputBase-root`]: {
                  backgroundColor: ((CanDoDataMaintenance !== true && trayDateSorted < minDateAllowed) || (trayDateSorted > maxDateAllowed)) && "red",
                  color: ((CanDoDataMaintenance !== true && trayDateSorted < minDateAllowed) || (trayDateSorted > maxDateAllowed)) && "white",
                },
              },
              maxDate: maxDateAllowed,
              minDate: CanDoDataMaintenance !== true ? minDateAllowed : null,
              xsCol: 3,
              arrowCheck: true,
            },
            {
              name: "Tray",
              label: "Tray",
              type: "number",
              min: "1",
              max: "999",
              length: "3",
              xsCol: 2,
            },
            {
              name: "Label",
              label: "Tray Name",
              sx: {
                [`.MuiInputBase-root`]: {
                  backgroundColor: !data.Label && "red",
                  color: !data.Label && "white",
                },
              },
              xsCol: 2,
            },
            {
              name: "NumberOfSamples",
              label: "No. of Samples",
              type: "number",
              min: "1",
              max: "99",
              xsCol: 2,
            },
          ],
        },
      ],
    },
  ];
};
