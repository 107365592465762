import { Button, Card, Grid, Typography } from '@mui/material'
import React, { useCallback, useEffect } from 'react'
import { SectionTitle } from '../../components/Form'
import useFetch from '../../hooks/useFetch';
import { MANAGEMENT_APIS } from '../../common/apis';

function MatchMachinesInsite() {

    const { get, } = useFetch();

    const handleClick = async () => {
        get(`${MANAGEMENT_APIS.MATCH_MACHINE_INSITE} `);
    }

    const handleCloseClick = () => {
        window.close();
    }

    const handleKeyDown = useCallback(
        (event) => {
            if (event.key === "F1") {
                event.preventDefault();
                handleCloseClick()
            }
            if (event.key === "F12") {
                event.preventDefault();
                handleClick();
            }
        },
        []);

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown]);

    return (
        <Card sx={{ p: 1, height: "300px", position: "relative" }}>
            <Grid
                container
                spacing={2}
            >
                <Grid item xs={12} >
                    <SectionTitle title="Match to Insite" />
                </Grid>
                <Grid item xs={12} mt={2} >
                    <Typography>Match CMS units with the Insite Database.</Typography>
                </Grid>
                <Grid item xs={12} mt={2} >
                    <Button onClick={handleClick}>Match Data (F12)</Button>
                </Grid>

                <Grid item xs={12} alignSelf={"end"} sx={{ position: 'absolute', bottom: 10, right: 10 }} >
                    <Button variant="outlined" onClick={handleCloseClick}>Close (F1)</Button>
                </Grid>
            </Grid>
        </Card>
    )
}

export default MatchMachinesInsite