import { memo } from "react";
import DataTable from "../../../components/DataTable";

export default memo(function HistoryTable({ columns, data }) {
  const options = {
    opacityCheck: true,
    columns: columns || [],
    dataSource: data || [],
    rowIdProperty: "SampleNumber",
    isDisableColumnFilter: true,
    rowStylingHandler: "resultSample",
    cellStylingHandler: "sampleBackGroundWhite",
    minHeight: 40,
    heightValue: 100,
    sxTable: {
      [`.MuiDataGrid-columnHeader`]: { backgroundColor: "#c0c0c0" },
    },
  };
  return <DataTable {...options} />;
});
