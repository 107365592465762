import { useState } from "react";
import NewDataTable, { NewDataGridAction } from "../../../components/NewDataTable";
import renderCellExpand from "../../../components/DatatableCellExpand";

export default function PaperlessTable({ actions, data, rowsSelected, finishCheck, ...props }) {

    const [rowSelection, setRowSelection] = useState({});

    const options = {
        columns: Columns1(actions, finishCheck) || [],
        dataSource: data || [],
        getRowId: (originalRow) => originalRow?.keyProp,
        sx: { height: "72dvh" },
        onRowSelectionChange: setRowSelection,
        state: { rowSelection },
        enableEditing: false,
        enableSorting: false,
        enableColumnFilters: false,
        enableColumnActions: false,
        muiTableBodyProps: {
            sx: (theme) => ({
                '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
                    { backgroundColor: "inherit" },
                '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
                    { backgroundColor: "inherit" },
                '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
                    { backgroundColor: "#e2f2ff" },
                // backgroundColor: "inherit",//
                '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
                    { backgroundColor: "#e2f2ff" },
            }),
        },
        muiTablePaperProps: (a) => {
            return {
                sx: {
                    border: "1px solid #253FC8",
                    borderRadius: "5px",
                    padding: "2px",
                    [`.MuiTableRow-root:hover td`]: { backgroundColor: "#7030a0 !important", color: "white" },
                }
            }
        },
        muiTableBodyRowProps: ({ row }) => ({
            //implement row selection click events manually
            onClick: () => {
                setRowSelection((prev) => ({
                    [row?.original?.keyProp]: !prev[row?.original?.keyProp],
                }))
                rowsSelected(row?.original);
            },
            selected: rowSelection[row?.keyProp],
            // sx: { cursor: 'pointer', },
            sx: {
                cursor: 'pointer',
            },
        }),
    };
    return <NewDataTable {...options} />
}

const Columns1 = (actions, finishCheck) => {
    return [
        {
            header: "Sample Number",
            accessorKey: "SampleNumber",
            size: 90,
        },
        {
            header: "Bottle Number",
            accessorKey: "BottleNumber",
            size: 75,
        },
        {
            header: "Registration ",
            accessorKey: "RegistrationId",
            maxSize: 65,
            size: 65,
        },
        {
            header: "Customer",
            accessorKey: "Customer",
            Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
            size: 120,
        },
        {
            header: "Site",
            accessorKey: "Site",
            Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
            size: 120,
        },
        {
            header: "Unit",
            accessorKey: "Unit",
            Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
            size: 90,
        },
        {
            header: "Model",
            accessorKey: "Model",
            Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
            size: 100,
        },
        {
            header: "Serial",
            accessorKey: "Serial",
            maxSize: 80,
            size: 80,
            Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
        },
        {
            header: "Component",
            accessorKey: "Component",
            Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
            size: 100,
        },
        {
            header: "Meter Reading",
            accessorKey: "UnitMeter",
            maxSize: 75,
            size: 75,
        },
        // {
        //     header: "MeterReading",
        //     accessorKey: "MeterReading",
        //     maxSize: 75,
        //     size: 75,
        // },
        {
            header: "Oil Type",
            accessorKey: "OilType",
            size: 50,
        },
        {
            header: "Scanned Date",
            accessorKey: "ScannedDate",
            size: 70,
            // Cell: ({ renderedCellValue }) => toLocalDate(renderedCellValue),
            Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
        },
        {
            header: "Laboratory",
            accessorKey: "Laboratory",
            size: 80,
        },
        // {
        //     header: "Tray",
        //     accessorKey: "TrayNumber",
        //     maxSize: 50,
        //     size: 50,
        // },
        {
            Cell: ({ row }) => (<>
                <NewDataGridAction
                    label={"Clear"}
                    disabled={!(row?.original?.BottleNumber > 0) || finishCheck}
                    onClick={() => actions.handleClearAlert(row?.original)}
                />
                <NewDataGridAction
                    label={"Delete"}
                    disabled={finishCheck || row?.original?.Step > 1}
                    sx={{ color: "red", border: "1px solid red", ml: "3px" }}
                    onClick={() => actions.handleDeleteAlert(row?.original)}
                />
            </>
            ),
            header: "Actions",
            size: 120,
        },
    ]
};
