import { useMsal } from "@azure/msal-react";
import { useBlockScreen, useErrorBox } from "../rootContext";
import { saveAs } from "file-saver";
import moment from "moment";
import { loginRequest } from "../authConfig";
import { useNavigate } from "react-router-dom";

export default function useFetch(baseUrl = window.BasePath) {
  const request = useRequest();
  return {
    get: (path, options = {}) => {
      return request(baseUrl + path, {
        options,
      });
    },
    post: (path, data, options = {}) => {
      return request(
        baseUrl + path,
        {
          options,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify(data),
        }
      );
    },
    getFile: (path, fileName, options = {}) => {
      return request(baseUrl + path, {
        options,
        isFile: true,
        fileName,
      });
    },
    postFile: (path, data, fileName, options = {}) => {
      return request(
        baseUrl + path,
        {
          options,
          isFile: true,
          fileName,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify(data),
        }
      );
    },
  };
}

function useRequest() {
  const { block, unblock } = useBlockScreen();
  const navigate = useNavigate();
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const showError = useErrorBox();

  return function (url, { options, isFile, fileName }, reqOptions = {}) {
    return new Promise(async (resolve, reject) => {
      !options.skipBlocker && block();

      let token = "";
      try {
        token = await acquireTokenCustom({ instance, account });
      } catch (error) {
        if (error.status === 404) {
          !options.skipBlocker && unblock();
          navigate("user-not-found");
          resolve();
          return;
        }
      }

      fetch(url, {
        method: reqOptions.method,
        headers: {
          ...reqOptions.headers,
          Authorization: "bearer " + token,
        },
        body: reqOptions.body,
      })
        .then(function (resp) {
          if (resp.ok) {
            if (resp.status === 204) {
              resolve(null);
              return;
            }
            if (isFile) {
              const removedDot = fileName.replaceAll(".", "");
              resp.blob().then(function (blob) {
                saveAs(
                  blob,
                  ((removedDot && `${removedDot} `) || "") +
                  moment().format("YYYY-MM-DD HH-mm")
                );
                resolve(null);
              });
              return;
            }
            resp.json().then(function (data) {
              resolve(data);
            });
            return;
          } else if (resp.status === 401) {
            showError(
              "Your session has been timed out please refresh this page by pressing Ctrl+R."
            );
            resolve(null);
            return;
          } else if (resp.status === 400 || resp.status === 500) {
            resp
              .json()
              .then(function (data) {
                // showError(
                //   // data.Message ||  to only show following message
                //   "Something went wrong please contact the system administrator."
                // );
              })
              .catch((e) => {
                // showError(
                //   "Something went wrong please contact the system administrator."
                // );
              });
            resolve(null);
            return;
          }
          // showError(
          //   "Something went wrong please contact the system administrator. status code:" +
          //   resp.status
          // );
          resolve(null);
        })
        .catch((e) => {
          reject(e);
          // showError(
          //   "Something went wrong please contact the system administrator."
          // );
        })
        .finally(() => {
          !options.skipBlocker && unblock();
        });
    });
  };
}

const USER_TOKEN_KEY = "user-token";
async function acquireTokenCustom({ instance, account }) {
  let tokenData = localStorage.getItem(USER_TOKEN_KEY);
  try {
    if (tokenData) {
      const tokenObj = JSON.parse(tokenData);
      if (
        tokenObj &&
        tokenObj.token &&
        tokenObj.ets &&
        tokenObj.ets > Date.now()
      ) {
        return tokenObj.token;
      }
    }
  } catch { }

  //token not found or expired

  //get azure active directory provided token
  const aadTokenResp = await instance.acquireTokenSilent({
    account: account,
    scopes: loginRequest.scopes,
  });

  // refresh token using azure active directory provided token
  const tokenUrl = window.BasePath + "/account/refresh-token";
  const resp = await fetch(tokenUrl, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ AccessToken: aadTokenResp.accessToken }),
  });
  if (resp.ok) {
    //save and return token
    const token = await resp.json();
    token.ets = Date.parse(token.expiration) - 10000;
    localStorage.setItem(USER_TOKEN_KEY, JSON.stringify(token));
    return token.token;
  }
  throw resp;
}
