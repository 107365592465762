import { Button, Card, Grid, Typography } from "@mui/material";
import moment from "moment/moment";
import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { DETAILS_APIS, TRAY_APIS } from "../../../common/apis";
import { LabSteps, ResultColWidths } from "../../../common/Constants";
import DropDown from "../../../components/DropDown";
import { BadgeButton } from "../../../components/FormFooter";
import SwitchInput from "../../../components/SwitchInput";
import TextBox from "../../../components/TextBox";
import useFetch from "../../../hooks/useFetch";
import useMasterData from "../../../hooks/useMasterData";
import { useRoot, useUser } from "../../../rootContext";
import { DateFormat, copyTextToClipboard, toLocalDate } from "../../../utils";
import ResultsTable from "./ResultsTable";
import SamplesTable from "./SamplesTable";
import SearchFilter from "./SearchFilter";
import AppDialog from "../../../components/AppDialog";
import NewVersion from "./NewVersion";
import { ChartsIcon, NotesIcon, ProvidedLimitIcon, } from "../../../icons/ButtonIcons";
import SelectImage from "../../../components/SelectImage";
import { useScreenPopups } from "../../../components/useScreenPopups";
import GroupDialog from "../../../components/GroupDialog";
import ResizePanel from "react-resize-panel";
import { DataGridAction } from "../../../components/DataTable";
import DropdownMenu from "../../../components/DropdownMenu";
import appResources from "../../../app-resources/en/Resources.json";
import QuickDetailsTree from "../../details-old/QuickDetailsTree";
import renderCellExpand from "../../../components/DatatableCellExpand";
import { WindowTypes, useWindowPopups } from "../../../components/useWindowPopups";
import Comments from "./Comments";
import { FormActions } from "../../../components/Form";
import { EventActions } from "../../management/sample-report-overview/SampleReportsOverviewForm";
import CommentsTop, { EvaluationDD, RecommendationDD } from "./CommentsTop";

let SampleRow = "";
let isChartAlreadyOpen = false;

export default function Evaluation({ evaluationActions }) {

  const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  const windowFeatures = "width=" + windowWidth + ",height=" + windowHeight + ",top=0,left=0,resizable,scrollbars=yes";

  const { post, get, getFile } = useFetch();
  const { getPresetComments, getMasterData } = useMasterData();
  const { CanEvaluate } = useRoot();
  const user = useUser();
  const [commentsData, dispatchComments] = useReducer(CommentsReducer, CommentsDefaults);

  const [historyComments, setHistoryComments] = useState({});
  const [selectedRowType, setSelectedRowType] = useState("");
  const [selectedSample, setSelectedSample] = useState(0);
  const [selectedHistSample, setSelectedHistSample] = useState({});
  const [samplesData, setSamplesData] = useState([]);
  const [samplesMoreData, setSampleMoreData] = useState({});
  const [history, setHistory] = useState({});
  const [commentOptions, setCommentOptions] = useState({});
  const [masterData, setMasterData] = useState({});
  const [selectedSampleRowData, setSelectedSampleRowData] = useState();
  const [newVersionOpen, setNewVersionOpen] = useState(false);
  const [dialogType, setDialogType] = useState({ open: false });
  const [imagesOpen, setImagesOpen] = useState(false);
  const [isRoleValid, setIsRoleValid] = useState(false);
  const [isHistorical, setIsHistorical] = useState(true);
  const [isHasExternalFactors, setIsHasExternalFactors] = useState(false);
  const [isDoQuickExport, setIsDoQuickExport] = useState(false);
  const [filterData, setFilterData] = useState();
  const [showTree, setShowTree] = useState(false);
  const [expandTreeData, setExpandTreeData] = useState(null);
  const [infoMessage, setInfoMessage] = useState();
  const [isInfoIcon, setIsInfoIcon] = useState(false);
  const [isWarningTrue, setIsWarningTrue] = useState(false);
  const [viewType, setViewType] = useState(0);
  const [viewTypeResults, setViewTypeResults] = useState(0);
  const [simplifyHistory, setSimplifyHistory] = useState(true);
  const [selectedRowId, setSelectedRowId] = useState(0);
  const [vselectedRowId, setVSelectedRowId] = useState(0);
  const [recommendationCommentId, setRecommendationCommentId] = useState(null)
  const [evaluationCommentId, setEvaluationCommentId] = useState(null)
  const [alertRankState, setAlertRankState] = useState();
  const [alertIdState, setAlertIdState] = useState();
  const [regBoxCheck, setRegBoxCheck] = useState(false);
  const [tick, setTick] = useState(false);

  const alertStatusesList = useMemo(
    function () {
      const list = (masterData.AlertStatuses || [])
        .filter((x) => x.ProductId === selectedSample.ProductId)
        .map((x) => ({
          value: x.AlertRank,
          text: x.AlertStatus,
          id: x.AlertStatusId,
        }))
      return list;
    }, [selectedSample, masterData.AlertStatuses]
  );

  //set value of alert status id dropdown
  function handleAlertIdStatus(e) {

    setAlertRankState(e.target.value);
    let val = alertStatusesList.filter((x) => x.value === e.target.value);
    if (val?.length > 0) {
      val = val[0]?.id;
      setAlertIdState(val);
    }

    if (e.target.value === 1 && commentsData?.Evaluation === null) {
      let { Evaluation, Recommendation, CustomerNotes, SiteNotes, UnitNotes, ComponentNotes, CustomerComments, SampleNotes } = getCurData();
      if (Evaluation === null) {
        Evaluation = "Readings appear to be satisfactory, keep sampling at the normal rate to monitor further.";
      }
      dispatchComments({
        type: EventActions.LOAD_DATA,
        payload: { Evaluation, Recommendation, CustomerNotes, SiteNotes, UnitNotes, ComponentNotes, CustomerComments, SampleNotes },
      });
    }
  }

  function isHistoricalOnChangeHandler(e) {
    setIsHistorical(e.target.checked);
  }
  function isHasExternalFactorsOnChangeHandler(e) {
    setIsHasExternalFactors(e.target.checked);
  }
  function isDoQuickExportOnChangeHandler(e) {
    setIsDoQuickExport(e.target.checked);
  }

  function handleHoldClick() {
    handleHoldClickNewWindow()
  }
  function handleNewVersionClick() {
    setNewVersionOpen(true);
  }
  function newVersionAddedToast() {
    setIsWarningTrue(false);
    setIsInfoIcon(false);
    refreshTable();
  }

  const handleViewType = (e) => {
    setViewType(e.target.value);
    localStorage.setItem("evalViewType", e.target.value);
  };
  const handleViewTypeResults = (e) => {
    setViewTypeResults(e.target.value);
    localStorage.setItem("evalViewTypeResults", e.target.value);
  };

  function handelViewTypeChange(e) {
    e.target.name === "viewType" && handleViewType(e);
    e.target.name === "viewTypeResults" && handleViewTypeResults(e);
  }

  function onSimplifyHistoryChange(e) {
    setSimplifyHistory(e.target.checked);
  }

  const onViewHandler = useCallback(async function (filter, tableIndex) {

    if (filter.Date === "Invalid date") {
      //  setIsSuccessIcon(false);
      setIsWarningTrue(true);
      setIsInfoIcon(true);

      return setInfoMessage(appResources.INVALID_DATE_FORMAT_MSG);
    }
    const query = filter.myFluids ? "?byUser=true" : "";
    const resp = await post(TRAY_APIS.SEARCH_EVALUATIONS + query, {
      Date: filter.LabAndDateOption === 3 || filter.LabAndDateOption === 5 ? null : moment(filter.Date).format(DateFormat),
      LabId: filter.LabAndDateOption === 4 || filter.LabAndDateOption === 5 ? null : user.DefaultLaboratoryId,
      TrayNumber: filter.LabAndDateOption === 0 ? filter.Tray : null,
      MinimumStep: LabSteps.Evaluate,
      MaximumStep: filter.LabAndDateOption === 0 ? LabSteps.Completed : LabSteps.Evaluate,
      Hold: false,
    });

    const sampleData = resp?.map((x, i) => ({ ...x, keyProp: i, }));

    let i = 0

    if (filter?.sampleNo > 0) {
      const indexCheck = sampleData.findIndex(item => item.SampleNumber === filter.sampleNo);
      if (indexCheck > -1) {
        i = indexCheck;
      }
      delete filter.sampleNo
    }
    else { i = tableIndex }

    setFilterData(filter);

    if (sampleData?.length < 1) {
      setIsWarningTrue(true);
      setIsInfoIcon(true);
      setInfoMessage(appResources.NO_SAMPLES_WERE_FOUND_MSG);
    } else {
      setIsWarningTrue(false);
      setIsInfoIcon(false);
      setInfoMessage();
    }

    if (sampleData.length > 0) {
      if (sampleData.length > 0 && (i > 0 && i < sampleData.length)) {
        SampleRow = sampleData[i];
        setSelectedSampleRowData(sampleData[i]);
        setSelectedRowId(i)
        setVSelectedRowId(i)

        if (sampleData[i]?.HasExternalFactors) {
          setIsHasExternalFactors(true);
        }
        setIsHasExternalFactors();
        setSamplesData(sampleData);
        setSelectedSample(sampleData[i]);
        sampleRowSelectedHandler(sampleData[i]);

        setAlertRankState(sampleData[i].AlertRank);
        setAlertIdState(sampleData[i].AlertStatusId);
        //load chart on first row select default
        if (sampleData?.length > 0 && isChartAlreadyOpen === false) {
          isChartAlreadyOpen = true;
          localStorage.removeItem("isCloseWindow");
          localStorage.setItem("isCloseWindow", false);
          localStorage.setItem("chartsData", JSON.stringify({ EqpMachineId: sampleData[i]?.EqpMachineId || 0, sampleId: sampleData[i]?.SampleNumber || 0, hideProp: 1 }));
          const url = "/Chart";
          window.open(url, "_blank", windowFeatures);
        }
      }
      if (sampleData.length > 0 && (i < 1 || i >= sampleData.length || i === undefined)) {
        setSelectedRowId(0)
        setVSelectedRowId(0)
        SampleRow = sampleData[0];
        setSelectedSampleRowData(sampleData[0]);
        if (sampleData[0]?.HasExternalFactors) {
          setIsHasExternalFactors(true);
        }
        setIsHasExternalFactors();
        setSamplesData(sampleData);
        setSelectedSample(sampleData[0]);
        sampleRowSelectedHandler(sampleData[0]);

        setAlertRankState(sampleData[0].AlertRank);
        setAlertIdState(sampleData[0].AlertStatusId);
        //load chart on first row select default
        if (sampleData?.length > 0 && isChartAlreadyOpen === false) {
          isChartAlreadyOpen = true;
          localStorage.removeItem("isCloseWindow");
          localStorage.setItem("isCloseWindow", false);
          localStorage.setItem("chartsData", JSON.stringify({ EqpMachineId: sampleData[0]?.EqpMachineId, sampleId: sampleData[0]?.SampleNumber, hideProp: 1 }));

          const url = "/Chart";
          window.open(url, "_blank", windowFeatures);
        }
      }
    } else {
      setSamplesData();
      setSelectedSample(0);
    }

    if (!sampleData.length) sampleRowSelectedHandler({});
  }, [user.DefaultLaboratoryId]);

  const sampleRowSelectedHandler = (sample) => {
    SampleRow = sample;
    setRecommendationCommentId(null)
    setEvaluationCommentId(null)

    if (sample?.keyProp !== undefined) {
      setSelectedRowId(sample?.keyProp || 0)
    }
    if (sample?.HasExternalFactors) {
      setIsHasExternalFactors(true);
    }
    if (sample?.Step > 1 && showTree === true) {
      setExpandTreeData({
        ProductId: sample?.ProductId,
        StartLetter: sample?.StartLetter,
        CustomerId: sample?.CustomerId,
        SiteId: sample?.SiteId,
        UnitId: sample?.UnitId,
        ComponentId: sample?.ComponentId,
        SampleNumber: sample?.SampleNumber,
        refreshCheck: true,
      });
    }

    if (sample.SampleNumber && sample.SampleNumber === selectedSample.SampleNumber) {
      return;
    }
    setHistory({})
    setSelectedHistSample({})
    setSelectedSampleRowData(sample);
    setAlertRankState(sample.AlertRank);
    setAlertIdState(sample.AlertStatusId);
    refreshSampleData(sample);
  }

  async function refreshSampleData(sample) {
    // await loadMoreData(sample && sample.SampleNumber);
    await loadHistory(sample && sample);
    const windowCheck = JSON.parse(localStorage.getItem("isCloseWindow"));

    setSelectedSample(sample || {});
    setSelectedRowType("sample");

    if ((windowCheck == true || windowCheck == null) && sample.SampleNumber > 0) {
      localStorage.setItem("isCloseWindow", false);
      localStorage.setItem("chartsData", JSON.stringify({ EqpMachineId: sample?.EqpMachineId, sampleId: sample.SampleNumber, hideProp: 1 }));
      const url = "/Chart";
      window.open(url, "_blank", windowFeatures);
      window.dispatchEvent(new Event('storage'))
    }

    if (windowCheck == false) {
      localStorage.setItem("isCloseWindow", false);
      localStorage.setItem("chartsData", JSON.stringify({ EqpMachineId: sample?.EqpMachineId || 0, sampleId: sample?.SampleNumber || 0, hideProp: 1 }));
      return window.dispatchEvent(new Event('storage'))
    }
  }

  const sampleHistoryRowSelectedHandler = (sample) => {
    if (isHistorical) {
      setSelectedHistSample(sample);
      setSelectedRowType("history");
    }
  };

  async function releaseHandler(e) {
    const data = {
      SampleNumber: selectedSample.SampleNumber,
      ...commentsData,
      AlertStatusId: alertIdState ?? null,
      HasExternalFactors: isHasExternalFactors ?? false,
      DoQuickExport: isDoQuickExport ?? false,
      Evaluator: user.Username,
    };

    const response = await post(TRAY_APIS.EVALUATE_SAMPLE, data);
    let i = Number(selectedRowId) || 0;
    const resp = document.querySelectorAll('[data-id="' + i + '"]')[0]?.nextElementSibling?.getAttribute("data-id")
    if (resp > 0) {
      onViewHandler(filterData, resp);
    }
    else {
      refreshTable();
    }
  }

  async function loadMoreData(sampleNo, force) {
    if (!sampleNo || (samplesMoreData[sampleNo] && !force)) return;

    const resp = await get(`${TRAY_APIS.NOTES_AND_IMG_COUNT}?sampleNo=${sampleNo}`);
    if (resp) {
      setSampleMoreData({ ...samplesMoreData, [resp.SampleNumber]: resp });
    }
  }

  async function loadHistory(sampleNo) {
    if (
      typeof sampleNo === "undefined" ||
      sampleNo === 0 ||
      Object.keys(sampleNo).length === 0
    ) {
      setHistory({});
      return;
    }

    const sampleData = await get(`${TRAY_APIS.HISTORY}?sampleNo=${sampleNo.SampleNumber}&includeLaterSamples=true&resultView=${viewTypeResults}`);

    if (sampleData.PastSamplesElementData.length > 0) {
      const temp = prepareResultsData(
        sampleData,
        evaluationActions,
        rowActionHandler,
        viewTypeResults,
      );
      setHistory(temp);
    }
    setSampleMoreData({
      ...samplesMoreData,
      [sampleData?.SampleNotesAndImageCount?.SampleNumber]:
        sampleData?.SampleNotesAndImageCount,
    });
    if (sampleNo?.historySample === true) {
      setSelectedRowType("history")
      return setSelectedHistSample(sampleData?.SampleNotesAndImageCount);
    }
    else {
      setTick(sampleData?.SampleNotesAndImageCount)
      setSelectedRowType("sample")
    };
  }

  function getChangeHandler(name) {

    return (e, value) => {
      if (value) {
        const prevVal = commentsData[name] || "";
        const alreadyAdded = prevVal && prevVal.includes(value.Comment);
        if (!alreadyAdded)
          if (name === "Recommendation") {
            setRecommendationCommentId(value?.CommentId)
          }
        if (name === "Evaluation") {
          setEvaluationCommentId(value?.CommentId)
        }
        const spc = prevVal?.length > 0 ? " " : ""
        dispatchComments({
          type: EventActions.LOAD_DATA,
          payload: { ...commentsData, [name]: prevVal + spc + value.Comment },
        });
      }
    };
  }

  function getHistoryChangeHandler(name) {
    return (e, value) => {
      if (value) {
        const prevVal = historyComments[name] || "";

        const spc = prevVal?.length > 0 ? " " : ""
        setHistoryComments({ ...historyComments, [name]: prevVal + spc + value.Comment });
      }
    };
  }

  const getCurData = useCallback(
    function () {
      if (!selectedSample.SampleNumber) return {};
      if (selectedRowType === "sample") {
        return samplesMoreData[selectedSample.SampleNumber] || {};
      }
      if (selectedRowType === "history") {
        const componentInfo = samplesMoreData[selectedSample.SampleNumber] || {};
        const { Recommendation, SampleNotes, CustomerComments, Evaluation, SampleNumber, } = selectedHistSample;
        return {
          ...componentInfo,
          Recommendation,
          CustomerComments,
          SampleNotes,
          Evaluation,
          SampleNumber,
        };
      }
      return {};
    },
    [selectedSample, selectedRowType, selectedHistSample, samplesMoreData]);

  const curData = getCurData();

  const handleRouteChange = (rows) => {
    let treeData = {
      ProductId: rows?.ProductId,
      StartLetter: rows?.StartLetter,
      CustomerId: rows?.CustomerId,
      SiteId: rows?.SiteId,
      UnitId: rows?.UnitId,
      ComponentId: rows?.ComponentId,
      SampleNumber: rows?.SampleNumber,
    };
    localStorage.setItem("treeData", JSON.stringify(treeData));
    window.open(window.location.origin + "/details/quick", "_blank")
  };

  async function saveCommentsHandler() {
    await post(TRAY_APIS.UPDATE_COMMENTS, {
      SampleNumber: selectedSample.SampleNumber,

      Evaluation: commentsData?.Evaluation,
      Recommendation: commentsData?.Recommendation,
      RegistrationNote: commentsData?.CustomerComments,
      SampleNote: commentsData?.SampleNotes,

      ComponentNote: commentsData?.ComponentNotes,
      UnitNote: commentsData?.UnitNotes,
      SiteNote: commentsData?.SiteNotes,
      CustomerNote: commentsData?.CustomerNotes,
      ScreenName: "Evaluation",
    });
    await loadMoreData(selectedSample.SampleNumber, true);
  }

  function handleChartClick() {
    HandleNewWindow("chart", selectedSample)
  }

  function FluidWatchOpenClick() {
    HandleNewWindow("fluid-watch", selectedSampleRowData)
  }

  function handleHoldClickNewWindow() {
    HandleNewWindow("hold", selectedSampleRowData)
  }

  function ImagesOpenClick() {
    HandleNewWindow("images", selectedSampleRowData)
  }

  function handleRerunSampleClick() {
    HandleNewWindow("return-to-lab", { ...selectedSampleRowData, ScreenName: "Evaluation", Option: "1", })
  }

  function reLoadViewHandler() {
    onViewHandler(filterData);
  }

  async function getSampleReport(row) {
    try {
      let fileName = "";
      if (row?.Customer === undefined) {
        let sampleNameData = await get(`${DETAILS_APIS.GET_SAMPLE_TREE_TOOLSTRIP}/?currentTreeUnitId=${row.UnitId || 0}`, { skipBlocker: true });
        fileName = `${sampleNameData[0] || ""} ${sampleNameData[1] || ""} ${sampleNameData[2] || ""} ${row?.Component || ""} ${row?.SampleNumber || ""} `;
      }
      else { fileName = `${row?.Customer || ""} ${row?.Site || ""} ${row?.Unit || ""} ${row?.Component || ""} ${row?.SampleNumber || ""} `; }
      await getFile(`${TRAY_APIS.REPORT_SAMPLE}/?sampleNo=${row?.SampleNumber}`, fileName);
    } catch (error) {
      console.error("Error fetching or opening PDF:", error);
    }
  }

  const viewMachineReport = async (data) => {
    let fileName = `${data?.Customer || ""} ${data?.Site || ""} ${data?.Unit || ""} `;
    await getFile(`${TRAY_APIS.MACHINE_REPORT}/?Machine_UnitId=${data.UnitId}`, fileName);
  };

  const HandleNewWindow = (actionName, data) => {
    useWindowPopups(actionName, data)
  }

  const handleShowInTree = (sample) => {
    setShowTree(true);
    setExpandTreeData({
      ProductId: sample?.ProductId,
      StartLetter: sample?.StartLetter,
      CustomerId: sample?.CustomerId,
      SiteId: sample?.SiteId,
      UnitId: sample?.UnitId,
      ComponentId: sample?.ComponentId,
      SampleNumber: sample?.SampleNumber,
      refreshCheck: true,
    });
  }

  const handleRouteChangeBT = (rows) => {
    let treeData = {
      ProductId: SampleRow?.ProductId,
      StartLetter: SampleRow?.StartLetter,
      CustomerId: SampleRow?.CustomerId,
      SiteId: SampleRow?.SiteId,
      UnitId: SampleRow?.UnitId,
      ComponentId: SampleRow?.ComponentId,
      SampleNumber: rows?.SampleNumber,
    };
    localStorage.setItem("treeData", JSON.stringify(treeData));
    window.open(window.location.origin + "/details/quick", "_blank")
  }

  const handleRegistrationCard = async (sif) => {
    await getFile(`${TRAY_APIS.REGISTRATION_CARD_REPORT}?RegistrationId=${sif}`, sif || "");
  }

  const rowActionHandler = (row, actionName) => {
    if (Object.values(WindowTypes).includes(actionName)) {
      HandleNewWindow(actionName, row)
      return;
    }
    if (actionName === "view-card") {

      if (row?.SIF > 0) {
        return handleRegistrationCard(row?.SIF)
      }
      else {
        let temp = history.data.filter(i => i.SampleNumber === row.SampleNumber)
        if (temp[0]?.SIF > 0) {
          return handleRegistrationCard(temp[0]?.SIF)
        }
      }
    }
    if (
      [
        "recent-unit-results",
        "primary-contacts-overview",
        "report-contacts",
        "website-contacts",
        "critical-log",
        "hold-management",
      ].indexOf(actionName) > -1
    ) {
      openDialog(row, actionName);
      return;
    } else if ((["edit-sample"].indexOf(actionName) > -1) && (row?.SampleNumber > 0)) {
      openDialog(row, actionName);
      return;
    }
    else if (actionName === "view-sample-report" && row?.SampleNumber > 0) {
      return getSampleReport(row);
    } else if (actionName === "view-mhr-report") {
      return viewMachineReport(row);
    } else if (actionName === "view-in-quick-details") {
      return handleRouteChange(row);
    } else if (actionName === "copy-sample-number" && row?.SampleNumber > 0) {
      return copyTextToClipboard(row?.SampleNumber);
    } else if (actionName === "show-in-tree") {
      return handleShowInTree(row);
    } else if (actionName === "view-in-quick-details-bt") {
      return handleRouteChangeBT(row, selectedSample);
    }
  }

  const dialogOptions = useScreenPopups({
    data: dialogType.data || {},
    cancel: closeDialog,
    dialogType: dialogType.type,
    reLoadViewHandler: reLoadViewHandler,
  });

  function openDialog(row, actionName) {
    setDialogType({ type: actionName, open: true, data: row });
  }

  function closeDialog() {
    setDialogType({ ...dialogType, open: false });
  }

  const handleUpArrow = () => {
    if (selectedRowId > 0) {
      const key = samplesData[selectedRowId - 1];
      if (key !== undefined) {
        sampleRowSelectedHandler(key)
      }
    }
  }

  const handleDownArrow = () => {

    if (selectedRowId < samplesData?.length) {
      const key = samplesData[selectedRowId + 1];
      if (key !== undefined) {
        sampleRowSelectedHandler(key)
      }
    }
  }

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "F4" && samplesData?.length > 0) {
        event.preventDefault();
        FluidWatchOpenClick();
      } else if (event.key === "F11") {
        event.preventDefault();
        if (isRoleValid && samplesData?.length > 0) {
          handleHoldClick();
        }
      } else if (event.key === "F12") {
        event.preventDefault();
        if (samplesData.length > 0 && isRoleValid) {
          releaseHandler();
        }
      } else if (event.key === "F3") {
        event.preventDefault();
        if (
          selectedSampleRowData?.UnitNoteCount +
          selectedSampleRowData?.ComponentNoteCount >
          0
        ) {
        }
      } else if (event.key === "F10") {
        event.preventDefault();
        if (samplesData.length > 0) {
          handleRerunSampleClick();
        }
      }
      else if (event.key === "ArrowDown" && document.activeElement.className.includes("cell") === true) {
        event.preventDefault();
        const a = document.getElementById("es1").querySelector('[aria-selected=true]').getAttribute("data-id");
        handleDownArrow()
      }
      else if (event.key === "ArrowUp" && document.activeElement.className.includes("cell") === true) {
        const a = document.getElementById("es1").querySelector('[aria-selected= true]').getAttribute("data-id");
        event.preventDefault();
        handleUpArrow()
      }
    },
    [samplesData, isRoleValid, selectedSampleRowData, selectedSample, commentsData]
  );

  useEffect(
    function () {
      loadHistory(selectedSample);
    },
    [viewTypeResults]
  );

  useEffect(
    function () {
      let { Evaluation, Recommendation, CustomerComments, SampleNotes } = getCurData();
      if (selectedSample?.SampleNumber === selectedHistSample?.SampleNumber) {
        setHistoryComments({})
      }
      else {
        setHistoryComments({ Evaluation, Recommendation, CustomerComments, SampleNotes });
      }
      setRegBoxCheck(CustomerComments?.length > 0 ? true : false)
    },
    [selectedHistSample]
  );

  useEffect(
    function () {
      let { Evaluation, Recommendation, CustomerNotes, SiteNotes, UnitNotes, ComponentNotes, CustomerComments, SampleNotes } = getCurData();
      dispatchComments({
        type: EventActions.LOAD_DATA,
        payload: { Evaluation, Recommendation, CustomerNotes, SiteNotes, UnitNotes, ComponentNotes, CustomerComments, SampleNotes },
      });
      setHistoryComments({});
      setRegBoxCheck(CustomerComments?.length > 0 ? true : false)
    },
    [selectedSample, tick]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    document.title = "Evaluation";
    if (
      user.RoleEvaluation ||
      user.RoleTrainee ||
      user.RoleDeveloper ||
      user.RoleAdministration
    ) {
      setIsRoleValid(true);
    }
  }, [user]);

  useEffect(function () {
    (async function () {
      const temp = await getPresetComments(true);
      setCommentOptions({
        evaluations: [{ CommentId: -1, Code: "", Comment: "" }, ...temp?.filter((x) => x.ForEvaluations)],
        recommendations: [{ CommentId: -1, Code: "", Comment: "" }, ...temp?.filter((x) => x.ForRecommendations)],
      });
      const mData = await getMasterData("AlertStatuses");
      setMasterData(mData);
    })();
    let view1 = localStorage.getItem("evalViewTypeResults");
    let view2 = localStorage.getItem("evalViewType");
    if (view1 !== null) {
      view1 = Number(view1);
      setViewTypeResults(view1);
    }
    if (view2 !== null) {
      view2 = Number(view2);
      setViewType(view2);
    }

    return () => {
      localStorage.removeItem("isCloseWindow");
      localStorage.setItem("isCloseWindow", true);
      window.dispatchEvent(new Event('storage'));
    }
  }, []);

  const refreshTable = async () => {
    const query = filterData.myFluids ? "?byUser=true" : "";

    const resp = await post(TRAY_APIS.SEARCH_EVALUATIONS + query, {
      Date: filterData.LabAndDateOption === 3 || filterData.LabAndDateOption === 5 ? null : moment(filterData.Date).format(DateFormat),
      LabId: filterData.LabAndDateOption === 4 || filterData.LabAndDateOption === 5 ? null : user.DefaultLaboratoryId,
      TrayNumber: filterData.LabAndDateOption === 0 ? filterData.Tray : null,
      MinimumStep: LabSteps.Evaluate,
      MaximumStep: filterData.LabAndDateOption === 0 ? LabSteps.Completed : LabSteps.Evaluate,
      Hold: false,
    });

    const resps = resp.map((x, i) => ({ ...x, keyProp: i, addi: i + Math.random(), }));
    setSamplesData(resps)
  }

  const handleStorage = async () => {
    let regData = JSON.parse(localStorage.getItem("edit-sample-resp"))
    if (regData?.SampleNumber > 0) {
      const sampleNo = regData;
      // searchRegClickHandler(false)

      loadHistory(sampleNo);
      refreshTable();
      localStorage.removeItem("edit-sample-resp");
    }

    let returnData = JSON.parse(localStorage.getItem("return-to-lab-resp"))
    if (returnData?.resp == true) {
      reLoadViewHandler();
      localStorage.removeItem("return-to-lab");
      localStorage.removeItem("return-to-lab-resp");
    }
  }

  useEffect(() => {
    window.onstorage = (e) => { handleStorage(e); };
  })

  const handleIndex1 = () => {
    setSamplesData([])
    setHistory({ ...history, "data": [] })
  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function delayedLoop() {
    var items = [1, 2, 3, 4, 5, 6];
    var delay = 1; // 1 millisecond

    for (var i = 0; i < items.length; i++) {
      await sleep(delay);
    }
  }

  const maxima = async () => {
    let a = 7;

    let selectedQuery = document.querySelector('[data-id="' + vselectedRowId + '"]');
    if (selectedQuery !== null && selectedQuery !== undefined) {
      selectedQuery.scrollIntoViewIfNeeded()
    }
    else {
      for (let i = 0; i < 7; i++) {

        const selectedQuery1 = document.querySelector('[data-id="' + a + '"]')
        if (selectedQuery1 !== null) {
          selectedQuery1.scrollIntoView();
        }
        let selectedQuery = document.querySelector('[data-id="' + vselectedRowId + '"]');

        if (selectedQuery !== null && selectedQuery !== undefined) {
          selectedQuery.scrollIntoViewIfNeeded();
          break;
        }
        a = a + 7;
        await delayedLoop();
      }
    }
  }

  useEffect(() => {
    setTimeout(
      function handleScroll() {
        if (vselectedRowId > 0) {
          maxima()
        }
      }, 500)
  }, [vselectedRowId]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card sx={{ p: 0.5 }}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Grid item xs={8}>
                <SearchFilter
                  onView={onViewHandler}
                  showTree={showTree}
                  setShowTree={setShowTree}
                  infoMessage={infoMessage}
                  isInfoIcon={isInfoIcon}
                  isWarningTrue={isWarningTrue}
                  handleIndex1={handleIndex1}
                />
              </Grid>

              <Grid item>
                <Grid container spacing={1} alignItems="flex-end">
                  {selectedSampleRowData?.UsesLimits && (
                    <Grid item>
                      <Button
                        variant="contained"
                        startIcon={<ProvidedLimitIcon />}
                      >
                        Limits
                      </Button>
                    </Grid>
                  )}
                  {samplesData?.length > 0 && (
                    <Grid item>
                      <SwitchInput
                        label="Simplify History"
                        value={simplifyHistory}
                        onChange={onSimplifyHistoryChange}
                      />
                    </Grid>
                  )}
                  {samplesData?.length > 0 && (
                    <Grid item minWidth="170px">
                      <DropDown
                        value={viewType}
                        name="viewType"
                        onChange={handelViewTypeChange}
                        selectOptions={[
                          { text: "Tray and History", value: 0 },
                          { text: "History", value: 1 },
                          { text: "Tray", value: 2 },
                        ]}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>

            </Grid>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={2} mt={1} hidden={!showTree}>
          <QuickDetailsTree
            expandTreeData={expandTreeData}
            minHeight={"79vh"}
            maxHeight={"79vh"}
          />
        </Grid>

        <Grid item xs={showTree === true ? 10 : 12}>
          <Grid item container spacing={0.5}>
            <Grid item xs={12} hidden={viewType === 1}>
              <Typography variant="subtitle2">
                Total Records:{" "}
                <b> {(samplesData && samplesData?.length) || 0}</b>
              </Typography>
              <div id="es1">
                <ResizePanel direction="s" style={{ paddingBottom: "10px" }}>
                  <SamplesTable
                    data={samplesData}
                    rowSelected={sampleRowSelectedHandler}
                    selectedRow={selectedRowId}
                    actions={evaluationActions}
                    rowActionHandler={rowActionHandler}
                  />
                </ResizePanel>
              </div>
              {/* <ResizableBox
                prop={<SamplesTable
                  data={samplesData}
                  rowSelected={sampleRowSelectedHandler}
                  selectedRow={selectedRowId}
                  actions={evaluationActions}
                  rowActionHandler={rowActionHandler}
                />}
              /> */}
            </Grid>

            {/* BUTTONS AND COMMENTS */}
            <Grid item xs={12}>
              <Card sx={{ p: 0.5 }}>
                <Grid container spacing={1}>

                  {Object.entries(historyComments)?.length > 0

                    ? <Grid item xs={12} container spacing={1} alignItems='end'>
                      {regBoxCheck &&
                        <Grid item xs={3}>
                          <TextBox
                            sx={{ "& .MuiInputBase-input": { backgroundColor: "#dda0dd" } }}
                            name="CustomerComments"
                            readOnly={true}
                            value={historyComments?.CustomerComments || ""}
                            label="Registration Comments: on report"
                            multiline
                            rows={5}
                          />
                        </Grid>}
                      <Grid item xs={(regBoxCheck === true) ? 3 : 4}>
                        <TextBox
                          sx={{ "& .MuiInputBase-input": { backgroundColor: "#dda0dd", } }}
                          name="SampleNotes"
                          readOnly={true}
                          value={historyComments?.SampleNotes || ""}
                          label="Sample Comments: internal only"
                          multiline
                          rows={5}
                        />
                      </Grid>
                      <Grid item xs={(regBoxCheck === true) ? 3 : 4}>
                        <EvaluationDD
                          id={"EvaluationId"}
                          dataList={commentOptions?.evaluations || []}
                          handleChange={getHistoryChangeHandler}
                          defaultValue={evaluationCommentId}
                        />
                        <TextBox
                          sx={{ "& .MuiInputBase-input": { backgroundColor: "#dda0dd" } }}
                          name="Evaluation"
                          readOnly={true}
                          value={historyComments?.Evaluation || ""}
                          multiline
                          rows={5}
                        />
                      </Grid>
                      <Grid item xs={(regBoxCheck === true) ? 3 : 4} >
                        <RecommendationDD
                          id={"RecommendationId"}
                          dataList={commentOptions.recommendations || []}
                          handleChange={getHistoryChangeHandler}
                          defaultValue={recommendationCommentId}
                        />
                        <TextBox
                          sx={{ "& .MuiInputBase-input": { backgroundColor: "#dda0dd" } }}
                          name="Recommendation"
                          readOnly={true}
                          value={historyComments?.Recommendation || ""}
                          multiline
                          rows={5}
                        />
                      </Grid>
                    </Grid>

                    : <Grid item xs={12}>
                      <CommentsTop
                        comments={commentsData}
                        dispatch={dispatchComments}
                        regBoxCheck={regBoxCheck}
                        commentOptions={commentOptions}

                        handleChange1={getChangeHandler}
                        defaultValue1={evaluationCommentId}
                        handleChange2={getChangeHandler}
                        defaultValue2={recommendationCommentId}
                      />
                    </Grid>
                  }

                  {/* BUTTONS SECTIONS */}
                  <Grid
                    item
                    xs={12}
                    hidden={
                      samplesData?.length === undefined ||
                      samplesData?.length <= 0
                    }
                  >
                    <Grid container spacing={0.5}>
                      {CanEvaluate && (
                        <>
                          <Grid item mt={0.75}>
                            <SwitchInput
                              name="Historical"
                              value={isHistorical}
                              label="Historical"
                              onChange={isHistoricalOnChangeHandler}
                            />
                          </Grid>
                          <Grid item mt={0.75}>
                            <SwitchInput
                              name="HasExternalFactors"
                              value={isHasExternalFactors}
                              label="Other Factors"
                              onChange={isHasExternalFactorsOnChangeHandler}
                            />
                          </Grid>
                          <Grid item mt={0.75}>
                            <SwitchInput
                              name="DoQuickExport"
                              value={isDoQuickExport}
                              label="Quick Export"
                              className={isDoQuickExport ? "red" : ""}
                              onChange={isDoQuickExportOnChangeHandler}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <DropDown
                              renderValue={(val) =>
                                ["Results", "Divided", "Adjusted"][val]
                              }
                              value={viewTypeResults}
                              name="viewTypeResults"
                              onChange={handelViewTypeChange}
                              selectOptions={[
                                { text: "Results", value: 0 },
                                { text: "Divided by Fluid Hours", value: 1 },
                                { text: "Adjusted for Topup", value: 2 },
                              ]}
                              disabled={selectedSample.FluidTypeId !== 1}
                            />
                          </Grid>
                        </>
                      )}
                      <Grid item >
                        <Typography>AlertRank</Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <DropDown
                          name="AlertStatusId"
                          placeholder="Alert Rank"
                          value={alertRankState}
                          key={selectedSampleRowData?.ProductId}
                          onChange={handleAlertIdStatus}
                          readOnly={samplesData?.length === 0 || !CanEvaluate}
                          required={true}
                          selectOptions={alertStatusesList}
                          sx={{
                            [`.MuiSelect-icon`]: { color: alertRankState > 0 ? "#fff" : "#000" },
                            [`.MuiSelect-select`]: {
                              backgroundColor:
                                alertRankState === 0
                                  ? "silver"
                                  : alertRankState === 1
                                    ? "green"
                                    : alertRankState === 2
                                      ? "DodgerBlue"
                                      : alertRankState === 3
                                        ? "orange"
                                        : alertRankState === 4
                                          ? "DeepPink"
                                          : alertRankState === 5
                                            ? "red"
                                            : samplesData?.length === 0
                                              ? ""
                                              : "",
                              color: alertRankState > 0 ? "#fff" : "#000"
                            }
                          }}
                        />
                      </Grid>

                      <Grid item>
                        <Button
                          variant={
                            selectedSampleRowData?.HasFluidWatch === true
                              ? "contained"
                              : "outlined"
                          }
                          color={
                            selectedSampleRowData?.HasFluidWatch === true
                              ? "lightseagreen"
                              : "primary"
                          }
                          onClick={FluidWatchOpenClick}
                          disabled={samplesData?.length === 0}
                        >
                          Fluid Watch (F4)
                        </Button>
                      </Grid>
                      {(CanEvaluate) && (
                        <>
                          <Grid item>
                            {selectedSampleRowData?.UnitNoteCount +
                              selectedSampleRowData?.ComponentNoteCount >
                              0 && (
                                <Button
                                  variant="outlined"
                                  startIcon={<NotesIcon />}
                                >
                                  Notes (F3)
                                </Button>
                              )}
                          </Grid>
                          {selectedSampleRowData?.Step > 6 && (
                            <Grid item>
                              <Button
                                variant="outlined"
                                disabled={samplesData?.length === 0}
                                onClick={handleNewVersionClick}
                              >
                                New Version
                              </Button>
                            </Grid>
                          )}
                          <Grid item>
                            <Button
                              variant="outlined"
                              startIcon={<ChartsIcon />}
                              onClick={handleChartClick}
                              disabled={samplesData?.length === 0}
                            >
                              Charts
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="outlined"
                              onClick={handleHoldClick}
                              disabled={samplesData?.length === 0}
                            >
                              Hold (F11)
                            </Button>
                          </Grid>
                          {selectedSampleRowData?.Step <= 6 &&
                            <Grid item>
                              <Button
                                variant="outlined"
                                color="error"
                                onClick={handleRerunSampleClick}
                                disabled={samplesData?.length === 0}
                              >
                                Rerun Sample (F10)
                              </Button>
                            </Grid>
                          }
                          {CanEvaluate && (
                            <Grid item>
                              <Button
                                variant="contained"
                                onClick={saveCommentsHandler}
                                disabled={samplesData?.length === 0 || Object.entries(historyComments)?.length > 0}
                              >
                                Save Comments
                              </Button>
                            </Grid>
                          )}
                          <Grid item>
                            <BadgeButton
                              variant="outlined"
                              badge={{
                                badgeContent: curData.SampleImageCount,
                              }}
                              label="Images"
                              onClick={ImagesOpenClick}
                              disabled={(curData.SampleImageCount || 0) < 1}
                            />
                          </Grid>

                          <Grid item>
                            <Button
                              variant="contained"
                              onClick={releaseHandler}
                              disabled={
                                !(CanEvaluate)
                                || samplesData?.length === 0
                                || selectedSampleRowData?.HoldType > 0
                                || !(alertRankState === 0 ? true : (alertRankState > 0 && commentsData?.Evaluation?.length > 3))
                              }
                            >
                              {selectedSampleRowData?.IsAutoEvaluated
                                ? (selectedSampleRowData?.Approved === null &&
                                  selectedSampleRowData?.Sampled === null)
                                  ? "Release Auto (F12)"
                                  : "Release (F12)"
                                : (selectedSampleRowData?.Approved === null &&
                                  selectedSampleRowData?.Sampled === null)
                                  ? "Release (F12)"
                                  : "Release (F12)"}
                            </Button>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>

            {/* DATA TABLE SECTION */}
            <Grid item xs={12} hidden={viewType === 2} id="eval-2-dt">
              <Typography variant="subtitle2">
                Total Records:{" "}
                <b> {(history.data && history.data?.length) || 0}</b>
              </Typography>
              <ResizePanel direction="s" style={{ paddingBottom: "10px" }}>
                <ResultsTable
                  data={history?.data}
                  rowSelected={sampleHistoryRowSelectedHandler}
                  selectedRow={selectedHistSample?.keyProp}
                  columns={simplifyHistory ? history.columnsSimple : history.columns}
                />
              </ResizePanel>
            </Grid>

            {/* COMMENTS SECTION */}
            <Grid item xs={12}>
              <Comments comments={commentsData} dispatch={dispatchComments} />
            </Grid>

          </Grid>
        </Grid >
      </Grid >
      <>
        <AppDialog
          open={newVersionOpen}
          handleClose={() => setNewVersionOpen(false)}
          title="New Version"
          maxWidth="xl"
        >
          <NewVersion
            sampleId={selectedSampleRowData && selectedSampleRowData.SampleNumber}
            setNewVersionOpen={setNewVersionOpen}
            newVersionAddedToast={newVersionAddedToast}
          />
        </AppDialog>

        <AppDialog
          open={imagesOpen}
          handleClose={() => setImagesOpen(false)}
          title="New Version"
          maxWidth="xl"
        >
          <SelectImage
            cancel={() => setImagesOpen(false)}
            sampleId={
              selectedSampleRowData && selectedSampleRowData?.SampleNumber
            }
            setImagePickerOpen={setImagesOpen}
          />
        </AppDialog>
      </>
      <GroupDialog
        {...dialogOptions}
        open={dialogType.open || false}
        close={closeDialog}
      />
    </>
  );
}

function prepareResultsData(
  { PastSamplesElementData: data, PastSamples: sampleData, TestSetSuperset, GetEventLogsBySampleNumber },
  evaluationActions,
  rowActionHandler,
  viewTypeResults
) {
  const colMap = {};
  const samplesMap = {};

  let hasSif = false;
  sampleData.forEach(function (s) {
    if (s.SIF) {
      hasSif = true;
    }
    samplesMap[s.SampleNumber] = s;
  });

  let newSampleNumber = "";
  if (SampleRow.NewVersion !== null) {
    newSampleNumber = SampleRow.NewVersion;
  }

  data.forEach((x) => {
    //for new version
    if (Object.keys(samplesMap).filter(item => item == x?.SampleNumber)?.length > 0) {
      if (newSampleNumber !== "") {
        if (samplesMap[x?.SampleNumber] !== undefined) {
          samplesMap[x?.SampleNumber][x?.TestItemCode] = x?.FormattedText || x?.FormattedNumeric || "";
          if (colMap[x?.TestItemCode]) {
            return;
          }
          colMap[x?.TestItemCode] = x;
        }
      } else {
        if (samplesMap[x?.SampleNumber] !== undefined) {
          samplesMap[x?.SampleNumber][x?.TestItemCode] = x?.FormattedText || x?.FormattedNumeric || "";
          if (colMap[x?.TestItemCode]) {
            return;
          }
          colMap[x?.TestItemCode] = x;
        }
      }
    }
  });

  const dataSrc2 = Object.keys(samplesMap).map((x,) => { return { ...samplesMap[x], } });

  let eventColumns = [];

  let events = GetEventLogsBySampleNumber;
  let dtRow = [];

  if (events !== null && events.length > 0) {
    for (const z of events) {

      if (z.EventTypeId === 3 && z.UnitHours !== undefined && z.SampleNumber !== undefined) {
        const zzz = dataSrc2.find(zz => zz.UnitHours === z.UnitHours);

        if (zzz !== undefined) {
          continue;
        }
      }
      const dr = {};
      if (z.SampleNumber !== undefined) {
        dr.SampleNumber = z.SampleNumber;
      }
      dr.Sampled = z.EventDate;
      if (z.UnitHours !== undefined) {
        dr.UnitHours = z.UnitHours;
      }
      dr.Event = z.EventType;
      if (z.EventTypeId === 3) {
        dr.FluidChanged = 'Yes';
      }
      dr.EventCode = z.EventCode;
      dtRow.push(dr);
    }
  }


  let dataSrc3 = [];
  dataSrc3 = [...dataSrc2, ...dtRow];

  eventColumns = (dtRow?.length > 0)
    ? [{
      headerName: "Event",
      field: "EventCode",// "EventType",
      width: 35,
      sortable: false,
      description: "EventType",
      renderCell: renderCellExpand,
    }]
    : []

  dataSrc3.sort(function (a, b) {
    return ((a.Sampled < b.Sampled) ? -1 : ((a.Sampled > b.Sampled) ? 1 : 0));
  }).reverse()

  const dataSrc = dataSrc3.map((item, i) => { return { ...item, keyProp: i, addi: i + Math.random(), } })

  const columns = TestSetSuperset.sort(sortFunction).map((x) => ({
    headerName: x.TestItemCode,
    field: x.TestItemCode,
    // eslint-disable-next-line eqeqeq
    minWidth: ResultColWidths[x.TestItemCode] || viewTypeResults == 0 ? (x.TestItemCode.length > 3 ? 60 : 40) : (x.TestItemCode.length > 3 ? 60 : 50),
    sortable: false,
    description: `${x.TestItemCode}: ${x.TestItem}`,
    flex: 1,
    renderCell: renderCellExpand,
  }));

  const columnsSimple = Object.keys(colMap)
    .map((x) => colMap[x])
    .sort(sortFunction)
    .map((x) => ({
      headerName: x.TestItemCode,
      field: x.TestItemCode,
      // eslint-disable-next-line eqeqeq
      minWidth: ResultColWidths[x.TestItemCode] || (viewTypeResults == 0 ? (x.TestItemCode.length > 3 ? 55 : 35) : (x.TestItemCode.length > 3 ? 55 : 45)),
      sortable: false,
      description: `${x.TestItemCode}: ${x.TestItem}`,
      flex: 1,
      renderCell: renderCellExpand,
    }));

  const extraColumns1 = [
    {
      headerName: "Sample Number",
      field: "SampleNumber",
      width: 120,
      renderCell: function ({ row }) {
        return (
          <>
            {(evaluationActions || []).map(function (ac, i) {
              return (
                <DataGridAction
                  key={i}
                  row={row}
                  onClick={ac.action}
                  label={ac.label}
                  startIcon={ac.startIcon}
                />
              );
            })}
            <DropdownMenu
              options={menuOptions}
              onItemSelect={(option) => rowActionHandler({ ...row, historySample: true }, option)}
            />
            <pre style={{ paddingTop: 0.25, fontSize: "12px" }}>{row.SampleNumber}</pre>
          </>
        );
      },
    },
    ...eventColumns,
    {
      headerName: "UH",
      field: "UnitHours",
      description: "Unit Hours",
      width: 50,
      renderCell: renderCellExpand,
    },
    {
      headerName: "CH",
      field: "ComponentHours",
      description: "Component Hours",
      width: 50,
      renderCell: renderCellExpand,
    },
    {
      headerName: "FH",
      field: "FluidHours",
      description: "Fluid Hours",
      width: 50,
      renderCell: renderCellExpand,
    },
    {
      headerName: "OC",
      field: "FluidChanged",
      description: "Fluid/Oil Changed",
      minWidth: 30,
      maxWidth: 30,
      valueFormatter: ({ value }) => (value ? "Yes" : ""),
    },
    {
      headerName: "FC",
      field: "FilterChanged",
      description: "Filter Changed",
      minWidth: 30,
      maxWidth: 30,
      valueFormatter: ({ value }) => (value ? "Yes" : ""),
    },
  ];

  const extraColumns2 = [
    {
      headerName: "Fluid",
      field: "FluidFormatted",
      description: "Topup in Litres",
      minWidth: 200,
      renderCell: renderCellExpand,
    },
    {
      headerName: "TU",
      field: "Topup",
      description: "Topup in Litres",
      width: 50,
    },
    {
      headerName: "KL",
      field: "KidneyLoop",
      description: "Kidney Loop",
      width: 50,
    },
    {
      headerName: "Sampled",
      field: "Sampled",
      valueFormatter: ({ value }) => toLocalDate(value),
    },
    {
      headerName: "Technician",
      field: "Evaluator",
      renderCell: renderCellExpand,
    },
    {
      headerName: "Registration",
      field: "SIF",
      hide: !hasSif,
    },
  ];

  return {
    columns: [...extraColumns1, ...columns, ...extraColumns2],
    columnsSimple: [...extraColumns1, ...columnsSimple, ...extraColumns2],
    data: dataSrc,
  };
}

function sortFunction(a, b) {
  return (
    a.TechnicianRank - b.TechnicianRank ||
    a.TestItemCode.localeCompare(b.TestItemCode)
  );
}

const menuOptions = [
  { label: "Copy Sample Number", name: "copy-sample-number" },
  { label: "View Sample Report", name: "view-sample-report" },
  { label: "Unit Hour History", name: "unit-hour-history" },
  { label: "Recent Unit History", name: "recent-unit-results" },
  { label: "Edit Sample", name: "edit-sample" },
  { label: "View in Quick Details", name: "view-in-quick-details-bt" },
  { label: "View Card", name: "view-card" },
];

function CommentsReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value, };
  }
  if (action.type === EventActions.LOAD_DATA) {
    return { ...action.payload, _ts_key: new Date() }
  } return state;
}

const CommentsDefaults = {
  CustomerComments: "",
  SampleNotes: "",
  Evaluation: "",
  Recommendation: "",
  ComponentNotes: "",
  UnitNotes: "",
  SiteNotes: "",
  CustomerNotes: "",
};