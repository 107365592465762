import { Button, Card, Grid, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState, } from "react";
import { DASHBOARD_APIS } from "../../../common/apis";
import useFetch from "../../../hooks/useFetch";
import { useToast } from "../../../rootContext";
import appResources from "../../../app-resources/en/Resources.json";
import NewDataTable, { NewDataGridAction } from "../../../components/NewDataTable";

export default function Cleanup() {
  const { get, getFile } = useFetch();
  const [cleanupData, setCleanUpData] = useState([]);
  const { showWarningToast } = useToast();

  useEffect(function () {
    document.title = "Cleanup Dashboard";
    getCleanUpData();
  }, []);

  async function getCleanUpData() {
    const cleanUpResponse = await get(DASHBOARD_APIS.CLEANUP);
    if (cleanUpResponse.length > 0) {
      setCleanUpData(cleanUpResponse);
    } else {
      setCleanUpData();
      showWarningToast(appResources.NO_CLEANUP_DASHBOARD_DATA_FOUND_MSG);
    }
  }

  const exportClick = (row) => {
    let fileName = "";
    switch (row?.CleanupId) {
      case 1:
        fileName = "Unmatched Komatsu Units";
        break;
      case 2:
        fileName = "Customer-Site Reorganisation";
        break;
      case 3:
        fileName = "Mismatched Fluidtypes";
        break;
      case 4:
        fileName = "Fluid Grade Formatting";
        break;
      case 5:
        fileName = "Large Unit Hours";
        break;
      case 6:
        fileName = "Dummy Samples";
        break;
      case 7:
        fileName = "Missing Component Types";
        break;
      case 8:
        fileName = "Unmatched Komatsu Models";
        break;
      case 9:
        fileName = "Unmatched Non-Komatsu Models";
        break;
      case 10:
        fileName = "Missing Component Types";
        break;
      default:
        return;
    }
    getFile(`${DASHBOARD_APIS.CLEANUP_EXPORT}?cleanupId=${row?.CleanupId}`, fileName);
  };

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "F1") {
        event.preventDefault();
        getCleanUpData()
      }
    },
    []
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);


  const columns1 = useMemo(
    () => [
      {
        id: "sendEmail",
        header: "Send Email",
        columnDefType: "display", //turns off data column features like sorting, filtering, etc.
        enableColumnOrdering: true, //but you can turn back any of those features on if you want like this
        Cell: ({ row }) => (
          <NewDataGridAction
            label={"Export"}
            // onClick={() => exportClick(row?.original?.CleanupId)}
            onClick={() => exportClick(row?.original)}
          />
        ),
        size: 45,
        maxSize: 45,
      },
      {
        accessorKey: "Cleanup",
        header: "Cleanup Task",
        size: 150,
        maxSize: 160,
      },
      {
        accessorKey: "Samples",
        header: "Total",
        size: 50,
        maxSize: 60,
        muiTableBodyCellProps: ({ row }) => {
          return {
            sx:
              row?.original?.Samples > 0
                ? { backgroundColor: "red !important", color: "white", py: 0.25, borderRight: "1px solid #E0E0E0", }
                : { py: 0.25, borderRight: "1px solid #E0E0E0", },
          };
        },
      },
      {
        accessorKey: "KOWA",
        header: "KOWA",
        size: 50,
        maxSize: 60,
        muiTableBodyCellProps: ({ row }) => {
          return {
            sx:
              row?.original?.KOWA > 0
                ? { backgroundColor: "red !important", color: "white", py: 0.25, borderRight: "1px solid #E0E0E0", }
                : { py: 0.25, borderRight: "1px solid #E0E0E0", },
          };
        },
      },
      {
        accessorKey: "KowaArchive",
        header: "KOWA Archive",
        size: 60,
        maxSize: 60,
        muiTableBodyCellProps: ({ row }) => {
          return {
            sx:
              row?.original?.KowaArchive > 0
                ? { backgroundColor: "red !important", color: "white", py: 0.25, borderRight: "1px solid #E0E0E0", }
                : { py: 0.25, borderRight: "1px solid #E0E0E0", },
          };
        },
      },
      {
        accessorKey: "Labcheck",
        header: "Labcheck",
        size: 50,
        maxSize: 60,
        muiTableBodyCellProps: ({ row }) => {
          return {
            sx:
              row?.original?.Labcheck > 0
                ? { backgroundColor: "red !important", color: "white", py: 0.25, borderRight: "1px solid #E0E0E0", }
                : { py: 0.25, borderRight: "1px solid #E0E0E0", },
          };
        },
      },
      {
        accessorKey: "LabcheckArchive",
        header: "Labcheck Archive",
        size: 60,
        maxSize: 60,
        muiTableBodyCellProps: ({ row }) => {
          return {
            sx:
              row?.original?.LabcheckArchive > 0
                ? { backgroundColor: "red !important", color: "white", py: 0.25, borderRight: "1px solid #E0E0E0", }
                : { py: 0.25, borderRight: "1px solid #E0E0E0", },
          };
        },
      },
      {
        accessorKey: "Instructions",
        header: "Instructions",
        size: 500,
        maxSize: 600,
      },
    ],
    []
  );

  const tblOptions1 = {
    columns: columns1,
    dataSource: cleanupData || [],
    enablePagination: false,
    sx: { height: 800 },
    enableColumnActions: false,
    muiTableBodyProps: {
      sx: (theme) => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "#e2f2ff" },
        // backgroundColor: "inherit",//
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "#e2f2ff" },
      }),
    },
    muiTablePaperProps: (a) => {
      return {
        sx: {
          border: "1px solid #253FC8",
          borderRadius: "5px",
          padding: "2px",
          [`.MuiTableRow-root:hover td`]: { backgroundColor: "#7030a0 !important", color: "white" },
        }
      }
    },

  };

  return (
    <>
      <Card sx={{ p: 1 }}>
        <Grid container>
          <Grid item mr={2}>
            <Button
              variant="contained"
              onClick={getCleanUpData}
            >
              View (F1)
            </Button>
          </Grid>

          <Grid item xs={6}>
            {cleanupData?.length > 1 ? (
              <Typography sx={{ fontSize: 11 }} mt={1} color="red">
                {/* Total Records: <b> {cleanupData && cleanupData.length}</b> */}
                There are {cleanupData?.length} cleanup tasks, of which ALL
                require attention
              </Typography>
            ) : cleanupData?.length === 1 ? (
              <Typography
                sx={{ fontWeight: 700, fontSize: 11 }}
                mt={1}
                color="red"
              >
                There is {cleanupData.length} cleanup task, which requires
                attention
              </Typography>
            ) : (
              <Typography
                sx={{ fontWeight: 700, fontSize: 11 }}
                mt={1}
                color="red"
              >
                There are no cleanup tasks that require attention
              </Typography>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12} mt={1}>
          <NewDataTable {...tblOptions1} />
        </Grid>
      </Card>
    </>
  );
}
