import { useEffect, useState } from "react";
import renderCellExpand from "../../components/DatatableCellExpand";
import NewDataTable from "../../components/NewDataTable";

export default function ParentsTable({ data, rowSelected, selectedRow }) {

  const [rowSelection, setRowSelection] = useState({});

  useEffect(() => {
    if (data[0]?.ParentId > 0) {
      setRowSelection((prev) => ({
        [data[0]?.ParentId]: true,
      }))
    }
  }, [data]);

  const options = {
    columns: Columns,
    dataSource: data,
    getRowId: (originalRow) => originalRow.ParentId,
    isDisableColumnFilter: true,
    rowsSelected: function (r) {
      r.length && rowSelected(r[0]);
    },
    selectionModel: selectedRow,
    sx: { height: "84dvh", },
    enableColumnActions: false,

    muiTableBodyProps: {
      sx: (theme) => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "#e2f2ff" },
        // backgroundColor: "inherit",//
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "#e2f2ff" },
      }),
    },

    muiTableBodyCellProps: ({ cell, column, row, table }) => {
      return {
        sx: cell.column.columnDef.accessorKey === "Product" ? {
          backgroundColor: (row?.original?.ProductColour + "!important") || "",
          color: "white",
          border: "0.5px solid #d0d0d0",
          py: 0,
        } : { height: "8px", borderLeft: "0.5px solid #d0d0d0", }
      }
    },

    muiTableBodyRowProps: ({ row }) => ({
      //implement row selection click events manually
      onClick: () => {
        rowSelected(row.original);
        setRowSelection((prev) => ({
          [row.id]: !prev[row.id],
        }))
      },
      selected: rowSelection[row.id],
      sx: {
        cursor: 'pointer',
      },
    }),
    state: { rowSelection },
  };
  return <NewDataTable {...options} />;
}

const Columns = [
  {
    header: "Product",
    accessorKey: "Product",
    size: 150,
    enableEditing: false,
  },
  {
    header: "Parent",
    accessorKey: "Parent",
    size: 300,
    sortable: false,
    enableEditing: false,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
]; 