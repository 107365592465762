import { Card, Grid } from "@mui/material";
import { useCallback, useEffect, useReducer, useState } from "react";
import useMasterData from "../../../hooks/useMasterData";
import { DETAILS_APIS } from "../../../common/apis";
import Form, { FormActions } from "../../../components/Form";
import FormFooter from "../../../components/FormFooter";
import DataTable, { DataGridAction } from "../../../components/DataTable";
import AppDialog from "../../../components/AppDialog";
import NewFluid from "./NewFluid";
import useFetch from "../../../hooks/useFetch";

export default function SearchFluid({
  actions,
  rowSelected,
  fetchFluidsDetails,
  propsFluidData,
}) {
  const { getMasterData } = useMasterData();
  const { get, post } = useFetch();
  const [data, dispatch] = useReducer(SearchFluidsFilterReducer, { FluidTypeId: 1, });

  const [fluidsData, setFluidsData] = useState([]);
  const [selectedSearchFluidRow, setSelectedSearchFluidRow] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newFluidOpen, setNewFluidOpen] = useState(false);
  const [masterData, setMasterData] = useState({});
  const [emptyCheck, setEmptyCheck] = useState(false);
  const tblOptions = {
    columns: GetColumns(actions, selectClick, editClick),
    dataSource: fluidsData,
    rowIdProperty: "FluidId",
    isDisableColumnFilter: true,
  };

  const formActions = {
    addNew: function () {
      setDialogOpen(true);
    },
    search: async function () {
      if (
        (data?.FluidFull && data?.FluidFull?.trim()) ||
        (data?.FluidLevel1 && data?.FluidLevel1?.trim()) ||
        (data?.FluidLevel2 && data?.FluidLevel2?.trim()) ||
        (data?.FluidLevel3 && data?.FluidLevel3?.trim())
      ) {
        setEmptyCheck(false)
        const tempFluidsData = await post(DETAILS_APIS.SEARCH, data);
        let filteredData = tempFluidsData.filter(item => item?.FluidId !== propsFluidData?.FluidId);
        setFluidsData(filteredData);
      } else {
        setEmptyCheck(true)
        setFluidsData([])
      }
    },
    clear: function () {
      dispatch({
        type: FormActions.RESET_FORM,
        payload: {
          FluidTypeId: data?.FluidTypeId || 1,
          key: Date.now(),
        },
      });
    },
  };

  function selectClick(row) {
    rowSelected && rowSelected(row);
    if (window.location.pathname === '/search-fluid') {
      localStorage.setItem("search-fluid-resp", JSON.stringify(row))
      window.dispatchEvent(new Event('storage'))
      window.close()
    }
  }

  function editClick(row) {
    setSelectedSearchFluidRow(row);
    setNewFluidOpen(true);
  }

  async function getFluidControlsData(data) {
    if (data?.FluidId) {
      const response = await get(`${DETAILS_APIS.GET_FLUID}?fluidId=${data?.FluidId}`);
      localStorage.removeItem("search-fluid");
      if (response?.length > 0) {
        let filteredData = response.filter(item => item?.FluidId !== propsFluidData?.FluidId);
        setFluidsData(filteredData);
        return dispatch({
          payload: {
            ...response[0],
            key: Date.now(),
          },
          type: EventActions.LOAD_DATA,
        });
      }
    }
    else if (data?.FluidTypeId) {
      return dispatch({
        payload: {
          ...data,
          key: Date.now(),
        },
        type: EventActions.LOAD_DATA,
      });
    }
  }

  const handleKeyDown = useCallback(
    (event) => {
      event.stopPropagation();
      if (event.key === "Tab") {
        event.preventDefault();
        var inputs = document.getElementsByTagName('input');
        for (var i = 0; i < inputs?.length; i++) {
          if (document.activeElement.id == inputs[i].id && i + 1 < inputs?.length && (window.location.pathname === '/trays/edit-registration' || window.location.pathname === "/search-fluid")) {
            inputs[i + 1].focus();
            inputs[i + 1].select();
            break;
          }

          else if (document.activeElement.id == inputs[i].id && i < inputs?.length && window.location.pathname === "/trays/registration") {
            inputs[i].focus();
            inputs[i].select();
            break;
          }
        }
      }
      else if (event.key === "F2") {
        event.preventDefault();
        (newFluidOpen === false && dialogOpen === false) && formActions.clear();
      }
      else if (event.key === "F11") {
        event.preventDefault();
        (newFluidOpen === false && dialogOpen === false) && formActions.addNew();
      }
      else if (event.key === "F12") {
        event.preventDefault();
        (newFluidOpen === false && dialogOpen === false) && formActions.search();
      }
      else if (event.key === "Enter") {
        event.preventDefault();
        (newFluidOpen === false && dialogOpen === false) && formActions.search();
      }
    },
    [newFluidOpen, data, dialogOpen]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    (async function () {
      const resp = await getMasterData("FluidTypes");
      setMasterData(resp);
    })();

    let parseData = JSON.parse(localStorage.getItem("search-fluid"));
    if (parseData !== null && parseData !== undefined && parseData?.FluidId > 0) {
      getFluidControlsData(parseData);
    }
    else {
      getFluidControlsData(propsFluidData);
    }
  }, []);

  return (
    <Card sx={{ p: 1 }}>
      <Grid container      >
        <Grid item xs={12}>
          <Form
            sections={GetFormSections(masterData)}
            data={data}
            dispatch={dispatch}
            key={data?.key}
          />
        </Grid>
        <Grid item xs={12}>
          <FormFooter
            footerText={emptyCheck === true ? "  Please provide additional fluid details for search"
              : fluidsData?.length > 0 ? ("Total Records:" + fluidsData.length)
                : `No fluids were found. To add a new fluid, add ALL the available
                details and then SAVE`}
            buttons={GetFormButtons(formActions)}
            hideDivider
            gmt={0}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <DataTable {...tblOptions} />
        </Grid>
      </Grid>
      <AppDialog
        open={dialogOpen}
        title="New Fluid"
        maxWidth="md"
        handleClose={() => setDialogOpen(false)}
      >
        <NewFluid
          cancel={() => setDialogOpen(false)}
          rowManageSelected={{
            FluidTypeId: data?.FluidTypeId,
            FluidLevel1: data?.FluidLevel1,
            FluidLevel2: data?.FluidLevel2,
            FluidLevel3: data?.FluidLevel3,
          }}
          fetchFluidsDetails={fetchFluidsDetails}
          getFluidControlsData={getFluidControlsData}
        />
      </AppDialog>

      <AppDialog
        open={newFluidOpen}
        title="Edit Fluid"
        maxWidth="md"
        handleClose={() => setNewFluidOpen(false)}
      >
        <NewFluid
          cancel={() => setNewFluidOpen(false)}
          rowManageSelected={selectedSearchFluidRow}
          getFluidControlsData={getFluidControlsData}
          isEdit={true}
        />
      </AppDialog>
    </Card>
  );
}

const GetFormButtons = ({ addNew, clear, search }) => [
  {
    variant: "outlined",
    label: "New Fluid (F11)",
    onClick: addNew,
  },
  {
    variant: "outlined",
    label: "Clear (F2)",
    onClick: clear,
  },
  {
    variant: "contained",
    label: "Search (F12)",
    onClick: search,
  },
];

function GetColumns(actions, selectClick, editClick) {
  return [
    {
      headerName: "Actions",
      field: "Actions",
      sortable: false,
      hide: !actions || !actions.length,
      minWidth: 150,
      renderCell: function ({ row }) {
        return (actions || []).map(function (ac, i) {
          return (
            <DataGridAction
              row={row}
              onClick={ac.action}
              label={ac.label}
              startIcon={ac.startIcon}
              sx={ac?.sx}
            />
          );
        });
      },
    },
    {
      headerName: "Select",
      field: "Select",
      hide: actions && actions.length,
      sortable: false,
      width: 80,

      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction
              row={row}
              onClick={selectClick}
              label="Select"
            />
          </>
        );
      },
    },
    {
      headerName: "Edit",
      field: "edit",
      hide: actions && actions.length,
      sortable: false,
      width: 80,

      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction
              row={row}
              onClick={editClick}
              label="Edit"
            />
          </>
        );
      },
    },
    {
      headerName: "Make",
      field: "FluidLevel1",
      minWidth: 120,
      sortable: false,
    },
    {
      headerName: "Type",
      field: "FluidLevel2",
      minWidth: 120,
      sortable: false,
    },
    {
      headerName: "Grade",
      field: "FluidLevel3",
      minWidth: 100,
      sortable: false,
    },
    {
      headerName: "Display",
      field: "FluidFormatted",
      minWidth: 400,
      sortable: false,
      flex: 1,
    },
    {
      headerName: "Category",
      field: "FluidType",
      minWidth: 130,
      flex: 1,
      sortable: false,
    },
  ];
}

function GetFormSections({ FluidTypes }) {
  return [
    {
      xsCol: 12,
      fields: [
        {
          xsCol: 3,
          name: "FluidTypeId",
          label: "Type",
          type: "dropdown",
          selectOptions: (FluidTypes || []).map((x) => ({
            value: x.FluidTypeId,
            text: x.FluidType,
          })),
        },
        {
          xsCol: 9,
          name: "FluidFull",
          label: "Fluid",
        },
        {
          xsCol: 3,
          name: "FluidLevel1",
          label: "Make",
        },
        {
          xsCol: 3,
          name: "FluidLevel2",
          label: "Type",
        },
        {
          xsCol: 3,
          name: "FluidLevel3",
          label: "Grade",
        },
      ],
    },
  ];
}

const EventActions = {
  LOAD_DATA: "load-data",
};

function SearchFluidsFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === FormActions.RESET_FORM) {
    return { ...action.payload };
  }
  if (action.type === EventActions.LOAD_DATA) {
    return { ...action.payload };
  }
  return state;
}
