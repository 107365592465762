import { Card } from "@mui/material";
import Button from "@mui/material/Button";
import Form, { SectionTitle } from "../../../components/Form";
import { Edit } from "@mui/icons-material";
import FormFooter from "../../../components/FormFooter";
import { ViewPhysicalIcon } from "../../../icons/ButtonIcons";
import { useRoot } from "../../../rootContext";
import { useWindowPopups } from "../../../components/useWindowPopups";

export default function CustomerAddress({ data }) {
  const { CanDoDataMaintenance } = useRoot();

  const HandleNewWindow = (actionName) => {
    useWindowPopups(actionName, data)
  }

  const handleViewPhysical = () => {
    window.open("https://www.google.com.au/maps/search/" + (data?.PhysicalStreet1 || "") + (data?.PhysicalCity || "") + (data?.PhysicalPostCode || "") + (data?.PhysicalCountry || ""))
  }

  return (
    <>
      <Card sx={{ p: 1 }}>
        <Form
          readOnly
          sections={GetFormSections(data, handleViewPhysical)}
          data={data}
          key={data?.CustomerId}
        />
        {CanDoDataMaintenance && <FormFooter buttons={GetFormButtons(HandleNewWindow)} />}
      </Card>

    </>
  );
}

const GetFormButtons = (HandleNewWindow) => [
  {
    variant: "contained", label: "Edit", startIcon: <Edit />,
    onClick: () => HandleNewWindow("edit-customer"),
  }
];

const GetFormSections = (data, handleViewPhysical) => [
  {
    xsCol: 12,
    fields: [
      {
        xsCol: 12,
        component: function () {
          return <SectionTitle title="Customer Address" />;
        },
      },
    ],
  },
  {
    xsCol: 6,
    fields: [
      { name: "PhysicalStreet1", label: "Physical Address 1", xsCol: 12 },
      { name: "PhysicalStreet2", label: "Address 2", xsCol: 12 },
      { name: "PhysicalCity", label: "Town", xsCol: 12 },
      { name: "PhysicalPostCode", label: "Postcode", xsCol: 6 },
      { name: "PhysicalState", label: "State", xsCol: 6 },
      {
        name: "PhysicalCountry",
        label: "Country",
        xsCol: 6,
      },
      {
        alignSelf: "flex-end",
        component: (props) => {
          return (
            <Button variant="outlined" startIcon={<ViewPhysicalIcon />} onClick={handleViewPhysical} disabled={data?.PhysicalCountry === null}>
              View Physical
            </Button>
          );
        },
      },
    ],
  },
  {
    xsCol: 6,
    fields: [
      { name: "PostalStreet1", label: "Postal Address 1", xsCol: 12 },
      { name: "PostalStreet2", label: "Address 2", xsCol: 12 },
      { name: "PostalCity", label: "Town", xsCol: 12 },
      { name: "PostalPostCode", label: "Postcode", xsCol: 6 },
      { name: "PostalState", label: "State", xsCol: 6 },
      {
        name: "PostalCountry",
        label: "Country",
        xsCol: 12,
      },
    ],
  },
];
