import React, { useEffect, useState } from 'react'
import NewDataTable, { NewDataGridAction } from '../../components/NewDataTable'
import renderCellExpand from '../../components/DatatableCellExpand';

function SearchContactTable({ contactsData,
    selectClick,
    editClick,
    accessClick,
    reportsClick,
    subscriptionsClick,
    exportsClick,
    primaryClick,
    selectCheck }) {

    const [sCheck, setSCheck] = useState(false);

    useEffect(() => {
        const val = selectCheck === true ? true : false;
        setSCheck(val)
    }, [selectCheck]);

    const tblOptions = {
        columns: GetColumns(
            selectClick,
            editClick,
            accessClick,
            reportsClick,
            subscriptionsClick,
            exportsClick,
            primaryClick,
        ),
        dataSource: contactsData || [],
        getRowId: (originalRow) => (originalRow?.ContactId || ""),

        sx: { height: "70dvh" },
        enableColumnActions: false,

        muiTableBodyProps: {
            sx: (theme) => ({
                '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
                    { backgroundColor: "inherit" },
                '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
                    { backgroundColor: "inherit" },
                '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
                    { backgroundColor: "#e2f2ff" },
                // backgroundColor: "inherit",//
                '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
                    { backgroundColor: "#e2f2ff" },
            }),
        },
        muiTableBodyCellProps: ({ cell, column, row, table }) => {
            return {
                sx: cell.column.columnDef.accessorKey === "IsKomatsu" ? {
                    backgroundColor: (
                        row?.original?.IsKomatsu
                            ? "#140a9a !important"
                            : row?.original?.IsPortalUser
                                ? "#ff4500 !important"
                                : row?.original?.IsLabcheckUser
                                    ? "#228b22 !important"
                                    : ""
                    ),
                    color: "white",
                    border: "0.5px solid #d0d0d0",
                    py: 0,
                    height: "22px",
                } : {
                    py: 0, height: "22px", borderLeft: "1px solid #d0d0d0"
                }
            }
        },
        muiTableBodyRowProps: ({ cell, column, row, table }) => {
            return {
                sx: { height: "22px", py: 0, borderLeft: "1px solid #d0d0d0" }
            }
        },
        state: { columnVisibility: { Select: sCheck } },
    }

    return (<>
        <NewDataTable {...tblOptions} />
    </>)
}

export default SearchContactTable;

function GetColumns(
    selectClick,
    editClick,
    accessClick,
    reportsClick,
    subscriptionsClick,
    exportsClick,
    primaryClick,
) {
    return [
        {
            header: "Select",
            accessorKey: "Select",
            size: 60,
            enableHiding: true,
            enableSorting: false,
            Cell: function ({ row }) {
                return (
                    <NewDataGridAction
                        row={row?.original}
                        onClick={() => selectClick(row?.original)}
                        label={"Select"}
                    />
                );
            },
        },
        {
            header: "Edit",
            size: 60,
            Cell: function ({ row }) {
                return (
                    <NewDataGridAction
                        row={row?.original}
                        onClick={() => editClick(row?.original)}
                        label={"Edit"}
                    />
                );
            },
        },

        {
            header: "Access",
            size: 60,
            Cell: function ({ row }) {
                return (
                    <NewDataGridAction
                        row={row?.original}
                        onClick={() => accessClick(row?.original)}
                        label={row?.original?.HasScope ? "Yes" : "No"}
                    //  className={value ? "outlined" : "contained"}
                    // color={value ? "primary" : "error"}
                    />
                );
            },
        },

        {
            header: "Reports",
            size: 60,
            Cell: function ({ row }) {
                return (
                    <NewDataGridAction
                        row={row?.original}
                        onClick={() => reportsClick(row?.original)}
                        label={row?.original?.HasLinkages ? "Yes" : "No"}
                        //   className={row.HasScope ? "contained" : "contained"}
                        disabled={!row?.original?.HasScope && !row?.original?.HasLinkages}
                    // color={
                    //   !row.HasScope && !row.HasLinkages
                    //     ? "inherit"
                    //     : !row.HasLinkages
                    //     ? "error"
                    //     : "info"
                    // }
                    />
                );
            },
        },
        {
            header: "Subscriptions",
            size: 80,
            Cell: function ({ row }) {
                return (
                    <NewDataGridAction
                        row={row?.original}
                        onClick={() => subscriptionsClick(row?.original)}
                        label={row?.original?.HasSubscriptions ? "Yes" : "No"}
                        //   className={row.HasScope ? "outlined" : "contained"}
                        disabled={!row?.original?.HasScope}
                    />
                );
            },
        },
        {
            header: "Exports",
            size: 60,
            Cell: function ({ row, value }) {
                return (
                    <NewDataGridAction
                        row={row?.original}
                        onClick={() => exportsClick(row?.original)}
                        label={row?.original?.HasExports ? "Yes" : "No"}
                    />
                );
            },
        },
        {
            header: "Primary",
            sortable: false,
            size: 60,
            Cell: function ({ row, value }) {
                return (
                    <NewDataGridAction
                        row={row?.original}
                        onClick={() => primaryClick(row?.original)}
                        label={row?.original?.IsPrimaryContact ? "Yes" : "No"}
                        // className={row.HasScope ? "outlined" : "contained"}
                        disabled={!row?.original?.HasScope}
                    />
                );
            },
        },
        {
            header: "Email",
            accessorKey: "Username",
            size: 200,
            Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
        },
        {
            header: "DisplayName",
            accessorKey: "DisplayName",
            size: 150,
            Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
        },
        {
            header: "Source",
            accessorKey: "IsKomatsu",
            Cell: function ({ row, val }) {
                return (
                    row?.original?.IsKomatsu
                        ? "Komatsu"
                        : row?.original?.IsPortalUser
                            ? "Portal"
                            : row?.original?.IsLabcheckUser
                                ? "Labcheck"
                                : "No Website"
                );
            },
            size: 90,
            sortable: false,
        },
        {
            header: "Mobile",
            accessorKey: "Mobile",
            size: 110,
            sortable: false,
        },
        {
            header: "Telephone",
            accessorKey: "Telephone",
            size: 110,
            sortable: false,
        },
        {
            header: "Role",
            accessorKey: "ContactRole",
            size: 60,
        },
        {
            header: "Archived",
            accessorKey: "IsArchived",
            Cell: ({ value }) => (value ? "Yes" : "No"),
            size: 60,
        },
        {
            header: "Company",
            accessorKey: "Company",
            size: 160,
            sortable: false,
            Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
        },
        {
            header: "Country",
            accessorKey: "Country",
            size: 170,
            sortable: false,
        },
    ];
}