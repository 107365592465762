import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { InputLabel } from "@mui/material";

const icon = <CheckBoxOutlineBlankIcon />;
const checkedIcon = <CheckBoxIcon />;

export default function TagsInput({
  label,
  id,
  options,
  onChange,
  textProp,
  value,
}) {
  return (
    <>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Autocomplete
        multiple
        id={id}
        options={options || []}
        onChange={onChange}
        disableCloseOnSelect
        value={value}
        getOptionLabel={(option) => option[textProp]}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option[textProp]}
          </li>
        )}
        sx={{
          "& .MuiFilledInput-root .MuiFilledInput-input": {
            p: 0,
          },
          "& .MuiAutocomplete-tag": {
            height: "30px",
          },
        }}
        renderInput={(params) => {
          return <TextField {...params} />;
        }}
      />
    </>
  );
}
