import { Button, Card, Grid, Typography } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useReducer, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import Form, { FormActions } from "../../../components/Form";
import { MANAGEMENT_APIS } from "../../../common/apis";
import AppDialog from "../../../components/AppDialog";
import { useToast, useUser } from "../../../rootContext";
import appResources from "../../../app-resources/en/Resources.json";
import renderCellExpand from "../../../components/DatatableCellExpand";
import EditPresetComments from "./EditPresetComments";
import ConfirmModal from "../../../components/ConfirmModal";
import NewDataTable, { NewDataGridAction } from "../../../components/NewDataTable";

export default function PresetComments() {
  const [data, dispatch] = useReducer(SearchFilterReducer, defaultPresetComments);

  const user = useUser();
  const { showToast, showErrorToast } = useToast();
  const { get, post } = useFetch();

  const [presetData, setPresetData] = useState([]);
  const [dialog, setDialog] = useState({ open: false, message: "", });
  const [addComment, setAddComment] = useState(false);
  const [editComment, setEditComment] = useState(false);
  const [rowData, setRowData] = useState({})
  //get api for loading data

  async function resetData() {
    const responseData = await post(MANAGEMENT_APIS.PRESET_COMMENTS, {
      ...data,
      UserId: user?.UserId,
    });
    setPresetData(responseData);
  }
  //use memo
  const filteredGridData = useMemo(
    () =>
      data.filterType === "Both"
        ? presetData
        : presetData.filter((item) => item.CommentType === data.filterType),
    [presetData, data.filterType]
  );

  const tblOptions = {
    columns: GetColumns(editClick),
    dataSource: filteredGridData || [],
    isDisableColumnFilter: true,
    sx: { height: "83dvh" },

    getRowId: (originalRow) => (originalRow?.CommentId || ""),
    enablePagination: false,
    enableColumnActions: false,

    muiTableBodyProps: {
      sx: (theme) => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "#e2f2ff" },
        // backgroundColor: "inherit",//
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "#e2f2ff" },
      }),
    },
    muiTableBodyCellProps: () => {
      return {
        sx: {
          height: "8px", borderLeft: "1px solid #d0d0d0"
        }
      }
    }
  };

  function editClick(rowData) {
    setEditComment(true)
    setRowData({
      ...rowData, Type: rowData.CommentUse === "Personal" ? 0 : 1,
      useOption: rowData.CommentType === "Both"
        ? 0 : rowData.CommentType === "Evaluation"
          ? 1 : rowData.CommentType === "Recommendation"
            ? 2 : 0,
    })
    // dispatch({
    //   payload: {
    //     ...rowData,
    //     //IncludePersonal: rowData.CommentUse,
    //     //Code: rowData.Code,
    //     filterType: rowData.CommentType,
    //     Type: rowData.CommentUse === "Personal" ? 0 : 1,
    //     useOption:
    //       rowData.CommentType === "Both"
    //         ? 0
    //         : rowData.CommentType === "Evaluation"
    //           ? 1
    //           : rowData.CommentType === "Recommendation"
    //             ? 2
    //             : 0,
    //     IncludePersonal: rowData.CommentUse === "Personal" ? true : false,
    //     IncludeShared: rowData.CommentUse === "Shared" ? true : false,
    //     _ts_key: rowData.Code,
    //   },
    //   type: EventActions.LOAD_DATA,
    // });
  }
  //Save data
  function saveClick() {
    (async function () {
      await post(MANAGEMENT_APIS.EDIT_PRESET_COMMENTS, {
        ...data,
        CommentId: data.CommentId ? data.CommentId : null,
        CommentUserId: user?.UserId,
        ForEvaluations:
          data.useOption === 0 || data.useOption === 1 ? true : false,
        ForRecommendations:
          data.useOption === 0 || data.useOption === 2 ? true : false,
        UserId: user?.UserId,
        Screen: "Preset Comments",
      });
      await resetData();
    })();
  }
  //delete
  async function deleteClick(data) {
    setDialog({
      open: true,
      message: appResources.DELETE_CONFIRMATION_MSG,
    });
    dispatch({
      payload: {
        ...data,
      },
    });
  }

  async function deleteClickAlert() {
    const response = await get(`${MANAGEMENT_APIS.DELETE_PRESET_COMMENTS}?CommentId=${data.CommentId}&Screen=Preset Comments`);

    if (response > 0) {
      showToast(appResources.DELETED_SUCCESSFULLY_MSG);
    } else {
      showErrorToast(appResources.SOMETHING_WENT_WRONG_MSG);
    }
    await resetData();
    setDialog((prevState) => ({ ...prevState, open: false }));
    dispatch({ type: FormActions.RESET_FORM });
  }

  function newCLick() {
    setAddComment(true)
    dispatch({ type: FormActions.RESET_FORM });
  }

  const handleClose = () => {
    setEditComment(false)
    setAddComment(false)
  }

  useEffect(() => {
    resetData();
  }, [data.IncludePersonal, data.IncludeShared, data.AllPersonal]);

  useEffect(() => {
    document.title = "Preset Comments";
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Card sx={{ p: 0.5 }}>
            <Form
              sections={GetFormSections(newCLick, deleteClick, saveClick)}
              data={data}
              dispatch={dispatch}
              key={data._ts_key}
            />
          </Card>
        </Grid>

        <Grid item xs={12} sx={{ mt: 1 }}>
          <Typography variant="subtitle2">
            Total Records: <b> {filteredGridData?.length}</b>
          </Typography>
          <NewDataTable {...tblOptions} />
        </Grid>
      </Grid>
      <AppDialog
        open={dialog.open}
        handleClose={() =>
          setDialog((prevState) => ({ ...prevState, open: false }))
        }
        title="Delete Preset Comment"
        maxWidth="md"
      >
        <ConfirmModal
          handleClose={() => {
            setDialog((prevState) => ({ ...prevState, open: false, }));
          }} alertAction={deleteClickAlert}
          message={dialog.message}
          buttonText={"Delete"}
          buttonText2={"Cancel"}
        />
      </AppDialog>

      <AppDialog
        open={addComment}
        handleClose={() => setAddComment(false)}
        title="Add Preset Comment"
        maxWidth="md"
      >
        <EditPresetComments
          resetData={resetData}
          cancel={handleClose}
        />
      </AppDialog>

      <AppDialog
        open={editComment}
        handleClose={() => setEditComment(false)}
        title="Edit Preset Comment"
        maxWidth="md"
      >
        <EditPresetComments
          resetData={resetData}
          rowData={rowData}
          cancel={handleClose}
        />
      </AppDialog>
    </>
  );
}

function GetColumns(editClick) {
  return [
    {
      header: "Edit",
      maxSize: 50,
      Cell: function ({ row }) {
        return (
          <NewDataGridAction
            row={row?.original}
            onClick={() => editClick(row?.original)}
            label={"Edit"}
          />
        );
      },
    },
    {
      header: "User",
      accessorKey: "Fullname",
      size: 100,
    },
    {
      header: "Type",
      accessorKey: "CommentType",
      size: 80,
    },
    {
      header: "Use",
      accessorKey: "CommentUse",
      size: 70,
    },
    {
      header: "Code",
      accessorKey: "Code",
      size: 100,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "Comment",
      accessorKey: "Comment",
      size: 1200,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
  ];
}

function GetFormSections(newCLick,) {
  return [
    {
      xsCol: 12,
      fields: [
        {
          xsCol: 12,
          group: [
            {
              name: "IncludePersonal",
              label: "Personal",
              type: "switch",
            },
            {
              name: "IncludeShared",
              label: "Shared",
              type: "switch",
            },
            {
              name: "AllPersonal",
              label: "All Personal",
              type: "switch",
            },
            {
              alignSelf: "flex-end",
              xsCol: 1,
              name: "filterType",
              label: "Type",
              type: "dropdown",
              required: true,
              selectOptions: getTypeOptions(),
            },
            {
              alignSelf: "flex-end",
              component: function () {
                return (
                  <Button
                    variant="contained"
                    onClick={newCLick}
                  >
                    New
                  </Button>
                );
              },
            },
          ],
        },
      ],
    },
  ];
}

const defaultPresetComments = {
  IncludePersonal: true,
  filterType: "Both",
};

const EventActions = {
  LOAD_DATA: "load-data",
};

function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === FormActions.RESET_FORM) {
    return { ...defaultPresetComments, _ts_key: Date.now() };
  }
  if (action.type === EventActions.LOAD_DATA) {
    return { ...action.payload };
  }
  return state;
}

function getTypeOptions() {
  return [
    { Id: 1, value: "Both", text: "Both" },
    { Id: 2, value: "Evaluation", text: "Evaluations" },
    { Id: 3, value: "Recommendation", text: "Recommendations" },
  ];
}