import React, { Fragment, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import {
  Box,
  Button,
  Card,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import useFetch from "../../../hooks/useFetch";
import Grid from "@mui/material/Grid";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import TextBox from "../../../components/TextBox";
import { DASHBOARD_APIS } from "../../../common/apis";
import SwitchInput from "../../../components/SwitchInput";
import { useUser } from "../../../rootContext";
import { DownArrowHead, UpArrowHead, UpdateIcon } from "../../../icons/ButtonIcons";
import TrayDashLab from "./TrayDashLab";

const TrayDashboard = () => {

  const { post } = useFetch();
  const user = useUser();

  const refreshOption = [0, 2, 5, 10, 15, 20, 30, 45, 60];

  const [labs, setLabs] = useState([
    { Name: "Brisbane", Id: 1, checked: true },
    { Name: "Perth", Id: 2, checked: true },
    { Name: "Newcastle", Id: 3, checked: true },
  ]);
  const [dataSource, setDataSource] = useState([]);
  const [trayDate, setTrayDate] = useState(new moment());
  const [updatedDate, setUpdatedDate] = useState();
  const [autoUpdateMinutesDropdown, setAutoUpdateMinutesDropdown] = useState(10);
  const [autoUpdateMinutes, setAutoUpdateMinutes] = useState(10);
  const [regCounts, setRegCounts] = useState({})

  const handleDateUp = () => {
    let date = moment(trayDate, "DD-MM-YYYY").add(1, 'days');
    handleChangeDate(date);
  }

  const handleDateDown = () => {
    let date = moment(trayDate, "DD-MM-YYYY").subtract(1, 'days');
    handleChangeDate(date);
  }

  const handleChangeDate = async (trayDateParam) => {
    if (trayDateParam._isValid) {
      const formattedDate = trayDateParam.format("YYYYMMDD");
      const data = await post(DASHBOARD_APIS.TRAY, {
        LabIds: labs?.filter((x) => x.checked).map((x) => x.Id),
        TrayDate: formattedDate,
      });
      setRegCounts(data?.overview)
      if (data?.labDashboardVm.length > 0) {
        const result = moveElement(data?.labDashboardVm);
        setDataSource(result);
        setUpdatedDate(moment().format("hh:mm:ss ddd D MMM YYYY"));
      } else {
        setDataSource();
      }
      setTrayDate(trayDateParam);
    };
  }

  const handleChangeSwitch = async (event) => {
    const labId = event.target.name.split("_")[1];
    const lab = labs.find((x) => x.Id == labId);

    lab.checked = event.target.checked;
    if (trayDate._isValid) {
      const formattedDate = trayDate.format("YYYYMMDD");
      const data = await post(DASHBOARD_APIS.TRAY, {
        LabIds: [...labs.filter((x) => x.Id != labId), lab].sort((a, b) => a.Id - b.Id)?.filter((x) => x.checked).map((x) => x.Id),
        TrayDate: formattedDate,
      });
      setRegCounts(data?.overview)
      if (data?.labDashboardVm.length > 0) {
        const result = moveElement(data?.labDashboardVm);
        setDataSource(result);
        setUpdatedDate(moment().format("hh:mm:ss ddd D MMM YYYY"));
      } else {
        setDataSource();
      }
      setLabs(
        [...labs.filter((x) => x.Id != labId), lab].sort((a, b) => a.Id - b.Id)
      );
    };
  }

  async function getTrayDashboardData() {
    if (trayDate._isValid) {
      const formattedDate = trayDate.format("YYYYMMDD");
      const data = await post(DASHBOARD_APIS.TRAY, {
        LabIds: labs?.filter((x) => x.checked).map((x) => x.Id),
        TrayDate: formattedDate,
      });
      setRegCounts(data?.overview)
      if (data?.labDashboardVm.length > 0) {
        const result = moveElement(data?.labDashboardVm);
        // setDataSource(data?.labDashboardVm);
        setDataSource(result);
        setUpdatedDate(moment().format("hh:mm:ss ddd D MMM YYYY"));
      } else {
        setDataSource();
      }
    } else return;
  }

  function updateClick() {
    getTrayDashboardData();
    setAutoUpdateMinutes(autoUpdateMinutesDropdown);
  }

  function moveElement(array) {
    const toIndex = 0;
    let fromIndex = 0;
    const lId = user?.DefaultLaboratoryId;

    const data1 = [...array];
    let e = data1.filter(i => i.LabId === lId)[0];
    fromIndex = data1.indexOf(e);

    const element = data1.splice(fromIndex, 1)[0];
    data1.splice(toIndex, 0, element);
    return (data1);
  }


  function handleChangeDropdown(e) {
    setAutoUpdateMinutesDropdown(e.target.value);
  }

  // Function to set up the interval
  const setupInterval = () => {
    const intervalFunction = () => {
      getTrayDashboardData();
    };
    const intervalTime = autoUpdateMinutes * 60 * 1000;
    return setInterval(intervalFunction, intervalTime);
  };

  const handleKeyUp = (event) => {
    if (event.key === "ArrowUp") {
      event.preventDefault();
      handleDateUp();
    }
    if (event.key === "ArrowDown") {
      event.preventDefault();
      handleDateDown();
    }
  }

  useEffect(() => {
    let intervalId;
    if (autoUpdateMinutes !== 0) {
      intervalId = setupInterval();
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [autoUpdateMinutes, trayDate]);


  useEffect(() => {
    (async () => {
      document.title = "Tray Dashboard";
      getTrayDashboardData();
    })();
  }, [user?.DefaultLaboratoryId]);

  return (
    <>
      <Box>
        <Grid
          container
          spacing={2}
          sx={{ userSelect: "none", marginTop: -2.5 }}
        >
          <Grid item xs={12}>
            <Card
              sx={{
                p: 1,
                padding: 0.5,
                width: "100%",
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item mt={0.5}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          label="Tray Date"
                          inputFormat="DD/MM/YYYY"
                          value={trayDate}
                          onChange={handleChangeDate}
                          renderInput={(params) => <TextBox
                            onKeyUp={(e) => handleKeyUp(e)}
                            {...params} />}
                          componentsProps={{
                            actionBar: {
                              actions: ["today"],
                            },
                          }}
                        />

                      </LocalizationProvider>

                    </Grid>
                    <Grid
                    // alignSelf={"end"}
                    >
                      <div title="Date Up" onClick={handleDateUp} style={{ marginTop: "30px", cursor: "pointer" }}>
                        {/* {"⌃"} */}
                        <UpArrowHead />
                      </div>

                      <div title="Date Down" onClick={handleDateDown} style={{ cursor: "pointer" }}>
                        {/* {"⌄"} */}
                        <DownArrowHead />
                      </div>
                    </Grid>
                    <Grid item ml={1}>
                      <Typography sx={{ paddingBottom: 1 }}>
                        Select Labs
                      </Typography>
                      {labs?.map((lab) => (
                        <FormControlLabel
                          control={
                            <SwitchInput
                              checked={lab.checked}
                              onChange={handleChangeSwitch}
                              name={"lab_" + lab.Id}
                              size="small"
                              label={"CMS " + lab.Name}
                            />
                          }
                          key={lab.Id}
                        />
                      ))}
                    </Grid>
                    <Grid item mt={0.5}>
                      <InputLabel>Auto Update Every</InputLabel>
                      <FormGroup row={true}>
                        <Select
                          value={autoUpdateMinutesDropdown}
                          onChange={handleChangeDropdown}
                          displayEmpty
                          inputProps={{ "aria-label": "Auto Update Every" }}
                          sx={{ width: 100, mr: 2 }}
                        >
                          {refreshOption.map((op) => (
                            <MenuItem value={op} key={op}>
                              {op}
                            </MenuItem>
                          ))}
                        </Select>
                        <Typography mt={1} mr={2}>
                          Minutes
                        </Typography>
                        <Button
                          variant="contained"
                          onClick={updateClick}
                          startIcon={<UpdateIcon />}
                        >
                          Update
                        </Button>
                      </FormGroup>
                    </Grid>

                    <Grid item>
                      <FormHelperText sx={{ mt: "25px" }}>
                        Updated: {updatedDate}
                      </FormHelperText>
                    </Grid>
                    <Grid item>
                      <FormHelperText sx={{ mt: "25px", color: regCounts?.MonthRegistered > regCounts?.PrevMonthRegistered ? "green" : "red" }}>
                        {trayDate.format("MMMM YYYY")}   Total {regCounts?.MonthRegistered || 0} ({regCounts?.PrevMonthRegistered || 0})
                      </FormHelperText>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
            </Card>
          </Grid>

          {dataSource?.map((data, i) => {
            return (
              <Fragment key={i}>
                <TrayDashLab
                  data={data}
                  trayDate={trayDate}
                  labs={labs} />
              </Fragment>);
          })}
        </Grid>
      </Box>
    </>
  );
};

export default TrayDashboard;
