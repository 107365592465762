import { Edit, Search } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import TextBoxGroup from "../../../components/TextBoxGroup";
import { applyLineBreaks, CheckNumber } from "../../../utils";
import Constants from "../../../common/Constants";
import { SectionTitle } from "../../../components/Form";
import { customTheme } from "../../../customTheme";
import { useMemo, useEffect, } from "react";
import { auto } from "@popperjs/core";
import moment from "moment/moment";
import { DialogTypes } from "../../../components/useScreenPopups";

export const useGetFormSections = (
  maxDateAllowed,
  hanldeLastMonth,
  regSampleExists,
  setRegSampleExists,
  testSets,
  data,
  { openWindow },
  masterData
) => {

  useEffect(() => {
    setRegSampleExists(0);
  }, [data.Sif]);

  const month1 = moment(data?.Sampled).isBefore(moment().subtract(1, "months"));
  const month6 = moment(data?.Sampled).isBefore(moment().subtract(6, "months"));

  return useMemo(
    () => [
      {
        xsCol: 10,
        fields: [
          {
            xsCol: 12,
            group: [
              {
                xsCol: 10,
                group: [
                  {
                    xsCol: 12,
                    component: function () {
                      return (
                        <Box
                          sx={{
                            backgroundColor: "primary.main",
                            color: "white",
                          }}
                          p={1}
                        >
                          <Typography sx={{ display: "inline" }}>
                            Sample Information
                          </Typography>
                          {data?.SampleNumber && (
                            <Typography
                              sx={{ display: "inline", float: "right" }}
                            >
                              Sample Number: {data?.SampleNumber || ""}
                            </Typography>
                          )}
                        </Box>
                      );
                    },
                  },
                  {
                    xsCol: 12,
                    component: function () {
                      return (
                        <Typography>If anything is UNKNOWN. e.g. Meter Reading, then make the value EMPTY and NOT ZERO; zero means that the value is known and brand new
                        </Typography>)
                    }
                  },
                  {
                    xsCol: 12,
                    group: [
                      {
                        xsCol: 6,
                        group: [
                          {
                            name: "InternalOnly",
                            label: "Internal Only",
                            xsCol: 3,
                            type: "switch",
                          },
                          {
                            name: "Sif",
                            label: "Registration",
                            label2: data.RegistrationId || "",
                            label2bgColor: "green",
                            label2Color: "white",
                            xsCol: 4,
                            // readOnly: true,
                          },
                          {
                            xsCol: 5,
                            name: "MeterReading",
                            label: "Meter Reading  ",
                            sx: { ".MuiInputBase-input": { backgroundColor: data?.MeterReading?.length < 1 ? "teal" : "" } },
                            label2: CheckNumber(data.LastMeterReading),
                          },
                        ],
                      },
                      {
                        xsCol: 6,
                        group: [
                          {
                            xsCol: 6,
                            name: "ComponentReading",
                            label: "Comp. Reading   ",
                            label2: CheckNumber(data.LastComponentHours),
                          },
                          {
                            xsCol: 6,
                            name: "FluidHours",
                            label: "Fluid Hours   ",
                            label2: CheckNumber(data.LastFluidHours),
                            type: "number",
                            max: 999999999,
                          },
                        ],
                      },
                      {
                        xsCol: 6,
                        group: [
                          {
                            name: "SampledAssumed",
                            label: "Assumed",
                            xsCol: 3,
                            type: "switch",
                          },
                          {
                            name: "DateSampledStr",
                            label: "Date Sampled (ddMMyy)",
                            xsCol: 4,
                            maxLength: 6,
                            type: "text",
                          },
                          {
                            maxDate: maxDateAllowed,
                            alignSelf: "flex-end",
                            name: "Sampled",
                            xsCol: 5,
                            type: "date",
                            label:
                              month1 && month6
                                ? "More than 6 months"
                                : month1
                                  ? "More than 1 month"
                                  : "",
                            sx: {
                              [`.MuiFormLabel-root`]: {
                                color:
                                  month1 && month6
                                    ? "red"
                                    : month1
                                      ? "green"
                                      : "",
                              },
                            },
                          },

                        ],
                      },
                      {
                        xsCol: 6,
                        group: [
                          {
                            xsCol: 4,
                            component: function () {
                              return (
                                <Button sx={{ mt: 1.5 }} variant="outlined" onClick={hanldeLastMonth}>Last Month (Esc)</Button>
                              );
                            },
                          },
                          {
                            xsCol: 4,
                            name: "Topup",
                            label: "Topup (Litres)",
                            type: "number",
                            max: 999999999,
                          },
                          {
                            xsCol: 4,
                            name: "JobNumber",
                            label: "Job Number",
                          },
                        ],
                      },
                    ],
                  },
                  {
                    xsCol: 12,
                    group: [
                      {
                        xsCol: 12,
                        component: function () {
                          return <SectionTitle title="Machine Information" />;
                        },
                      },
                      {
                        xsCol: 6,
                        group: [
                          {
                            name: "MeterChanged",
                            label: "Meter Changed",
                            checked: data?.MeterChangedEventId !== null,
                            xsCol: 4,
                            type: "switch",
                          },
                          {
                            name: "MeterChangedHours",
                            label: "Changed At",
                            xsCol: 4,
                            type: "number",
                            disabled: (data) => !data.MeterChanged,
                          },
                          {
                            name: "MeterChangedAtDate",
                            label: "Approx. Changed",
                            xsCol: 4,
                            type: "date",
                            disabled: (data) => !data.MeterChanged,
                          },
                        ],
                      },
                      {
                        xsCol: 6,
                        group: [
                          {
                            name: "ComponentChanged",
                            label: "Comp. Changed",
                            checked: data?.CompChangedEventId !== null,
                            xsCol: 4,
                            type: "switch",
                          },
                          {
                            name: "CompChangedHours",
                            label: "Changed At",
                            xsCol: 4,
                            type: "number",
                            disabled: (data) => !data.ComponentChanged,
                          },
                          {
                            name: "CompChangedDate",
                            label: "Approx. Changed",
                            xsCol: 4,
                            type: "date",
                            disabled: (data) => !data.ComponentChanged,
                          },
                        ],
                      },
                      {
                        xsCol: 6,
                        group: [
                          {
                            name: "FluidChanged",
                            label: "Fluid Changed",
                            xsCol: 4,
                            type: "switch",
                          },
                          {
                            name: "FluidChangedAt",
                            label: "Changed At",
                            xsCol: 4,
                            type: "number",
                            disabled: (data) => !data.FluidChanged,
                          },
                          {
                            name: "FluidChangedAtDate",
                            label: "Approx. Changed",
                            xsCol: 4,
                            type: "date",
                            disabled: (data) => !data.FluidChanged,
                          },
                        ],
                      },
                      {
                        xsCol: 6,
                        group: [
                          {
                            name: "ComponentRepaired",
                            label: "Comp. Repaired",
                            xsCol: 4,
                            checked: data?.CompRepairedEventId !== null,
                            type: "switch",
                          },
                          {
                            name: "ComponentRepairedAt",
                            label: "Repaired At",
                            xsCol: 4,
                            type: "number",
                            disabled: (data) => !data.ComponentRepaired,
                          },
                          {
                            // name: "CompRepairedDate",
                            name: "ComponentRepairedAtDate",
                            label: "Approx. Repaired",
                            xsCol: 4,
                            type: "date",
                            disabled: (data) => !data.ComponentRepaired,
                          },
                        ],
                      },
                      {
                        xsCol: 6,
                        group: [
                          {
                            xsCol: 4,
                            name: "FilterChanged",
                            label: "Filter Changed",
                            type: "switch",
                          },
                          {
                            xsCol: 8,
                            name: "FluidFormatted",
                            errorId: "FluidId",
                            component: function ({ id, value, errorMsg }) {
                              const options = {
                                label: "Fluid",
                                label2: data?.FluidType || "",
                                labelColor: (data?.FluidFormatted === undefined || data?.FluidFormatted === null) ? "red" : "",
                                id,
                                value,
                                errorMsg,
                                readOnly: true,
                                buttons: [
                                  {
                                    onClick: () =>
                                      openWindow(DialogTypes.SEARCH_FLUID),
                                    icon: <Search />,
                                    label: "(F3)",
                                  },
                                ],
                              };
                              return <TextBoxGroup {...options
                              } />;
                            },
                          },
                        ],
                      },
                      {
                        xsCol: 6,
                        group: [
                          {
                            name: "MakeFluidDefault",
                            label: "Make Fluid Default",
                            xsCol: 4,
                            type: "switch",
                          },
                          {
                            name: "TestSetId",
                            label: "Test Set",
                            xsCol: 4,
                            type: "dropdown",
                            selectOptions: testSets?.map((x) => ({
                              value: x.TestSetId,
                              text: x.TestSet,
                            })),
                            sx: {
                              [`.MuiFormLabel-root`]: { color: data?.TestSetId > 0 ? " " : "red" },
                            },
                          },
                          {
                            name: "MakeTestSetDefault",
                            label: "Make Test Set Default",
                            labelColor: "red",
                            xsCol: 4,
                            type: "switch",
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              {
                xsCol: 2,
                name: "SummaryArea",
                direction: "column",
                container: true,
                sx: { height: "100%" },
                component: function ({ value }) {
                  return (
                    <>
                      <Grid item>
                        <SectionTitle title="Summary" />
                      </Grid>
                      <Grid
                        item
                        sx={{
                          border: `1px solid ${customTheme.palette.primary.main}`,
                          color: regSampleExists?.SampleNumber > 0
                            ? "white" : `${data?.SummaryAreaForeColor}`,
                          backgroundColor: regSampleExists?.SampleNumber > 0
                            ? "red" : data?.SummaryAreaBackColor + "!important",
                          px: 0.5,
                          maxHeight: "370px",
                          overflowY: auto,
                        }}
                        flex={1}
                      >
                        <>
                          {regSampleExists?.SampleNumber > 0 ? (
                            <Typography>
                              {`RegistrationId ${regSampleExists?.RegistrationId} has already been registered for Sample Number ${regSampleExists?.SampleNumber}`}
                            </Typography>
                          ) : (
                            applyLineBreaks(value || "", true)
                          )}
                        </>
                      </Grid>
                    </>
                  );
                },
              },
            ],
          },

          {
            xsCol: 12,
            group: [
              {
                xsCol: 12,
                component: function () {
                  return <SectionTitle title="Other Information" />;
                },
              },
              {
                xsCol: 12,
                group: [
                  {
                    xsCol: 3,
                    name: "ServiceTypeId",
                    label: "Service",
                    type: "dropdown",
                    selectOptions: (masterData.ServiceTypes || []).map((x) => ({
                      value: x.ServiceTypeId,
                      text: x.ServiceType,
                    })),
                  },
                  {
                    xsCol: 3,
                    name: "SampleOptionId",
                    label: "Kidney Loop",
                    type: "dropdown",
                    selectOptions: (masterData.SampleOptions || []).map(
                      (x) => ({
                        value: x.SampleOptionId,
                        text: x.SampleOption,
                      })
                    ),
                  },
                  {
                    xsCol: 3,
                    name: "OwnerLaboratoryId",
                    label: "Tray Owner",
                    type: "dropdown",
                    required: true,
                    selectOptions: (masterData.Laboratories || [])
                      .filter((x) => !x.IsForImporting)
                      .map((x) => ({
                        value: x.LaboratoryId,
                        text: x.Laboratory,
                      })),
                  },
                  {
                    xsCol: 3,
                    name: "SampleTypeId",
                    label: "Kit Type",
                    type: "dropdown",
                    required: true,
                    selectOptions: (masterData.SampleTypes || []).map((x) => ({
                      value: x.SampleTypeId,
                      text: x.SampleType,
                    })),
                  },
                ],
              },
              {
                xsCol: 12,
                group: [
                  {
                    xsCol: 3,
                    name: "Component",
                    errorId: "ComponentId",
                    component: function ({ id, value, errorMsg }) {
                      const options = {
                        label: "Edit Component",
                        labelColor: (data?.Component === undefined || data?.Component === null) ? "red" : "",
                        id,
                        value,
                        readOnly: true,
                        errorMsg,
                        buttons: [
                          {
                            onClick: () =>
                              data.ComponentId &&
                              openWindow(DialogTypes.EDIT_COMPONENT),
                            label: "(F7)",
                            icon: <Edit />,
                          },
                        ],
                      };
                      return <TextBoxGroup {...options} />;
                    },
                  },
                  {
                    xsCol: 3,
                    name: "Unit",
                    component: function ({ id, value }) {
                      const options = {
                        label: "Edit Unit",
                        labelColor: (data?.Unit === undefined || data?.Unit === null) ? "red" : "",
                        id,
                        value,
                        readOnly: true,
                        buttons: [
                          {
                            onClick: () =>
                              openWindow(DialogTypes.EDIT_UNIT),
                            label: "(F6)",
                            icon: <Edit />,
                          },
                        ],
                      };
                      return <TextBoxGroup {...options} />;
                    },
                  },
                  {
                    xsCol: 3,
                    name: "Site",
                    component: function ({ id, value }) {
                      const options = {
                        label: "Edit Site ",
                        labelColor: (data?.Site === undefined || data?.Site === null) ? "red" : "",
                        id,
                        value,
                        readOnly: true,
                        buttons: [
                          {
                            onClick: () =>
                              data.SiteId && openWindow(DialogTypes.EDIT_SITE),
                            label: "(F5)",
                            icon: <Edit />,
                          },
                        ],
                      };
                      return <TextBoxGroup {...options} />;
                    },
                  },
                  {
                    xsCol: 3,
                    name: "Customer",
                    component: function ({ id, value }) {
                      const options = {
                        label: "Edit Customer",
                        labelColor: (data?.Customer === undefined || data?.Customer === null) ? "red" : "",
                        id,
                        value,
                        readOnly: true,
                        buttons: [
                          {
                            onClick: () =>
                              data.CustomerId &&
                              openWindow(DialogTypes.EDIT_CUSTOMER),
                            label: "(F4)",
                            icon: <Edit />,
                          },
                        ],
                      };
                      return <TextBoxGroup {...options} />;
                    },
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        xsCol: 2,
        fields: [
          {
            xsCol: 12,
            component: function () {
              return <SectionTitle title="Additional Information" />;
            },
          },
          {
            xsCol: 12,
            group: [
              {
                xsCol: 12,
                name: "CustomerComments",
                label: "Registration Comments: on report",
                type: "textarea",
              },
              {
                xsCol: 12,
                name: "SampleNote",
                label: "Sample Comments: internal only",
                type: "textarea",
              },
              {
                name: "ComponentNote",
                label: "Component: internal only",
                xsCol: 12,
                type: "textarea",
              },
              {
                name: "UnitNote",
                label: "Unit: internal only",
                xsCol: 12,
                type: "textarea",
              },
              {
                name: "SiteNote",
                label: "Site: internal only",
                xsCol: 12,
                type: "textarea",
              },
              {
                name: "CustomerNote",
                label: "Customer: internal only",
                xsCol: 12,
                type: "textarea",
              },
            ],
          },
        ],
      },
    ],
    [testSets, regSampleExists, data, month1, month6, data?.Sif],
  );
};

export function GetOverViewString(item, trayData) {
  var details = [];
  var sampleNumber = 0;
  var componentId = 0;
  var extraAdded = false;
  var duplicateExists = false;
  var check;

  // check if there are details
  if (item.Component != null) {
    sampleNumber = item.SampleNumber;
    componentId = item.ComponentId;

    check = trayData?.filter(x => (x?.ComponentId === item?.ComponentId && x?.SampleNumber !== item?.SampleNumber))

    duplicateExists = check?.length > 0 ? true : false;

    var specificTestSets = null;
    if (
      item.TestSetCustomerSpecific &&
      item.TestSetCustomerSpecific.Value === true
    ) {
      // specificTestSets = DataCmsAnalysis.GetTestSetsSpecific(
      //   item.ComponentId.Value,
      //   userState
      // );
    }

    // customer details
    if (item.ProductArchived) {
      details.push(
        " This product is ARCHIVED and cannot register : move this unit"
      );
      extraAdded = true;
    } else if (item.CustomerArchived) {
      details.push(
        " This customer is ARCHIVED and cannot register : move this unit"
      );
      extraAdded = true;
    } else if (item.SiteArchived) {
      details.push(
        " This site is ARCHIVED and cannot register : move this unit"
      );
      extraAdded = true;
    }

    if (item.SampleArchived) {
      details.push(" This Sample is ARCHIVED : edit this sample");
      extraAdded = true;
    } else if (item.ComponentArchived) {
      details.push(" This Component is ARCHIVED : registering will unarchive");
      extraAdded = true;
    } else if (item.UnitArchived) {
      details.push(" This unit is ARCHIVED : registering will unarchive");
      extraAdded = true;
    }

    if (item.IsScrapped) {
      details.push(" This unit is marked as SCRAPPED : edit this unit");
      extraAdded = true;
    } else if (item.IsParked) {
      details.push(" This unit is marked as PARKED : edit this unit");
      extraAdded = true;
    }

    if (item.HasContacts === false) {
      details.push(" There are no report contacts");
      extraAdded = true;
    } else if (item.HasContactsNonKomatsu === false) {
      details.push(" There are no customer report contacts");
      extraAdded = true;
    }

    if (item.HasPrimaryContacts === false) {
      //TODO
      details.push(" There are no primary contacts");
      extraAdded = true;
    }

    if (item.HasAddress) {
      //TODO
      details.push(" There is no site or customer address");
      extraAdded = true;
    }

    if (specificTestSets != null && specificTestSets.Count > 0) {
      details.push("");
      details.push(
        " There " +
        (specificTestSets.Count !== 1 ? "is an" : "are") +
        " available specific test set" +
        (specificTestSets.Count !== 1 ? "s" : "") +
        ":"
      );
      extraAdded = true;
    }

    if (duplicateExists === true) {
      details.push(" This COMPONENT is REPEATED in this tray");
      extraAdded = false;
    }

    if (extraAdded === true) {
      details.push("");
    }

    details.push(" Product: " + item.Product);
    details.push(" Customer: " + item.Customer);
    details.push(" Site: " + (item.Site || "Unknown"));
    if (item.AddressId) {
      var indent = " ";
      var address = "";
      if (item.Street1 != null) {
        address += indent + item.Street1 + "\r";
      }
      if (item.Street2 != null) {
        address += indent + item.Street2 + "\r\n";
      }
      if (item.City != null) {
        address += indent + item.City + "\r\n";
      }
      if (item.State || item.PostCode) {
        if (item.State) address += indent + item.State;
        if (item.PostCode) address += indent + item.PostCode;
        address += "\r\n";
      }
      if (item.Country != null) {
        address += indent + item.Country;
      }
      if (address) {
        details.push(" Address: ");
        details.push(address);
      }
    }
    details.push("");

    // unit details
    details.push(" Unit: " + item.Unit);
    if (item.Serial != null) {
      details.push(" Serial: " + item.Serial);
    }
    details.push(
      " Model: " +
      (item.Model
        ? item.Model.replace("Unknown", Constants.Unknown)
        : Constants.Unknown)
    );
    details.push(" Measure: " + item.Measure);
    details.push("");

    // component details
    details.push(" Component: " + item.Component);
    //if (item.FluidType != null && item.Fluid != null)
    //{
    //    details.Append(item.FluidType + ": " + item.Fluid + "\r\n");
    //}   ;
    if (item.ComponentType) {
      details.push(
        " Type: " + item.ComponentType.replace("Unknown", Constants.Unknown)
      );
    }
    details.push(
      " Capacity: " +
      (item.Capacity ? item.Capacity + " litres" : Constants.Unknown)
    );

    // default or last fluid
    if (item.DefaultFluidId) {
      details.push(
        " Default " + (item?.DefaultFluidType || "") + ": " + (item?.DefaultFluidFormatted || "")
      );
    }
    if (
      item.LastFluidId &&
      (!item.DefaultFluidId || item.DefaultFluidId != item.LastFluidId)
    ) {
      details.push(
        " Last " + item.LastFluidType + ": " + item.LastFluidFormatted
      );
    }

    // default or last test set
    if (item.DefaultTestSetId) {
      details.push(" Default Test Set: " + item.DefaultTestSet);
    } else if (item.LastTestSetId) {
      details.push(" Last Test Set: " + item.LastTestSet);
    }
  }

  // add the details text
  item.SummaryArea = "\r\n" + details.join("\r\n");

  // var numberOfRepeatComponents = 0;
  // only check for duplicates on the tray view
  // if (comboTrayView.SelectedIndex == 0) {
  //   //numberOfRepeatComponents = (from DataGridViewRow r in dataTray.Rows where componentId > 0 && Convert.ToInt64(r.Cells["SampleNumber"].Value) != sampleNumber && Convert.ToInt32(r.Cells["ComponentId"].Value) == componentId select r).Count<DataGridViewRow>();
  // }
  // repeat component or not
  if (duplicateExists === true) {
    item.SummaryArea =
      Constants.Space +
      //repeatedComponentText +
      "\r\n" +
      item.SummaryArea;
    item.SummaryAreaBackColor = "Teal"//Constants.NotificationMinorKomtraxForeColour; // Constants.HighlightLowBackColour;
    item.SummaryAreaForeColor = "white"//Constants.HighlightForeColour;
  } else if (extraAdded == true) {
    item.SummaryAreaBackColor = "purple"//Constants.NotificationModerateForeColour;
    item.SummaryAreaForeColor = "white"// Constants.HighlightForeColour;
  } else {
    item.SummaryAreaBackColor = "white"// defaultDropAreaColour;
    item.SummaryAreaForeColor = "black"// Constants.NormalForeColour;
  }
}

export const RegistrationFormActions = {
  APPLY_NEW_FLUID: "apply-new-fluid",
  APPLY_FLUID: "apply-fluid",
  RESET_FORM: "reset-form",
  LOAD_DATA: "load-data",
  DATE_CHANGE: "date-change",
};
