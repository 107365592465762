import React, { useEffect, useState } from "react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

const InsiteIframe = ({ eqpMachineId }) => {

    const [dtl, setDtl] = useState("");

    const appInsights = useAppInsightsContext();
    const metricData = {
        name: `React Component Engaged Test KOWA (eqpMachineId) ${dtl}`,
        sampleCount: 1
    };
    const additionalProperties = { "Component Name": "My InsiteIframe" };
    appInsights.trackMetric(metricData, additionalProperties);

    useEffect(() => { setDtl(eqpMachineId) }, [eqpMachineId])
    return (
        eqpMachineId > 0 ? <iframe
            id="insightsframe"
            title="insights frame"
            src={
                // "https://insiteuat.komatsu.com.au/profile/" + // For UAT
                "https://insite.komatsu.com.au/profile/" + // For PROD
                eqpMachineId +
                "/overview"
            }
            height={"100%"}
            width={"100%"}
        /> : <></>
    );
}
export default InsiteIframe;
