import { useTheme } from "@emotion/react";
import { Button, Card, Grid, Typography } from "@mui/material";
import { useReducer, useEffect, useState, useCallback, useMemo } from "react";
import Form, { FormActions } from "../../../components/Form";
import useFetch from "../../../hooks/useFetch";
import moment from "moment";
import { toBasicLocalDate, toIsoFormat, toLocalDateTime } from "../../../utils";
import { MASTER_DATA_APIS } from "../../../common/apis";
import { ExcelIcon, } from "../../../icons/ButtonIcons";
import { useUser } from "../../../rootContext";
import NewDataTable from "../../../components/NewDataTable";

export default function AuditLogs() {
  const user = useUser();

  const SearchFilterDefaults = {
    DateFrom: toIsoFormat(new moment()), //moment().toISOString(),
    DateTo: toIsoFormat(new moment()),// moment().toISOString(),
    Username: user.Username,
  };

  const [data, dispatch] = useReducer(SearchFilterReducer, SearchFilterDefaults);
  const curTheme = useTheme();
  const { post, postFile } = useFetch();

  const [auditsData, setAuditsData] = useState([]);
  const [usersDropdown, setUsersDropdown] = useState([]);
  const [infoMessage, setInfoMessage] = useState();

  useEffect(() => {
    document.title = "Audit Log";
    (async function () {
      let resData = await post(MASTER_DATA_APIS.AUDIT_LOG_USERS, {
        IsAdministrator: true,
      });
      if (resData) {
        resData.unshift({ Username: 0, FullName: "All Users" });
      }
      setUsersDropdown(resData);
    })();
  }, []);

  async function view() {
    let responseData = await post(MASTER_DATA_APIS.AUDIT_LOGS, {
      ...data,
      Username: data.Username ? data.Username : null,
      IsAdministrator: true,
    });
    let userName =
      usersDropdown?.find((item) => item.Username === data?.Username)
        ?.FullName ?? "";
    if (responseData.length > 0) {
      setAuditsData(responseData);

      setInfoMessage(`${responseData.length} logs found for ${userName}`);
    } else {
      setAuditsData([]);
      setInfoMessage(`No logs were found for ${userName}`);
    }
  }

  //Export excel
  function exportToExcelClick() {
    let fileName = "Audit Log " + toBasicLocalDate(data?.DateFrom) + "-" + toBasicLocalDate(data?.DateTo) + " Run";
    postFile(MASTER_DATA_APIS.EXPORT_AUDIT_LOGS,
      {
        ...data,
        Username: data.Username ? data.Username : null,
        IsAdministrator: true,
      },
      fileName
    );
  }

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "F12") {
        event.preventDefault();
        view()
      }
    },
    [data]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  const columns1 = useMemo(
    () => [
      {
        accessorKey: "FullName",
        header: "Name",
        minSize: 20,
        maxSize: 30,
      },
      {
        accessorKey: "Username",
        header: "User",
        minSize: 20,
        maxSize: 20,
      },
      {
        accessorKey: "Added",
        header: "Added",
        minSize: 20,
        maxSize: 20,
        accessorFn: (dataRow) => toLocalDateTime(dataRow.Added),
      },
      {
        accessorKey: "Event",
        header: "Event",
        size: 45,
        maxSize: 50,
      },
      {
        accessorKey: "Description",
        header: "Description",
        size: 150,
        maxSize: 160,
      },
    ], [])

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Card sx={{ p: 1 }}>
            <Form
              sections={GetFormSections(
                usersDropdown,
                view,
                exportToExcelClick,
                auditsData,
                infoMessage
              )}
              data={data}
              dispatch={dispatch}
            />
          </Card>
        </Grid>

        <Grid item xs={12} sx={{ mt: curTheme.spacing(2) }}>
          <Typography variant="subtitle2">
            Total Records: <b> {auditsData && auditsData.length}</b>
          </Typography>
          <NewDataTable
            columns={columns1}
            dataSource={auditsData}
            heightValue={"750px"}
          />
        </Grid>
      </Grid>
    </>
  );
}

function GetFormSections(
  usersDropdown,
  view,
  exportToExcelClick,
  auditsData,
  infoMessage
) {
  return [
    {
      fluid: true,
      fields: [
        {
          name: "Username",
          label: "User",
          type: "dropdown",
          xsCol: 2,
          required: true,
          selectOptions: (usersDropdown || []).map((x) => ({
            value: x.Username,
            text: x.FullName,
          })),
        },
        {
          name: "DateFrom",
          label: "From",
          type: "date",
        },
        {
          name: "DateTo",
          label: "To",
          type: "date",
        },
        {
          name: "Title",
          label: "Event",
          xsCol: 2,
        },
        {
          name: "Description",
          label: "Description",
          xsCol: 2,
        },

        {
          component: function () {
            return (
              <Button
                variant="contained"
                onClick={view}
                sx={{ mt: "12px" }}
              >
                View (F12)
              </Button>
            );
          },
        },
        {
          component: function () {
            return (
              auditsData?.length > 0 && (
                <Button
                  variant="outlined"
                  onClick={exportToExcelClick}
                  sx={{ mt: "12px" }}
                  startIcon={<ExcelIcon />}
                >
                  Excel
                </Button>
              )
            );
          },
        },
        {
          flex: 2,
          alignSelf: "center",
          component: function () {
            return (
              <Typography
                sx={{
                  mt: 1,

                  fontSize: 11,
                  color: auditsData?.length > 0 ? "green" : "red",
                }}
              >
                {infoMessage}
              </Typography>
            );
          },
        },
      ],
    },
  ];
}

function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  return state;
}