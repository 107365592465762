import { FLEET_APIS } from "../../../common/apis";
import DataTable, { DataGridAction } from "../../../components/DataTable";
import NewDataTable, { NewDataGridAction } from "../../../components/NewDataTable";
import useFetch from "../../../hooks/useFetch";

export default function TemplatesModelTable({ data, refreshModels }) {
  const { get } = useFetch();

  async function deleteClick(row) {
    await get(`${FLEET_APIS.ADD_DELETE_TEMPLATE_MODEL}?TemplateId=${row.TemplateId}&UnitMakeId=${row.UnitMakeId}&IsDelete=true&Screen=Machine Templates`);
    refreshModels(row);
  }

  const options = {
    columns: Columns(deleteClick),
    dataSource: data,

    getRowId: (originalRow) => (originalRow?.TemplateUnitMakeId || ""),
    enablePagination: false,
    sx: { height: "70dvh", },
    enableColumnActions: false,

    muiTableBodyProps: {
      sx: (theme) => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "#e2f2ff" },
        // backgroundColor: "inherit",//
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "#e2f2ff" },
      }),
    },
  }

  return <NewDataTable {...options} />;
}

function Columns(deleteClick) {
  return [
    {
      header: "Make",
      accessorKey: "UnitMake",
      size: 130,
    },
    {
      header: "Model",
      accessorKey: "UnitModel",
      size: 130,
    },
    {
      header: "Delete",
      sortable: false,
      size: 50,
      Cell: function ({ row }) {
        return (
          <NewDataGridAction
            row={row?.original}
            onClick={() => deleteClick(row?.original)}
            label={"Delete"}
          />
        );
      },
    },
  ];
}
