import { Button, Card, Grid, Typography } from '@mui/material'
import React, { useCallback, useEffect } from 'react'
import { SectionTitle } from '../../components/Form'
import { MANAGEMENT_APIS, } from "../../common/apis";
import useFetch from '../../hooks/useFetch';

function KomatsuContacts() {
    const { get, } = useFetch();

    const handleUpdateClick = async () => {
        await get(`${MANAGEMENT_APIS.UPDATE_KOMATSU_CONTACTS} `)

    }

    const handleDeleteClick = async () => {
        await get(`${MANAGEMENT_APIS.TERMINATED_STAFF} `)
    }

    const handleCloseClick = () => {
        window.close();
    }

    const handleKeyDown = useCallback(
        (event) => {
            if (event.key === "F1") {
                event.preventDefault();
                handleCloseClick()
            }
        },
        []);

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown]);

    return (
        <Card sx={{ p: 1, height: "300px", position: "relative" }}>
            <Grid
                container
                spacing={2}
            >
                <Grid item xs={12} >
                    <SectionTitle title="Manage Komatsu Contacts" />
                </Grid>
                <Grid item xs={12} mt={2} >
                    <Typography>Select the action the maintenance action for Komatsu staff.</Typography>
                </Grid>
                <Grid item xs={6} mt={2} >
                    <Button onClick={handleUpdateClick}>Update Komatsu Staff Details</Button>
                </Grid>
                <Grid item xs={6} mt={2}>
                    <Button variant="outlined" onClick={handleDeleteClick} sx={{ float: "right" }}>Delete Terminal Staff</Button>
                </Grid>
                <Grid item xs={12} alignSelf={"end"} sx={{ position: 'absolute', bottom: 10, right: 10 }} >
                    <Button variant="outlined" onClick={handleCloseClick}>Close (F1)</Button>
                </Grid>
            </Grid>
        </Card>
    )
}

export default KomatsuContacts;