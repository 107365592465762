import { Button, Card, Grid, Typography } from "@mui/material";
import { useCallback, useEffect, useReducer, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import Form, { FormActions } from "../../../components/Form";
import { TRAY_APIS } from "../../../common/apis";
import { SearchIcon } from "../../../icons/ButtonIcons";
import { useUser } from "../../../rootContext";
import InsiteModelTable from "./InsiteModelTable";

export default function InsiteModelSearch() {
  const user = useUser();
  const { post } = useFetch();

  const SearchFilterDefaults = {
    Make: "KOMATSU",
    Model: "",
    Source: "Insite Model Search",
    Username: user?.Username,
  };

  const [data, dispatch] = useReducer(
    SearchFilterReducer,
    SearchFilterDefaults
  );

  const [feedbackData, setFeedbackData] = useState([]);
  //call api for search audit data
  async function search() {
    let resData = await post(TRAY_APIS.MODEL_INSITE, {
      ...data,
    });
    if (resData) {
      setFeedbackData(resData);
    }
  }

  useEffect(() => {
    document.title = "Insite Model Search";
  }, []);


  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        if (data?.Make) {
          search();
        }
      }
    }, [data]);

  useEffect(() => {
    document.addEventListener("keyup", handleKeyPress);
    return () => {
      document.removeEventListener("keyup", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Card sx={{ p: 1 }}>
            <Form
              sections={GetFormSections(search, data)}
              data={data}
              dispatch={dispatch}
            />
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="subtitle2"
            sx={{ marginLeft: "5px", marginTop: "10px" }}
          >
            Total Records: <b>{feedbackData && feedbackData.length}</b>
          </Typography>
          <InsiteModelTable
            feedbackData={feedbackData}
          />
        </Grid>
      </Grid>
    </>
  );
}

function GetFormSections(search, data) {
  return [
    {
      fields: [
        {
          xsCol: 12,
          group: [
            {
              xsCol: 2,
              type: "text",
              name: "Make",
              label: "Make",
              placeholder: "KOMATSU",
            },
            {
              xsCol: 2,
              name: "Model",
              label: "Model",
            },
            {
              alignSelf: "end",
              component: function () {
                return (
                  <Button
                    variant="contained"
                    onClick={search}
                    disabled={
                      data?.Make == "" && data?.Model == "" ? true : false
                    }
                    startIcon={<SearchIcon />}
                  >
                    Search
                  </Button>
                );
              },
            },
          ],
        },
      ],
    },
  ];
}

function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  return state;
}
