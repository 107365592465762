import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Typography } from '@mui/material'
import React, { useCallback, useEffect, useReducer, useState } from 'react'
import { toIsoFormat, toLocalDateTime, toLocalDayDate2 } from '../../../utils'
import { AddIcon, DeleteIcon, DeleteTrayIcon, TickGreenIcon, UpdateIcon } from '../../../icons/ButtonIcons'
import TextBox from '../../../components/TextBox'
import { useRoot, useToast, useUser } from '../../../rootContext'
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TRAY_APIS } from '../../../common/apis'
import useFetch from '../../../hooks/useFetch'
import appResources from "../../../app-resources/en/Resources.json";
import AppDialog from '../../../components/AppDialog'
import ConfirmModal from '../../../components/ConfirmModal'
// import { FormActions } from '../../../components/Form'

function ManagementSection({ allData, trayData, dialogType, registrationDone, setInfoMessage, getTrayData, filter }) {
    const user = useUser();
    const { get, post } = useFetch();
    const { CanRegister, CanAdministrate } = useRoot();
    const { showToast, showWarningToast } = useToast();

    // const [data, dispatch] = useReducer(manageReducer, {});

    const [openAccordion, setOpenAccordion] = useState(false);

    const [labelValue, setLabelValue] = useState(allData?.Label);
    const [extraSampleValue, setExtraSampleValue] = useState(1);
    const [forTrayValue, setForTrayValue] = useState(1);
    const [insertSample, setInsertSample] = useState({ Sample: 1, Tray: 1, Lab: 1 });
    const [moveToTrayValue, setMoveToTrayValue] = useState(1);
    const [moveFromLaboratoryValue, setMoveFromLaboratoryValue] = useState(1);
    const [moveToLaboratoryValue, setMoveToLaboratoryValue] = useState(1);
    const [moveFromTrayValue, setMoveFromTrayValue] = useState(1);

    const [alertObj, setAlertObj] = useState({ alertActionName: null, alertTitle: "", alertMessage: "", alertOpen: false })

    const actions = {

        updateLabel: async function () {
            if (labelValue) {
                const responseData = await get(
                    `${TRAY_APIS.UPDATE_TRAY_LABEL}?Laboratory=${allData.OwnerLaboratoryId}&Tray=${allData.Tray}&TrayDate=${allData.TrayDate}&Label=${labelValue}`
                );
                if (responseData >= 1) {
                    showToast(appResources.LABEL_UPDATED_SUCCESSFULLY_MSG);
                    registrationDone(allData.SampleNumber);
                }
            }
        },

        deleteSample: async function () {
            if (allData.Step === 1) {
                setAlertObj({
                    alertActionName: actionsAlert?.alertDeleteSample,
                    alertTitle: "Delete Sample",
                    alertMessage: `${appResources.CREATE_TRAY_DELETE_LAST_SAMPLE_MSG} ${allData.SampleNumber} in tray ${allData.Tray} for ${allData.Laboratory}?`,
                    alertOpen: true
                });
            }
            else if (allData.Step > 1 && CanAdministrate === true) {
                setAlertObj({
                    alertActionName: actionsAlert?.alertDeleteSample,
                    alertTitle: "Delete Sample",
                    alertMessage: `${appResources.CREATE_TRAY_DELETE_LAST_SAMPLE_MSG} ${allData.SampleNumber} in tray ${allData.Tray} for ${allData.Laboratory}?`,
                    alertOpen: true
                });
            }
            else {
                setInfoMessage(`Sample ${allData.SampleNumber} is at step ${allData.Step} and so cannot be deleted(except by an administrator`)

            }
        },

        deleteTray: async function () {
            setAlertObj({
                alertActionName: actionsAlert?.alertDeleteTray,
                alertTitle: `Delete Tray ${forTrayValue || allData?.Tray}`,
                alertMessage: `${appResources.DELETE_TRAY_CONFIRMATION_MSG} ${forTrayValue || allData.Tray} for ${allData.Laboratory}?\n ${appResources.NONE_OF_THE_SAMPLES_MSG}`,
                alertOpen: true
            });
        },

        addExtraSample: async function () {
            setAlertObj({
                alertActionName: actionsAlert?.alertAddExtraSample,
                alertTitle: "Add Extra Sample",
                alertMessage: `${appResources.CREATE_TRAY_ADD_EXTRA_SAMPLE_MSG} on ${toLocalDayDate2(allData.TrayDate)} for ${allData.Laboratory}?`,
                alertOpen: true
            });
        },

        insertSampleTray: async function () {
            const resp = await get(`${TRAY_APIS.TRAY_SAMPLE_COUNT}?dateTime=${toLocalDateTime(toLocalDayDate2(allData.TrayDate))}&Laboratory=${insertSample?.Lab}&Tray=${insertSample?.Tray}`);

            setAlertObj({
                alertActionName: actionsAlert?.alertInsertSampleTray,
                alertTitle: "Insert Sample into Tray",
                alertMessage: `"Are you sure you wish to INSERT sample ${insertSample?.Sample}, to the existing  ${resp?.SampleCount}, in tray ${insertSample?.Tray} on ${toLocalDayDate2(allData?.TrayDate)} for ${resp?.OwnerLaboratory}?`,
                alertOpen: true
            });
        },

        moveTray: async function () {
            setAlertObj({
                alertActionName: actionsAlert?.alertMoveTray,
                alertTitle: "Move Tray",
                alertMessage: `${appResources.MOVE_TRAY_CONFIRMATION_MSG
                    } ${moveFromTrayValue}  from ${moveFromLaboratoryValue === "1"
                        ? "CMS Brisbane"
                        : moveFromLaboratoryValue === "2"
                            ? "CMS Perth"
                            : moveFromLaboratoryValue === "3"
                                ? "CMS New Castle"
                                : ""
                    } to tray ${moveToTrayValue} for ${moveToLaboratoryValue === "1"
                        ? "CMS Brisbane"
                        : moveToLaboratoryValue === "2"
                            ? "CMS Perth"
                            : moveToLaboratoryValue === "3"
                                ? "CMS New Castle"
                                : ""
                    } for ${toLocalDayDate2(
                        allData.TrayDate
                    )}\n If existing, this will push the destination and later trays to new trays and sample numbers ?`,
                alertOpen: true
            });
        },

        swapTray: async function () {
            setAlertObj({
                alertActionName: actionsAlert?.alertSwapTray,
                alertTitle: "Swap Tray",
                alertMessage: `${appResources.SWAP_TRAY_CONFIRMATION_MSG
                    } ${moveFromTrayValue}  from ${moveFromLaboratoryValue === "1"
                        ? "CMS Brisbane"
                        : moveFromLaboratoryValue === "2"
                            ? "CMS Perth"
                            : moveFromLaboratoryValue === "3"
                                ? "CMS New Castle"
                                : ""
                    } to tray ${moveToTrayValue} for ${moveToLaboratoryValue === "1"
                        ? "CMS Brisbane"
                        : moveToLaboratoryValue === "2"
                            ? "CMS Perth"
                            : moveToLaboratoryValue === "3"
                                ? "CMS New Castle"
                                : ""
                    } for ${toLocalDayDate2(
                        allData.TrayDate
                    )} \n This requires that both trays do exist`,
                alertOpen: true
            });
        },
    };

    const actionsAlert = {
        alertInsertSampleTray: async function () {
            const addedSample = await post(TRAY_APIS.INSERT_SAMPLE_TRAY, {
                Date: toIsoFormat(allData?.TrayDate),
                Laboratory: insertSample?.Lab,
                OwnerLaboratory: user?.RealDefaultLaboratoryId || 1,
                Tray: insertSample?.Tray,
                Sample: insertSample?.Sample,
                UserId: user?.UserId,
                Screen: "Registration",
            });
            if (addedSample > 0) {
                showToast(appResources.EXTRA_SAMPLE_ADDED_SUCCESSFULLY_MSG);
            } else {
                showWarningToast(appResources.SOMETHING_WENT_WRONG_MSG);
            }
            registrationDone();
        },
        alertAddExtraSample: async function () {
            const addedSample = await post(TRAY_APIS.ADD_EXTRA_SAMPLE, {
                TrayDate: toIsoFormat(allData.TrayDate),
                // TrayDate: toLocalDate2(allData.TrayDate),
                Laboratory: allData.LaboratoryId,
                Tray: allData.Tray,
                NoofSamples: extraSampleValue >= 1 ? extraSampleValue : 1,
                Screen: "Registration",
            });
            if (addedSample[0]?.Added) {
                showToast(appResources.EXTRA_SAMPLE_ADDED_SUCCESSFULLY_MSG);
            } else {
                showWarningToast(appResources.SOMETHING_WENT_WRONG_MSG);
            }
            registrationDone(allData.SampleNumber);
        },
        alertDeleteSample: async function () {
            const sampleDeleted = await get(`${TRAY_APIS.DELETE_SELECTED_SAMPLE}?SampleNumber=${allData.SampleNumber}&Screen=Registration`);
            if (sampleDeleted > 0) {
                showToast(`Sample ${allData?.SampleNumber} for ${allData.Laboratory} has been DELETED`);
                registrationDone(true);
                setAlertObj({ alertOpen: false });
            } else {
                showWarningToast(appResources.SOMETHING_WENT_WRONG_MSG);
            }
        },
        alertDeleteTray: async function () {
            const deleteData = await post(TRAY_APIS.DELETE_TRAY, {
                TrayDate: toIsoFormat(allData.TrayDate),
                Laboratory: allData.LaboratoryId,
                Tray: insertSample?.Tray || forTrayValue,
                UserId: user.UserId,
                Screen: "Registration",
            });
            if (deleteData.Deleted) {
                getTrayData && getTrayData(filter)
            }
        },
        alertMoveTray: async function () {
            const addedSample = await post(TRAY_APIS.MOVE_TRAYS, {
                FromLaboratoryId: Number(moveFromLaboratoryValue),
                FromTray: Number(moveFromTrayValue),
                ToLaboratoryId: Number(moveToLaboratoryValue),
                ToTray: Number(moveToTrayValue),
                TrayDate: (allData.TrayDate),
                Screen: "Registration",
            });
            registrationDone(allData.SampleNumber);
        },
        alertSwapTray: async function () {
            const addedSample = await post(TRAY_APIS.SWAP_TRAYS, {
                LaboratoryId1: Number(moveFromLaboratoryValue),
                Tray1: Number(moveFromTrayValue),
                LaboratoryId2: Number(moveToLaboratoryValue),
                Tray2: Number(moveToTrayValue),
                TrayDate: allData.TrayDate,
                Screen: "Registration",
            });
            registrationDone(allData.SampleNumber);
        },
    };

    const handleKeyDown = useCallback(
        (event) => {
            if (event.key === "F11") {
                event.preventDefault();
                (dialogType.open === false) && setOpenAccordion(!openAccordion)
            }
        },
        [openAccordion]);


    useEffect(() => {
        setMoveFromLaboratoryValue(user.DefaultLaboratoryId);
        setMoveToLaboratoryValue(user.DefaultLaboratoryId);
    }, [user])

    useEffect(() => {
        setInsertSample({ ...insertSample, Tray: filter?.Tray, Lab: allData?.LaboratoryId })
        setForTrayValue(filter?.Tray)
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown]);

    return (
        < >
            <Accordion
                sx={{ width: "100%" }}
                hidden={!allData?.SampleNumber || !CanRegister}
                expanded={openAccordion}
                onChange={() => setOpenAccordion(!openAccordion)}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{ color: "white" }}
                >
                    <Typography>Management Section (F11)</Typography>
                </AccordionSummary>

                <AccordionDetails>
                    {/* <Form
                        sections={GetFormSections(data)}
                        data={data}
                        spacing={2}
                        dispatch={dispatch}
                        errors={formErrors}
                    /> */}
                    <Grid container spacing={1}>
                        <Grid container item xs={8} spacing={1}>
                            <Grid container item xs={12} spacing={1}>
                                <Grid item xs={4}>
                                    <TextBox
                                        label="Label"
                                        id="my-textbox"
                                        value={labelValue}
                                        onChange={(e) => setLabelValue(e.target.value)}
                                    />
                                </Grid>

                                {CanRegister && <Grid item>
                                    <Button
                                        variant="outlined"
                                        startIcon={<UpdateIcon />}
                                        onClick={() => actions.updateLabel()}
                                        sx={{ marginTop: "14px" }}
                                    >
                                        Update Label
                                    </Button>
                                </Grid>}
                            </Grid>

                            <Grid container item xs={12} spacing={1}>
                                <Grid item xs={4}>
                                    <TextBox
                                        label="Tray Value"
                                        type="number"
                                        placeholder="For Tray"
                                        value={forTrayValue}
                                        onChange={(e) => setForTrayValue(e.target.value)}
                                    />
                                </Grid>
                                {CanRegister && <Grid item>
                                    <Button
                                        variant="outlined"
                                        startIcon={<DeleteIcon sx={{ marginBottom: "3px" }} />}
                                        onClick={() => actions.deleteTray()}
                                        name="alertDeleteTray"
                                        sx={{ marginTop: "14px" }}
                                    >
                                        Delete Tray
                                    </Button>
                                </Grid>}

                                <Grid container item xs={12} spacing={1}>
                                    <Grid item xs={4}>
                                        <TextBox
                                            label="Samples"
                                            type="number"
                                            value={extraSampleValue}
                                            onChange={(e) => setExtraSampleValue(e.target.value)}
                                        />
                                    </Grid>
                                    {CanRegister && <Grid item>
                                        <Button
                                            variant="outlined"
                                            startIcon={<AddIcon />}
                                            onClick={() => actions.addExtraSample()}
                                            name="alertAddExtraSample"
                                            sx={{ marginTop: "14px" }}
                                        >
                                            Add Sample
                                        </Button>
                                    </Grid>}
                                    {CanRegister && <Grid item>
                                        <Button
                                            variant="outlined"
                                            startIcon={<DeleteIcon sx={{ marginBottom: "3px" }} />}
                                            onClick={() => actions.deleteSample()}
                                            name="alertDeleteSample"
                                            sx={{ marginTop: "14px" }}
                                        >
                                            Delete Sample
                                        </Button>
                                    </Grid>}
                                </Grid>

                                <Grid container item xs={7} spacing={1}>
                                    <Grid item xs={3}>
                                        <TextBox
                                            label="Sample"
                                            type="number"
                                            InputProps={{
                                                inputProps: { min: 1, max: 10, maxLength: 3 },
                                            }}
                                            placeholder="Tray"
                                            value={insertSample?.Sample}
                                            onChange={(e) => setInsertSample({ ...insertSample, Sample: e.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextBox
                                            label="Tray"
                                            type="number"
                                            InputProps={{
                                                inputProps: { min: 1, max: 10, maxLength: 3 },
                                            }}
                                            placeholder="to"
                                            value={insertSample?.Tray}
                                            onChange={(e) => setInsertSample({ ...insertSample, Tray: e.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextBox
                                            label="Lab"
                                            type="number"
                                            InputProps={{
                                                inputProps: { min: 0, max: 3 },
                                            }}
                                            placeholder="Lab"
                                            value={insertSample?.Lab}
                                            onChange={(e) => setInsertSample({ ...insertSample, Lab: e.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={3} alignSelf="end">
                                        <Button
                                            variant="outlined"
                                            onClick={() => actions.insertSampleTray()}
                                        >
                                            Insert Sample
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item xs={7} spacing={1}>
                                <Grid item xs={3}>
                                    <TextBox
                                        label="From Tray"
                                        type="number"
                                        InputProps={{
                                            inputProps: { min: 1, max: 10, maxLength: 3 },
                                        }}
                                        placeholder="Tray"
                                        value={moveFromTrayValue}
                                        onChange={(e) => setMoveFromTrayValue(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextBox
                                        label="To Tray"
                                        type="number"
                                        InputProps={{
                                            inputProps: { min: 1, max: 10, maxLength: 3 },
                                        }}
                                        placeholder="to"
                                        value={moveToTrayValue}
                                        onChange={(e) => setMoveToTrayValue(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextBox
                                        label="From Lab"
                                        type="number"
                                        InputProps={{
                                            inputProps: { min: 0, max: 3 },
                                        }}
                                        placeholder="Lab"
                                        value={moveFromLaboratoryValue}
                                        onChange={(e) =>
                                            setMoveFromLaboratoryValue(e.target.value)
                                        }
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextBox
                                        label="To Lab"
                                        type="number"
                                        InputProps={{
                                            inputProps: { min: 0, max: 3 },
                                        }}
                                        placeholder="to"
                                        value={moveToLaboratoryValue}
                                        onChange={(e) => setMoveToLaboratoryValue(e.target.value)}
                                    />
                                </Grid>
                                {CanRegister && <Grid item>
                                    <Button
                                        variant="outlined"
                                        startIcon={<DeleteTrayIcon sx={{ marginTop: "3px" }} />}
                                        onClick={() => actions.moveTray()}
                                        name="alertMoveTray"
                                    >
                                        Move Tray
                                    </Button>
                                </Grid>}
                                {CanRegister && <Grid item>
                                    <Button
                                        variant="outlined"
                                        startIcon={<DeleteTrayIcon sx={{ marginTop: "3px" }} />}
                                        onClick={() => actions.swapTray()}
                                        name="alertSwapTray"
                                    >
                                        Swap Trays
                                    </Button>
                                </Grid>}
                            </Grid>
                        </Grid>

                        {/* MOSTLY TEXT DATA */}
                        <Grid item container xs={4}>

                            {trayData?.length > 0 && <Grid item container xs={12}>

                                {trayData.every(item => item?.Step > 1)
                                    ? <>
                                        <TickGreenIcon />
                                        <Typography sx={{ mt: 1, ml: 1 }}>All {trayData?.length} registered</Typography>
                                    </>
                                    : ""}
                            </Grid>}

                            <Grid item xs={12}>
                                <Typography variant="body1">
                                    Displaying Tray <b>{allData.Tray}</b>
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="body1">
                                    Sample <b>{allData?.Sample}</b> for{" "}
                                    <b>{toLocalDayDate2(allData?.TrayDate)}</b>
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="body1">
                                    Tray Laboratory is <b>{allData?.Laboratory}</b>
                                </Typography>
                            </Grid>

                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>

            {/* Dialog Box */}
            <AppDialog
                open={alertObj?.alertOpen}
                title={alertObj?.alertTitle}
                maxWidth="md"
                handleClose={() => {
                    setAlertObj({ alertOpen: false });
                }}
            >
                <ConfirmModal
                    handleClose={() => { setAlertObj({ alertOpen: false }); }}
                    alertAction={alertObj?.alertActionName}
                    message={alertObj?.alertMessage}
                />
            </AppDialog>
        </ >
    )
}

export default ManagementSection

// function GetFormSections(data) {
//     return [
//         {
//             fields: [
//                 {
//                     name: "Title",
//                     label: "Title",
//                     xsCol: 3,
//                 },

//                 {
//                     name: "SampleNumber",
//                     label: "Sample Number",
//                     xsCol: 3,
//                     readOnly: "true",
//                 },
//                 {
//                     name: "LightSource",
//                     label: "Light Source",
//                     xsCol: 2,
//                 },
//                 {
//                     name: "Magnification",
//                     label: "Magnification",
//                     xsCol: 2,
//                 },
//                 {
//                     name: "DisplayRank",
//                     label: "Display Rank",
//                     type: "number",
//                     xsCol: 2,
//                 },
//                 {
//                     name: "DisplayToContacts",
//                     label: "Include in Report",
//                     type: "switch",
//                     // className: data.DisplayToContacts ? "red" : "",
//                     xsCol: 3,
//                 },

//                 {
//                     name: "Comment",
//                     label: "Description - Leave Empty if the same as title",
//                     xsCol: 9,
//                     type: "textarea",
//                 },
//             ],
//         },
//     ];
// }

// const manageActions = {
//     LOAD_DATA: "load-data",
// };

// function manageReducer(state, action) {
//     if (action.type === FormActions.INPUT_CHANGED) {
//         return { ...state, [action.payload.name]: action.payload.value };
//     }
//     if (action.type === manageActions.LOAD_DATA) {
//         return action.payload;
//     }
//     return state;
// }
