import NewDataTable, { NewDataGridAction } from "../../../components/NewDataTable";
import { toLocalDate, } from "../../../utils";
import renderCellExpand from "./../../../components/DatatableCellExpand";

export default function RegistrationsTable({ data, actions, screen }) {

  const options = {
    columns: GetColumns(actions),
    dataSource: data || [],
    getRowId: (originalRow) => (originalRow.SIF || ""),
    enablePagination: false,
    sx: { height: "73dvh" },
    enableColumnActions: false,
    muiTablePaperProps: {
      sx: {
        border: "1px solid #253FC8",
        borderRadius: "5px",
        padding: "2px",
        [`.MuiTableRow-root:hover td`]: { backgroundColor: "#8238ba !important", color: "white" },
        [`.MuiTableRow-root.Mui-selected td`]: {
          backgroundColor: "#7030a0!important", color: "white"
        }
      }
    },
    muiTableBodyProps: {
      sx: (theme) => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "#e2f2ff" },
        // backgroundColor: "inherit",//
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "#e2f2ff" },
      }),
    },

    muiTableBodyCellProps: ({ cell, column, row, table }) => {
      return {
        sx: cell.column.columnDef.accessorKey === "Product" ? {
          backgroundColor: (row?.original?.ProductColour + "!important") || "",
          color: "white",
          py: 0.25,
        } : { height: "8px", borderLeft: "1px solid #d0d0d0", }
      }
    }

  };

  return <NewDataTable {...options} />;
}

function GetColumns(actions) {
  return [
    {
      header: "Actions",
      size: 120,
      Cell: function ({ row }) {
        return (actions || []).map(function (ac, i) {
          return (
            <NewDataGridAction
              minWidth={"60px"}
              key={i}
              row={row?.original}
              onClick={ac.action}
              label={ac.label}
              sx={ac.sx}
            />
          );
        });
      },
    },
    {
      header: "Registration",
      accessorKey: "SIF",
      sortable: false,
      size: 90,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "Product",
      accessorKey: "Product",
      sortable: false,
      size: 90,
    },
    {
      header: "Customer",
      accessorKey: "Customer",
      sortable: false,
      flex: 1,
      size: 120,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "Site",
      accessorKey: "Site",
      sortable: false,
      size: 110,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "Unit",
      accessorKey: "Unit",
      sortable: false,
      size: 110,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "Make",
      accessorKey: "UnitMake",
      sortable: false,
      size: 100,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "Model",
      accessorKey: "UnitModel",
      sortable: false,
      size: 110,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "Serial",
      accessorKey: "Serial",
      sortable: false,
      size: 90,
    },
    {
      header: "Component",
      accessorKey: "Component",
      sortable: false,
      size: 135,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "Sample Number",
      accessorKey: "SampleNumber",
      sortable: false,
      size: 123,
    },
    {
      header: "Sampled",
      accessorKey: "Sampled",
      Cell: ({ renderedCellValue }) => toLocalDate({ renderedCellValue }),
      sortable: false,
      size: 85,
    },
    {
      header: "Added",
      accessorKey: "Added",
      Cell: ({ renderedCellValue }) => toLocalDate({ renderedCellValue }),
      sortable: false,
      size: 85,
    },
    {
      header: "Received",
      accessorKey: "Received",
      Cell: ({ renderedCellValue }) => toLocalDate({ renderedCellValue }),
      sortable: false,
      size: 85,
    },
    {
      header: "Approved",
      accessorKey: "Approved",
      Cell: ({ renderedCellValue }) => toLocalDate({ renderedCellValue }),
      sortable: false,
      size: 85,
    },
    {
      header: "MR",
      accessorKey: "UnitMeter",
      sortable: false,
      size: 70,
    },
    {
      header: "CR",
      accessorKey: "ComponentMeter",
      sortable: false,
      size: 70,
    },
    {
      header: "FH",
      accessorKey: "FluidMeter",
      sortable: false,
      size: 70,
    },
  ];
}