import { Button, Card, Grid, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { SectionTitle } from '../../components/Form'
import useFetch from '../../hooks/useFetch';
import { MANAGEMENT_APIS } from '../../common/apis';

function ApplyCMSRole() {

    const { get, post } = useFetch();
    const [contactsList, setContactsList] = useState([]);
    const [contactMessage, setContactMessage] = useState("");

    const handleClick = async () => {
        let delList = [];
        if (contactsList.length > 0) {
            contactsList.map(item => { delList.push(item.ContactId) })
        }
        const temp = post(`${MANAGEMENT_APIS.APPLY_CMS_ROLE} `, delList);
    }

    const handleCloseClick = () => {
        window.close();
    }

    useEffect(() => {
        (async function () {
            const resp = await get(`${MANAGEMENT_APIS.CONTACTS_PORTAL}`);
            if (resp?.length > 0) {
                setContactsList(resp);
                if (resp?.length === 1) {
                    setContactMessage("There is 1 portal contact");
                }
                else {
                    setContactMessage("There are " + resp?.length + " portal contacts");
                }
            }
            else {
                setContactsList([]);
                setContactMessage("There are no portal contacts");
            }
        })();
    }, []);

    const handleKeyDown = useCallback(
        (event) => {
            if (event.key === "F1") {
                event.preventDefault();
                handleCloseClick()
            }
            if (event.key === "F12") {
                event.preventDefault();
                if (contactsList?.length > 0) {
                    handleClick()
                }
            }
        },
        []);

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown]);

    return (
        <Card sx={{ p: 1, height: "300px", position: "relative" }}>
            <Grid
                container
                spacing={2}
            >
                <Grid item xs={12} >
                    <SectionTitle title="Apply CMS Role to Portal Contacts" />
                </Grid>

                <Grid item xs={12} >
                    <Typography sx={{ color: contactsList?.length > 0 ? "green" : "red" }}>{contactMessage || ""}</Typography>
                </Grid>

                {contactsList?.length > 0 && <>
                    <Grid item xs={12} justifySelf={"center"} justifyContent={"center"}>
                        <Button onClick={handleClick}>Apply CMS Role (F12)</Button>
                    </Grid>

                    <Grid item xs={12} >
                        <Typography>Note: the Contact may be being moved from Type 2 to Type4.</Typography>
                    </Grid>
                </>}

                <Grid item xs={12} alignSelf={"end"} sx={{ position: 'absolute', bottom: 10, right: 10 }} >
                    <Button variant="outlined" onClick={handleCloseClick}>Close (F1)</Button>
                </Grid>
            </Grid>
        </Card>
    )
}

export default ApplyCMSRole