import * as React from "react";
import { Button, Card, Grid, Typography } from "@mui/material";
import TextBox from "../../components/TextBox";
import ParentsTable from "./ParentsTable";
import useFetch from "../../hooks/useFetch";
import ViewParent from "./ViewParent";
import ParentsCustomersTable from "./ParentsCustomersTable";
import AppDialog from "../../components/AppDialog";
import NewParent from "./NewParent";
import { FLEET_APIS, MASTER_DATA_APIS } from "../../common/apis";
import { NewIcon } from "../../icons/ButtonIcons";

export default function CustomerParents() {

  const { get } = useFetch();

  const [filter, setFilter] = React.useState({});
  const [parentsData, setParentsData] = React.useState([]);
  const [parentsCustomerData, setParentsCustomerData] = React.useState([]);
  const [selectedParent, setSelectedParent] = React.useState({});
  const [infoMessage, setInfoMessage] = React.useState();
  const [newParentOpen, setNewParentOpen] = React.useState(false);

  function handelFilterChange(e) {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  }

  async function fetchData() {
    const response = await get(`${MASTER_DATA_APIS.GET_BY_TYPES}?types=ParentsAll`);
    if (response?.ParentsAll?.length > 0) {
      setParentsData(response?.ParentsAll);
      setSelectedParent(response?.ParentsAll[0]);
      fetchCustomerData(response?.ParentsAll[0]?.ParentId);
    }
  }
  async function fetchCustomerData(parentId) {
    const sampleData = await get(`${FLEET_APIS.PARENT_CUSTOMERS}?ParentId=` + parentId);

    setParentsCustomerData(sampleData);
  }

  function ParentRowSelectedHandler(row) {
    setParentsCustomerData([]);
    setSelectedParent(row);
    fetchCustomerData(row.ParentId);
  }

  function handleNewParentClick() {
    setSelectedParent({});
    setNewParentOpen(true);
  }
  const filteredData = React.useMemo(
    function () {
      return parentsData.filter(
        (x) =>
          !filter.startsWith ||
          x.Parent?.toUpperCase().startsWith(filter.startsWith.toUpperCase())
      );
    },
    [parentsData, filter]
  );

  React.useEffect(function () {
    document.title = "Customer Parents";
    fetchData();
  }, []);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item container xs={12} spacing={1} alignItems="flex-end">
          <Grid item xs={2}>
            <TextBox
              name="startsWith"
              label="Starts with"
              onChange={handelFilterChange}
            />
          </Grid>

          <Grid item xs={8}>
            <Typography sx={{ color: "green" }}>{infoMessage}</Typography>
          </Grid>

          <Grid item xs={2}>
            <Button
              onClick={handleNewParentClick}
              startIcon={<NewIcon />}
            >
              New
            </Button>
          </Grid>

        </Grid>

        <Grid item xs={4}>
          <Typography variant="subtitle2">
            Total Records: <b> {filteredData?.length}</b>
          </Typography>
          <ParentsTable
            data={filteredData}
            rowSelected={ParentRowSelectedHandler}
            selectedRow={selectedParent?.ParentId}
          />
        </Grid>

        <Grid
          item
          xs={4}
          hidden={selectedParent && !selectedParent.ParentId}
          mt={2}
        >
          <Card sx={{ p: 1 }}>
            <ViewParent
              parentsData={selectedParent}
              fetchData={fetchData}
              setSelectedParent={setSelectedParent}
              setInfoMessage={setInfoMessage}
              key={selectedParent && selectedParent.ParentId}
            />
          </Card>
        </Grid>

        <Grid item xs={4} hidden={selectedParent && !selectedParent.ParentId}>
          <Typography variant="subtitle2">
            Total Records: <b> {parentsCustomerData?.length}</b>
          </Typography>
          <ParentsCustomersTable
            data={parentsCustomerData}
          // rowSelected={fluidRowSelectedHandler}
          />
        </Grid>

      </Grid>
      <AppDialog
        open={newParentOpen}
        handleClose={() => setNewParentOpen(false)}
        title="Add new parent"
        maxWidth="xl"
      >
        <NewParent
          fetchData={fetchData}
          setNewParentOpen={setNewParentOpen}
          setInfoMessage={setInfoMessage}
        />
      </AppDialog>
    </>
  );
}
