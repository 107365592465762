import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import useFetch from "../../../hooks/useFetch";
import { useState } from "react";
import { TEST_SETS_APIS } from "../../../common/apis";
import SearchTestSet from "./SearchTestSet";

const ScreenName = "Test Set Management";

const steps = [
  "Select Action",
  "Select Replacement Test Set",
  "Action Summary",
];

export default function ManageTestSets({ source, close, masterData, testSets }) {
  const { get } = useFetch();
  const [activeStep, setActiveStep] = useState(0);
  const [replacement, setReplacement] = useState({});
  const [processResponse, setProcessResponse] = useState("");
  const [action, setAction] = useState("deleteOriginal");

  const handleNext = async () => {
    if (activeStep === 2) {
      await PerformOperation();
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  async function PerformOperation() {
    const isDel = action === "deleteOriginal";
    const resp = await get(`${TEST_SETS_APIS.REPLACE}?src=${source.TestSetId}&dest=${replacement?.TestSetId}&delSrc=${isDel}&screen=${ScreenName}`);

    if (resp?.Success) {
      const msg = isDel ? "The test set  " + source.TestSet + "  has been REPLACED by  " + replacement?.TestSet + "  and then DELETED"
        : "The test set  " + source.TestSet + "  has been REPLACED by  " + replacement?.TestSet;
      setProcessResponse(msg);
      return;
    }
    else {
      const msg = isDel
        ? "Unfortunately, the test set  " + source.TestSet + "  could NOT be replaced by  " + replacement?.TestSet
        : "Unfortunately, the test set  " + source.TestSet + "  could NOT be replaced by  " + replacement?.TestSet + "  and deleted";
      setProcessResponse(msg);
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setReplacement({});
  };


  return (
    <Grid container spacing={2} direction="column" sx={{
      padding: "10px",
      minWidth: "50vw",
      minHeight: "43vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    }}>
      <Grid item>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Grid>
      {activeStep === steps.length ? (
        <>
          <Grid item>
            <Typography variant="body2">{processResponse}</Typography>
          </Grid>
          <Grid item alignSelf="flex-end">
            <Button onClick={close}>Close</Button>
          </Grid>
        </>
      ) : (
        <>
          <Grid item container direction="column">
            <Grid item>
              {activeStep === 0
                ? <Step1 setAction={setAction} />
                : activeStep === 1
                  ? <Step2
                    setReplacement={setReplacement}
                    source={source}
                    masterData={masterData}
                    testSets={testSets}
                  />
                  : <Step3 source={source} replacement={replacement} />
              }
            </Grid>
          </Grid>

          <Box
            sx={{
              marginTop: "auto",
              display: "flex",
              justifyContent: "space-between",
              mt: 1
            }}
          >
            <Button disabled={activeStep === 0} onClick={handleBack} variant="outlined">
              Back
            </Button>
            <Button
              onClick={handleNext}
              disabled={activeStep === 1 && !replacement?.TestSetId}
            >
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>

          </Box>
        </>
      )}
    </Grid>
  );
}

function Step1({ setAction }) {
  return (
    <FormControl>
      <RadioGroup
        defaultValue="deleteOriginal"
        name="manageAction"
        onChange={(e) => setAction(e.target.value)}
      >
        <FormControlLabel
          value="deleteOriginal"
          control={<Radio />}
          label="Replace all instances of the test set with a different one, and DELETE the original test set."
        />
        <FormControlLabel
          value="keepOriginal"
          control={<Radio />}
          label="Replace all instances of the test set with a different one, but KEEP the original test set."
        />
      </RadioGroup>
    </FormControl>
  );
}

function Step2({ setReplacement, source, masterData, testSets }) {

  return (
    <SearchTestSet
      masterData={masterData}
      testSets={testSets}
      source={source}
      actions={[
        {
          action: setReplacement,
          label: "Select",
        },
      ]} />
  );
}

function Step3({ source = {}, replacement = {} }) {
  return (
    <Typography variant="subtitle2">
      <p>Source Product: {source.Product}</p>
      <p>Source Test Set: {source.TestSet}</p>
      <p>Source TestSetId: {source.TestSetId}</p>
      <p>------------------------------</p>
      <p>Replacement Product: {replacement?.Product}</p>
      <p>Replacement Test Set: {replacement?.TestSet}</p>
      <p>Replacement TestSetId: {replacement?.TestSetId}</p>
    </Typography>
  );
}

