import { Delete } from "@mui/icons-material";
import { Button, Grid, } from "@mui/material";
import { useCallback, useEffect, } from "react";
import { useReducer, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import Form, { FormActions } from "../../../components/Form";
import { MANAGEMENT_APIS } from "../../../common/apis";
import AppDialog from "../../../components/AppDialog";
import { useToast, useUser } from "../../../rootContext";
import appResources from "../../../app-resources/en/Resources.json";
import ConfirmModal from "../../../components/ConfirmModal";

export default function EditPresetComments({ resetData, rowData, cancel }) {

    const [data, dispatch] = useReducer(
        SearchFilterReducer,
        defaultPresetComments
    );

    const user = useUser();
    const { showToast, showErrorToast } = useToast();
    const { get, post } = useFetch();
    const [dialog, setDialog] = useState({ open: false, message: "", });

    //get api for loading data

    useEffect(() => {
        document.title = "Preset Comments";
        dispatch({
            type: EventActions.LOAD_DATA,
            payload: rowData
        });
    }, []);

    //Save data
    function saveClick() {
        (async function () {
            await post(MANAGEMENT_APIS.EDIT_PRESET_COMMENTS, {
                ...data,
                CommentId: data.CommentId ? data.CommentId : null,
                CommentUserId: user?.UserId,
                ForEvaluations:
                    data.useOption === 0 || data.useOption === 1 ? true : false,
                ForRecommendations:
                    data.useOption === 0 || data.useOption === 2 ? true : false,
                UserId: user?.UserId,
                Screen: "Preset Comments",
            });
            await resetData();
            cancel && cancel();
        })();
    }
    //delete
    async function deleteClick(data) {
        setDialog({
            open: true,
            message: appResources.DELETE_CONFIRMATION_MSG,
        });
        dispatch({
            payload: {
                ...data,
            },
        });
    }
    async function deleteClickAlert() {
        const response = await get(
            `${MANAGEMENT_APIS.DELETE_PRESET_COMMENTS}?CommentId=${data.CommentId}&Screen=Preset Comments`
        );
        if (response > 0) {
            showToast(appResources.DELETED_SUCCESSFULLY_MSG);
        } else {
            showErrorToast(appResources.SOMETHING_WENT_WRONG_MSG);
        }
        await resetData();
        cancel && cancel();
        setDialog((prevState) => ({ ...prevState, open: false }));
        dispatch({ type: FormActions.RESET_FORM });
    }

    const handleKeyDown = useCallback(
        (event) => {
            if (event.key === "F12") {
                event.preventDefault();
                ((data?.Code?.trim()?.length > 2)
                    && (data?.Comment?.trim()?.length > 2))
                    && saveClick()
            }
        },
        [data]
    );

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown]);

    return (
        <>
            <Grid container>
                <Grid item xs={12} sx={{ width: "40vw" }}>
                    <Form
                        sections={GetFormSections(deleteClick, saveClick, rowData)}
                        data={data}
                        dispatch={dispatch}
                        key={data._ts_key}
                    />
                </Grid>
            </Grid>

            <AppDialog
                open={dialog.open}
                handleClose={() =>
                    setDialog((prevState) => ({ ...prevState, open: false }))
                }
                title="Delete Preset Comment"
                maxWidth="md"
            >
                <ConfirmModal
                    handleClose={() => {
                        setDialog((prevState) => ({
                            ...prevState,
                            open: false,
                        }));
                    }} alertAction={deleteClickAlert}
                    message={dialog.message}
                    buttonText={"Delete"}
                    buttonText2={"Cancel"} />
            </AppDialog>
        </>
    );
}

function GetFormSections(deleteClick, saveClick, rowData) {
    return [
        {
            xsCol: 12,
            fields: [
                {
                    name: "Code",
                    label: "Code",
                    xsCol: 4,
                },
                {
                    name: "Type",
                    label: "Type",
                    type: "dropdown",
                    selectOptions: getCommentTypeOptions(),
                    xsCol: 4,
                },
                {
                    name: "useOption",
                    label: "Use",
                    type: "dropdown",
                    selectOptions: getUseOptions(),
                    xsCol: 4,
                },
                {
                    name: "Comment",
                    label: "Comment",
                    xsCol: 12,
                    type: "textarea",
                    rows: 6,
                },
                {
                    hidden: !(rowData && rowData),
                    component: function ({ data }) {
                        return (
                            <Button
                                color="danger"
                                variant="contained"
                                onClick={deleteClick}
                                sx={{ mt: "23px" }}
                                startIcon={<Delete />}
                            >
                                Delete
                            </Button>
                        );
                    },
                },
                {
                    component: function ({ data }) {
                        return (
                            <Button
                                variant="contained"
                                onClick={saveClick}
                                sx={{ mt: "23px" }}
                                disabled={(data?.Code === undefined || data?.Code?.trim()?.length < 3)
                                    || (data?.Comment === undefined || data?.Comment?.trim()?.length < 3)}
                            >
                                Save (F12)
                            </Button>
                        );
                    },
                },
            ],
        },
    ];
}

const defaultPresetComments = {
    useOption: 0,
    Type: 0,
};

const EventActions = {
    LOAD_DATA: "load-data",
};
function SearchFilterReducer(state, action) {
    if (action.type === FormActions.INPUT_CHANGED) {
        return { ...state, ...defaultPresetComments, [action.payload.name]: action.payload.value };
    }
    if (action.type === FormActions.RESET_FORM) {
        return { ...defaultPresetComments, _ts_key: Date.now() };
    }
    if (action.type === EventActions.LOAD_DATA) {
        return { ...action.payload, _ts_key: Date.now() };
    }
    return state;
}

function getCommentTypeOptions() {
    return [
        { Id: 1, value: 0, text: "Personal" },
        { Id: 2, value: 1, text: "Shared" },
    ];
}
function getUseOptions() {
    return [
        { Id: 1, value: 0, text: "Both" },
        { Id: 2, value: 1, text: "Evaluations" },
        { Id: 3, value: 2, text: "Recommendations" },
    ];
}
