import { Grid } from "@mui/material";
import { useReducer, useState } from "react";
import { FLEET_APIS } from "../../../common/apis";
import Form, { FormActions } from "../../../components/Form";
import FormFooter from "../../../components/FormFooter";
import useFetch from "../../../hooks/useFetch";
import AppDialog from "../../../components/AppDialog";
import NewDataTable, { NewDataGridAction } from "../../../components/NewDataTable";
import EditUnitModel from "../../../forms/EditUnitModel";
import { useWindowPopups } from "../../../components/useWindowPopups";

export default function AddUnitSearch({
  TemplateId,
  rowSelected,
  setDialog,
  refreshModels,
}) {
  const { get, post } = useFetch();
  const [data, dispatch] = useReducer(SearchFluidsFilterReducer, {});

  const [fluidsData, setFluidsData] = useState([]);
  const [openAddUnit, setOpenAddUnit] = useState(false);
  const [unitSearchRowSelected, setUnitSearchRowSelected] = useState(false);

  const HandleNewWindow = (actionName) => {
    useWindowPopups(actionName)
  }

  const tblOptions = {
    columns: GetColumns(selectClick, editClick),
    dataSource: (fluidsData || []).map((x, i) => ({
      ...x, keyProp: i,
    })),
    getRowId: (originalRow) => (originalRow?.keyProp || ""),
    enableColumnActions: false,

    muiTableBodyProps: {
      sx: (theme) => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "#e2f2ff" },
        // backgroundColor: "inherit",//
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "#e2f2ff" },
      }),
    },
    rowsSelected: function (rows) {
      rowSelected && rowSelected(rows[0]);
    },
  };

  const formActions = {
    clear: function () {
      dispatch({ type: FormActions.RESET_FORM });
    },
    addNew: function () {
      setUnitSearchRowSelected(null);
      // setOpenAddUnit(true);
      HandleNewWindow("new-unit-model");
    },
    search: async function () {
      const tempFluidsData = await post(FLEET_APIS.SEARCH_UNIT_MODELS, {
        ...data,
        MakeOption: 1,
        ModelOption: 1,
      });
      setFluidsData(tempFluidsData);
    },
  };

  async function selectClick(row) {
    await get(`${FLEET_APIS.ADD_DELETE_TEMPLATE_MODEL}?TemplateId=${TemplateId}&UnitMakeId=${row.UnitMakeId}&IsDelete=false&Screen=Machine Templates`);

    setDialog((prevState) => ({
      ...prevState,
      open: false,
    }));
    refreshModels(TemplateId);
  }

  function editClick(row) {
    setUnitSearchRowSelected(row);
    setOpenAddUnit(true);
  }

  return (
    <>
      <Grid container sx={{ minWidth: "60vw" }}>
        <Grid item xs={12}>
          <Form
            sections={GetFormSections(data)}
            data={data}
            dispatch={dispatch}
            key={data._key}
          />
        </Grid>
        <Grid item xs={12}>
          <FormFooter
            buttons={GetFormButtons(data, formActions)}
            hideDivider
            gmt={0}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <NewDataTable {...tblOptions} />
        </Grid>
      </Grid>

      <AppDialog
        open={openAddUnit}
        title="New Unit Model"
        maxWidth="lg"
        handleClose={() => setOpenAddUnit(false)}
      >
        <EditUnitModel
          unitModel={unitSearchRowSelected}
        />
      </AppDialog>
    </>
  );
}

function GetFormSections() {
  return [
    {
      xsCol: 12,
      fields: [
        {
          xsCol: 6,
          name: "Make",
          label: "Make",
        },
        {
          xsCol: 6,
          name: "Model",
          label: "Model",
        },
      ],
    },
  ];
}

const GetFormButtons = (data, { addNew, clear, search }) => [
  {
    variant: "outlined",
    label: "Clear",
    onClick: clear,
  },
  {
    variant: "outlined",
    label: "New Model",
    onClick: addNew,
  },

  {
    variant: "contained",
    label: "Search",
    onClick: search,
    disabled: !(data?.Make || data?.Model)
  },
];

function GetColumns(selectClick, editClick) {
  return [
    {
      header: "Select",
      size: 50,
      Cell: function ({ row }) {
        return (
          <NewDataGridAction
            row={row?.original}
            onClick={() => selectClick(row?.original)}
            label={"Select"}
          />
        );
      },
    },
    {
      header: "Make",
      accessorKey: "UnitMake",
      size: 170,
    },
    {
      header: "Model",
      accessorKey: "UnitModel",
      size: 170,
    },
    {
      header: "Formatted Model",
      accessorKey: "UnitModelFormatted",
      size: 200,
    },
    {
      header: "Verified",
      accessorKey: "Verified",
      size: 80,
    },
    {
      header: "Komatsu",
      accessorKey: "IsKomatsu",
      size: 100,
    },
    {
      header: "Komatsu Group",
      accessorKey: "IsKomatsuGroup",
      size: 150,
    },
    {
      header: "Edit",
      sortable: false,
      size: 50,
      Cell: function ({ row }) {
        return (
          <NewDataGridAction
            row={row?.original}
            onClick={() => editClick(row?.original)}
            label={"Edit"}
          />
        );
      },
    },
  ];
}

function SearchFluidsFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === FormActions.RESET_FORM) {
    return { _key: Date.now() };
  }
  return state;
}
