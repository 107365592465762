import { useState } from "react";
import { FLEET_APIS } from "../../../common/apis";
import AppDialog from "../../../components/AppDialog";
import DataTable, { DataGridAction } from "../../../components/DataTable";
import useFetch from "../../../hooks/useFetch";
import AddComponent from "./AddComponent";
import NewDataTable, { NewDataGridAction } from "../../../components/NewDataTable";

export default function TemplatesComponentTable({
  data,
  refreshComponents,
  TemplateId,
}) {
  const { get } = useFetch();

  const [openSelectComponent, setOpenSelectComponent] = useState(false);
  const [componentRowData, setComponentRowData] = useState(false);

  async function deleteComponentClick(row) {
    await get(`${FLEET_APIS.DELETE_TEMPLATE_COMPONENT}?TemplateComponentId=${row.TemplateComponentId}&Screen=Machine Templates`);
    refreshComponents(row);
  }

  async function editClick(row) {
    setComponentRowData(row);
    setOpenSelectComponent(true);
  }

  const options = {
    columns: Columns(deleteComponentClick, editClick),
    dataSource: data,
    rowIdProperty: "TemplateComponentId",
    isDisableColumnFilter: true,
    sx: { height: "70dvh", },
    enableColumnActions: false,

    muiTableBodyProps: {
      sx: (theme) => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "#e2f2ff" },
        // backgroundColor: "inherit",//
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "#e2f2ff" },
      }),
    },
  };

  return (
    <>
      <AppDialog
        open={openSelectComponent}
        title="Edit Machine Template Component"
        maxWidth="lg"
        handleClose={() => setOpenSelectComponent(false)}
      >
        <AddComponent
          TemplateId={TemplateId}
          componentData={componentRowData}
          refreshComponents={refreshComponents}
          setOpenSelectComponent={setOpenSelectComponent}
        />
      </AppDialog>
      <NewDataTable {...options} />{" "}
    </>
  );

}

function Columns(deleteComponentClick, editClick) {
  return [
    {
      header: "Edit",
      sortable: false,
      size: 80,
      Cell: function ({ row }) {
        return (
          <NewDataGridAction
            row={row?.original}
            onClick={() => editClick(row?.original)}
            label={"Edit"}
          />
        );
      },
    },
    {
      header: "Component",
      accessorKey: "Component",
      minWidth: 150,
    },
    {
      header: "Fluid",
      accessorKey: "FluidType",
      minWidth: 170,
    },
    {
      header: "Type",
      accessorKey: "ComponentType",
      minWidth: 170,
    },
    {
      header: "Location",
      accessorKey: "Location",
      minWidth: 200,
    },
    {
      header: "Delete",
      sortable: false,
      minWidth: 120,
      Cell: function ({ row }) {
        return (
          <NewDataGridAction
            row={row?.original}
            onClick={() => deleteComponentClick(row?.original)}
            label={"Delete"}
          />
        );
      },
    },
  ];
}
