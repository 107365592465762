import { Card } from "@mui/material";
import Form, { SectionTitle } from "../../../components/Form";
import { Assessment, Edit, EventNote, Insights, LocationOn, NotificationsNone, } from "@mui/icons-material";
import FormFooter from "../../../components/FormFooter";
import { useState, useEffect } from "react";
import useFetch from "../../../hooks/useFetch";
import { DETAILS_APIS, TRAY_APIS } from "../../../common/apis";
import { useRoot } from "../../../rootContext";
import { useWindowPopups } from "../../../components/useWindowPopups";

function UnitDetail({ unitDetails, UnitTypes }) {

  const { post, getFile } = useFetch();
  const { CanDoDataMaintenance } = useRoot();

  const [isNotify, setIsNotify] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    const UnitTypeData = UnitTypes?.find(i => i?.UnitTypeId === unitDetails?.UnitTypeId);
    const UnitType = UnitTypeData?.UnitType || "";

    setData({ ...unitDetails, UnitType: UnitType, key: Date?.now() });

  }, [unitDetails, UnitTypes])

  useEffect(() => {
    if (data?.NotificationUserId !== null) {
      return setIsNotify(true)
    }
    else {
      return setIsNotify(false)
    }
  }, [data?.NotificationUserId])

  const getMachineInfo = async () => {
    let dataList = await post(DETAILS_APIS.UPDATE_MACHINE_NOTIFICATIONS,
      { UnitId: data?.UnitId, HasNotification: isNotify })
    if (dataList?.Success === true) {
      return setIsNotify(!isNotify)
    }
  }

  const viewHealthReport = async () => {
    let fileName = `CMHR ${data?.UnitModelFormatted || ""} ${data?.Serial || ""} `
    await getFile(`${TRAY_APIS.MACHINE_HEALTH_REPORT}/?EqpMachineId=${data.EqpMachineId}`, fileName);
  };

  const HandleNewWindow = (actionName) => {
    useWindowPopups(actionName, data)
  }

  const actions = {
    edit: function (e) {
      HandleNewWindow("edit-unit")
      // setOpen(true);
    },
    events: function (e) {
      HandleNewWindow("edit-event")
      // setOpenEditEvent(true);
    },
    notifyMe: function () {
      getMachineInfo()
    },
    healthReport: function () {
      viewHealthReport()
    },
    viewInsite: function () {
      window.open(
        `https://insite.komatsu.com.au/list?make=${data?.UnitMake}&model=${data?.UnitModel}&serial=${data?.Serial}`,
        "_blank"
      );
    },
    viewLocation: function () {
      window.open(
        `https://maps.google.com/?q=${data?.LocationLatitude},${data?.LocationLongitude}`,
        "_blank"
      );
    }
  };

  return (
    <>
      <Card sx={{ p: 1 }}>
        <Form
          readOnly
          sections={FormSections}
          data={data}
          key={(data?.UnitId + new Date()) || data?.key}
        />
        <FormFooter buttons={GetFormButtons(data, isNotify, actions, CanDoDataMaintenance)} />
      </Card>
    </>
  );
}

export default UnitDetail;

const GetFormButtons = (data, isNotify, actions, CanDoDataMaintenance) => [
  {
    variant: data?.EventLogCount > 0 ? "contained" : "outlined",
    color: data?.EventLogCount > 0 ? "lightseagreen" : "primary",
    label: "Event Log",
    startIcon: <EventNote />,
    onClick: actions.events,
    badge: { badgeContent: data.EventLogCount, badgeColor: "lightseagreen", sx: { [`& .MuiBadge-badge`]: { border: "1px solid white" } } },
  },
  {
    variant: "outlined", label: "Location", startIcon: <LocationOn />,
    onClick: actions.viewLocation,
    disabled: (data?.LocationLatitude === null && data?.LocationLongitude === null)
  },
  { variant: "outlined", label: "Insite", startIcon: <Insights />, onClick: actions.viewInsite },
  {
    variant: "outlined", label: "Health Report", startIcon: <Assessment />,
    disabled: (data?.EqpMachineId === null),
    onClick: actions.healthReport,
  },
  {
    variant: "outlined",
    label: isNotify === true ? "Unnotify" : "Notify Me",
    startIcon: <NotificationsNone />, onClick: actions.notifyMe,
  },
  CanDoDataMaintenance && {
    variant: "contained",
    label: "Edit",
    onClick: actions.edit,
    startIcon: <Edit />,
  },
];

const FormSections = [
  {
    xsCol: 12,
    fields: [
      {
        xsCol: 12,
        component: function () {
          return <SectionTitle title="Unit Details" />;
        },
      },
    ],
  },
  {
    fields: [
      { name: "Unit", label: "Unit / Fleet No.", xsCol: 3 },
      { name: "UIN", label: "UIN Asset No.", xsCol: 3 },
      { name: "KalBranch", label: "KAL Branch", xsCol: 3 },
      { name: "Serial", label: "Serial", xsCol: 3 },
      { name: "UnitType", label: "Type", xsCol: 3 },
      { name: "MeasureLong", label: "Measure", xsCol: 3 },
      { name: "UnitModelFormatted", label: "Model", xsCol: 3 },
      { name: "EventLogCount", label: "Event Log", xsCol: 3 },
      { name: "PIN", label: "PIN", xsCol: 3 },
      { name: "EqpMachineId", label: "MachineId", xsCol: 3 },
      { name: "LocationLatitude", label: "Latitude", xsCol: 3 },
      { name: "LocationLongitude", label: "Longitude", xsCol: 3 },
      { name: "UnitNotes", label: "Comment", type: "textarea", xsCol: 12, rows: 2 },
    ],
  },
];
