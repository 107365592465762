import { Card, Grid, Typography } from "@mui/material";
import TextBox from "../../../components/TextBox";
import UnitModelsTable from "./UnitModelsTable";
import useFetch from "../../../hooks/useFetch";
import { FLEET_APIS } from "../../../common/apis";
import { useEffect, useMemo, useState } from "react";
import EditUnitModel from "../../../forms/EditUnitModel";

export default function UnitModels() {

  const { get } = useFetch();

  const [unitsData, setUnitsData] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState({});
  const [filter, setFilter] = useState({});

  async function fetchUnitModels() {
    const temp = await get(FLEET_APIS.UNIT_MODELS_ALL);
    setUnitsData(temp || []);
  }

  useEffect(function () {
    document.title = "Unit Models";
    fetchUnitModels();
  }, []);

  function handelFilterChange(e) {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  }
  function unitRowSelectedHandler(row) {
    setSelectedUnit(row);
  }

  const filteredData = useMemo(
    function () {
      return unitsData.filter(
        (x) =>
          !filter.startsWith ||
          x.UnitModelFormatted?.toUpperCase().startsWith(
            filter.startsWith.toUpperCase()
          )
      );
    },
    [unitsData, filter]
  );

  return (
    <>
      <Grid container spacing={1}>
        <Grid item container xs={12} spacing={1} alignItems="flex-end">
          <Grid item>
            <TextBox
              name="startsWith"
              label="Starts with"
              //value={filter.startsWith}
              onChange={handelFilterChange}
            />
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Typography>
            Total Records: <b>{filteredData?.length}</b>
          </Typography>
          <UnitModelsTable
            data={filteredData}
            rowSelected={unitRowSelectedHandler}
            fetchUnitModels={fetchUnitModels}
            selectedRow={selectedUnit?.UnitMakeId + selectedUnit?.TemplateId}
          />
        </Grid>
        <Grid item xs={6} mt={"20px"}>
          <Card sx={{ p: 1 }}>
            <EditUnitModel
              manageCheck={true}
              unitModel={selectedUnit}
              key={selectedUnit.UnitMakeId}
              fetchUnitModels={fetchUnitModels}
            />
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
