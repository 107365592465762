import { useState } from "react";
import renderCellExpand from "../../../components/DatatableCellExpand";
import NewDataTable from "../../../components/NewDataTable";

export default function TestSetsTable({ data, actions }) {

  const [rowSelection, setRowSelection] = useState({});

  const options = {
    columns: GetColumns(actions),
    dataSource: data || [],
    getRowId: (originalRow) => originalRow.TestSetId,

    sx: { height: "84dvh", },
    enableColumnActions: false,
    enableSorting: false,
    muiTableBodyProps: {
      sx: (theme) => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "#e2f2ff" },
        // backgroundColor: "inherit",//
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "#e2f2ff" },
      }),
    },

    muiTableBodyCellProps: ({ cell, column, row, table }) => {
      return {
        sx: cell.column.columnDef.accessorKey === "Product" ? {
          backgroundColor: (row?.original?.Colour + "!important") || "",
          color: "white",
          border: "0.5px solid #d0d0d0",
          py: 0,
        } : { height: "8px", borderLeft: "0.5px solid #d0d0d0", }
      }
    },

    muiTableBodyRowProps: ({ row }) => ({
      //implement row selection click events manually
      onClick: () => {
        // rowSelected(row.original);
        setRowSelection((prev) => ({
          [row.id]: !prev[row.id],
        }))
      },
      selected: rowSelection[row.id],
      sx: {
        cursor: 'pointer',
      },
    }),
    state: { rowSelection },
  };
  return <NewDataTable {...options} />;
}

function GetColumns(actions) {
  return [
    {
      header: "Test Set",
      accessorKey: "TestSet",
      size: 200,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "Specific",
      accessorKey: "IsCustomerSpecific",
      Cell: ({ renderedCellValue }) => boolToYesAndEmpty(renderedCellValue),
      size: 45,
    },
    {
      header: "System",
      accessorKey: "IsSystemTestSet",
      Cell: ({ renderedCellValue }) => boolToYesAndEmpty(renderedCellValue),
      size: 45,
    },
    {
      header: "Limits",
      accessorKey: "Limits",
      Cell: ({ renderedCellValue }) => boolToYesAndEmpty(renderedCellValue),
      size: 45,
    },
    {
      header: "Fluid",
      accessorKey: "FluidType",
      size: 120,
    },
    {
      header: "Product",
      accessorKey: "Product",
      size: 100,
    },
    {
      header: "Archived",
      accessorKey: "IsArchived",
      Cell: ({ renderedCellValue }) => boolToYesAndEmpty(renderedCellValue),
      size: 60,
    },
  ];
}

function boolToYesAndEmpty(value) {
  return value ? "Yes" : "";
}
