import { Card } from "@mui/material";
import Form, { SectionTitle } from "../../../components/Form";
import {
  ContactPage,
  Edit,
  EventNote,
  NotificationsNone,
} from "@mui/icons-material";
import FormFooter from "../../../components/FormFooter";
import { useState, useEffect } from "react";
import { DETAILS_APIS } from "../../../common/apis";
import useFetch from "../../../hooks/useFetch";
import { useRoot } from "../../../rootContext";
import { useWindowPopups } from "../../../components/useWindowPopups";

function SiteDetail({
  siteDetails,
}) {
  const { post } = useFetch();
  const { CanDoDataMaintenance } = useRoot();

  const [isNotify, setIsNotify] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    setData({ ...siteDetails, key: Date?.now() })
  }, [siteDetails])

  useEffect(() => {
    if (data?.NotificationUserId !== null) {
      return setIsNotify(true)
    }
    else {
      return setIsNotify(false)
    }
  }, [data?.NotificationUserId])

  const getMachineInfo = async () => {
    let dataList = await post(DETAILS_APIS.UPDATE_MACHINE_NOTIFICATIONS,
      { SiteId: data?.SiteId, HasNotification: isNotify })
    if (dataList?.Success === true) {
      return setIsNotify(!isNotify)
    }
  }

  const HandleNewWindow = (actionName) => {
    useWindowPopups(actionName, data)
  }

  const actions = {
    edit: function (e) {
      HandleNewWindow("edit-site")
    },
    events: function (e) {
      HandleNewWindow("edit-event")
    },
    notifyMe: function () {
      getMachineInfo()
    },
    contacts: function () {
      HandleNewWindow("search-contact", { SiteId: siteDetails?.SiteId });
    },
  };

  return (
    <>
      <Card sx={{ p: 1 }}>
        <Form
          readOnly
          sections={FormSections}
          spacing={2}
          data={data}
          key={data?.SiteId || data?.key}
        />
        <FormFooter buttons={GetFormButtons(data, isNotify, actions, CanDoDataMaintenance)} />
      </Card>

    </>
  );
}

export default SiteDetail;

const GetFormButtons = (data, isNotify, actions, CanDoDataMaintenance) => [
  {
    variant: data?.EventLogCount > 0 ? "contained" : "outlined",
    color: data?.EventLogCount > 0 ? "lightseagreen" : "primary", label: "Event Log",
    startIcon: <EventNote />,
    onClick: actions.events,
    badge: { badgeContent: data.EventLogCount, badgeColor: "lightseagreen", sx: { [`& .MuiBadge-badge`]: { border: "1px solid white" } } },
  },
  {
    variant: "outlined",
    onClick: actions.contacts,
    label: "Contact",
    // disabled: !data.HasPrimaryContact,
    startIcon: <ContactPage />,
  },
  {
    variant: "outlined",
    label: isNotify === true ? "Unnotify" : "Notify Me",
    onClick: actions.notifyMe,
    startIcon: <NotificationsNone />
  },
  CanDoDataMaintenance && {
    variant: "contained",
    label: "Edit",
    onClick: actions.edit,
    startIcon: <Edit />,
  },
];

const FormSections = [
  {
    xsCol: 12,
    fields: [
      {
        xsCol: 12,
        component: function () {
          return <SectionTitle title="Site Details" />;
        },
      },
      { name: "Site", label: "Site", xsCol: 3 },
      { name: "SiteExportOption", label: "Exports", xsCol: 3 },
      { name: "SiteCmsOwnerName", label: "CMS Owner", xsCol: 3, },
      { name: "KalBranch", label: "Branch", xsCol: 3 },
      // { name: "EventLogCount", label: "Event Log", xsCol: 3 },
      // { name: "HasPrimaryContact", label: "Contacts", xsCol: 3, },
      { name: "SiteNotes", label: "Comment", type: "textarea", xsCol: 12, rows: 2 },
    ],
  },
];
