import { Delete } from "@mui/icons-material";
import { Button, Card, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useReducer, useState } from "react";
import { MASTER_DATA_APIS } from "../../common/apis";
import DataTable, { DataGridAction } from "../../components/DataTable";
import Form, { FormActions } from "../../components/Form";
import useFetch from "../../hooks/useFetch";
import { NewIcon, SaveIcon } from "../../icons/ButtonIcons";
import AppDialog from "../../components/AppDialog";
import appResources from "../../app-resources/en/Resources.json";
import { useRoot } from "../../rootContext";
import renderCellExpand from "../../components/DatatableCellExpand";
import ConfirmModal from "../../components/ConfirmModal";

export default function AutoEvaluationComments() {
  const [data, dispatch] = useReducer(SearchFilterReducer, {});
  const { get, post } = useFetch();
  const { CanAdministrate } = useRoot();
  const [dialog, setDialog] = useState({
    open: false,
    message: "",
  });

  const [commentsData, setCommentsData] = useState([]);

  //get api for loading data
  useEffect(() => {
    document.title = "AutoEvaluation Comments";
    resetData();
  }, []);

  async function resetData() {
    let resData = await get(MASTER_DATA_APIS.AUTO_EVAL_COMMENTS, {});
    if (resData) {
      setCommentsData(resData);
    }
  }

  //Save data
  async function saveClick() {
    await post(MASTER_DATA_APIS.EDIT_AUTO_EVAL_COMMENTS, { ...data });
    // Object.keys(data)[0] === "AutoEvalCommentId"
    //   ? showInfoToast(appResources.COMMENT_UPDATED_SUCCESSFULLY_MSG)
    //   : showToast(appResources.COMMENT_ADDED_SUCCESSFULLY_MSG);
    await resetData();
    dispatch({ type: FormActions.RESET_FORM });
  }

  //Delete data
  async function deleteClick() {
    setDialog({
      open: true,
      message: appResources.DELETE_CONFIRMATION_MSG,
    });
  }

  async function deleteClickAlert() {
    await get(
      `${MASTER_DATA_APIS.DELETE_AUTO_EVAL_COMMENTS}?AutoEvalCommentId=${data.AutoEvalCommentId}`
    );
    await resetData();
    dispatch({ type: FormActions.RESET_FORM });
    setDialog((prevState) => ({ ...prevState, open: false }));
  }

  const tblOptions = {
    columns: GetColumns(editClick),
    dataSource: commentsData || [],
    rowIdProperty: "AutoEvalCommentId",
    isDisableColumnFilter: true,
    sx: { height: 700 },
  };
  function editClick(rowData) {
    dispatch({
      payload: {
        ...rowData,
      },
      type: EventActions.LOAD_DATA,
    });
  }
  function newCLick() {
    dispatch({ type: FormActions.RESET_FORM });
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography sx={{ fontSize: "13px" }}>
            Please edit a comment for auto-evaluation
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ p: 1 }}>
            <Form
              sections={GetFormSections(
                newCLick,
                deleteClick,
                saveClick,
                data,
                CanAdministrate
              )}
              data={data}
              dispatch={dispatch}
              key={data.AutoEvalCommentId || data._key}
            />
          </Card>
        </Grid>

        <Grid item xs={12} sx={{ mt: 2 }}>
          <Typography
            variant="subtitle2"
            sx={{ marginLeft: "5px", marginTop: "10px" }}
          >
            Total Records:{" "}
            <b> {commentsData.length > 0 && commentsData.length}</b>
          </Typography>
          <DataTable {...tblOptions} />
        </Grid>
      </Grid>
      <AppDialog
        open={dialog.open}
        handleClose={() =>
          setDialog((prevState) => ({ ...prevState, open: false }))
        }
        title="Delete Comment"
        maxWidth="md"
      >
        <ConfirmModal
          handleClose={() => {
            setDialog((prevState) => ({ ...prevState, open: false }));
          }}
          alertAction={deleteClickAlert}
          message={dialog.message}
          buttonText={"Delete"}
          buttonText2={"Cancel"} />
      </AppDialog>
    </>
  );
}

function GetColumns(editClick) {
  return [
    {
      headerName: "Edit",
      field: "Edit",
      sortable: false,
      minWidth: 120,
      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction row={row} onClick={editClick} label="Edit" />
          </>
        );
      },
    },
    {
      headerName: "Evaluation",
      field: "AutoEvalComment",
      minWidth: 300,
      flex: 1,
      renderCell: renderCellExpand,
    },
  ];
}
function GetFormSections(
  newCLick,
  deleteClick,
  saveClick,
  data,
  CanAdministrate
) {
  return [
    {
      fields: [
        {
          name: "AutoEvalComment",
          xsCol: 8,
          type: "textarea",
        },
        {
          xsCol: 4,
          group: [
            {
              component: function () {
                return (
                  <Button
                    variant="outlined"
                    disabled={!data.AutoEvalComment}
                    onClick={newCLick}
                    sx={{ mt: "23px" }}
                    startIcon={<NewIcon />}
                  >
                    New
                  </Button>
                );
              },
            },
            CanAdministrate && {
              component: function () {
                return (
                  <Button
                    variant="outlined"
                    disabled={!data?.AutoEvalCommentId}
                    onClick={deleteClick}
                    sx={{ mt: "23px" }}
                    startIcon={<Delete />}
                  >
                    Delete
                  </Button>
                );
              },
            },
            CanAdministrate && {
              component: function () {
                return (
                  <Button
                    variant="contained"
                    disabled={!data.AutoEvalComment}
                    onClick={saveClick}
                    sx={{ mt: "23px" }}
                    startIcon={<SaveIcon />}
                  >
                    Save
                  </Button>
                );
              },
            },
          ],
        },
      ],
    },
  ];
}
const EventActions = {
  LOAD_DATA: "load-data",
};
function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === FormActions.RESET_FORM) {
    return { _key: Date.now() };
  }
  if (action.type === EventActions.LOAD_DATA) {
    return { ...action.payload };
  }
  return state;
}
