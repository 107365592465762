import * as React from "react";
import { Check } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { useReducer, } from "react";
import Form, { FormActions } from "../../components/Form";
import FormFooter from "../../components/FormFooter";
import useFetch from "../../hooks/useFetch";
import useMasterData from "../../hooks/useMasterData";
import { FLEET_APIS } from "../../common/apis";
import appResources from "../../app-resources/en/Resources.json";

export default function NewParent({
  fetchData,
  setNewParentOpen,
  setInfoMessage,
}) {
  //useFetch
  const [data, dispatch] = useReducer(FluidReducer, SearchFilterDefaults);
  const [masterData, setMasterData] = React.useState({});
  const { get, } = useFetch();
  const { getMasterData } = useMasterData();

  const actions = {
    save: async function () {
      const response = await get(
        `${FLEET_APIS.ADD_PARENT}?productid=` +
        data.ProductId +
        "&Parent=" +
        data.Parent
      );
      if (response > 0) {
        setInfoMessage(appResources.CUSTOMER_PARENT_ADDED);
        fetchData();
      } else {
      }
      setNewParentOpen(false);
    },
  };

  async function fetchProductType() {
    const temp = await getMasterData("Products", ``);
    setMasterData(temp || {});
    return temp;
  }

  React.useEffect(function () {
    (async function () {
      await fetchProductType();
    })();
    //func();
  }, []);

  return (
    <>
      <Grid container xs={12} >
        <Grid item xs={12}>
          <Form
            sections={GetFormSections(masterData)}
            data={data}
            dispatch={dispatch}
          />
        </Grid>
        <Grid item xs={12}>
          <FormFooter buttons={GetFormButtons(actions)} hideDivider gmt={0} />
        </Grid>
      </Grid>
    </>
  );
}

const GetFormButtons = ({ save }) => [
  {
    variant: "contained",
    label: "Save",
    type: "submit",
    onClick: save,
    startIcon: <Check />,
  },
];

function GetFormSections(data) {
  return [
    {
      xsCol: 12,
      fields: [
        {
          xsCol: 6,
          name: "Parent",
          label: "Parent",
        },
        {
          xsCol: 6,
          name: "ProductId",
          label: "Product",
          type: "dropdown",
          selectOptions: (data.Products || []).map((x) => ({
            value: x.ProductId,
            text: x.Product,
          })),
          defaultValue: 1,
        },
      ],
    },
  ];
}

function FluidReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  return state;
}
const SearchFilterDefaults = {
  ProductId: 2,
};
