import React from "react";
import Grid from "@mui/material/Grid";
import Chart from "chart.js/auto";
import { Scatter } from "react-chartjs-2";
import { useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import {
  Button,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
} from "@mui/material";
import baChartAnnotations from "chartjs-plugin-annotation";
Chart.register(baChartAnnotations);
//updating chart

const TabTrayChart = ({ sampleId }) => {
  const { get } = useFetch();

  const [options, setOptions] = useState({
    elements: {
      pointStyle: "cross",
    },
    plugins: {
      datalabels: {
        display: false,
        color: "black",
        align: "top",
        formatter: function (value, context) {
          return value.y;
        },
      },

      legend: {
        position: "right",
        align: "center",
        labels: {
          boxWidth: 10,
          pointStyle: "cross",
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return context.raw.elementHover;
          },
          title: function (context) {
            return "";
          },
        },
      },
      annotation: {
        annotations: {
          line1: {
            type: "line",
            yMin: 60,
            yMax: 60,
            borderColor: "rgb(255, 99, 132)",
            borderWidth: 2,
          },
        },
      },
    },
  });
  const [chartData, setChartData] = useState(null);


  function setChartOptions(isShow) {
    setOptions({
      elements: {
        pointStyle: "cross",
      },
      plugins: {
        datalabels: {
          display: isShow,
          color: "black",
          align: "top",
          formatter: function (value, context) {
            return value.y;
          },
        },
        legend: {
          position: "right",
          align: "center",
          labels: {
            boxWidth: 10,
            pointStyle: "cross",
          },
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              return context.raw.elementHover;
            },
            title: function (context) {
              return "";
            },
          },
        },
      },
    });
  }
  const handleValueChkChange = (event, val) => {
    setChartOptions(val);
  };

  function handleRefresh() {
    window.location.reload(true);
  }

  async function fetchData(sampleNumber) {
    const sampleData = await get(`/trays/TrayChart?SampleNumber=${sampleNumber}`);

    setChartData(sampleData && sampleData.chartData);
  }

  useEffect(function () {
    fetchData(sampleId);
  }, [sampleId]);

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid
          item
          direction="column"
          alignItems="center"
          justifyContent="center"
          xs={9}
        >
          <Stack direction="row" spacing={2}>
            <FormGroup>
              <FormControlLabel
                onChange={handleValueChkChange}
                control={<Switch />}
                label="Values"
              />
            </FormGroup>

            <FormGroup>
              <Button onClick={handleRefresh}>Refresh</Button>
            </FormGroup>
          </Stack>
          {chartData && (
            <Scatter
              data={chartData}
              options={options}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default TabTrayChart;
