import { Button, Card, Grid, Box, Typography, ListItem } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import TextBox from "../../../components/TextBox";
import useFetch from "../../../hooks/useFetch";
import DropDown from "../../../components/DropDown";
import DropDownAdvance from "../../../components/DropDownAdvance";
import AppDialog from "../../../components/AppDialog";
import LinkFluidWatch from "./LinkFluidWatch";
import { List } from "@mui/material";
import { DETAILS_APIS } from "../../../common/apis";
import { useRoot, useToast } from "../../../rootContext";
import appResources from "../../../app-resources/en/Resources.json";
import { SaveCommentsIcon, DeleteIcon, LinkIcon, } from "../../../icons/ButtonIcons";
import useQuery from "../../../hooks/useQuery";
import ConfirmModal from "../../../components/ConfirmModal";
import useMasterData from "../../../hooks/useMasterData";

export default function FluidWatch({
  fluidId,
  setFluidWatchOpen,
}) {
  const { post, get } = useFetch();
  const { showToast, showErrorToast } = useToast();
  const { CanDoSpecialReports, CanEvaluateNotTrainee } = useRoot();
  const { getMasterData } = useMasterData();

  const [fluids, setFluids] = useState({});
  const [fluidIdFilter, setFluidIdFilter] = useState();
  const [fluidIds, setFluidId] = useState(fluidId);
  const [masterData, setMasterData] = useState({});
  const [selectedFluid, setSelectedFluid] = useState({});
  const [selectedFluidType, setSelectedFluidType] = useState();
  const [validationError, setValidationError] = useState("");
  const [addLinkDialogueOpen, setAddLinkDialogueOpen] = useState(false);
  const [linksList, setLinksList] = useState([]);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  const [alertWidth, setAlertWidth] = useState("");
  const { urlfluidId } = useQuery();

  // get fluid types and fluid data
  useEffect(
    function () {

      (async function () {
        if (!fluidIds && !urlfluidId) {
          await refreshFluids(1);
          setSelectedFluidType(1);
        }
        else {
          const temp = await getFluidData(urlfluidId || fluidIds);
          if (Array.isArray(temp)) {
            const tempArray = temp[0];
            await refreshFluids(tempArray.FluidTypeId);
            setSelectedFluid(tempArray);
            const fluidLinkData = await get(`${DETAILS_APIS.GET_FLUID_WATCH_LINKS}?FluidId=${fluidIds || urlfluidId}`, { skipBlocker: true });
            setLinksList(fluidLinkData);
            return setSelectedFluidType(tempArray.FluidTypeId);
          }
          else if (typeof temp === "object") {
            await refreshFluids(temp.FluidTypeId);
            setSelectedFluid(temp);
            const fluidLinkData = await get(`${DETAILS_APIS.GET_FLUID_WATCH_LINKS}?FluidId=${fluidIds || urlfluidId}`, { skipBlocker: true });
            setLinksList(fluidLinkData);
            return setSelectedFluidType(temp.FluidTypeId);
          }
        }
      })();
    },
    []
  );

  async function fetchFluidType() {
    const resp = await getMasterData("FluidTypes");
    if (resp?.FluidTypes?.length > 0) {
      setMasterData(resp)
    }
  }

  useEffect(() => {
    fetchFluidType()
  }, [])

  function watchOnchangeHandler(e) {
    setSelectedFluid({ ...selectedFluid, [e.target.name]: e.target.value });
  }

  function handelOpenDialogueClick() {
    setAddLinkDialogueOpen(true);
  }

  //Call Fluids links data when fluid id change.
  async function getFluidLinkData(fId) {
    const fluidLinkData = await get(`${DETAILS_APIS.GET_FLUID_WATCH_LINKS}?FluidId=${fId || fluidIds}`, { skipBlocker: true });
    setLinksList(fluidLinkData);
  }

  async function refreshFluids(fType) {
    if (!fType || fluids[fType]) return;
    const temp = await get(`${DETAILS_APIS.WATCH_BY_TYPE}?typeId=${fType}`, { skipBlocker: true, });
    setFluids({ ...fluids, [fType]: temp });
  }

  function fluidTypeChangeHandler(event) {
    setSelectedFluidType(event.target.value);
    setSelectedFluid({});
    refreshFluids(event.target.value);
  }

  //Get Fluid existing controls data
  async function getFluidData(fId) {
    if (!fId) return {};
    return ((await get(`${DETAILS_APIS.GET_WATCH_DATA}?fluidId=${fId}`, { skipBlocker: true, })) || {});
  }

  //Save new fluid
  function SaveClick() {
    if (fluidIds > 0 || urlfluidId > 0) {
      setValidationError(""); // Clear validation error if fluid is selected
      (async () => {
        const response = await post(DETAILS_APIS.SAVE_FLUID_WATCH, {
          FluidId: fluidIds || urlfluidId,
          FluidWatchId: selectedFluid.FluidWatchId || fluidIdFilter,
          WatchNotes: selectedFluid.WatchNotes,
          WatchDetail1: selectedFluid.WatchDetail1,
          WatchDetail2: selectedFluid.WatchDetail2,
          Screen: "Fluid Watch",
        });
        if (response > 0) {
          showToast(appResources.FLUID_WATCH_ADDED_SUCCESSFULLY_MSG);
          setFluidWatchOpen && setFluidWatchOpen(false);
        } else {
          showErrorToast(appResources.SOMETHING_WENT_WRONG_MSG);
          setFluidWatchOpen && setFluidWatchOpen(false);
        }
      })();
    }
    else {
      setValidationError("Please select a fluid."); // Set validation error message if fluid is not selected
    }
  }

  //Delete new fluid
  function deleteDialogue() {
    setAlertTitle("Fluid Watch");
    setAlertWidth("md");
    setOpenDialog(true);
    setAlertMessage(appResources.DELETE_FLUID_WATCH_CONFIRMATION_MSG);
  }

  function deleteClickAlert() {
    (async () => {
      const response = await get(
        `${DETAILS_APIS.DELETE_FLUID_WATCH}?FluidId=${selectedFluid.FluidId}&Screen="Fluid Watch"`
      );
      if (response > 0) {
        showToast(appResources.FLUID_WATCH_DELETED_SUCCESSFULLY_MSG);
        setFluidWatchOpen && setFluidWatchOpen(false);
      } else {
        showErrorToast(appResources.SOMETHING_WENT_WRONG_MSG);
        setFluidWatchOpen && setFluidWatchOpen(false);
      }
    })();
    setOpenDialog(false);
  }

  const fluidOptions = useMemo(
    function () {
      if (fluids && fluids[selectedFluidType]) {
        return (
          fluids[selectedFluidType].filter(
            (x) => x.FluidTypeId === selectedFluidType
          ) || []
        );
      }
    },
    [fluids, selectedFluidType]
  );

  const fluidType = useMemo(
    function () {
      if (masterData.FluidTypes && selectedFluidType) {
        return (
          masterData?.FluidTypes?.find(
            (x) => x.FluidTypeId === selectedFluidType
          ) || {}
        );
      }
      return [];
    },
    [selectedFluidType]
  );

  const handleDDChange = async (e, value, name) => {
    setFluidId(value);
    const fId = value;
    setFluidIdFilter(fId);
    const temp = await getFluidData(fId);
    setSelectedFluid({ ...(temp || {}), FluidId: fId });
    getFluidLinkData(fId)
  }

  return (
    <>
      <Card
        sx={{ p: 1, overflow: "auto", minWidth: "75vw", minHeight: "40vh" }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <DropDown
              label="Fluid Type"
              onChange={fluidTypeChangeHandler}
              key={selectedFluid?.FluidTypeId || selectedFluidType}
              selectOptions={(masterData.FluidTypes || []).map((x) => ({
                text: x.FluidType,
                value: x.FluidTypeId,
              }))}
              value={selectedFluid?.FluidTypeId || selectedFluidType}
            />
          </Grid>
          <Grid item xs={6}>
            <FluidsDropDown
              id={"DefaultFluidId"}
              dataList={fluidOptions}
              handleChange={(e, val, text) => handleDDChange(e, val, text)}
              defaultValue={selectedFluid?.FluidId}
            />
            {validationError && (
              <Typography style={{ color: "red" }}>
                {validationError}
              </Typography>
            )}
          </Grid>
          {fluidType.DetailsLabel1 && (
            <Grid item xs={3}>
              <TextBox
                name="WatchDetail1"
                label={fluidType.DetailsLabel1}
                value={selectedFluid.WatchDetail1 || ""}
                onChange={watchOnchangeHandler}
              />
            </Grid>
          )}
          {fluidType.DetailsLabel2 && (
            <Grid item xs={3}>
              <TextBox
                name="WatchDetail2"
                label={fluidType.DetailsLabel2}
                value={selectedFluid.WatchDetail2 || ""}
                onChange={watchOnchangeHandler}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextBox
              label="Notes"
              name="WatchNotes"
              multiline
              rows={4}
              value={selectedFluid.WatchNotes || ""}
              onChange={watchOnchangeHandler}
            />
          </Grid>
          <Grid item xs={6}>
            <List>
              {linksList?.map((item, i) => (
                <ListItem key={i} sx={{ p: 0 }}>
                  <a
                    href={(item.Link.includes("http") === true ? item.Link : "https://" + item.Link)}
                    target="_blank"
                    rel="noopener noreferrer">
                    {item?.LinkDescription || ""}
                  </a>
                  <br></br>
                </ListItem>
              ))}
            </List>
          </Grid>
          {(CanEvaluateNotTrainee) && <Grid item xs={6}>
            <Grid container spacing={2} justifyContent="flex-end">
              {CanDoSpecialReports && <Grid item>
                <Button
                  onClick={deleteDialogue}
                  startIcon={<DeleteIcon />}
                  variant="outlined"
                  disabled={!selectedFluid.FluidWatchId}
                >
                  Delete
                </Button>
              </Grid>}
              <Grid item>
                <Button
                  item
                  variant="contained"
                  startIcon={<SaveCommentsIcon />}
                  onClick={SaveClick}
                >
                  Save
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={handelOpenDialogueClick}
                  startIcon={<LinkIcon />}
                  variant="outlined"
                >
                  Links
                </Button>
              </Grid>
            </Grid>
          </Grid>}
        </Grid>
      </Card>


      <AppDialog
        open={addLinkDialogueOpen}
        handleClose={() => setAddLinkDialogueOpen(false)}
        title="Links for Fluid Watch"
        maxWidth="sm"
      >
        <LinkFluidWatch
          fluidId={selectedFluid.FluidId}
          onFluidLinkUpdated={getFluidLinkData}
          setAddLinkDialogueOpen={setAddLinkDialogueOpen}
        />
      </AppDialog>
      <AppDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        title={alertTitle}
        maxWidth={alertWidth}
      >
        {alertTitle === "Fluid Watch" && (
          <>
            <ConfirmModal
              handleClose={() => { setOpenDialog(false); }}
              alertAction={deleteClickAlert}
              message={alertMessage}
              buttonText={"Delete"}
              buttonText2={"Cancel"}
            />
          </>
        )}
      </AppDialog>
    </>
  );
}

function FluidsDropDown({ id, dataList, handleChange, defaultValue }) {

  const renderOptions = useCallback(function (props, option) {
    return (
      <Box component="li" sx={{ minWidth: "280px", maxWidth: "280px" }} {...props} key={option.FluidId}>
        {option.FluidFormatted || "Select"}
      </Box>
    );
  }, []);

  const filterOptions = useCallback(function (options, value) {
    return !value.inputValue
      ? options
      : options
        .filter((x) =>
          x.FluidFormatted.toLowerCase().includes(
            value.inputValue.toLowerCase()
          )
        )
  }, []);

  const getOptionLabel = useCallback(function (option) {
    return option.FluidFormatted || "Select";
  }, []);

  return (
    <DropDownAdvance
      id={id}
      label="Fluid"
      renderOptions={renderOptions}
      filterOptions={filterOptions}
      getOptionLabel={getOptionLabel}
      onChange={(e, val) => handleChange(e, val?.FluidId || null, "DefaultFluidId")}
      options={dataList || []}
      defaultValue={dataList?.find((x) => x.FluidId === defaultValue) || "Select"}
    />
  );
}
