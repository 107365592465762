import renderCellExpand from "../../../components/DatatableCellExpand";
import NewDataTable from "../../../components/NewDataTable";
import { toDateTimeFormat1, toLocalDate2, } from "../../../utils";
import { useEffect, useState } from "react";

export default function RerunSampleTable(props) {
  const [rowSelection, setRowSelection] = useState({});

  useEffect(() => { setRowSelection({}) }, [props?.data])

  const options1 = {
    columns: Columns1,
    dataSource: (props.data || []).map((x, i) => ({
      ...x,
      keyProp: i,
    })),
    getRowId: (originalRow) => originalRow.keyProp,
    enablePagination: false,
    // sx: { height: 300 },
    sx: { height: 750 },
    enableColumnActions: false,

    onRowSelectionChange: setRowSelection,
    muiSelectCheckboxProps: {
      color: 'secondary',
      backgroundColor: "blue"
    },
    muiTableBodyProps: {
      sx: (theme) => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "#e2f2ff" },
        // backgroundColor: "inherit",//
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "#e2f2ff" },
      }),
    },

    muiTableBodyRowProps: ({ row }) => ({
      //implement row selection click events manually
      onClick: () => {
        setRowSelection((prev) => ({
          [row.id]: !prev[row.id],
        }))
      },
      sx: {
        cursor: 'pointer',
        height: "20px",
        fontWeight: "500",
      },
    }),
    state: { rowSelection },
  };

  return <NewDataTable {...options1} />;//<DataTable {...options}></DataTable>;
}

const Columns1 = [
  {
    header: "Laboratory",
    accessorKey: "Laboratory",
    size: 65,
  },
  {
    header: "Tray",
    accessorKey: "Tray",
    size: 25,
  },
  {
    header: "Tray Date",
    accessorKey: "TrayDate",
    Cell: ({ renderedCellValue }) => toLocalDate2(renderedCellValue),
    size: 50,
  },
  {
    header: "SampleNumber",
    accessorKey: "SampleNumber",
    size: 70,
    Cell: ({ renderedCellValue }) => <pre>{(renderedCellValue)}</pre>,
  },
  {
    header: "Count",
    accessorKey: "SampleCount",
    size: 40,
  },
  {
    header: "Sent Back",
    accessorKey: "SentBack",
    size: 80,
    Cell: ({ renderedCellValue }) => toDateTimeFormat1(renderedCellValue),
  },
  {
    header: "Sent By",
    accessorKey: "SentBy",
    size: 120,
  },
  {
    header: "Sent From",
    accessorKey: "SentFrom",
    size: 80,
  },
  {
    header: "Released",
    accessorKey: "LaboratoryRelease",
    size: 80,
    Cell: ({ renderedCellValue }) => toDateTimeFormat1(renderedCellValue),
  },
  {
    header: "Reason",
    accessorKey: "Reason",
    size: 1000,
    flex: 1,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),

  },
];