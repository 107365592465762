import DataTable, { DataGridAction } from "../../components/DataTable";
import { toLocalDate } from "../../utils";

export default function SampleEventsTable({ data, sx, editClick }) {
  const options = {
    columns: GetColumns(editClick),
    dataSource: data || [],
    rowIdProperty: "EventLogId",
    isDisableColumnFilter: true,
  };
  return <DataTable {...options} sx={sx} />;
}
function GetColumns(editClick) {
  return [
    {
      headerName: "Edit",
      field: "Edit",
      sortable: false,
      width: 80,
      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction row={row} onClick={() => editClick(row)} label="Edit" />
          </>
        );
      },
    },
    {
      headerName: "Scope",
      field: "Scope",
      width: 100,
    },
    {
      headerName: "Type",
      field: "EventType",
      width: 200,
      cellClassName: ({ row }) => {
        if (row.BackColour === "ForestGreen") {
          return "cell-fg-wt";
        }
        if (row.BackColour === "DimGray") {
          return "cell-dg-wt";
        }
        if (row.BackColour === "Red") {
          return "cell-rd-wt";
        }
        if (row.BackColour === "Maroon") {
          return "cell-mn-wt";
        }
        if (row.BackColour === "Purple") {
          return "cell-pr-wt";
        }
        return "";
      },
    },
    {
      headerName: "Occured",
      field: "EventDate",
      valueFormatter: ({ value }) => toLocalDate(value),
      width: 150,
    },
    {
      headerName: "Hours",
      field: "UnitHours",
      width: 120,
    },

    {
      headerName: "Reading",
      field: "MeterReading",
      width: 120,
    },
    {
      headerName: "Event",
      field: "Event",
      minWidth: 180,
      flex: 1
    },
  ]
}
