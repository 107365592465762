import { toDateTimeFormat1 } from "../../../utils";
import renderCellExpand from "../../../components/DatatableCellExpand";
import NewDataTable, { NewDataGridAction } from "../../../components/NewDataTable";

export default function ActionedTable({ data, sx, editClick, viewClick }) {

  const tblOptions1 = {
    columns: GetColumns(editClick, viewClick),
    dataSource: data || [],
    enablePagination: false,
    sx: { height: 750 },
    enableColumnActions: false,
    muiTableBodyProps: {
      sx: (theme) => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "#e2f2ff" },
        // backgroundColor: "inherit",//
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "#e2f2ff" },
      }),
    },
    muiTableBodyCellProps: ({ cell, column, row, table }) => {
      return {
        sx: cell.column.columnDef.accessorKey === "AlertStatus"
          ? {
            backgroundColor: (row?.original?.AlertColour + "!important") || "",
            color: "white",
            height: "8px", borderLeft: "1px solid #d0d0d0"
          }
          : cell.column.columnDef.accessorKey === "Product"
            ? {
              backgroundColor: (row?.original?.ProductColour + "!important") || "",
              color: "white",
              height: "8px", borderLeft: "1px solid #d0d0d0"
            }
            : {
              height: "8px", borderLeft: "1px solid #d0d0d0"
            }
      }
    }

  };

  return <NewDataTable {...tblOptions1} />
}

function GetColumns(editClick, viewClick) {
  return [
    {
      header: "Actions",
      field: "Actions",
      sortable: false,
      size: 130,
      Cell: ({ row }) => (<>
        <NewDataGridAction
          label={"Edit"}
          onClick={() => editClick(row?.original)}
        />
        <NewDataGridAction
          label={"View"}
          onClick={() => viewClick(row?.original)}
          sx={{ marginLeft: "6px" }} />
      </>
      ),
    },
    {
      header: "Evaluator",
      accessorKey: "Evaluator",
      size: 140,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "Created By",
      accessorKey: "CreatedBy",
      size: 140,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "SampleNumber",
      accessorKey: "SampleNumber",
      size: 120,
    },

    {
      header: "Status",
      accessorKey: "AlertStatus",
      size: 70,
    },
    {
      header: "Contact",
      accessorKey: "Contact",
      size: 150,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "Email",
      accessorKey: "Email",
      size: 220,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },

    {
      header: "Telephone",
      accessorKey: "Telephone",
      size: 90,
    },
    {
      header: "Created",
      accessorKey: "Created",
      Cell: ({ renderedCellValue }) => toDateTimeFormat1(renderedCellValue),
      size: 100,
    },
    {
      header: "Win",
      accessorKey: "IsWin",
      valueFormatter: ({ value }) => (value ? "Yes" : ""),
      size: 60,
    },
    {
      header: "Comments",
      accessorKey: "Comments",
      size: 220,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "Corrective Action",
      accessorKey: "CorrectiveAction",
      size: 200,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "Customer",
      accessorKey: "Customer",
      size: 200,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "Site",
      accessorKey: "Site",
      size: 200,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "Unit",
      accessorKey: "Unit",
      size: 150,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "Model",
      accessorKey: "FullModel",
      size: 200,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "Serial",
      accessorKey: "Serial",
      size: 80,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "Component",
      accessorKey: "Component",
      size: 180,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "Product",
      accessorKey: "Product",
      size: 125,
    },
  ];
}
