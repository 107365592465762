import GetStepNames from "../../../common/GetStepNames";
import DataTable, { DataGridAction } from "../../../components/DataTable";
import DropdownMenu from "../../../components/DropdownMenu";
import renderCellExpand from "../../../components/DatatableCellExpand";

export default function SamplesTable({
  data,
  rowSelected,
  sx,
  selectedRow,
  actions,
  rowActionHandler,
}) {

  const options = {
    opacityCheck: true,
    columns: GetColumns(data, actions, { rowActionHandler }),
    // dataSource: data,
    dataSource: (data || []),
    rowIdProperty: "keyProp",
    // rowIdProperty: "SampleNumber",
    isDisableColumnFilter: true,
    svgColor: "black",
    rowStylingHandler: "evalSample",
    cellStylingHandler: "sampleResult",
    rowsSelected: function (r) {
      if (r) {
        r.length && rowSelected(r[0]);
      }
    },
    selectionModel: selectedRow,
    selectedRowIds: selectedRow,
    minHeight: 16,
    heightValue: 100,
  };
  return <DataTable {...options} sx={sx} />;
};

const GetColumns = (data, actions, { rowActionHandler }) => [
  {
    headerName: "Sample Number",
    field: "SampleNumber",
    width: 123,
    renderCell: function ({ row }) {
      return (
        <>
          {(actions || []).map(function (ac, i) {
            return (
              <DataGridAction
                key={i}
                row={row}
                onClick={ac.action}
                label={ac.label}
                startIcon={ac.startIcon}
              />
            );
          })}
          <DropdownMenu
            options={Options}
            onItemSelect={(option) => rowActionHandler(row, option)}
          />
          <pre style={{ paddingTop: 0.25, fontSize: "12px" }}>{row.SampleNumber}</pre>
        </>
      );
    },
  },
  {
    headerName: "Step",
    field: "Step",
    valueFormatter: ({ value }) => GetStepNames(value).short,
    width: 55,
  },
  {
    headerName: "AutoEval",
    field: "IsAutoEvaluated",
    valueFormatter: ({ value }) => (value === false || data?.Step <= 4 ? "" : data?.Step == 5 ? "?" : "Yes"),
    width: 56,
  },
  {
    headerName: "Test Set",
    field: "TestSet",
    minWidth: 120,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Status",
    field: "AlertStatus",
    width: 60,
  },
  {
    headerName: "Product",
    field: "Product",
    width: 80,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Customer",
    field: "Customer",
    minWidth: 150,
    flex: 1,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Site",
    field: "Site",
    width: 120,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Unit",
    field: "Unit",
    minWidth: 85,
    flex: 1,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Model",
    field: "UnitModelFormatted",
    minWidth: 170,
    flex: 1,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Serial",
    field: "Serial",
    width: 65,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Component",
    field: "Component",
    minWidth: 140,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Fluid",
    field: "FluidFormatted",
    minWidth: 160,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Capacity",
    field: "Capacity",
    width: 60,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Laboratory",
    field: "Laboratory",
    width: 100,
  },
  {
    headerName: "Label",
    field: "Label",
    width: 90,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Hold",
    field: "HoldName",
    width: 70,
    renderCell: renderCellExpand,
  },
];

const Options = [
  { label: "Copy Sample Number", name: "copy-sample-number" },
  { label: "View Sample Report", name: "view-sample-report" },
  { label: "View MHR Report", name: "view-mhr-report" },
  { label: "Edit Sample", name: "edit-sample" },
  { label: "Unit Hour History", name: "unit-hour-history" },
  {
    label: "Contacts",
    name: "contacts",
    items: [
      { label: "Primary Contacts", name: "primary-contacts-overview" },
      { label: "Report Contacts", name: "report-contacts" },
      { label: "Website Contacts", name: "website-contacts" },
    ],
  },
  { label: "Add Critical Log", name: "critical-log" },
  { label: "Hold Management", name: "hold-management" },
  { label: "Show in Tree", name: "show-in-tree" },
  { label: "View in Quick Details", name: "view-in-quick-details" },
  { label: "View Card", name: "view-card" },
];
