import React, { useCallback, useEffect, useReducer, useState } from "react";
import Button from "@mui/material/Button";
import { Card, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import moment from "moment";
import useFetch from "../../../hooks/useFetch";
import { DASHBOARD_APIS } from "../../../common/apis";
import {
  ExportIcon,
  ExportOverviewIcon,
  SearchIcon,
} from "../../../icons/ButtonIcons";
import { useToast } from "../../../rootContext";
import appResources from "../../../app-resources/en/Resources.json";
import { toLocalDayDate2 } from "../../../utils";
import Form, { FormActions } from "../../../components/Form";
import AutoEvaluationOverviewTable from "./AutoEvaluationOverviewTable";
import AutoEvaluationDetailsTable from "./AutoEvaluationDetailsTable";

const AutoEvaluationDashboard = () => {

  const { showWarningToast } = useToast();
  const { post } = useFetch();

  const [dataSourceOverview, setDataSourceOverview] = useState([]);
  const [dataSourceDetails, setDataSourceDetails] = useState([]);

  async function autoEvalClick(propData) {
    const formattedFromDate = moment(propData?.fromDate).format("YYYYMMDD");
    const formattedToDate = moment(propData?.toDate).format("YYYYMMDD");

    if (
      formattedFromDate === "Invalid date" ||
      formattedToDate === "Invalid date"
    ) {
      return showWarningToast(appResources.INVALID_DATE_FORMAT_MSG);
    }
    let data = await post(DASHBOARD_APIS.AUTO_EVALUATION, {
      DateTo: formattedToDate,
      DateFrom: formattedFromDate,
    });
    if (data?.Detail.length > 0) {
      setDataSourceOverview(data.Overview.filter((x) => x.AutoEvalTotal));
      setDataSourceDetails(data.Detail);
    } else {
      setDataSourceOverview();
      setDataSourceDetails();
      showWarningToast(
        appResources.NO_AUTO_EVALUATION_DASHBOARD_DATA_FOUND_MSG +
        " for " +
        toLocalDayDate2(formattedFromDate)
      );
    }
  }

  useEffect(() => {
    document.title = "Auto-Evaluation Dashboard";
  }, []);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <SearchFilter
            dataSourceOverview={dataSourceOverview}
            autoEvalClick={autoEvalClick}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Total Records: <b> {dataSourceOverview?.length}</b>
          </Typography>
          <AutoEvaluationOverviewTable data={dataSourceOverview} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Total Records: <b> {dataSourceDetails?.length}</b>
          </Typography>
          <AutoEvaluationDetailsTable data={dataSourceDetails} />
        </Grid>
      </Grid>
    </>
  );
};

export default AutoEvaluationDashboard;

function SearchFilter({ autoEvalClick, dataSourceOverview, }) {

  const { getFile } = useFetch();
  const [data, dispatch] = useReducer(SampleFilterReducer, SampleFilterDefaults);

  function overviewExportClick() {
    const DateFrom = moment(data?.fromDate).format("YYYYMMDD");
    const DateTo = moment(data?.toDate).format("YYYYMMDD");
    getFile(
      `/dashboard/export-overview?TrayDate=${DateFrom}&TrayDate2=${DateTo}`,
      "Auto Evaluation Overview"
    );
  }

  function detailsExportClick() {
    const DateFrom = data?.fromDate.format("YYYYMMDD");
    const DateTo = data?.toDate.format("YYYYMMDD");
    getFile(
      `/dashboard/export-details?TrayDate=${DateFrom}&TrayDate2=${DateTo}`,
      "Auto Evaluation Details"
    );
  }

  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      autoEvalClick(data)
    }
  }

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "F1") {
        event.preventDefault();
        autoEvalClick(data);
      }
    },
    [data]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <Card sx={{ p: 1 }}>
      <Form
        sections={GetFormSections(data, dataSourceOverview, autoEvalClick, overviewExportClick, detailsExportClick, handleEnterPress)}
        data={data}
        spacing={2}
        dispatch={dispatch}
        key={data?._ts_key}
      />
    </Card>
  );
}

function GetFormSections(data, dataSourceOverview, autoEvalClick, overviewExportClick, detailsExportClick, handleEnterPress) {
  return [
    {
      fields: [
        {
          name: "fromDate",
          label: "From",
          type: "date",
          arrowCheck: true,
          handleEnterPress: handleEnterPress,
        },
        {
          name: "toDate",
          label: "To",
          type: "date",
          arrowCheck: true,
          handleEnterPress: handleEnterPress,
        },
        {
          xsCol: 1,
          alignSelf: "flex-end",
          component: function () {
            return (
              <Button
                variant="contained"
                onClick={() => autoEvalClick(data)}
                startIcon={<SearchIcon />}
              >
                View (F1)
              </Button>
            );
          },
        },
        dataSourceOverview?.length > 0 && {
          xsCol: 1,
          alignSelf: "flex-end",
          component: function () {
            return (
              <Button
                variant="outlined"
                onClick={overviewExportClick}
                startIcon={<ExportOverviewIcon />}
              >
                Export Overview
              </Button>
            );
          },
        },
        dataSourceOverview?.length > 0 && {
          xsCol: 1,
          alignSelf: "flex-end",
          component: function () {
            return (
              <Button
                variant="outlined"
                onClick={detailsExportClick}
                startIcon={<ExportIcon />}
              >
                Export Details
              </Button>
            );
          },
        },
      ],
    },
  ];
}

function SampleFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  return state;
}

const SampleFilterDefaults = {
  fromDate: moment().subtract(6, "days"),
  toDate: moment(),
};
