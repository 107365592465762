import { Button, Card, Grid, Typography } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useReducer, useState } from "react";
import { FLEET_APIS } from "../../../common/apis";
import Form, { FormActions } from "../../../components/Form";
import TextBox from "../../../components/TextBox";
import useFetch from "../../../hooks/useFetch";
import TemplatesTable from "./TemplatesTable";
import TemplatesModelTable from "./TemplatesModelTable";
import TemplatesComponentTable from "./TemplatesComponentTable";
import AppDialog from "../../../components/AppDialog";
import AddComponent from "./AddComponent";
import AddUnitSearch from "./AddUnitSearch";
import appResources from "../../../app-resources/en/Resources.json";
import {
  AddIcon,
  CopyIcon,
  DeleteIcon,
  NewIcon,
  SaveIcon,
} from "../../../icons/ButtonIcons";
import { useRoot, useToast } from "../../../rootContext";
import { ValidateSchema } from "../../../validation/schemas/MachineTemplateSchema";
import { ValidateCopySchema } from "../../../validation/schemas/MachineCopyTemplateSchema";
import ConfirmModal from "../../../components/ConfirmModal";

export default function MachineTemplates() {
  const [data, dispatch] = useReducer(SearchFilterReducer, SearchFilterDefaults);
  const { get, post } = useFetch();
  const { CanManageMachineTemplates } = useRoot();
  const { showToast, showInfoToast, showErrorToast } = useToast();

  const [formErrors, setFormErrors] = useState({});
  const [templatesData, setTemplatesData] = useState([]);
  const [unitMakesData, setUnitMakesData] = useState([]);
  const [machineTemplateComponentsData, setMachineTemplateComponentsData] = useState([]);
  const [rowSelectedData, setRowSelectedData] = useState();
  const [filter, setFilter] = useState({});
  const [dialog, setDialog] = useState({
    title: "",
    open: false,
    message: "",
    width: "",
  });

  async function getTemplates() {
    const response = await get(`${FLEET_APIS.MACHINE_TEMPLATES}`);
    if (response.length > 0) {
      setTemplatesData(response);
      getMachineTemplateData(response[0]);
    } else {
      setTemplatesData([]);
    }
  }

  const rowsSelectedHandler = (row) => {
    getMachineTemplateData(row);
  };

  function getMachineTemplateData(row) {
    dispatch({
      payload: {
        ...row,
      },
      type: EventActions.LOAD_DATA,
    });
    setRowSelectedData(row);
    getModelComponentData(row);
  }

  function refreshModels(row) {
    getModelComponentData(row);
  }

  function refreshComponents(row) {
    getModelComponentData(row);
  }

  async function getModelComponentData(row) {
    const response = await get(`${FLEET_APIS.VIEW_MACHINE_TEMPLATES}?TemplateId=${row.TemplateId || row}`);
    if (
      response.MachineTemplateUnitMakes.length > 0 ||
      response.MachineTemplateComponents.length > 0
    ) {
      setUnitMakesData(response.MachineTemplateUnitMakes);
      setMachineTemplateComponentsData(response.MachineTemplateComponents);
    } else {
      setUnitMakesData([]);
      setMachineTemplateComponentsData([]);
    }
  }

  function handelFilterChange(e) {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  }

  const filteredData = useMemo(
    function () {
      return templatesData.filter(
        (x) =>
          !filter.startsWith ||
          x.Template?.toUpperCase().startsWith(filter.startsWith.toUpperCase())
      );
    },
    [templatesData, filter]
  );

  //Save template data
  async function saveTemplateClick() {
    setFormErrors({});
    ValidateSchema(data).then(async function ({ data: req, errors }) {
      if (req) {
        const response = await post(FLEET_APIS.SAVE_MACHINE_TEMPLATE, {
          TemplateId: rowSelectedData ? rowSelectedData.TemplateId : null,
          Template: data.Template,
          Screen: "Machine Templates",
        });
        if (response[0].TemplateCount === 0) {
          showInfoToast(appResources.UPDATED_SUCCESSFULLY_MSG);
          await getTemplates();
        } else {
          showErrorToast(appResources.SOMETHING_WENT_WRONG_MSG);
        }
      }
      if (errors) {
        setFormErrors(errors);
      }
    });
  }

  function deleteTemplateClick() {
    setDialog({
      title: "Delete Template",
      open: true,
      message: "Do you wish to delete the Machine Template: \n" + data.Template,
      width: "md",
    });
  }

  async function deleteTemplateClickAlert() {
    const response = await post(FLEET_APIS.DELETE_MACHINE_TEMPLATE, {
      TemplateId: rowSelectedData ? rowSelectedData.TemplateId : null,
      Screen: "Machine Templates",
    });
    if (response > 0) {
      showToast(appResources.DELETED_SUCCESSFULLY_MSG);
    } else {
      showErrorToast(appResources.SOMETHING_WENT_WRONG_MSG);
    }
    await getTemplates();
    setDialog((prevState) => ({
      ...prevState,
      open: false,
    }));
    newClick();
  }

  //Copy template
  async function copyTemplateClick() {
    setFormErrors({});
    ValidateCopySchema(data).then(async function ({ data: req, errors }) {
      if (req) {
        const response = await post(FLEET_APIS.COPY_MACHINE_TEMPLATE, {
          TemplateId: rowSelectedData ? rowSelectedData.TemplateId : null,
          Template: data.NewTemplate,
          Screen: "Machine Templates",
        });
        if (response[0].TemplateCount === 0) {
          showToast(appResources.ADDED_SUCCESSFULLY_MSG);
          await getTemplates();
        } else {
          showErrorToast(appResources.SOMETHING_WENT_WRONG_MSG);
        }
      }
      if (errors) {
        setFormErrors(errors);
      }
    });
  }

  function newClick() {
    setRowSelectedData();
    dispatch({
      type: EventActions.RESET_FORM,
    });
  }

  function addComponentClick() {
    setDialog({
      title: "New Machine Template Component",
      open: true,
      width: "lg",
    });
  }

  useEffect(function () {
    document.title = "Templates";
    getTemplates();
  }, []);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item container xs={6} spacing={1}>
          <Grid item sx={{ m: 1 }}>
            <TextBox
              name="startsWith"
              label="Starts With"
              onChange={handelFilterChange}
            />
          </Grid>
        </Grid>

        <Grid item container xs={6} justifyContent="flex-end">
          {CanManageMachineTemplates && <Grid item>
            <Button
              onClick={newClick}
              startIcon={<NewIcon />}
              sx={{ marginTop: "23px" }}
            >
              New
            </Button>
          </Grid>}
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={2.5}>
          <Typography variant="subtitle2">
            Total Records: <b> {filteredData && filteredData.length}</b>
          </Typography>
          <TemplatesTable
            rowSelected={rowsSelectedHandler}
            selectedRow={rowSelectedData?.TemplateId}
            data={filteredData}
          />
        </Grid>
        <Grid item xs={9.5} mt={2}>
          <Card sx={{ p: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Form
                  sections={GetFormSections(
                    saveTemplateClick,
                    deleteTemplateClick,
                    copyTemplateClick,
                    rowSelectedData,
                    CanManageMachineTemplates
                  )}
                  data={data}
                  dispatch={dispatch}
                  errors={formErrors}
                  key={data.TemplateId}
                />
              </Grid>
              {rowSelectedData && (
                <>
                  <Grid item md={4}>
                    {CanManageMachineTemplates && <Grid item sx={{ mb: 1 }}>
                      <Button
                        variant="outlined"
                        onClick={() =>
                          setDialog({
                            title: "Unit Model Search",
                            open: true,
                            width: "xl",
                          })
                        }
                        startIcon={<AddIcon />}
                      >
                        Add Model
                      </Button>
                    </Grid>}
                    <Typography variant="subtitle2">
                      Total Records:{" "}
                      <b> {unitMakesData && unitMakesData.length}</b>
                    </Typography>
                    <TemplatesModelTable
                      data={unitMakesData}
                      refreshModels={refreshModels}
                    />
                  </Grid>
                  <Grid item md={8}>
                    {CanManageMachineTemplates && <Grid item sx={{ mb: 1 }}>
                      <Button
                        variant="outlined"
                        onClick={addComponentClick}
                        startIcon={<AddIcon />}
                      >
                        Add Component
                      </Button>
                    </Grid>}
                    <Typography variant="subtitle2">
                      Total Records:{" "}
                      <b> {machineTemplateComponentsData?.length}</b>
                    </Typography>
                    <TemplatesComponentTable
                      data={machineTemplateComponentsData}
                      refreshComponents={refreshComponents}
                      TemplateId={rowSelectedData && rowSelectedData.TemplateId}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Card>
        </Grid>
      </Grid>
      <AppDialog
        open={dialog.open}
        title={dialog.title}
        maxWidth={dialog.width}
        handleClose={() =>
          setDialog((prevState) => ({
            ...prevState,
            open: false,
          }))
        }
      >
        {dialog.title === "Unit Model Search" && (
          <AddUnitSearch
            TemplateId={rowSelectedData && rowSelectedData.TemplateId}
            refreshModels={refreshModels}
            setDialog={setDialog}
          />
        )}
        {dialog.title === "New Machine Template Component" && (
          <AddComponent
            TemplateId={rowSelectedData && rowSelectedData.TemplateId}
            refreshComponents={refreshComponents}
            setDialog={setDialog}
          />
        )}
        {dialog.title === "Delete Template" && (
          <ConfirmModal
            handleClose={() => {
              setDialog((prevState) => ({ ...prevState, open: false, }));
            }} alertAction={deleteTemplateClickAlert}
            message={dialog.message}
            buttonText={"Delete"}
            buttonText2={"Cancel"}
          />
        )}
      </AppDialog>
    </>
  );
}

function GetFormSections(
  saveTemplateClick,
  deleteTemplateClick,
  copyTemplateClick,
  rowSelectedData,
  CanManageMachineTemplates
) {
  return [
    {
      fields: [
        {
          group: [
            {
              name: "Template",
              label: "Template",
              xsCol: 3,
            },
            {
              alignContent: "center",
              component: function () {
                return (
                  <Button
                    variant="contained"
                    onClick={saveTemplateClick}
                    startIcon={<SaveIcon />}
                  >
                    Save
                  </Button>
                );
              },
            },
            CanManageMachineTemplates && {
              alignContent: "center",
              xsCol: 3,
              component: function ({ data }) {
                return (
                  <Button
                    disabled={!data.TemplateId}
                    color="danger"
                    onClick={deleteTemplateClick}
                    startIcon={<DeleteIcon />}
                  >
                    Delete
                  </Button>
                );
              },
            },
            {
              name: "NewTemplate",
              label: "New Template",
              xsCol: 3,
            },
            {
              alignContent: "center",
              component: function () {
                return (
                  <Button
                    variant="outlined"
                    onClick={copyTemplateClick}
                    disabled={!(rowSelectedData && rowSelectedData.TemplateId)}
                    startIcon={<CopyIcon />}
                  >
                    Copy
                  </Button>
                );
              },
            },
          ],
        },
      ],
    },
  ];
}

function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === EventActions.LOAD_DATA) {
    return { ...action.payload };
  }
  if (action.type === EventActions.RESET_FORM) {
    return {};
  }
  return state;
}

const SearchFilterDefaults = {};
const EventActions = {
  LOAD_DATA: "load-data",
};
