import DataTable from "../../../components/DataTable";

export default function ResultsTable({
  data = [],
  columns = [],
  rowSelected,
  selectedRow,
}) {
  const options = {
    columns: columns,
    opacityCheck: true,
    laboratoryResultsDT: true,
    dataSource: (data || []).map((x, i) => ({ ...x, keyProp: i, })),
    rowIdProperty: "keyProp",
    isDisableColumnFilter: true,
    cellStylingHandler: "sampleResult",
    rowsSelected: function (r) {
      r.length && rowSelected(r[0]);
    },
    selectionModel: selectedRow,
    minHeight: 32,
    heightValue: 100,
    sxTable: {
      [`.MuiDataGrid-columnHeader`]: { backgroundColor: "#c0c0c0" },
    },
  };
  return <DataTable {...options} />;
}
