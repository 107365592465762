import { Button } from "@mui/material";
import moment from "moment";
import { useCallback, useEffect, useReducer } from "react";
import Form, { FormActions } from "../../../components/Form";
import {
  SearchIcon,
} from "../../../icons/ButtonIcons";
import { toIsoFormat } from "../../../utils";

export default function SearchFilter({
  onView,
  infoMessage,
  successMessage,
  isInfoIcon,
  isSuccessIcon,
  isWarningTrue,
}) {

  const [data, dispatch] = useReducer(
    SearchFilterReducer,
    SearchFilterDefaults
  );

  const actions = {
    view: () => {
      onView(data); localStorage.setItem("local02", JSON.stringify({ ...data, CreationDate: moment.utc().format("YYYYMMDD") }))
    },
  };

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "F1") {
        event.preventDefault();
        actions.view();
      }
    },
    [data]
  );

  useEffect(() => {
    let trayLocalData = localStorage.getItem("local02");
    if (trayLocalData !== null) {
      let checkDate = moment.utc().format("YYYYMMDD")
      trayLocalData = JSON.parse(trayLocalData);
      if (checkDate === trayLocalData?.CreationDate) {
        dispatch({
          payload: { ...trayLocalData, },
          type: EventActions.LOAD_DATA,
        });
        onView(trayLocalData)
      }
      if (checkDate !== trayLocalData?.CreationDate) {
        onView(data)
      }
    }
    if (trayLocalData === null) {
      onView(data)
    }
  }, [])

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      actions.view();
    }
  }

  return (
    <>
      <Form
        sections={GetFormSections(
          data,
          handleEnterPress,
          infoMessage,
          successMessage,
          isInfoIcon,
          isSuccessIcon,
          isWarningTrue,
          actions
        )}
        data={data}
        spacing={1}
        dispatch={dispatch}
        key={data?._ts_key}
      />
    </>
  );
}
function GetFormSections(
  data,
  handleEnterPress,
  infoMessage,
  successMessage,
  isInfoIcon,
  isSuccessIcon,
  isWarningTrue,
  { view }
) {
  return [
    {
      fluid: true,
      fields: [
        {
          name: "Date",
          label: "Date",
          type: "date",
          alignSelf: "end",
          xsCol: 2,
          disabled: data.LabAndDateOption === 2 || data.LabAndDateOption === 4,
          handleEnterPress: handleEnterPress,
          arrowCheck: true,
        },
        {
          name: "Tray",
          label: "Tray",
          type: "number",
          alignSelf: "end",
          xsCol: 1,
          disabled: data.LabAndDateOption !== 0,
          min: 1,
          max: 999,
          handleEnterPress: handleEnterPress,
        },

        {
          name: "LabAndDateOption",
          type: "dropdown",
          xsCol: 3,
          alignSelf: "end",
          required: true,
          selectOptions: getLabDateOptions(),
        },

        {
          alignSelf: "flex-end",
          component: function () {
            return (
              <Button
                variant="contained"
                onClick={view}
                startIcon={<SearchIcon />}
              >
                View (F1)
              </Button>
            );
          },
        },
      ],
    },
  ];
}
const EventActions = {
  LOAD_DATA: "load-data",
};

function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === EventActions.LOAD_DATA) {
    return { ...action.payload, _ts_key: new Date() }
  }
  return state;
}

const SearchFilterDefaults = {
  Date: toIsoFormat(new moment()), //moment().toISOString(),
  Tray: 1,
  LabAndDateOption: 0,
};

function getLabDateOptions() {
  return [
    { value: 0, text: "By Date and Tray for Laboratory" },
    { value: 1, text: "Unreleased by Date for Laboratory" },
    { value: 2, text: "Unreleased for Laboratory" },
    { value: 3, text: "Unreleased by Date for ALL Laboratories" },
    { value: 4, text: "Unreleased for ALL Laboratories" },
  ];
}
