import { useState } from "react";
import NewDataTable from "../../../components/NewDataTable";

export default function QuickMoveToSitesTable({ data, rowSelected }) {
  const [rowSelection, setRowSelection] = useState({});

  const options1 = {
    columns: toSiteColumns1,
    dataSource: data || [],
    getRowId: (originalRow) => originalRow.FluidId,
    sx: { height: 800 },
    onRowSelectionChange: setRowSelection,
    muiSelectCheckboxProps: {
      color: 'secondary',
      backgroundColor: "blue"
    },
    muiTableBodyProps: {
      sx: (theme) => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "#e2f2ff" },
        // backgroundColor: "inherit",//
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "#e2f2ff" },
      }),
    },
    muiTableBodyRowProps: ({ row }) => ({
      //implement row selection click events manually
      onClick: () => {
        setRowSelection((prev) => ({
          [row.id]: !prev[row.id],
        }))
        rowSelected(row.original);
      },
      selected: rowSelection[row.id],
      sx: {
        cursor: 'pointer',
      },
    }),
    state: { rowSelection },
  };
  return <NewDataTable {...options1} />;
}

const toSiteColumns1 = [
  {
    header: "Product",
    accessorKey: "Product",
    maxSize: 200,
    size: 200,
    sortable: false,
    enableEditing: false,
  },
  {
    header: "Destination Customer",
    accessorKey: "Customer",
    maxSize: 170,
    size: 170,
    sortable: false,
    enableEditing: false,
  },
];