import { Button, Card, Grid, Typography } from "@mui/material";
import { useCallback, useEffect, } from "react";
import { useReducer, useState } from "react";
import moment from "moment";
import useFetch from "../../../hooks/useFetch";
import Form, { FormActions } from "../../../components/Form";
import { toIsoFormat, toLocalDateTime2 } from "../../../utils";
import { DASHBOARD_APIS } from "../../../common/apis";
import appResources from "../../../app-resources/en/Resources.json";
import {
  ExportHistoryIcon,
  ExportMismatchIcon,
} from "../../../icons/ButtonIcons";
import renderCellExpand from "../../../components/DatatableCellExpand";
import NewDataTable from "../../../components/NewDataTable";

export default function IkowaFeedback() {

  const [data, dispatch] = useReducer(
    SearchFilterReducer,
    SearchFilterDefaults
  );

  const { post, postFile } = useFetch();

  const [feedbackData, setFeedbackData] = useState([]);
  const [infoMessage, setInfoMessage] = useState();

  async function view() {
    if (
      data?.DateFrom === "Invalid date" ||
      data?.DateTo === "Invalid date"
    ) {
      return setInfoMessage(appResources.INVALID_DATE_FORMAT_MSG);
    } else {
      let resData = await post(DASHBOARD_APIS.FEEDBACK, {
        ...data,
        RankDifference: data?.RankDifference === "" ? 1 : data.RankDifference,
      });

      if (resData?.length > 0) {
        setFeedbackData(resData);
        setInfoMessage(`There are ${resData?.length} mismatched samples for iKOWA
          feedback to Japan`);
      } else {
        setFeedbackData();
        setInfoMessage();
        setInfoMessage(appResources.NO_IKOWA_DATA_FOUND_MSG);
      }
    }
  }

  const options1 = {
    columns: GetColumns(data),
    dataSource: feedbackData || [],
    getRowId: (originalRow) => (originalRow.SampleNumber || "") + " " + (originalRow.UnitHours || ""),
    enablePagination: false,
    sx: { height: "80dvh" },
    enableColumnActions: false,

    muiTableBodyProps: {
      sx: (theme) => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "#e2f2ff" },
        // backgroundColor: "inherit",//
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "#e2f2ff" },
      }),
    },

    muiTableBodyCellProps: ({ cell, column, row, table }) => {
      return {
        sx: {
          height: "8px", borderLeft: "1px solid #d0d0d0"
        }
      }
    }

  };

  //Export excel
  function mismatchesToExcelClick() {
    postFile(
      DASHBOARD_APIS.EXPORT_MISMATCH,
      {
        ...data,
      },
      "iKOWA Mismatches Feedback"
    );
  }

  function historyToExcelClick() {
    postFile(
      DASHBOARD_APIS.EXPORT_HISTORY,
      {
        ...data,
      },
      "KOWA History"
    );
  }

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "F1") {
        event.preventDefault();
        view();
      }
    },
    [data]
  );

  useEffect(() => {
    document.title = "iKOWA Feedback";

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Card sx={{ p: 1 }}>
            <Form
              sections={GetFormSections(
                view,
                mismatchesToExcelClick,
                historyToExcelClick,
                infoMessage,
                feedbackData
              )}
              data={data}
              dispatch={dispatch}
            />
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Total Records: <b> {feedbackData?.length}</b>
          </Typography>
          {/* <DataTable {...tblOptions} /> */}
          <NewDataTable {...options1} />
        </Grid>
      </Grid>
    </>
  );
}

function GetColumns(data) {
  return [
    {
      header: "SampleNumber",
      accessorKey: "SampleNumber",
      size: 80,
    },
    {
      header: "iKowaDate",
      accessorKey: "iKowaDate",
      Cell: ({ renderedCellValue }) => toLocalDateTime2({ renderedCellValue }),
      size: 90,
    },
    {
      header: "Laboratory",
      accessorKey: "Laboratory",
      size: 90,
    },
    {
      header: "Technician",
      accessorKey: "Evaluator",
      size: 200,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "ExternalFactors",
      accessorKey: "ExternalFactors",
      hide: !data.IncludeExternalFactors,
      // valueFormatter: ({ value }) => (value === "Yes" ? "Yes" : ""),
      size: 125,
    },
    {
      header: "AlertRank",
      accessorKey: "AlertRank",
      size: 85,
    },
    {
      header: "iKowaRank",
      accessorKey: "iKowaRank",
      size: 85,
    },
    {
      header: "Difference",
      accessorKey: "RankDifference",

      size: 85,
    },
    {
      header: "ComponentTypeJP",
      accessorKey: "ComponentTypeJP",
      size: 200,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "ComponentTypeAU",
      accessorKey: "ComponentTypeAU",
      size: 200,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
  ];
}

function GetFormSections(
  view,
  mismatchesToExcelClick,
  historyToExcelClick,
  infoMessage,
  feedbackData
) {
  return [
    {
      fluid: true,
      fields: [
        {
          name: "DateFrom",
          label: "From",
          type: "date",
          alignSelf: "end",
        },
        {
          name: "DateTo",
          label: "To",
          type: "date",
          alignSelf: "end",
        },
        {
          name: "RankDifference",
          label: "Difference",
          type: "number",
          xsCol: 1,
          min: 1,
          max: 4,
          alignSelf: "end",
        },
        {
          name: "IncludeExternalFactors",
          label: "External Factors",
          type: "switch",
        },
        {
          name: "UnblockedOnly",
          label: "Quality",
          type: "switch",
        },
        {
          name: "KomatsuOnly",
          label: "Komatsu Only",
          type: "switch",
        },

        {
          alignSelf: "flex-end",
          component: function () {
            return (
              <Button
                variant="contained"
                onClick={view}
              >
                View (F1)
              </Button>
            );
          },
        },
        {
          alignSelf: "flex-end",
          component: function () {
            return (
              feedbackData?.length > 0 && (
                <Button
                  variant="outlined"
                  onClick={mismatchesToExcelClick}
                  startIcon={<ExportMismatchIcon />}
                >
                  Export Mismatches
                </Button>
              )
            );
          },
        },
        {
          alignSelf: "flex-end",
          component: function () {
            return (
              feedbackData?.length > 0 && (
                <Button
                  variant="outlined"
                  onClick={historyToExcelClick}
                  startIcon={<ExportHistoryIcon />}
                >
                  Export History
                </Button>
              )
            );
          },
        },
        {
          flex: 2,
          alignSelf: "center",
          component: function () {
            return (
              <Typography sx={{ mt: 3, fontWeight: 700, fontSize: 11 }}>
                {infoMessage}
              </Typography>
            );
          },
        },
      ],
    },
  ];
}

function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  return state;
}

const SearchFilterDefaults = {
  // DateFrom: moment().toISOString(),
  // DateTo: moment().toISOString(),
  DateFrom: toIsoFormat(new moment()),
  DateTo: toIsoFormat(new moment()),
  RankDifference: 1,
  UnblockedOnly: true,
  KomatsuOnly: true,
};
