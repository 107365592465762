import { Card, Grid, Typography } from "@mui/material";
import { useCallback, useEffect, useReducer, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { CONTACTS_APIS } from "../../common/apis";
import Form, { FormActions } from "../../components/Form";
import FormFooter from "../../components/FormFooter";
import { ClearIcon, ContactIcon, SearchIcon } from "../../icons/ButtonIcons";
import { useRoot, useToast } from "../../rootContext";
import appResources from "../../app-resources/en/Resources.json";
import { useWindowPopups } from "../../components/useWindowPopups";
import SearchContactTable from "./SearchContactTable";

export default function SearchContact() {

  const { post } = useFetch();
  const { CanDoDataMaintenance } = useRoot();
  const { showWarningToast } = useToast();
  const [data, dispatch] = useReducer(
    SearchContactsFilterReducer,
    SearchContactDefaults
  );

  const [contactsData, setContactsData] = useState([]);
  const [localData, setLocalData] = useState({});

  function refreshGrid() {
    formActions.search();
  }

  const HandleNewWindow = (actionName, data) => {
    useWindowPopups(actionName, data)
  }

  const formActions = {
    newContact: function (e) {
      HandleNewWindow("add-contact", data);
    },
    clear: function (e) {
      dispatch({
        type: FormActions.RESET_FORM,
      });
      setContactsData();
    },
    search: async function (e) {
      if (
        data?.ContactId === null &&
        data?.Username === "" &&
        data?.Forename === "" &&
        data?.Surname === "" &&
        data?.Mobile === ""
      ) {
        return showWarningToast(appResources.NO_SEARCH_CONTACT_DATA_FOUND_MSG);
      }
      const resp = await post(CONTACTS_APIS.SEARCH_CONTACTS, {
        ...data,
        ContactId: data.ContactId ? data.ContactId : (localData?.ContactId || null),
        Username: data.Username ? data.Username : "",
        Forename: data.Forename ? data.Forename : "",
        Surname: data.Surname ? data.Surname : "",
        Mobile: data.Mobile ? data.Mobile : "",
        NoScopes: false,
        IncludeArchived: data.IsArchived ? data.IsArchived : false,
        ScopeSiteId: localData?.SiteId,
      });
      setContactsData(resp);
    },
  };

  function selectClick(row) {
    localStorage.setItem("search-contact-resp", JSON.stringify({ ...row }));
    window.close();
  }

  function editClick(row) {
    HandleNewWindow("edit-contact", row);
  }

  async function accessClick(row) {
    HandleNewWindow("contact-access-permission", {
      ContactId: row?.ContactId,
      PropId: 2,
      UserName: row?.Username,
      DisplayName: row?.DisplayName,
      IsKomatsu: row?.IsKomatsu,
    });
  }

  function reportsClick(row) {
    HandleNewWindow("contact-access-permission", {
      ContactId: row?.ContactId,
      PropId: 3,
      UserName: row?.Username,
      DisplayName: row?.DisplayName,
      IsKomatsu: row?.IsKomatsu,
    });
  }

  function subscriptionsClick(row) {
    HandleNewWindow("contact-access-permission", {
      ContactId: row?.ContactId,
      PropId: 4,
      UserName: row?.Username,
      DisplayName: row?.DisplayName,
      IsKomatsu: row?.IsKomatsu,
    });
  }

  function exportsClick(row) {
    HandleNewWindow("export-contacts-overview", {
      ContactId: row?.ContactId,
      PropId: 5,
      UserName: row?.Username,
      DisplayName: row?.DisplayName,
    });
  }

  function primaryClick(row) {
    HandleNewWindow("contact-primary-mapping", {
      ContactId: row?.ContactId,
      PropId: 6,
      UserName: row?.Username,
      DisplayName: row?.DisplayName,
    });
  }

  const eventCb = () => {
    const addContact = JSON.parse(localStorage.getItem('add-contact-resp'));
    if (addContact?.resp === true) {
      refreshGrid();
      localStorage.removeItem('add-contact-resp')
    }

    const editContact = JSON.parse(localStorage.getItem('edit-contact-resp'));
    if (editContact?.resp === true) {
      refreshGrid();
      localStorage.removeItem('edit-contact-resp')
    }
  }

  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        refreshGrid();
      }
    }, [data]);

  useEffect(() => {
    document.addEventListener("keyup", handleKeyPress);
    return () => {
      document.removeEventListener("keyup", handleKeyPress);
    };
  }, [handleKeyPress]);

  useEffect(function () {
    window.addEventListener("storage", eventCb);
    return function () {
      window.removeEventListener("storage", eventCb);
    };
  });

  useEffect(() => {
    document.title = "Search Contact";
    let lData = JSON.parse(localStorage.getItem("search-contact"));
    if (lData !== null && lData !== undefined) {
      setLocalData(lData)
      localStorage.removeItem("search-contact");
    }
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={12}  >
          <Card sx={{ p: 1, }}>
            <Grid item xs={12}>
              <Form
                sections={GetFormSections()}
                data={data}
                dispatch={dispatch}
                key={data._ts_key || ""}
              />
            </Grid>
            <Grid item xs={12}>
              <FormFooter
                buttons={GetFormButtons(contactsData, formActions, CanDoDataMaintenance)}
                hideDivider
                gmt={0}
              />
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12} sx={{ mt: 2 }}>
          {contactsData?.length > 0 && (
            <Typography variant="subtitle2">
              Total Records: <b> {contactsData?.length}</b>
            </Typography>
          )}
          {contactsData?.length < 1 && (
            <Typography sx={{ color: "red" }}>
              {appResources.NO_CONTACTS_FOUND}
            </Typography>
          )}
          <SearchContactTable
            contactsData={contactsData}
            selectClick={selectClick}
            editClick={editClick}
            accessClick={accessClick}
            reportsClick={reportsClick}
            subscriptionsClick={subscriptionsClick}
            exportsClick={exportsClick}
            primaryClick={primaryClick}
            selectCheck={localData?.selectCheck}
          />
        </Grid>
      </Grid>
    </>
  );
}

const GetFormButtons = (contactsData,
  { newContact, clear, search },
  CanDoDataMaintenance
) => [
    {
      hidden: !CanDoDataMaintenance,
      variant: "outlined",
      label: "New",
      onClick: newContact,
      startIcon: <ContactIcon />,
    },
    {
      variant: "outlined",
      label: "Clear",
      onClick: clear,
      startIcon: <ClearIcon />,
    },
    {
      variant: "contained",
      label: "Search",
      onClick: search,
      startIcon: <SearchIcon />,
    },
  ];


function GetFormSections() {
  return [
    {
      xsCol: 12,
      fields: [
        { name: "Username", label: "Email", xsCol: 3 },
        { name: "Mobile", label: "Mobile", xsCol: 3 },
        { name: "Forename", label: "Forename", xsCol: 2 },
        { name: "Surname", label: "Surname", xsCol: 2 },
        { name: "ContactId", label: "ContactId", xsCol: 2, type: "number" },
        {
          group: [
            {
              name: "IsArchived",
              label: "Include Archive",
              mdCol: 2,
              type: "switch",
            },

            { name: "IsKomatsu", label: "Komatsu", mdCol: 1, type: "switch" },
            {
              name: "IsLabcheckUser",
              label: "Labcheck",
              mdCol: 1,
              type: "switch",
            },
            {
              name: "NoLinkages",
              label: "No Access",
              mdCol: 1,
              type: "switch",
            },
            {
              name: "IsPortalUser",
              label: "Customer Portal",
              mdCol: 2,
              type: "switch",
            },
            { name: "IsEmailUser", label: "Email", mdCol: 2, type: "switch" },
            { name: "NoScopes", label: "No Reports", mdCol: 2, type: "switch" },
          ],
        },
      ],
    },
  ];
}

function SearchContactsFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === FormActions.RESET_FORM) {
    return { ...SearchContactDefaults, _ts_key: Date.now() };
  }
  return state;
}
const SearchContactDefaults = {
  IsArchived: true,
  IsKomatsu: true,
  IsLabcheckUser: true,
  IsPortalUser: true,
  IsEmailUser: true,
  ContactId: null,
  Username: "",
  Forename: "",
  Surname: "",
  Mobile: "",
  NoScopes: false,
};
