import DataTable, { DataGridAction } from "../../../components/DataTable";
import renderCellExpand from "../../../components/DatatableCellExpand";

export default function OutstandingTable({ data, addClick, viewClick }) {
  const options = {
    columns: GetColumns({ addClick, viewClick }),
    dataSource: data,
    rowIdProperty: "SampleNumber",
    cellStylingHandler: "criticalLogs",
    isDisableColumnFilter: true,
    sx: {
      height: 750,
    },
  };
  return <DataTable {...options} />;
}

function GetColumns({ addClick, viewClick }) {
  return [
    {
      headerName: "Actions",
      field: "Actions",
      sortable: false,
      minWidth: 140,
      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction
              row={row}
              onClick={addClick}
              label="Add"
            />
            <DataGridAction
              row={row}
              onClick={viewClick}
              label="View"
              sx={{ marginLeft: "6px" }}
            />
          </>
        );
      },
    },
    {
      headerName: "Evaluator",
      field: "Evaluator",
      width: 140,
      renderCell: renderCellExpand,
    },
    {
      headerName: "SampleNumber",
      field: "SampleNumber",
      width: 120,
    },

    {
      headerName: "Status",
      field: "AlertStatus",
      width: 90,
    },
    {
      headerName: "Primary 1",
      field: "Primary1",
      width: 150,
    },
    {
      headerName: "Email 1",
      field: "Email1",
      width: 200,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Mobile 1",
      field: "Mobile1",
      width: 130,
    },
    {
      headerName: "Telephone 1",
      field: "Telephone1",
      width: 130,
    },
    {
      headerName: "Customer",
      field: "Customer",
      width: 180,
      renderCell: renderCellExpand,
    },

    {
      headerName: "Site",
      field: "Site",
      width: 150,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Unit",
      field: "Unit",
      width: 150,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Model",
      field: "FullModel",
      width: 200,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Serial",
      field: "Serial",
      width: 100,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Component",
      field: "Component",
      minWidth: 140,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Product",
      field: "Product",
      minWidth: 150,
      renderCell: renderCellExpand,
    },
  ];
}
