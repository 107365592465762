import { Alert, Grid, Typography } from "@mui/material";
import { useReducer } from "react";
import ManageFluids from "./ManageFluids";
import FluidWatch from "./FluidWatch";
import Form, { FormActions } from "../../../components/Form";
import AppDialog from "../../../components/AppDialog";
import useFetch from "../../../hooks/useFetch";
import { DETAILS_APIS } from "../../../common/apis";
import FormFooter from "../../../components/FormFooter";
import appResources from "../../../app-resources/en/Resources.json";
import { FluidWatchIcon, ManagementIcon, SaveCommentsIcon, } from "../../../icons/ButtonIcons";
import { useRoot, useToast } from "../../../rootContext";
import { useState } from "react";
import { useEffect } from "react";
import useMasterData from "../../../hooks/useMasterData";

export default function ViewFluid({ cancel, fluid, fetchFluidsDetails, fluidsTypeList }) {
  const { post } = useFetch();
  const { getMasterData } = useMasterData();

  const { CanDoDataMaintenance, CanManageTestSets } = useRoot();
  const [data, dispatch] = useReducer(FluidReducer, fluid);
  const { showToast, showErrorToast } = useToast();

  const [masterData, setMasterData] = useState();
  const [manageFluidsOpen, setManageFluidsOpen] = useState(false);
  const [fluidWatchOpen, setFluidWatchOpen] = useState(false);

  const actions = {
    cancel: function () {
      cancel && cancel();
    },
    save: async function () {
      const response = await post(DETAILS_APIS.UPDATE_FLUID, {
        ...data,
        Screen: "Fluids",
      });
      if (response > 0) {
        showToast(appResources.FLUID_UPDATED_SUCCESSFULLY_MSG);
        fetchFluidsDetails(0);
      } else {
        showErrorToast(appResources.SOMETHING_WENT_WRONG_MSG);
      }
    },
    manage: function () {
      setManageFluidsOpen(true);
    },
    fluidWatch: function () {
      setFluidWatchOpen(true);
    },
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Form
            sections={GetFormSections(fluidsTypeList, fluid)}
            data={data}
            dispatch={dispatch}
            key={data?.FluidId}
          />
        </Grid>

        <Grid item xs={12}>
          <FormFooter
            buttons={GetFormButtons(data, actions, CanManageTestSets, CanDoDataMaintenance)}
            hideDivider
            gmt={0}
          />
        </Grid>
      </Grid>
      <AppDialog
        open={manageFluidsOpen}
        handleClose={() => setManageFluidsOpen(false)}
        title={`Fluid Management ${fluid.FluidFormatted}`}
        maxWidth="xl"
      >
        <ManageFluids
          SelectedFluid={fluid}
          fetchFluidsDetails={fetchFluidsDetails}
          cancel={() => setManageFluidsOpen(false)}
        />
      </AppDialog>
      <AppDialog
        open={fluidWatchOpen}
        handleClose={() => setFluidWatchOpen(false)}
        title="Fluid Watch"
        maxWidth="xl"
      >
        <FluidWatch
          fluidId={fluid.FluidId}
          fluidRow={fluid}
          setFluidWatchOpen={setFluidWatchOpen}
        />
      </AppDialog>
    </>
  );
}

const GetFormButtons = (data, { cancel, fluidWatch, manage, save }, CanManageTestSets, CanDoDataMaintenance) => [
  {
    onClick: fluidWatch,
    label: "Fluid Watch",
    variant: "outlined",
    startIcon: <FluidWatchIcon />,
  },
  {
    hidden: !CanManageTestSets,
    label: "Manage",
    onClick: manage,
    variant: "outlined",
    startIcon: <ManagementIcon />,
  },
  {
    hidden: !CanDoDataMaintenance,
    variant: "contained",
    label: "Save",
    type: "submit",
    onClick: save,
    startIcon: <SaveCommentsIcon />,
  },
];

function GetFormSections(fluidsTypeList, fluid) {
  return [
    {
      xsCol: 12,
      fields: [
        {
          xsCol: 12,
          group: [
            {
              name: "FluidTypeId",
              label: "Fluid Type",
              type: "dropdown",
              xsCol: 6,
              selectOptions: (fluidsTypeList || []).map((x) => ({
                value: x.FluidTypeId,
                text: x.FluidType,
              })),
              defaultValue: 0,
            },
          ],
        },

        {
          xsCol: 6,
          name: "FluidLevel1",
          label: "Level 1 (e.g. make)",
        },
        {
          xsCol: 6,
          name: "FluidLevel2",
          label: "Level 2 (e.g. type)",
        },

        {
          xsCol: 12,
          component: function () {
            return (
              <Grid xs={12}>
                <Alert severity="info" icon={<></>}>
                  <p>For multigrades use a DASH -, e.g. 15W-40; 85W-140</p>
                  <p>For single grades, use a W, e.g. 10W</p>
                  <p>For ISO grades use the grade only, e.g. 68</p>
                </Alert>
              </Grid>
            );
          },
        },
        {
          xsCol: 6,
          name: "FluidLevel3",
          label: "Level 3 (e.g. grade)",
        },
        {
          xsCol: 6,
          component: function () {
            return (
              <Typography sx={{ mt: 3 }}>
                Formatted: {fluid.FluidFormatted}
              </Typography>
            );
          },
        },
      ],
    },
  ];
}

function FluidReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  return state;
}
