import { Button, Card, Grid, Typography } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useReducer, useState } from "react";
import DataTable, { DataGridAction } from "../../components/DataTable";
import Form, { FormActions } from "../../components/Form";
import useFetch from "../../hooks/useFetch";
import { MASTER_DATA_APIS } from "../../common/apis";
import { SaveIcon } from "../../icons/ButtonIcons";
import Colors from "../../data/colors";
import { useRoot } from "../../rootContext";

export default function Products() {

  const [data, dispatch] = useReducer(SearchFilterReducer, defaultProducts);
  const [masterData, setMasterData] = useState();
  const { get, post } = useFetch();
  const { CanAdministrate } = useRoot();

  async function getProducts() {
    const response = await get(`${MASTER_DATA_APIS.GET_BY_TYPES}?types=Products`);
    setMasterData(response?.Products);
  }

  //use memo
  const filteredData = useMemo(
    () =>
      data?.ShowArchivedFilter
        ? masterData
        : (masterData || []).filter((x) => {
          return !x.IsArchived;
        }),
    [data, masterData]
  );

  const tblOptions = {
    columns: GetColumns(editClick, CanAdministrate),
    dataSource: filteredData || [],
    rowIdProperty: "ProductId",
    isDisableColumnFilter: true,
    cellStylingHandler: "product",
    sx: { height: 500 },
  };

  function editClick(rowData) {
    dispatch({
      payload: {
        ...rowData,
        ShowArchivedFilter: data?.ShowArchivedFilter === true ? true : false,
        ReportColour: rowData?.ReportColour,
      },
      type: EventActions.LOAD_DATA,
    });
  }
  //Save data
  async function saveClick() {
    await post(MASTER_DATA_APIS.EDIT_PRODUCT, {
      ...data,
      Screen: "Edit Product",
    });
    getProducts();
  }

  useEffect(() => {
    document.title = "Products";
    getProducts();
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography sx={{ marginBottom: "16px" }}>
            Edit the details for the product KOWA
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ p: 1 }}>
            <Form
              sections={GetFormSections(saveClick, data, Colors)}
              data={data}
              dispatch={dispatch}
              key={data.ProductId}
            />
          </Card>
        </Grid>

        <Grid item xs={12} sx={{ mt: 2 }}>
          <Typography variant="subtitle2">
            Total Records: <b> {filteredData && filteredData.length}</b>
          </Typography>
          <DataTable {...tblOptions} />
        </Grid>
      </Grid>
    </>
  );
}

function GetColumns(editClick, CanAdministrate) {
  return [
    {
      headerName: "Edit",
      field: "Edit",
      sortable: false,
      minWidth: 120,
      renderCell: function ({ row }) {
        return (
          CanAdministrate === true ? <>
            <DataGridAction row={row} onClick={editClick} label="Edit" />
          </> : <></>
        );
      },
    },
    {
      headerName: "Product",
      field: "Product",
      minWidth: 200,
    },
    {
      headerName: "AutoEval",
      field: "CanAutoEvaluate",
      valueFormatter: ({ value }) => (value ? "Yes" : "No"),
      minWidth: 100,
    },
    {
      headerName: "Evaluate",
      field: "NoEvaluations",
      valueFormatter: ({ value }) => (value ? "No" : "Yes"),
      minWidth: 100,
    },
    {
      headerName: "Archived",
      field: "IsArchived",
      valueFormatter: ({ value }) => (value ? "Yes" : "No"),
      minWidth: 100,
    },
    {
      headerName: "Hidden",
      field: "HideProduct",
      valueFormatter: ({ value }) => (value ? "Yes" : "No"),
      minWidth: 100,
    },
    {
      headerName: "Display",
      field: "DisplayRank",
      minWidth: 100,
      flex: 1,
    },
  ];
}
function GetFormSections(saveClick, data, Colors) {
  return [
    {
      fields: [
        {
          group: [
            {
              name: "Product",
              label: "Product",
              xsCol: 2,
            },

            {
              name: "DisplayProduct",
              label: "Formal",
              xsCol: 2,
            },

            {
              name: "ReportProduct",
              label: "On Report",
              xsCol: 2,
            },

            {
              name: "Colour",
              label: "Colour",
              xsCol: 2,
              type: "dropdown",
              selectOptions: (Colors || []).map((x) => ({
                value: x.TestItemColour,
                text: x.TestItemColour,
              })),
              sx: {
                [`.MuiSelect-select`]: {
                  backgroundColor: data?.Colour,
                  color: data?.Colour ? "white" : "",
                  [`.MuiSvgIcon-root-MuiSelect-icon`]: {
                    color: data?.TestItemColour ? "white" : "",
                  },
                },
                [`.MuiSelect-icon`]: {
                  color: data?.Colour ? "white" : "",
                },
              },
            },
            {
              name: "ReportColour",
              label: "Report Colour",
              xsCol: 2,
              type: "dropdown",
              selectOptions: (Colors || []).map((x) => ({
                value: x.TestItemColour,
                text: x.TestItemColour,
              })),
              // sx: {
              //   [`.MuiSelect-select`]: {
              //     backgroundColor: data?.ReportColour,
              //     color: "white",
              //     [`.MuiSvgIcon-root-MuiSelect-icon`]: {
              //       color: "white",
              //     },
              //   },
              //   [`.MuiSelect-icon`]: {
              //     color: "white",
              //   },
              // },
            },

            {
              group: [
                {
                  name: "HideProduct",
                  label: "Hidden",
                  type: "switch",
                  xsCol: 2,
                },
                {
                  name: "IsArchived",
                  label: "Archived",
                  type: "switch",
                  xsCol: 2,
                },
                {
                  name: "DisplayRank",
                  label: "Rank",
                  xsCol: 2,
                  type: "number",
                },
              ],
            },
            {
              name: "IsNotifyActionsCriticals",
              label: "Notify for Actions and Criticals",
              type: "switch",
            },
            {
              name: "CanAutoEvaluate",
              label: "Use Auto-Evaluation",
              type: "switch",
            },
            {
              name: "IsDefault",
              label: "Default Product",
              type: "switch",
            },
            {
              name: "NoEvaluations",
              label: "No Evaluation is Required",
              type: "switch",
            },
            {
              name: "IsForImport",
              label: "For Importing",
              type: "switch",
            },
            {
              name: "HasWebsite",
              label: "Allow Website Access",
              type: "switch",
            },
            {
              name: "CanDeleteSamples",
              label: "Allow Samples to be Deleted",
              type: "switch",
            },
          ],
        },
        {
          xsCol: 11,
          group: [
            {
              name: "ShowArchivedFilter",
              label: "Show Archived",
              xsCol: 6,
              type: "switch",
            },
          ],
        },
        {
          xsCol: 1,
          group: [
            {
              component: function () {
                return (
                  <Button
                    variant="contained"
                    onClick={saveClick}
                    //  sx={{ mt: "23px" }}
                    startIcon={<SaveIcon />}
                  >
                    Save
                  </Button>
                );
              },
            },
          ],
        },
      ],
    },
  ];
}

const defaultProducts = {
  ShowArchivedFilter: true,
};

const EventActions = {
  LOAD_DATA: "load-data",
};
function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === FormActions.RESET_FORM) {
    return { ...action.payload };
  }
  if (action.type === EventActions.LOAD_DATA) {
    return { ...action.payload };
  }
  return state;
}
