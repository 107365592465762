import { Typography } from "@mui/material";
import GetStepNames from "../../../common/GetStepNames";
import DropdownMenu from "../../../components/DropdownMenu";
import renderCellExpand from "../../../components/DatatableCellExpand";
import NewDataTable from "../../../components/NewDataTable";
import { useEffect, useRef, useState } from "react";

export default function SamplesTable2({
  data,
  rowSelected,
  selectedRow,
  selectedSampleNo,
  rowActionHandler,
}) {
  const [rowSelection, setRowSelection] = useState({});
  const [rowSelectionAll, setRowSelectionAll] = useState({});
  const rowVirtualizerInstanceRef = useRef(null);

  useEffect(() => {
    try {
      setRowSelection(
        { [selectedSampleNo]: true }
      )
      rowVirtualizerInstanceRef?.current?.scrollToIndex?.(selectedRow);
      // const a = document.querySelector('[data-index="' + selectedRow + '"]');
      // setTimeout(a?.scrollIntoViewIfNeeded(), 1500)

    } catch (error) {
      console.log("Message" + error);
    }
  }, [selectedRow, selectedSampleNo]);

  const options1 = {
    rowVirtualizerInstanceRef,
    enableRowVirtualization: true,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enablePagination: false,
    enableGlobalFilter: false,
    editDisplayMode: 'cell',
    enableColumnFilterModes: false,
    enableColumnActions: false,
    enableSorting: false,
    columnVirtualizerOptions: { overscan: 1 },
    rowVirtualizerOptions: { overscan: 5 },
    columns: GetColumns({ rowActionHandler }),
    data: data || [],
    getRowId: (originalRow) => originalRow.SampleNumber,
    sx: {
      height: 290
    },
    muiSelectCheckboxProps: {
      color: 'secondary',
      backgroundColor: "blue"
    },
    onRowSelectionChange: setRowSelection,
    state: { rowSelection },
    muiTablePaperProps: (a) => {
      return {
        sx: {
          border: "1px solid #253FC8",
          borderRadius: "5px",
          padding: "2px",
          [`.MuiTableRow-root:hover td`]: { backgroundColor: "#7030a0 !important", color: "white" },
          [`.MuiTableRow-root.Mui-selected `]: {
            opacity: rowSelectionAll?.HoldTypeId > 0 ? 1 : rowSelectionAll?.AlertRank > 0 ? "0.5!important" : "",
            backgroundColor: rowSelectionAll?.HoldTypeId > 0 ? "#ffff00!important" : ""
            // opacity: "0.5!important"
          }
        }
      }
    },

    muiTableBodyRowProps: ({ row, index }) => (
      {
        //implement row selection click events manually
        onClick: () => {
          const box = document.getElementById('s1');
          box.tabIndex = '-1';
          box.focus();
          rowSelected({ ...row?.original, keyProp: row.index })
          setRowSelection((prev) => ({
            [row.id]: !prev[row.id],
          }))
          setRowSelectionAll(row?.original)
        },
        selected: rowSelection[row.id],
        sx: {
          backgroundColor: row?.original?.HoldTypeId > 0 ? "#daa520 !important" : row?.original?.AlertRank > 0 ? row?.original?.AlertColour + "!important" : row.index % 2 === 1 ? "#e2f2ff" : "",
          opacity: row?.original?.HoldTypeId > 0 ? "" : row?.original?.AlertRank > 0 ? 1 : "",
          color: row?.original?.HoldTypeId > 0 ? "black !important" : row?.original?.AlertRank > 0 ? "white" : "",
          cursor: 'pointer',
        },
        // className: "cls",
      }),

    muiTableBodyCellProps: ({ cell, column, row, table }) => {
      return {
        sx: cell.column.columnDef.accessorKey === "Product"
          ? {
            backgroundColor: row?.original?.ProductId > 0 ? row?.original?.ProductColour + "!important" : "",
            color: row?.original?.ProductId > 0 ? "white" : "black",
            py: 0.25,
            border: "0.25px solid #d0d0d0"
          }
          : cell.column.columnDef.accessorKey === "Step"
            ? {
              backgroundColor: row?.original?.AlertRank > 0 ? row?.original?.AlertColour + "!important" : "cornflowerblue!important",
              color: "white",
              py: 0.25,
              border: "0.25px solid #d0d0d0"
            }
            : {
              // backgroundColor: row?.original?.ProductColour||"white !important",
              color: row?.original?.HoldTypeId > 0 ? "black !important" : row?.original?.AlertRank > 0 ? "white" : "",
              py: "0px !important",
              border: "0.25px solid #d0d0d0",
            },
        // className: "cls", cornflowerblue
      }
    },
  };

  return <>
    <NewDataTable {...options1} />
  </>;
}

const GetColumns = ({ rowActionHandler }) => [
  {
    header: "Sample Number",
    accessorKey: "SampleNumber",
    size: 105,
    Cell: ({ renderedCellValue, row }) => {
      return (
        <>
          <DropdownMenu
            sx={{ py: "3px", px: "3px", color: row?.original?.AlertRank > 0 ? "white" : "" }}
            options={
              row.original.Step === 2 ? labOption : row.original.Step === 8 ? compOption : Options
            }
            onItemSelect={(option) => rowActionHandler(row.original, option)}
          />
          <Typography >{row?.original?.SampleNumber}</Typography>
        </>
      );
    },
  },
  {
    header: "Step",
    accessorKey: "Step",
    Cell: ({ renderedCellValue, row }) => GetStepNames(renderedCellValue).short,
    size: 40,
  },
  {
    header: "Status",
    accessorKey: "AlertStatus",
    size: 50,
  },
  {
    header: "Test Set",
    accessorKey: "TestSet",
    size: 140,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },

  {
    header: "Product",
    accessorKey: "Product",
    valueFormatter: "",
    size: 70,
  },
  {
    header: "Customer",
    accessorKey: "Customer",
    size: 150,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "Site",
    accessorKey: "Site",
    size: 100,
    flex: 1,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "Unit",
    accessorKey: "Unit",
    size: 125,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "Serial",
    accessorKey: "Serial",
    size: 80,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "Component",
    accessorKey: "Component",
    size: 135,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "Fluid",
    accessorKey: "FluidFormatted",
    size: 150,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "Hold",
    accessorKey: "HoldType",
    size: 85,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "Label",
    accessorKey: "Label",
    size: 150,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "Training",
    accessorKey: "Training",
    size: 105,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
];

const Options = [
  { label: "Copy Sample Number", name: "copy-sample-number" },
  { label: "Sample Images", name: "add-sample-images" },
  { label: "View Graphs", name: "view-graphs" },
  { label: "Hold Management", name: "hold-management" },
];

const compOption = [
  { label: "Copy Sample Number", name: "copy-sample-number" },
  { label: "Sample Images", name: "add-sample-images" },
  { label: "View Graphs", name: "view-graphs" },
  { label: "View Sample Report", name: "view-sample-report" },
  { label: `Edit Sample `, name: "edit-sample" },
  { label: "Hold Management", name: "hold-management" },
];
const labOption = [
  { label: "Copy Sample Number", name: "copy-sample-number" },
  { label: "Sample Images", name: "add-sample-images" },
  { label: "View Graphs", name: "view-graphs" },
  { label: `Edit Sample `, name: "edit-sample" },
  { label: "Hold Management", name: "hold-management" },
];
