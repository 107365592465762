import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Fragment, createContext, useContext, useEffect, useState, } from "react";
import { Button, Checkbox, ClickAwayListener, Grid, Typography, useTheme } from "@mui/material";
import useFetch from "../../../hooks/useFetch";
import { TRAY_APIS } from "../../../common/apis";
import AppDialog from "../../../components/AppDialog";
import EditSample from "../../../forms/EditSample";
import { PopUpMenu } from "./PopUpMenu";
import { FilterIcon } from "../../../icons/ButtonIcons";

const Table2Context = createContext();
function useTable2() {
  return useContext(Table2Context);
}

function ProductRow({ row, ...props }) {
  const { expandState } = useTable2();
  const [open, setOpen] = useState(true);

  useEffect(
    function () {
      if (expandState) {
        setOpen(true);
      }
    },
    [expandState]
  );

  return (
    <Fragment>
      <Grid container>
        <DataTable2Row>
          <DataTable2Cell width="35px">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </DataTable2Cell>

          <DataTable2Cell width="35px" />
          <DataTable2Cell style={{ fontWeight: "bold", paddingTop: "8px" }}>
            <Typography variant="subtitle2">
              {row.Product + ":" + row.samples.length}
            </Typography>
          </DataTable2Cell>
        </DataTable2Row>
      </Grid>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {row.samples.map((sample, index) => (
          <SampleRow key={index} sample={sample} {...props} />
        ))}
      </Collapse>
    </Fragment>
  );
}

function SampleRow({ sample, ...props }) {
  const [open, setOpen] = useState(false);
  const { expandState } = useTable2();
  const handleContactsCollapse = () => {
    setOpen(!open);
  };

  useEffect(
    function () {
      if (expandState) {
        setOpen(expandState === "expanded");
      }
    },
    [expandState]
  );

  return (
    <Fragment>
      <DataTable2Row key={sample.SampleNumber}>
        {/* ExpandAll functionality Here */}
        <DataTable2Cell width="35px" />
        <DataTable2Cell width="35px">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleContactsCollapse()}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </DataTable2Cell>
        {SampleColumns.map((col, i) => (
          <DataTable2Cell key={i} {...col}>
            <Typography variant="caption">
              {col.renderCell
                ? col.renderCell({ row: sample, ...col, ...props })
                : sample[col.field]}
            </Typography>
          </DataTable2Cell>
        ))}
      </DataTable2Row>

      <DataTable2Row>
        <DataTable2Cell className="Table2Cell-borderNone" />
        <DataTable2Cell className="Table2Cell-borderNone" />
        <DataTable2Cell flex={1} className="Table2Cell-borderNone">
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 0 }}>
              <Box size="small">
                <DataTable2Header sx={{ border: "none" }}>
                  <DataTable2Row>
                    {ContactColumns.map((col, i) => (
                      <DataTable2Cell
                        key={i}
                        {...col}
                        style={{
                          padding: "5px",
                          backgroundColor: "#9bfc9b",
                        }}
                      >
                        <Typography variant="subtitle2">
                          {col.headerName}
                        </Typography>
                      </DataTable2Cell>
                    ))}
                  </DataTable2Row>
                </DataTable2Header>
                <DataTable2Body>
                  {sample.contacts.map((c) => (
                    <DataTable2Row key={c.ContactId}>
                      {ContactColumns.map((col, i) => (
                        <DataTable2Cell
                          item
                          key={i}
                          {...col}
                          style={{
                            padding: "5px",
                          }}
                        >
                          <DataTable2Cell key={i} {...col}>
                            <Typography variant="caption">
                              {col.renderCell
                                ? col.renderCell({
                                  row: c,
                                  ...col,
                                  ...props,
                                })
                                : c[col.field]}
                            </Typography>
                          </DataTable2Cell>
                        </DataTable2Cell>
                      ))}
                    </DataTable2Row>
                  ))}
                </DataTable2Body>
              </Box>
            </Box>
          </Collapse>
        </DataTable2Cell>
      </DataTable2Row>
    </Fragment>
  );
}

export default function DataTable2({
  rows = [],
  setActionData,
  actionData,
  expandState,
  setSamplesData,
  setRecords,
  ...props
}) {
  const theme = useTheme();
  // To handle Table's Width

  const [anchorEl, setAnchorEl] = useState(null);
  const [openPop, setOpenPop] = useState(false);
  const [headerName, setHeaderName] = useState("")
  const [rowsResults, setRowsResults] = useState([])

  const [searchDataCheckboxIds, setSearchDataCheckboxIds] = useState({});
  const [searchDataCheckbox, setSearchDataCheckbox] = useState({});

  const handleCheckboxIds = (e) => {
    setSearchDataCheckboxIds({ ...searchDataCheckboxIds, [headerName]: e })
  }
  const handleCheckbox = (e) => {
    setSearchDataCheckbox({ ...searchDataCheckbox, [headerName]: e })
  }

  const handleSelect = (name1, name2, list) => {

    if (name1 === "ascending") {
      handleAscending(name2)
    }
    if (name1 === "descending") {
      handleDescending(name2)
    }
    if (name1 === "filtering") {
      handleFiltering(name2, list)
    }
    setOpenPop(false)
    setAnchorEl(null)
  };

  const handleAscending = (names) => {
    let sorted = [];

    const name = names[0].toUpperCase() + names.slice(1)

    sorted = rows.sort((r1, r2) => (r1[name] > r2[name]) ? 1 : (r1[name] < r2[name]) ? -1 : 0);

    handleGrouping(sorted)
  };

  const handleDescending = (names) => {
    let sorted = [];

    const name = names[0].toUpperCase() + names.slice(1)
    sorted = rows.sort((r1, r2) => {

      return ((r1[name] > r2[name]) ? 1 : (r1[name] < r2[name]) ? -1 : 0)
    });
    let reversed = sorted.reverse();

    handleGrouping(reversed)
  };

  const handleFiltering = (names, list) => {
    let a = getDifference(rows, list, names);
    handleGrouping(a)
  };

  function getDifference(array1, array2, name) {
    return array1.filter(object1 => {
      return array2.some(object2 => {
        return object1[name] === object2[name];
      });
    });
  }

  const handleMenuSelect = (event, name) => {
    setHeaderName(name)
    setAnchorEl(event.currentTarget);
    setOpenPop(true);
  }

  const handleGrouping = (rows) => {
    const groups = rows.reduce(function (ac, item) {
      if (!ac[item.Product]) {
        ac[item.Product] = {
          ProductId: item.ProductId,
          Product: item.Product,
          samples: {},
        };
      }
      if (!ac[item.Product].samples[item.SampleNumber]) {
        ac[item.Product].samples[item.SampleNumber] = {
          ...item,
          contacts: [],
        };
      }

      ac[item.Product].samples[item.SampleNumber].contacts.push(item);
      return ac;
    }, {});

    const tempData = Object.keys(groups).map((pName) => {
      const p = groups[pName];
      return {
        ...p,
        samples: Object.keys(p.samples).map((s) => p.samples[s]),
      };
    });
    setActionData({})
    setRowsResults(tempData)
    setSamplesData(tempData)
    let sampleNumberCount = 0;
    tempData.forEach((item) => {
      item.samples.forEach((sample) => {
        sampleNumberCount++;
      });
    });
    setRecords(sampleNumberCount);
  };

  useEffect(() => {
    handleGrouping(rows)
  }, [rows]);

  return (
    <Table2Context.Provider value={{ setActionData, actionData, expandState }}>
      <Grid
        container
        sx={{
          backgroundColor: theme.palette.common.white,
          border: "1px solid",
          borderColor: theme.palette.primary.main,
          borderRadius: 1,
          overflow: "auto",
          maxHeight: "75vh",
          width: "100%",
          height: `${rowsResults.length === 0 ? "250px" : "auto"}`,
        }}
      >
        <DataTable2Header sx={{ position: "absolute", backgroundColor: "white", zIndex: 999 }}>
          <DataTable2Row>
            {/* Empty Header Cell */}
            <DataTable2Cell width="35px" style={{ borderRight: "none" }} />
            {/* Empty Header Cell */}
            <DataTable2Cell width="35px" style={{ borderLeft: "none" }} />
            {/* Sample Header */}

            {SampleColumns.map((col, i) => (
              <>
                <DataTable2Cell key={i} {...col} openPop={openPop}>
                  <Box component="span">
                    <Typography variant="subtitle2" sx={{ fontWeight: "800", }}>
                      {col.headerName}
                    </Typography>
                  </Box>
                  {col?.filterCheck === false ? "" : <Box component="span" onClick={(e) => handleMenuSelect(e, col.field)}>
                    <FilterIcon />
                  </Box>}
                </DataTable2Cell>
              </>
            ))}
            {openPop && <ClickAwayListener onClickAway={() => { setOpenPop(false); setAnchorEl(null) }}>
              <PopUpMenu
                anchorEl={anchorEl}
                openPop={openPop}
                setOpenPop={setOpenPop}
                handleClick={handleSelect}
                nameVal={headerName}
                rowData={rows}
                searchDataCheckboxIds={searchDataCheckboxIds?.[headerName] || []}
                setSearchDataCheckboxIds={handleCheckboxIds}
                searchDataCheckbox={searchDataCheckbox}
                setSearchDataCheckbox={handleCheckbox}
              />
            </ClickAwayListener>}
          </DataTable2Row>
        </DataTable2Header>

        {rowsResults.length === 0 && (
          <Typography
            variant="caption"
            sx={{
              margin: "auto",
            }}
          >
            No Rows
          </Typography>
        )}
        <DataTable2Body sx={{ marginTop: "32px" }}>
          {rowsResults.map((row) => (
            <ProductRow key={row.ProductId} row={row} {...props} />
          ))}
        </DataTable2Body>
      </Grid>
    </Table2Context.Provider>
  );
}

const ContactColumns = [
  {
    headerName: "Username",
    field: "Username",
    width: 250,
  },
  {
    headerName: "Contact",
    field: "DisplayName",
  },
  {
    headerName: "Email",
    field: "EmailFrom",
    renderCell: renderEmailFrom,
    width: 60,
  },
  {
    headerName: "Report",
    field: "SampleReportFrom",
    renderCell: renderSampleReportFrom,
    width: 60,
  },
  {
    headerName: "Excel",
    field: "ExcelFrom",
    renderCell: renderExcelFrom,
    width: 60,
  },
  {
    headerName: "Machine",
    field: "MachineReportFrom",
    renderCell: renderMachineReportFrom,
    width: 60,
  },
  {
    headerName: "SMS",
    field: "SmsFrom",
    renderCell: renderSmsFrom,
    width: 60,
  },
  {
    headerName: "XML",
    field: "XmlFrom",
    renderCell: renderXmlFrom,
    width: 60,
  },
  {
    headerName: "Mail",
    field: "MailFrom",
    renderCell: renderMailFrom,
    width: 60,
  },
];

function RenderReportButton({ row }) {
  const { getFile } = useFetch();

  async function reportClickHandler(row) {
    let fileName = `${row?.Customer} ${row?.Site} ${row?.Unit} ${row?.Component} ${row?.SampleNumber} `
    await getFile(`${TRAY_APIS.REPORT_SAMPLE}/?sampleNo=${row?.SampleNumber}`, fileName);
  }
  return (
    <Button
      size="small"
      sx={{ minWidth: "55px" }}
      variant="outlined"
      onClick={() => reportClickHandler(row)}
      {...{ row }}
    >
      Report
    </Button>
  );
}

function RenderEditButton({ row, field }) {
  const [openEditSample, setOpenEditSample] = useState(false);
  return (
    <>
      <Button
        sx={{ minWidth: "55px" }}
        size="small"
        onClick={() => setOpenEditSample(true)}
        {...{ row }}
        variant="outlined"
      >
        Edit
      </Button>
      <AppDialog
        open={openEditSample}
        handleClose={() => setOpenEditSample(false)}
        title="Edit Sample"
        maxWidth="100vw"
      >
        <EditSample
          sampleNo={row.SampleNumber}
          setOpenEditSample={setOpenEditSample}
        />
      </AppDialog>
    </>
  );
}

function renderActionCheckBox({ row, field }) {
  return <Table2Checkbox {...{ row, field }} />;
}

function renderSmsFrom({ row, field, val }) {
  if (row.SmsFrom != null && row.SmsFrom !== "") {
    return "✔️";
  } else {
    return "";
  }
}

function renderMailFrom({ row, field, val }) {
  if (row.MailFrom != null && row.MailFrom !== "") {
    return "✔️";
  } else {
    return "";
  }
}

function renderEmailFrom({ row, field, val }) {
  if (
    row.EmailFrom != null &&
    row.EmailFrom !== "" &&
    (row.SampleReportFrom == null || row.SampleReportFrom == "") &&
    (row.ExcelFrom == null || row.ExcelFrom == "") &&
    (row.MachineReportFrom == null || row.MachineReportFrom == "")
  ) {
    return "✔️";
  } else {
    row.EmailFrom = null;
    return "";
  }
}

function renderSampleReportFrom({ row, field, val }) {
  if (row.SampleReportFrom != null && row.SampleReportFrom !== "") {
    return "✔️";
  } else {
    return "";
  }
}
function renderExcelFrom({ row, field, val }) {
  if (row.ExcelFrom != null && row.ExcelFrom !== "") {
    return "✔️";
  } else {
    return "";
  }
}
function renderMachineReportFrom({ row, field, val }) {
  if (row.MachineReportFrom != null && row.MachineReportFrom !== "") {
    return "✔️";
  } else {
    return "";
  }
}
function renderXmlFrom({ row, field, val }) {
  if (row.XmlFrom != null && row.XmlFrom !== "") {
    return "✔️";
  } else {
    return "";
  }
}

function Table2Checkbox({ row, field }) {
  const { setActionData, actionData } = useTable2();
  const curRow = actionData[row.SampleNumber] || {};
  function handleChecked() {
    setActionData((curState) => ({
      ...curState,
      [row.SampleNumber]: {
        ...curRow,
        action: curRow.action !== field ? field : "",
      },
    }));
  }
  return (
    <>
      <Checkbox
        disableRipple
        checked={curRow?.action === field}
        onClick={handleChecked}
        sx={{
          [`.MuiSvgIcon-root`]: { fontSize: "18px" },
          width: "53px",
          height: "10px",
        }}
      />
    </>
  );
}
const renderProductColour = ({ row }) => {
  return (
    <DataTable2Cell
      sx={{
        backgroundColor: row.ProductColour,
        minWidth: "100px",
        marginLeft: "-6px",
        minHeight: "30px",
      }}
    >
      <Typography variant="caption" sx={{ color: "white" }}>
        {row.Product}
      </Typography>
    </DataTable2Cell>
  );
};
const renderSampleNumberColour = ({ row }) => {
  return (
    <DataTable2Cell
      sx={{
        backgroundColor: row.AlertColour,
        minWidth: "160px",
        minHeight: "30px",
      }}
    >
      <Typography variant="caption" sx={{ color: "white" }}>
        {row.SampleNumber}
      </Typography>
    </DataTable2Cell>
  );
};
const renderContacts = ({ row }) => {
  return (
    <DataTable2Cell
      sx={{
        backgroundColor: row.Contacts ? "white" : "red",
        minWidth: "160px",
        marginLeft: "-6px",
        minHeight: "30px",
      }}
    >
      <Typography
        variant="caption"
        sx={{ color: row.Contacts ? "black" : "white" }}
      >
        {row.Contacts ? "Yes" : "No"}
      </Typography>
    </DataTable2Cell>
  );
};
const renderPrimary = ({ row }) => {
  return (
    <DataTable2Cell
      sx={{
        backgroundColor: row.PrimaryContact ? "white" : "#800080",
        minWidth: "160px",
        marginLeft: "-6px",
        minHeight: "30px",
      }}
    >
      <Typography
        variant="caption"
        sx={{ color: row.PrimaryContact ? "black" : "white" }}
      >
        {row.PrimaryContact ? "Yes" : "No"}
      </Typography>
    </DataTable2Cell>
  );
};

const SampleColumns = [
  {
    headerName: "Export",
    filterCheck: false,
    field: "export",
    minWidth: 70,
    maxWidth: 70,
    renderCell: renderActionCheckBox,
  },
  {
    headerName: "Hold",
    filterCheck: false,
    field: "hold",
    minWidth: 70,
    maxWidth: 70,
    renderCell: renderActionCheckBox,
  },
  {
    headerName: "Evaluation",
    filterCheck: false,
    field: "eval",
    minWidth: 90,
    maxWidth: 90,
    renderCell: renderActionCheckBox,
  },
  {
    headerName: "Laboratory",
    filterCheck: false,
    field: "lab",
    minWidth: 90,
    maxWidth: 90,
    renderCell: renderActionCheckBox,
  },
  {
    headerName: "Sample Number",
    field: "SampleNumber",
    width: 120,
    renderCell: renderSampleNumberColour,
  },
  {
    headerName: "View",
    filterCheck: false,
    field: "",
    width: 69,
    renderCell: RenderEditButton,
  },
  {
    headerName: "Report",
    filterCheck: false,
    field: "",
    width: 69,
    renderCell: RenderReportButton,
  },
  {
    headerName: "Product",
    field: "Product",
    width: 80,
    renderCell: renderProductColour,
  },
  {
    headerName: "Contacts",
    field: "Contacts",
    width: 80,
    renderCell: renderContacts,
  },
  {
    headerName: "Primary",
    field: "PrimaryContact",
    width: 75,
    renderCell: renderPrimary,
  },
  {
    headerName: "Component",
    field: "Component",
    width: 170,
  },
  {
    headerName: "Unit",
    field: "Unit",
    width: 120,
  },
  {
    headerName: "Site",
    field: "Site",
    width: 150,
  },
  {
    headerName: "Customer",
    field: "Customer",
    width: 300,
    flex: 1,
  },
  {
    headerName: "Technician",
    field: "Evaluator",
    width: 90,
  },
  {
    headerName: "Quiet",
    filterCheck: false,
    field: "quiet",
    minWidth: 60,
    maxWidth: 60,
    renderCell: renderActionCheckBox,
  },
];

function DataTable2Body({ children, sx }) {
  return (
    <Grid
      container
      sx={{
        "& .Table2-row": {
          borderBottom: "1px solid #e0e0e0",
          "& .Table2-cell": {},
        }, ...sx
      }}
    >
      {children}
    </Grid>
  );
}

function DataTable2Header({ children, sx }) {
  return (
    <Box
      sx={{
        fontWeight: "bold",
        "& .Table2-row": {
          borderBottom: "1px solid #e0e0e0",
          "& .Table2-cell": {
            borderRight: "1px solid #e0e0e0",
            height: "36px",
          },
        }, ...sx || {}
      }}
    >
      {children}
    </Box>
  );
}

function DataTable2Row({ children }) {
  return (
    <Grid container flexWrap={"nowrap"} className="Table2-row">
      {children}
    </Grid>
  );
}

function DataTable2Cell({ children, ...props }) {
  if (!props.width && !props.flex) {
  }
  const widthValue = (!props.width && !props.flex && "100px") || props.width;

  return (
    <>
      <Grid
        container
        fontSize={11}
        sx={{
          textAlign: "start",
          overflow: "hidden",
          textOverflow: "ellipsis",
          "&.Table2Cell-borderNone": {
            border: "none",
          },
        }}
        width={widthValue}
        minWidth={widthValue}
        maxWidth={widthValue}
        {...props}
        paddingX="7px"
        className="Table2-cell"
        alignItems="center"
      >
        {children}
      </Grid>
    </>
  );
}