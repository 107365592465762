import { Box, Button, Card, Grid, Typography } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useState } from "react";
import { FLEET_APIS } from "../../../common/apis";
import SwitchInput from "../../../components/SwitchInput";
import TextBox from "../../../components/TextBox";
import useFetch from "../../../hooks/useFetch";
import QuickMergeSitesTable from "./QuickMergeSitesTable";
import QuickMergeToSitesTable from "./QuickMergeToSitesTable";
import { SaveIcon, } from "../../../icons/ButtonIcons";
import { useRoot } from "../../../rootContext";

export default function QuickMergeSites() {

  const { get, post } = useFetch();
  const { CanDoDataMaintenance } = useRoot();

  const [mergeSites, setMergeSites] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedToFluidRows, setSelectedToFluidRows] = useState([]);
  const [isPreserveContacts, setIsPreserveContacts] = useState(true);
  const [newSite, setNewSite] = useState();
  //call api for loading data

  useEffect(function () {
    document.title = "Quick Merge Sites";
    getSitesAllData();
  }, []);

  async function getSitesAllData() {
    const response = await get(FLEET_APIS.SITES_ALL_NEW);
    if (response?.length > 0) {
      setMergeSites(response);
    }
  }

  const rowsSelectedHandler = (selection) => {
    const isExisting = selectedRows.includes(selection);
    let newArr = [];
    if (isExisting === true) {
      newArr = selectedRows.filter(item => item.SiteId !== selection.SiteId)
    }
    else {
      newArr = [...selectedRows, selection]
    }
    setSelectedRows(newArr);
    // setSelectedRows(selection);
  };

  const rowToFluidSelectedHandler = (selection) => {
    setSelectedToFluidRows(selection);
    setNewSite(selection.Site);
  };

  const filteredData = useMemo(
    function () {
      return mergeSites;
    },
    [mergeSites]
  );

  async function saveClicked() {
    await post(FLEET_APIS.MERGE_SITES, {
      FromSiteId: selectedRows?.map((site) => site.SiteId),
      ToSiteId: selectedToFluidRows.SiteId,
      PreserveContacts: isPreserveContacts,
      OldSite: selectedToFluidRows.Site,
      Site: newSite,
      CustomerId: selectedToFluidRows.CustomerId,
      Screen: "Merge Unit Makes",
    });
    await getSitesAllData();
    setSelectedRows([]);
    setSelectedToFluidRows([]);
  }

  const filter = useMemo(() => {
    const selectedRowsProducts = selectedRows.map((site) => site.Product);
    const selectedRowsCustomers = selectedRows.map((site) => site.Customer);
    const selectedRowsSiteIds = selectedRows.map((site) => site.SiteId);

    const allProductsMatch = selectedRowsProducts.every(
      (product) => product === selectedToFluidRows.Product
    );

    const allSiteIdsMatch = selectedRowsSiteIds.some(
      (siteId) => siteId === selectedToFluidRows.SiteId
    );

    const allCustomersMatch = selectedRowsCustomers.every(
      (customer) => customer === selectedToFluidRows.Customer
    );

    const warnings = [];
    if (!allProductsMatch) {
      warnings.push("You have selected different FROM and TO Products");
    }
    if (allSiteIdsMatch) {
      warnings.push("You have selected the same FROM and TO Site");
    }
    if (!allCustomersMatch) {
      warnings.push("You have selected different FROM and TO Customers");
    }

    return warnings;
  }, [selectedRows, selectedToFluidRows]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="subtitle2">
            Total Records: <b> {filteredData && filteredData.length}</b>
          </Typography>
          <QuickMergeSitesTable
            rowsSelected={rowsSelectedHandler}
            data={filteredData}
          />
        </Grid>
        <Grid item xs={4} mt={2}>
          {selectedRows?.length > 0 ? (
            <Card sx={{ p: 1 }}>
              {selectedToFluidRows.Product ? (
                Object.keys(filter).length > 0 ? (
                  filter.map((x, index) => (
                    <Typography color={"red"} key={index}>
                      {x}
                    </Typography>
                  ))
                ) : (
                  <Typography>Please select both From and To Sites</Typography>
                )
              ) : (
                <Typography>Please select both From and To Sites</Typography>
              )}
              <Grid
                item
                xs={8}
                sx={{ mt: 1, mb: 2 }}
              >
                <TextBox
                  label="TO Site"
                  value={newSite}
                  onChange={(e) => setNewSite(e.target.value)}
                />
              </Grid>
              <Grid item sx={{ mb: 2 }}>
                <SwitchInput
                  label="Keep FROM Contacts"
                  value={isPreserveContacts || false}
                  className="green"
                  onChange={(e) => setIsPreserveContacts(e.target.checked)}
                />
              </Grid>

              <Typography>From Site:</Typography>

              <Box sx={{ maxHeight: 600, overflowY: "auto" }}>
                {selectedRows &&
                  selectedRows.map((item, index) => {
                    return (
                      <Typography
                        variant="body2"
                        sx={{ m: 1 }}
                        key={index}
                      >
                        {index + 1}.{" "}
                        {item.Product + " " + item.Customer + " " + item.Site + " (" + item.SiteId + ")"}
                      </Typography>
                    );
                  })}
              </Box>

              <Typography sx={{ mt: 1 }}>To Site:</Typography>
              <Typography sx={{ m: 1 }}>
                {selectedToFluidRows.Product &&
                  selectedToFluidRows.Product + " " + selectedToFluidRows.Customer + " " + selectedToFluidRows.Site + " (" + selectedToFluidRows.SiteId + ")"}
              </Typography>

              {CanDoDataMaintenance && <Button
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={saveClicked}
                disabled={!selectedToFluidRows.Product}
              >
                Save and Merge
              </Button>}
            </Card>
          ) : (
            <Card sx={{ p: 1, height: 745 }}>
              <Typography>Please select both From and To Sites</Typography>
            </Card>
          )}
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2">
            Total Records: <b> {mergeSites?.length}</b>
          </Typography>
          <QuickMergeToSitesTable
            rowSelected={rowToFluidSelectedHandler}
            data={mergeSites}
          />
        </Grid>
      </Grid>
    </>
  );
}
