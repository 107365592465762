import React, { useEffect, useState } from "react";
import { useRoot } from "../rootContext";
import {
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { MANAGEMENT_APIS, MASTER_DATA_APIS, } from "../common/apis";
import useFetch from "../hooks/useFetch";
import { SaveIcon } from "../icons/ButtonIcons";
import moment from "moment";

const LabDialog = ({ setDialog, getLab, setSelectedLabID }) => {
  const { get, } = useFetch();
  const [labData, setLabData] = useState([]);
  const [checked, setChecked] = useState(false);
  const [labID, setLabID] = useState();
  const [selectedLabName, setSelectedLabName] = useState("");
  const { setUser, user } = useRoot();

  const handleChangeRadio = (event) => {
    setLabID(Number(event.target.value));
    setSelectedLabName(event.target.name);
  };

  const handleDefaultChecked = (event) => {
    setChecked(event.target.checked);
  };

  // To get all Laboratories dynamically
  useEffect(() => {
    (async () => {
      const response = await get(`${MASTER_DATA_APIS.GET_BY_TYPES}?types=Laboratories`);
      setLabData(response.Laboratories || []);

    })();
    setLabID(user.DefaultLaboratoryId);
    setSelectedLabName(user.DefaultLaboratory);
  }, []);

  async function handleSave() {
    if (selectedLabName) {
      setSelectedLabID && setSelectedLabID(labID);
      getLab && getLab(selectedLabName);
    }

    setUser({ ...user, DefaultLaboratory: selectedLabName, DefaultLaboratoryId: labID });
    localStorage.setItem("local-labId", JSON.stringify({ DefaultLaboratoryId: labID, DefaultLaboratory: selectedLabName, CreationDate: moment.utc().format("YYYYMMDD") }))

    if (checked) {
      // user.DefaultLaboratoryId = labID;
      // user.DefaultLaboratory = selectedLabName;
      await get(`${MANAGEMENT_APIS.CHANGE_LABORATORY}?LaboratoryId=${labID}`)
      setUser({ ...user, DefaultLaboratoryId: labID, DefaultLaboratory: selectedLabName, RealDefaultLaboratory: selectedLabName, RealDefaultLaboratoryId: labID });
      localStorage.setItem("local-labId", JSON.stringify({ DefaultLaboratoryId: labID, DefaultLaboratory: selectedLabName, RealDefaultLaboratory: selectedLabName, RealDefaultLaboratoryId: labID, CreationDate: moment.utc().format("YYYYMMDD") }))
    }

    setDialog((prevState) => ({
      ...prevState,
      open: false,
    }));
  }

  return (
    <>
      <Typography sx={{ my: 1 }}>
        Select the Laboratory that you wish to change to:
      </Typography>

      <Card
        sx={{
          p: 1.5,
          minWidth: "33vw",
          minHeight: "130px",
          overflow: "auto",
        }}
      >
        {labData?.length > 0 ? (
          <Grid container flex spacing={3} padding={0.5}>
            <Grid item marginTop={"4.5px"}>
              <Typography>Laboratory</Typography>
            </Grid>

            <Grid item>
              <FormControl>
                <RadioGroup
                  aria-labelledby="radio-buttons-group-label"
                  // defaultValue={defaultLab}
                  value={labID}
                  name="radio-buttons-group"
                  onChange={handleChangeRadio}
                >
                  {labData.map((lab, i) => {
                    return (
                      <FormControlLabel
                        key={i}
                        name={lab.Laboratory}
                        value={lab.LaboratoryId}
                        control={<Radio />}
                        label={
                          user.RealDefaultLaboratoryId === lab.LaboratoryId
                            ? `${lab?.Laboratory} (default)`
                            : lab?.Laboratory
                        }
                      />
                    );
                  })}
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "inline",
                alignItems: "start",
              }}
            >
              <Checkbox
                disableRipple
                onChange={handleDefaultChecked}
                disabled={labID === user.RealDefaultLaboratoryId}
              />
              <Typography
                variant="subtitle2"
                sx={{ marginLeft: "-4px", marginTop: "7.5px" }}
              >
                Set as default
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Typography sx={{ textAlign: "center", fontWeight: "bold" }}>
            Loading...
          </Typography>
        )}
      </Card>
      <Grid container sx={{ mt: 1.5 }} justifyContent={"end"}>
        <Grid item>
          <Button onClick={handleSave} startIcon={<SaveIcon />}>
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default LabDialog;
