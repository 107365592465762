import { Button, Card, Grid, } from "@mui/material";
import { useEffect, useReducer } from "react";
import Form, { FormActions } from "../../../components/Form";
import { FLEET_APIS, } from "../../../common/apis";
import useFetch from "../../../hooks/useFetch";
import { SaveIcon, } from "../../../icons/ButtonIcons";
import useMasterData from "../../../hooks/useMasterData";

export default function AddComponent({
  TemplateId,
  componentData,
  refreshComponents,
  setDialog,
  setOpenSelectComponent,
}) {
  const [data, dispatch] = useReducer(SearchFilterReducer, componentData || {});
  const { post } = useFetch();
  const { masterData } = useMasterData("FluidTypes,ComponentTypes,ComponentLocations");

  useEffect(
    function () {
      (async function () {
        if (!componentData && masterData.FluidTypes) {
          const defFluid = masterData.FluidTypes.find((x) => x.IsDefault) || {};
          const defCompType =
            masterData.ComponentTypes.find((x) => x.IsDefault) || {};
          dispatch({
            type: ComponentFormActions.SET_DEFAULTS,
            payload: {
              FluidTypeId: defFluid.FluidTypeId,
              ComponentTypeId: defCompType.ComponentTypeId,
            },
          });
        }
      })();
    },
    [masterData]
  );

  async function saveClick() {
    await post(FLEET_APIS.SAVE_TEMPLATE_COMPONENT, {
      TemplateComponentId: componentData && componentData?.TemplateComponentId,
      TemplateId: TemplateId,
      ...data,
      Screen: "Machine Templates",
    });
    refreshComponents(TemplateId);

    setOpenSelectComponent && setOpenSelectComponent(false);
    setDialog && setDialog((prevState) => ({
      ...prevState,
      open: false,
    }));
  }

  return (
    <Card sx={{ p: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Form
            sections={FormSections}
            masterData={masterData}
            data={data}
            dispatch={dispatch}
            key={data._ts_key}
          />
        </Grid>
        <Grid item container xs={12} justifyContent="flex-end">
          <Button onClick={saveClick} startIcon={<SaveIcon />}>
            Save
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
}

const FormSections = [
  {
    fluid: true,
    fields: [
      {
        name: "Component",
        label: "Component",
        xsCol: 6,
      },
      {
        name: "FluidTypeId",
        label: "Fluid Type",
        type: "dropdown",
        xsCol: 6,
        required: true,
        optionConfigs: {
          name: "FluidTypes",
          textProp: "FluidType",
          valProp: "FluidTypeId",
        },
      },
      {
        name: "ComponentTypeId",
        label: "Component Type",
        type: "dropdown",
        xsCol: 6,
        required: true,
        optionConfigs: {
          name: "ComponentTypes",
          textProp: "ComponentType",
          valProp: "ComponentTypeId",
        },
      },
      {
        name: "ComponentLocationId",
        label: "Location",
        type: "dropdown",
        xsCol: 6,
        required: true,
        optionConfigs: {
          name: "ComponentLocations",
          textProp: "Location",
          valProp: "LocationId",
        },
      },
    ],
  },
];

const ComponentFormActions = {
  SET_DEFAULTS: "set-defaults",
};

function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === ComponentFormActions.SET_DEFAULTS) {
    return { ...action.payload, _ts_key: Date.now() };
  }
  return state;
}
