import { useState } from "react";
import renderCellExpand from "../../../components/DatatableCellExpand";
import NewDataTable from "../../../components/NewDataTable";

export default function TemplatesTable({ data, rowSelected }) {
  const [rowSelection, setRowSelection] = useState({});

  const options = {
    columns: fromSiteColumns,
    dataSource: (data || []).map((x, i) => ({ ...x, keyProp: i, })),
    getRowId: (originalRow) => (originalRow?.keyProp),
    enablePagination: false,
    sx: { height: "83dvh" },
    enableColumnActions: false,

    muiTableBodyProps: {
      sx: (theme) => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "#e2f2ff" },
        // backgroundColor: "inherit",//
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "#e2f2ff" },
      }),
    },
    muiTableBodyRowProps: ({ row }) => ({
      //implement row selection click events manually
      onClick: () => {
        setRowSelection((prev) => ({
          [row?.original.keyProp]: !prev[row?.original.keyProp],
        }))
        rowSelected(row?.original);
      },
      selected: rowSelection[row?.original?.keyProp],
      sx: { cursor: 'pointer', },
    }),
  }

  return (
    <NewDataTable {...options} />
  )
}

const fromSiteColumns = [
  {
    header: "Template",
    accessorKey: "Template",
    size: 150,
    renderCell: renderCellExpand,
  },
];
