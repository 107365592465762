import { useState } from "react";
import NewDataTable from "../../../components/NewDataTable";

export default function FluidsTable({ data, rowSelected, }) {
  const [rowSelection, setRowSelection] = useState({});

  // useEffect(() => {
  //   if (data[0]?.FluidId > 0) {
  //     setRowSelection((prev) => ({
  //       [data[0]?.FluidId]: true,
  //     }))
  //   }
  // }, [data]);

  const options1 = {
    columns: Columns1,
    dataSource: data || [],
    getRowId: (originalRow) => originalRow.FluidId,
    enablePagination: false,
    sx: { height: 800 },
    enableColumnActions: false,
    onRowSelectionChange: setRowSelection,
    muiSelectCheckboxProps: {
      color: 'secondary',
      backgroundColor: "blue"
    },
    muiTableBodyProps: {
      sx: (theme) => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "#e2f2ff" },
        // backgroundColor: "inherit",//
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "#e2f2ff" },
      }),
    },
    muiTableBodyRowProps: ({ row }) => ({
      //implement row selection click events manually
      onClick: () => {
        rowSelected(row.original);
        setRowSelection((prev) => ({
          [row.id]: !prev[row.id],
        }))
      },
      selected: rowSelection[row.id],
      sx: {
        cursor: 'pointer',
      },
    }),
    state: { rowSelection },
  };

  // return <DataTable {...options} />;
  return <NewDataTable {...options1} />;
}

const Columns1 = [
  {
    header: "Type",
    accessorKey: "FluidType",
    maxSize: 150,
    size: 150,
    sortable: false,
    enableEditing: false,
  },
  {
    header: "Fluid",
    accessorKey: "FluidFormatted",
    maxSize: 150,
    size: 150,
    sortable: false,
    enableEditing: false,
  },
];